import React, { useEffect } from 'react';
import { Button, Modal } from 'antd';
import Loader from '../../components/Loader';
import { useParams } from 'react-router-dom';
import { useProjectDeleteFile } from '../../providers/useProjectFiles';
import { useGlobalState } from '../../context/GlobalContext';

interface IDeleteFileModal {
   isModalOpen: boolean;
   onCancel: () => void;
   isFolder: boolean;
   path: string;
   projectInfoRefetch: () => void;
}
const DeleteFileModal: React.FC<IDeleteFileModal> = (props) => {
   const { isFolder, isModalOpen, onCancel, path, projectInfoRefetch } = props;
   const { messageApi } = useGlobalState();
   const { id: projectName } = useParams();

   const {
      mutate: deleteFile,
      data: deleteFileData,
      isPending: isDeleteFileLoading,
      isError: isDeleteFileError,
      error: deleteFileError,
   } = useProjectDeleteFile();

   useEffect(() => {
      if (isDeleteFileError) {
         showError(`${deleteFileError.message}`);
      }
      console.log(deleteFileData);
      if (deleteFileData !== undefined && deleteFileData !== null) {
         if (deleteFileData.ack === 'ok') {
            projectInfoRefetch();
            onCancel();
         }
      }
   }, [isDeleteFileLoading, deleteFileData]);

   const showError = (message: string): void => {
      void messageApi?.open({
         type: 'error',
         content: message,
      });
   };

   const handleCreateProject = (): void => {
      deleteFile({ path, projectName: projectName! });
   };

   return (
      <>
         {isDeleteFileLoading && <Loader />}
         <Modal
            title={`Delete ${isFolder ? 'Folder' : 'File'}?`}
            open={isModalOpen}
            onCancel={() => {
               onCancel();
            }}
            footer={(_, { OkBtn, CancelBtn }) => (
               <>
                  <Button type="text" onClick={onCancel}>
                     Cancel
                  </Button>

                  <Button
                     key="submit"
                     type="primary"
                     onClick={handleCreateProject}
                     danger
                  >
                     Delete
                  </Button>
               </>
            )}
            width={'25%'}
         >
            <div className="flex flex-col items-center ">
               Are you sure you want to delete this{' '}
               {isFolder ? 'folder and all files in it' : 'file'}? this cannot
               be undone.
            </div>
         </Modal>
      </>
   );
};

export default DeleteFileModal;
