import { message } from 'antd';
import { useEffect } from 'react';

interface ILoader {
   description?: string;
}
const Loader: React.FC<ILoader> = ({ description = 'Executing...' }) => {
   const [messageApi, contextHolder] = message.useMessage();

   useEffect(() => {
      messageApi.destroy();
      void messageApi.open({
         type: 'loading',
         content: description,
         duration: 0,
      });
   }, []);

   return <>{contextHolder}</>;
};

export default Loader;
