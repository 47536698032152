import {
   type UseMutationResult,
   useQuery,
   type UseQueryResult,
   useMutation,
} from '@tanstack/react-query';
import { usePapaParse } from 'react-papaparse';
import {
   type ICreateDataSourceResponse,
   type ICreateDataSourcePayload,
   type ITestConnectionResponse,
   type ITestConnectionPayload,
   type IDataSourceResponse,
   type IUpdateDataSourcePayload,
   type IRunQueryPayload,
   type IRunQueryResponse,
   type IRunResultsPath,
} from './models/IDataSource';
import {
   apiDelete,
   apiGet,
   apiGetJson,
   apiPost,
   apiPut,
} from '../api/ApiService';
import {
   ACTION_CONNECTORS,
   ACTION_DATA,
   ACTION_DATA_SOURCES,
   ACTION_DATA_SOURCES_SEARCH,
   ACTION_DRIVERS,
   ACTION_METADATA,
} from '../api/ApiRoutes';
import ApiConstants from '../api/ApiConstants';
import {
   type IDriver,
   type IConnector,
   type IProperty,
} from './models/IConnector';
import { CONNECTORS, DRIVERS, PROPERTIES } from './mock_data';
import { getConnectorIconbyType, getDriverIconbyType } from '../utils/Utils';
import {
   type IMetaDataFieldsPayload,
   type IMetaDataFieldsResponse,
} from './models/IMetaDataFields';
import { type IProjectSearchJson } from '../api/models/IProject';

// const dataSourcesFromJson = (json: IDataSourceJson[]): IDataSource[] =>
//    json.map((ds) => ({
//       id: ds.id,
//       name: ds.name,
//       type: ds.dbt_type,
//       schemaInfo:
//          ds.catalogs
//             ?.filter(
//                (sc) =>
//                   (!ds.database &&
//                      !ds.name &&
//                      sc.database?.toLowerCase() === 'null') ||
//                   (!ds.database &&
//                      !ds.name &&
//                      ds.dbt_type.toLowerCase() === 'bigquery') ||
//                   sc.database?.toLowerCase() === ds.database?.toLowerCase() ||
//                   sc.database?.toLowerCase() === ds.name?.toLowerCase(),
//             )
//             .map((s) => ({
//                schemas: s.schemas.filter(
//                   (c) => c?.toLowerCase() !== 'information_schema',
//                ),
//                name: s.database,
//             })) ?? [],
//       databaseName: ds.database,
//       schema: ds.schema,
//       username: ds.username,
//       password: ds.password,
//       port: ds.port,
//       host: ds.host,
//       lastUpdated: '2024-04-05T07:14:52.27591Z',
//    }));

export const useDataSources = (): UseQueryResult<
   IDataSourceResponse | null,
   Error
> => {
   return useQuery({
      queryKey: ['datasources', ACTION_DATA_SOURCES_SEARCH],
      queryFn: async () => {
         try {
            const jsonResponse = await apiPost({
               path: `${ACTION_DATA_SOURCES_SEARCH}/search`,
               url: ApiConstants.datasourceBaseUrl,
               body: {
                  query: '',
                  page: 0,
                  size: 10,
               },
               headers: {},
            });
            if (!jsonResponse || jsonResponse.status !== 200) {
               throw new Error('Api Error');
            }
            const data = jsonResponse.data as IDataSourceResponse;
            data.results.forEach((dataSource) => {
               dataSource.icon = getConnectorIconbyType(dataSource.type);
            });
            return data;
         } catch (error: any) {
            let errText = 'DataSources failed';
            if (error?.response?.data?.error !== undefined) {
               errText = error.response.data.error;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
      },
      retry: false,
   });
};

export function useTestConnection(): UseMutationResult<
   ITestConnectionResponse,
   Error,
   ITestConnectionPayload
> {
   const runTestConnection = async (
      payload: ITestConnectionPayload,
   ): Promise<ITestConnectionResponse> => {
      const payloadData = { ...payload };
      delete payloadData.connector_id;
      console.log('useTestConnection', payloadData);
      try {
         const response = await apiPost({
            path: `${ACTION_CONNECTORS}/${payload.connector_id}/test`,
            url: ApiConstants.connectorBaseUrl,
            body: payloadData,
         });
         if (response.status === 200) {
            return response.data as ITestConnectionResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Datasource Test Connection Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<ITestConnectionResponse, Error, ITestConnectionPayload>({
      mutationFn: runTestConnection,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useCreateDataSource(): UseMutationResult<
   ICreateDataSourceResponse,
   Error,
   ICreateDataSourcePayload
> {
   const createDataSource = async (
      payload: ICreateDataSourcePayload,
   ): Promise<ICreateDataSourceResponse> => {
      try {
         const response = await apiPost({
            path: `${ACTION_DATA_SOURCES}/${payload.connector_id}/data-source`,
            url: ApiConstants.connectorBaseUrl,
            body: payload,
         });
         if (response.status === 200) {
            return response.data as ICreateDataSourceResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Data Source creation Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<
      ICreateDataSourceResponse,
      Error,
      ICreateDataSourcePayload
   >({
      mutationFn: createDataSource,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useUpdateDataSource(): UseMutationResult<
   ICreateDataSourceResponse,
   Error,
   IUpdateDataSourcePayload
> {
   const updateDataSource = async (
      payload: IUpdateDataSourcePayload,
   ): Promise<ICreateDataSourceResponse> => {
      try {
         const response = await apiPut({
            path: `${ACTION_DATA_SOURCES}/${payload.connector_id}/data-source`,
            url: ApiConstants.connectorBaseUrl,
            body: payload,
         });
         if (response.status === 200) {
            return response.data as ICreateDataSourceResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Data Source creation Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<
      ICreateDataSourceResponse,
      Error,
      IUpdateDataSourcePayload
   >({
      mutationFn: updateDataSource,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useDeleteDataSource(): UseMutationResult<any, Error, string> {
   const deleteDataSource = async (payload: string): Promise<any> => {
      try {
         // project search first
         const initialSearchData = {
            page: 0,
            size: 5,
            query: '*',
            source_id: `${payload}`,
         };
         const responseApi = await apiPost<IProjectSearchJson>({
            path: `api/v1/project/search`,
            url: ApiConstants.workspaceBaseUrl,
            body: initialSearchData,
         });
         console.log('**Delete Project Search', responseApi, payload);
         if (
            !(
               responseApi?.data?.results?.length &&
               responseApi?.data?.results?.length > 0
            )
         ) {
            const response = await apiDelete({
               path: `${ACTION_DATA_SOURCES_SEARCH}/${payload}`,
               url: ApiConstants.datasourceBaseUrl,
               body: null,
            });

            console.log('**Delete Project Delete', response, payload);
            if (response.status === 200) {
               return response.data;
            } else {
               // TODO: add error handling when any error occur from server
               throw new Error('Api Error: Delete Failed');
            }
         } else {
            throw new Error(
               `To Delete a datasource first you need to delete projects created with this datasouce.`,
            );
         }
      } catch (e: any) {
         console.log('**Delete Project Ex', e, payload);
         let errText = 'Data Source delete Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<any, Error, string>({
      mutationFn: deleteDataSource,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export const useGetConnectors = (): UseQueryResult<
   IConnector[] | null,
   Error
> => {
   return useQuery({
      queryKey: ['connectors'],
      queryFn: async () => {
         try {
            const jsonResponse = await apiGet({
               path: ACTION_CONNECTORS,
               url: ApiConstants.connectorBaseUrl,
               body: null,
               headers: {},
            });
            if (!jsonResponse || jsonResponse.status !== 200) {
               throw new Error('Api Error');
            }
            const data = jsonResponse.data as IConnector[];
            data.forEach((connector) => {
               connector.icon =
                  connector.icon ?? getConnectorIconbyType(connector.type);
            });
            return data;
         } catch (error: any) {
            return CONNECTORS;
            // let errText = 'Connectors failed';
            // if (error?.response?.data?.error !== undefined) {
            //    errText = error.response.data.error;
            // } else if (error.message !== undefined) {
            //    errText = error.message;
            // }
            // if (errText === 'Network Error' || errText === 'timeout exceeded') {
            //    throw new Error(`Please check your internet connection`);
            // } else {
            //    throw new Error(errText);
            // }
         }
      },
   });
};

export const useGetDrivers = (
   connectorId: string,
): UseQueryResult<IDriver[] | null, Error> => {
   return useQuery({
      queryKey: ['drivers', connectorId],
      queryFn: async () => {
         if (
            connectorId === null ||
            connectorId === undefined ||
            connectorId === ''
         )
            return null;
         try {
            const jsonResponse = await apiGet({
               path: `${ACTION_DRIVERS}?cid=${connectorId}`,
               url: ApiConstants.connectorBaseUrl,
               body: null,
               headers: {},
            });
            if (!jsonResponse || jsonResponse.status !== 200) {
               throw new Error('Api Error');
            }
            const data = jsonResponse.data as IDriver[];
            data.forEach((driver) => {
               driver.icon = driver.icon ?? getDriverIconbyType(driver.name);
            });
            return data;
         } catch (error: any) {
            return DRIVERS;

            // let errText = 'Drivers failed';
            // if (error?.response?.data?.error !== undefined) {
            //    errText = error.response.data.error;
            // } else if (error.message !== undefined) {
            //    errText = error.message;
            // }
            // if (errText === 'Network Error' || errText === 'timeout exceeded') {
            //    throw new Error(`Please check your internet connection`);
            // } else {
            //    throw new Error(errText);
            // }
         }
      },
   });
};

export const useGetProperties = (
   connectorId: string,
   connectorVersionId: string,
): UseQueryResult<IProperty[] | null, Error> => {
   return useQuery({
      queryKey: ['Properties', connectorId, connectorVersionId],
      queryFn: async () => {
         if (
            connectorId === null ||
            connectorId === '' ||
            connectorVersionId === null ||
            connectorVersionId === ''
         )
            return null;
         try {
            const jsonResponse = await apiGet({
               path: `${ACTION_CONNECTORS}/${connectorId}?version=${connectorVersionId}`,
               url: ApiConstants.connectorBaseUrl,
               body: null,
               headers: {},
            });
            if (!jsonResponse || jsonResponse.status !== 200) {
               return PROPERTIES;
               // throw new Error('Api Error');
            }
            const data = jsonResponse.data as IProperty[];
            if (data.length <= 0) return PROPERTIES;
            return data;
         } catch (error: any) {
            return PROPERTIES;
            // if (error?.response?.data?.error !== undefined) {
            //    errText = error.response.data.error;
            // } else if (error.message !== undefined) {
            //    errText = error.message;
            // }
            // if (errText === 'Network Error' || errText === 'timeout exceeded') {
            //    throw new Error(`Please check your internet connection`);
            // } else {
            //    throw new Error(errText);
            // }
         }
      },
   });
};

export function useMetaData(): UseMutationResult<
   IRunQueryResponse,
   Error,
   IRunQueryPayload
> {
   const runQuery = async (
      payload: IRunQueryPayload,
   ): Promise<IRunQueryResponse> => {
      console.log('useMetaData', payload);
      try {
         const response = await apiPost({
            path: `${ACTION_METADATA}/objects`,
            url: ApiConstants.connectorBaseUrl,
            body: payload,
         });
         console.log('useMetaData 2', response);
         if (response?.status === 200) {
            return response.data as IRunQueryResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Datasource Test Connection Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<IRunQueryResponse, Error, IRunQueryPayload>({
      mutationFn: runQuery,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useMetaDataFields(): UseMutationResult<
   IMetaDataFieldsResponse,
   Error,
   IMetaDataFieldsPayload
> {
   const runMetaDataFields = async (
      payload: IMetaDataFieldsPayload,
   ): Promise<IMetaDataFieldsResponse> => {
      try {
         const response = await apiPost({
            path: `${ACTION_METADATA}/fields`,
            url: ApiConstants.connectorBaseUrl,
            body: payload,
         });
         if (response?.status === 200) {
            return response.data as IMetaDataFieldsResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Datasource Test Connection Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<IMetaDataFieldsResponse, Error, IMetaDataFieldsPayload>({
      mutationFn: runMetaDataFields,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useRunQuery(): UseMutationResult<
   IRunQueryResponse,
   Error,
   IRunQueryPayload
> {
   const runQuery = async (
      payload: IRunQueryPayload,
   ): Promise<IRunQueryResponse> => {
      console.log('useRunQuery', payload);
      try {
         const response = await apiPost({
            path: `${ACTION_DATA}/read`,
            url: ApiConstants.connectorBaseUrl,
            body: payload,
         });
         console.log('useRunQuery 2', response);
         if (response?.status === 200) {
            return response.data as IRunQueryResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Datasource Test Connection Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<IRunQueryResponse, Error, IRunQueryPayload>({
      mutationFn: runQuery,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useResultPathURL(): UseMutationResult<
   IRunResultsPath,
   Error,
   string
> {
   const runResultsPath = async (payload: string): Promise<IRunResultsPath> => {
      console.log('useResultPathURL', payload);
      try {
         const response = await apiGet({
            path: `${ACTION_DATA}?path=${payload}`,
            url: ApiConstants.connectorBaseUrl,
            body: {},
         });
         console.log('useResultPathURL 2', response);
         if (response?.status === 200) {
            return response.data as IRunResultsPath;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Datasource Test Connection Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<IRunResultsPath, Error, string>({
      mutationFn: runResultsPath,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useResultsPreviewData(): UseMutationResult<any, Error, string> {
   const { readRemoteFile } = usePapaParse();

   const runQuery = async (payload: string): Promise<any> => {
      const fileDataPromise = new Promise((resolve, reject) => {
         try {
            readRemoteFile(payload, {
               complete: (results) => {
                  const data = results.data as any[];
                  if (results === undefined || data.length === 0) {
                     resolve({ columns: [], rows: [] });
                     return;
                  }

                  const columns = [];
                  const rows = [];

                  for (let i = 0; i < data[0].length; i++) {
                     try {
                        columns.push({
                           title: data[0][i],
                           dataIndex: data[0][i],
                           key: data[0][i].toString(),
                        });
                     } catch (e) {
                        reject(
                           new Error(
                              'The result could not be loaded because the data format is incorrect.',
                           ),
                        );
                     }
                  }

                  for (let i = 2; i < data.length - 1; i++) {
                     try {
                        const row = new Map();
                        for (let j = 0; j < data[i].length; j++) {
                           row.set(columns[j].dataIndex, data[i][j]);
                        }
                        row.set('key', i);
                        rows.push(Object.fromEntries(row.entries()));
                     } catch (e) {
                        reject(
                           new Error(
                              'The result could not be loaded because the data format is incorrect.',
                           ),
                        );
                     }
                  }
                  resolve({ columns, rows });
               },
               download: true,
            });
         } catch (e: any) {
            let errText = 'Datasource Test Connection Failed!';
            if (e?.response?.data?.error) {
               errText = e.response.data.error;
            } else if (
               e?.response?.data?.error_messages !== undefined &&
               e?.response?.data?.error_messages?.length
            ) {
               errText = e?.response?.data?.error_messages[0];
            } else if (e.message) {
               errText = e.message;
            }
            throw new Error(errText);
         }
      });
      return await fileDataPromise;
   };

   return useMutation<any, Error, string>({
      mutationFn: runQuery,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useResultsPreviewDataTxt(): UseMutationResult<
   string,
   Error,
   string
> {
   const runQuery = async (payload: string): Promise<any> => {
      try {
         const response = await apiGetJson({
            path: '',
            url: payload,
            body: {},
            headers: {},
         });
         console.log('useResultsPreviewDataTxt', response);
         if (response?.status === 200) {
            return response.data as string;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Datasource Test Connection Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<string, Error, string>({
      mutationFn: runQuery,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}

export function useResultsPreviewDataJson(): UseMutationResult<
   any[],
   Error,
   string
> {
   const runQuery = async (payload: string): Promise<any> => {
      try {
         const response = await apiGetJson({
            path: '',
            url: payload,
            body: {},
            headers: {},
         });
         if (response?.status === 200) {
            return response.data as any[];
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'Datasource Test Connection Failed!';
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<any[], Error, string>({
      mutationFn: runQuery,
      onSuccess: async (dm: any) => {},
      onSettled: async (data: any) => {},
      onError: async (err: any) => {
         console.log(err);
      },
   });
}
