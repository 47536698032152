import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Select, Tooltip } from 'antd';
import React, { useState } from 'react';
import {
   type IAddUserSecretsPayload,
   type SecretData,
} from '../providers/models/IUserSecrets';
import { showError } from '../utils/Utils';
import { useGlobalState } from '../context/GlobalContext';

interface ISceretRow {
   isEditAble: boolean;
   onSaveClick: (payload: IAddUserSecretsPayload) => void;
   onDeleteClick: (data?: SecretData | null) => void;
   data: SecretData | null;
   isNewSecret: boolean;
}
const SceretRow: React.FC<ISceretRow> = (props) => {
   const {
      onSaveClick,
      onDeleteClick,
      isEditAble: _isEditAble,
      data,
      isNewSecret,
   } = props;
   const [isEditAble, setIsEditAble] = useState(_isEditAble);
   const [type, setType] = useState(data?.type ?? 'GIT');
   const [name, setName] = useState(data?.name ?? '');
   const [username, setUsername] = useState(
      data?.secrets?.git_username?.value ?? '',
   );
   const [token, setToken] = useState('');
   const { messageApi } = useGlobalState();

   const isValid = (): boolean => {
      if (type === '') {
         showError(messageApi, 'Type should not be empty');
         return false;
      }
      if (name === '') {
         showError(messageApi, 'Name should not be empty');
         return false;
      }
      if (username === '') {
         showError(messageApi, 'Username should not be empty');
         return false;
      }
      if (isNewSecret && token === '') {
         showError(messageApi, 'Token should not be empty');
         return false;
      }
      return true;
   };

   const onSaveBtnClick = (): void => {
      if (!isValid()) return;
      let payload: IAddUserSecretsPayload;
      if (isNewSecret) {
         payload = {
            name,
            type,
            secrets: {
               git_token: {
                  value: token,
                  is_secret: true,
               },
               git_username: {
                  value: username,
                  is_secret: false,
               },
            },
         };
      } else {
         payload = {
            id: data?.id,
            name,
            type,
            secrets: {
               git_username: {
                  value: username,
                  is_secret: false,
               },
            },
         };
         if (token !== '') {
            payload.secrets.git_token = {
               value: token,
               is_secret: true,
            };
         }
         setIsEditAble(false);
      }
      onSaveClick(payload);
   };
   return (
      <div className="flex flex-row gap-2 ">
         {/* <Select
            placeholder="Type"
            size="large"
            variant={!isEditAble ? 'borderless' : undefined}
            className="w-full type-select disabled:text-main disabled:placeholder-main"
            onChange={(value: string, options: any) => {
               console.log(options);
               setType(value);
            }}
            value={type === '' ? null : type}
            options={[{ value: 'GIT', label: 'GIT' }]}
            disabled={!isEditAble}
         /> */}
         <Input
            placeholder="name"
            className="w-[60%] disabled:border-0 disabled:bg-inherit disabled:text-main disabled:ps-0"
            size="large"
            onChange={(e) => {
               setName(e.target.value);
            }}
            value={name}
            disabled={!isEditAble}
         />
         <Input
            placeholder="Username"
            className="w-[60%] disabled:border-0 disabled:bg-inherit disabled:text-main disabled:ps-1.5"
            size="large"
            onChange={(e) => {
               setUsername(e.target.value);
            }}
            value={username}
            disabled={!isEditAble}
         />
         <Input
            placeholder={isNewSecret ? 'Token' : '******'}
            className="disabled:border-0 disabled:bg-inherit disabled:placeholder-main disabled:ps-0"
            size="large"
            onChange={(e) => {
               setToken(e.target.value);
            }}
            value={token}
            disabled={!isEditAble}
         />
         <div className="flex flex-row items-center gap-2 w-[47%]">
            <Tooltip title="Save">
               <Button
                  type="link"
                  size="large"
                  disabled={!isEditAble}
                  onClick={() => {
                     onSaveBtnClick();
                  }}
                  className="p-1 pt-2"
               >
                  <SaveOutlined className="text-xl" />
               </Button>
            </Tooltip>
            <Tooltip title="Edit">
               <Button
                  type="link"
                  size="large"
                  onClick={() => {
                     setIsEditAble(true);
                  }}
                  disabled={isEditAble}
                  className="p-1 pt-2"
               >
                  <EditOutlined className="text-xl" />
               </Button>
            </Tooltip>
            <Tooltip title="Delete">
               <Button
                  type="link"
                  size="large"
                  danger
                  onClick={() => {
                     if (isNewSecret) {
                        onDeleteClick();
                     } else {
                        onDeleteClick(data);
                     }
                  }}
                  className="p-1 pt-2"
               >
                  <DeleteOutlined className="text-xl" />
               </Button>
            </Tooltip>
         </div>
      </div>
   );
};

export default SceretRow;
