/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect } from 'react';
import { useGetGitProjectCommits } from '../../../providers/useGitProjects';
import { Link, useParams } from 'react-router-dom';
import { showError, showSuccess } from '../../../utils/Utils';
import { useGlobalState } from '../../../context/GlobalContext';
import { Avatar, Divider, Tooltip } from 'antd';
import Loading from '../../../components/Loading';
import {
   CopyOutlined,
   ExportOutlined,
   FileSyncOutlined,
} from '@ant-design/icons';
import moment from 'moment';

interface IGitHistory {
   gitUrl: string;
}
const GitHistory: React.FC<IGitHistory> = (props) => {
   const { gitUrl } = props;
   const { messageApi } = useGlobalState();
   const { id: projectId } = useParams();
   const {
      data: gitCommits,
      isError: isGitCommitsError,
      isPending: isGitCommitsLoading,
      error: gitCommitsError,
   } = useGetGitProjectCommits(projectId);

   useEffect(() => {
      if (isGitCommitsError) {
         showError(messageApi, `${gitCommitsError.message}`);
      }
      if (gitCommits !== undefined && gitCommits !== null) {
         console.log(gitCommits);
      }
   }, [isGitCommitsLoading, gitCommits]);

   return (
      <div className="flex flex-col justify-between h-[calc(100vh-155px)]">
         {isGitCommitsLoading && <Loading />}
         <div
            className="flex flex-col  p-3 mt-3 overflow-y-auto rounded-md border-solid border-[0.5px] border-gray-400
          h-[81vh] "
         >
            {gitCommits?.map((commit, index) => (
               <>
                  <div className="flex flex-row group">
                     <div className={`flex flex-col w-full p-1`}>
                        <div className="flex flex-row justify-between items-center gap-1">
                           <Tooltip title={commit.full_message} key={index}>
                              <div className="overflow-hidden whitespace-nowrap text-ellipsis text-[15px]">
                                 {commit.full_message}
                              </div>
                           </Tooltip>
                           <div className="flex flex-row items-center gap-2">
                              <Tooltip title={'Copy Commit Hash'} key={index}>
                                 <FileSyncOutlined
                                    className="text-border text-md invisible group-hover:visible hover:text-primary cursor-pointer"
                                    onClick={() => {
                                       const commitUrl = `${commit.commit_id}`;
                                       void navigator.clipboard.writeText(
                                          commitUrl,
                                       );
                                       showSuccess(
                                          messageApi,
                                          'Copied Succesfully',
                                       );
                                    }}
                                 />
                              </Tooltip>
                              {commit.is_in_remote ? (
                                 <Tooltip title={'Copy Commit URL'} key={index}>
                                    <CopyOutlined
                                       className="text-border text-md invisible group-hover:visible hover:text-primary cursor-pointer"
                                       onClick={() => {
                                          const commitUrl = `${gitUrl.replace('.git', '/commit/')}${commit.commit_id}`;
                                          void navigator.clipboard.writeText(
                                             commitUrl,
                                          );
                                          showSuccess(
                                             messageApi,
                                             'Copied Succesfully',
                                          );
                                       }}
                                    />
                                 </Tooltip>
                              ) : (
                                 <Tooltip
                                    title={'Commit not pushed'}
                                    key={index}
                                 >
                                    <CopyOutlined
                                       className="text-border text-md invisible group-hover:visible hover:text-primary cursor-pointer"
                                       onClick={() => {}}
                                    />
                                 </Tooltip>
                              )}
                              {commit.is_in_remote ? (
                                 <Tooltip title={'Commit Link'} key={index}>
                                    <Link
                                       to={`${gitUrl.replace('.git', '/commit/')}${commit.commit_id}`}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >
                                       <ExportOutlined
                                          className="text-border text-md invisible
                           group-hover:visible hover:text-primary cursor-pointer"
                                       />
                                    </Link>
                                 </Tooltip>
                              ) : (
                                 <Tooltip
                                    title={'Commit not pushed'}
                                    key={index}
                                 >
                                    <ExportOutlined
                                       className="text-border text-md invisible
                            group-hover:visible hover:text-primary cursor-pointer"
                                    />
                                 </Tooltip>
                              )}
                           </div>
                        </div>

                        <div className="flex flex-row mt-2  justify-between items-center text-main text-xs">
                           <div className="flex flex-row items-center gap-1">
                              <Avatar
                                 className="bg-primary !text-[7px]"
                                 size={15}
                              >
                                 {commit.commiter_name?.toUpperCase().charAt(0)}
                              </Avatar>
                              {commit.commiter_name}
                           </div>
                           <div className="flex flex-row items-center ">
                              {moment
                                 .duration(
                                    moment().diff(
                                       moment.unix(commit.commit_time / 1000),
                                    ),
                                 )
                                 .humanize() + ' ago'}
                           </div>
                        </div>
                     </div>
                  </div>
                  <Divider className="mt-3 mb-2" />
               </>
            ))}
         </div>
      </div>
   );
};

export default GitHistory;
