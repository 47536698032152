/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { useGlobalState } from '../../context/GlobalContext';
import { showError, validatePassword } from '../../utils/Utils';

interface IResetPasswordModal {
   isModalOpen: boolean;
   onCancel: () => void;
   onResetClicked: (password: string) => void;
}
const UpdatePasswordModal: React.FC<IResetPasswordModal> = (props) => {
   const { isModalOpen, onCancel, onResetClicked } = props;
   const [password, setPassword] = useState<string>('');
   const [confirmPassword, setConfirmPassword] = useState<string>('');

   const { currentTheme, messageApi } = useGlobalState();

   const isValid = (): boolean => {
      if (password !== confirmPassword) {
         showError(messageApi, 'Password not matched');
         return false;
      }
      const message = validatePassword(password);
      if (message !== '') {
         showError(messageApi, message);
         return false;
      }
      return true;
   };
   return (
      <>
         <Modal
            title="Update Password"
            open={isModalOpen}
            maskClosable={false}
            onCancel={() => {
               onCancel();
            }}
            footer={() => (
               <div className="flex flex-row justify-end items-center mt-8">
                  <div className="flex gap-4">
                     <Button
                        key="back"
                        onClick={() => {
                           onCancel();
                        }}
                     >
                        Cancel
                     </Button>

                     <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                           if (isValid()) {
                              onResetClicked(password);
                           }
                        }}
                     >
                        Update
                     </Button>
                  </div>
               </div>
            )}
            width={'40%'}
            className={`${currentTheme}`}
         >
            <div className="flex flex-col gap-3 my-6">
               <Input.Password
                  placeholder="Password"
                  size="large"
                  onChange={(e) => {
                     setPassword(e.target.value);
                  }}
                  value={password}
               />
               <Input.Password
                  placeholder="Confirm Password"
                  size="large"
                  onChange={(e) => {
                     setConfirmPassword(e.target.value);
                  }}
                  value={confirmPassword}
               />
            </div>
         </Modal>
      </>
   );
};

export default UpdatePasswordModal;
