import { Avatar, Badge, Tooltip } from 'antd';
import moment from 'moment';
import { ReactComponent as ProjectIcon } from '../../assets/svgs/selected-project-icon.svg';
import { DeleteOutlined } from '@ant-design/icons';
import { type IProjectInfo } from '../../api/models/IProject';

export const PAGE_SIZE = 20;

export const initialSearchData = {
   page: 0,
   size: PAGE_SIZE,
   query: '*',
   sort_key: 'last_updated_timestamp',
   sort_value: 'desc',
   filter_by: [],
};

export const PROJECT_COLUMNS = (
   onDeleteClick: (data: IProjectInfo) => void,
): any[] => [
   {
      title: 'Project Name',
      dataIndex: 'configuration',
      key: 'project-name',
      render: (configuration: any) => {
         return (
            <div className="flex gap-1.5">
               <ProjectIcon className="size-5 " />
               {configuration.name}
            </div>
         );
      },
   },
   {
      title: 'Data source',
      dataIndex: 'configuration',
      key: 'source_ds',
      render: (configuration: any) => {
         return (
            <div className="flex gap-1.5">
               <img width={20} height={20} src={configuration.dataSourceIcon} />
               {configuration.dataSource ?? configuration.sourceDatabase}
            </div>
         );
      },
   },
   {
      title: 'Database',
      dataIndex: 'configuration',
      key: 'source_database',
      render: (configuration: any) => {
         return configuration.sourceDatabase;
      },
   },
   {
      title: 'Schema',
      dataIndex: 'configuration',
      key: 'schema',
      render: (configuration: any) => {
         return configuration.schema;
      },
   },
   {
      title: 'Status',
      dataIndex: '',
      key: 'in-progress',
      render: (configuration: any) => {
         return <Badge status="warning" text="In Progress" />;
      },
   },
   {
      title: 'Last updated',
      dataIndex: 'configuration',
      key: 'last-updated',
      render: (configuration: any) => {
         const time =
            moment(
               parseInt(configuration?.lastUpdatedTimestamp as string),
            ).fromNow() + '';
         return <div>{time}</div>;
      },
   },
   {
      title: 'Last Updated by',
      dataIndex: 'configuration',
      key: 'createdBy',
      // width: '80px',
      render: (configuration: any) => {
         return (
            <Tooltip title={configuration.createdBy}>
               <Avatar className="bg-primary cursor-pointer " size={25}>
                  {configuration.createdBy.charAt(0).toUpperCase()}
               </Avatar>
            </Tooltip>
         );
      },
   },
   {
      title: '',
      dataIndex: 'configuration',
      key: 'actions',
      width: '80px',
      render: (configuration: any) => {
         return (
            <div className="flex flex-row justify-center invisible group-hover:visible">
               <DeleteOutlined
                  className="text-xl text-red-500"
                  onClick={(e) => {
                     const data: IProjectInfo = {
                        projectName: configuration.name,
                        id: configuration.id,
                        hasWorkspace: configuration.hasWorkspace,
                        configuration: null,
                        IsDbtProject: false,
                        gitUrl: '',
                     };
                     onDeleteClick(data);
                     e.stopPropagation();
                  }}
               />
            </div>
         );
      },
   },
];
