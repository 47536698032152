import React from 'react';
import { Button, Dropdown, Input, Space, Table, Tag } from 'antd';
import type { MenuProps, TableProps } from 'antd';
import { DownOutlined, MoreOutlined, SyncOutlined } from '@ant-design/icons';

interface DataType {
   key: string;
   name: string;
   source: string;
   destination: string;
   sync_frequence: string;
   last_synced: string;
   tags: string[];
}

const columns: TableProps<DataType>['columns'] = [
   {
      title: 'Pipeline Name',
      dataIndex: 'name',
      key: 'name',
      //   render: (text) => <a>{text}</a>,
   },
   {
      title: 'Sources',
      dataIndex: 'source',
      key: 'source',
   },
   {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
   },
   {
      title: 'Sync Frequence',
      dataIndex: 'sync_frequence',
      key: 'sync_frequence',
   },
   {
      title: 'Last synced',
      dataIndex: 'last_synced',
      key: 'last_synced',
      sorter: (a, b) => +a.key - +b.key,
   },
   {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
         <>
            {tags.map((tag) => {
               let color = tag.length > 5 ? 'geekblue' : 'green';
               if (tag === 'loser') {
                  color = 'volcano';
               }
               return (
                  <Tag
                     className="rounded-xl border-0 px-2"
                     color={color}
                     key={tag}
                  >
                     {tag.toUpperCase()}
                  </Tag>
               );
            })}
         </>
      ),
   },
   {
      title: '',
      key: 'action',
      render: (_, record) => (
         <MoreOutlined className="text-xl text-primary cursor-pointer" />
      ),
   },
];

const data: DataType[] = [
   {
      key: '1',
      name: 'Pipline 1',
      source: 'snowflake',
      destination: 'Autobot_Wnbay8',
      sync_frequence: 'Every 1 Day',
      last_synced: '4 hours ago',
      tags: ['Pending'],
   },
   {
      key: '2',
      name: 'Pipline 2',
      source: 'snowflake',
      destination: 'Autobot_Wnbay8',
      sync_frequence: 'Every 1 Day',
      last_synced: '5 hours ago',
      tags: ['Draft'],
   },
   {
      key: '3',
      name: 'Pipline 3',
      source: 'snowflake',
      destination: 'Autobot_Wnbay8',
      sync_frequence: 'Every 1 Day',
      last_synced: '6 hours ago',
      tags: ['Enabled'],
   },
];
const Pipelines: React.FC = () => {
   const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
         console.log(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `selectedRowKeys: ${selectedRowKeys}`,
            'selectedRows: ',
            selectedRows,
         );
      },
      getCheckboxProps: (record: DataType) => ({
         disabled: record.name === 'Disabled User', // Column configuration not to be checked
         name: record.name,
      }),
   };
   const handleMenuClick: MenuProps['onClick'] = (e) => {
      //   message.info('Click on menu item.');
      console.log('click', e);
   };
   const sources: MenuProps['items'] = [
      {
         label: 'Snowflake',
         key: '1',
      },
      {
         label: 'My Sql',
         key: '2',
      },
      {
         label: 'Postgresql',
         key: '3',
      },
   ];

   const destinations: MenuProps['items'] = [
      {
         label: 'Autobot_Wnbay8',
         key: '1',
      },
      {
         label: 'Autobot_Wnbay2',
         key: '2',
      },
      {
         label: 'Autobot_Wnbay1',
         key: '3',
      },
   ];

   const statuses: MenuProps['items'] = [
      {
         label: 'In Progress',
         key: '1',
      },
      {
         label: 'Failed',
         key: '2',
      },
      {
         label: 'Completed',
         key: '3',
      },
      {
         label: 'Paused',
         key: '3',
      },
   ];

   return (
      <div className="w-full overflow-y-auto h-[calc(100vh-0px)] max-h-[calc(100vh-0px)]">
         <div className="p-5">
            <div className="flex flex-row items-center justify-between">
               <div className="flex flex-row items-center gap-4">
                  <div className="text-3xl text-main">Pipelines</div>
                  <div className="text-xs text-main mx-2 mt-1">
                     <SyncOutlined className="text-xs text-primary me-1" />
                     Last refreshed 20 minutes ago
                  </div>
                  <div
                     className="flex flex-col gap-1 justify-center
                   items-center bg-green-100 dark:bg-green-400 dark:bg-opacity-50 min-w-[100px] py-1.5 rounded-lg text-[10px]"
                  >
                     <div className="flex flex-row  text-green-400 dark:text-green-100">
                        <SyncOutlined className="me-1" />
                        Enabled
                     </div>
                     <span className="text-main">1</span>
                  </div>

                  <div
                     className="flex flex-col gap-1 justify-center 
                  items-center bg-purple-100 dark:bg-purple-400 dark:bg-opacity-50 min-w-[100px] py-1.5 rounded-lg text-[10px]"
                  >
                     <div className="flex flex-row  text-purple-400 dark:text-purple-100">
                        <SyncOutlined className="me-1" />
                        Draft
                     </div>
                     <span className="text-main">2</span>
                  </div>

                  <div
                     className="flex flex-col gap-1 justify-center items-center 
                  bg-gray-100 dark:bg-gray-400 dark:bg-opacity-50 min-w-[100px] py-1.5 rounded-lg text-[10px]"
                  >
                     <div className="flex flex-row  text-gray-400 dark:text-gray-100">
                        <SyncOutlined className="me-1" />
                        Paused
                     </div>
                     <span className="text-main">4</span>
                  </div>
                  <div
                     className="flex flex-col gap-1 justify-center items-center 
                  bg-red-100 dark:bg-red-400 dark:bg-opacity-50 min-w-[100px] py-1.5 rounded-lg text-[10px]"
                  >
                     <div className="flex flex-row  text-red-500 dark:text-red-100">
                        <SyncOutlined className="me-1" />
                        Failed
                     </div>
                     <span className="text-main">2</span>
                  </div>
               </div>
               <Button
                  onClick={() => {
                     //  setDataSourcePageType(DataSourcePageType.CREATE);
                  }}
                  type="primary"
                  size="large"
               >
                  Create Pipeline
               </Button>
            </div>
            <div className="mt-2 shadow-lg rounded-lg p-3">
               <div className="flex flex-row mb-2 gap-3">
                  <Input className="w-1/5" placeholder="Search Pipelines" />
                  <Dropdown
                     menu={{
                        items: sources,
                        onClick: handleMenuClick,
                     }}
                     trigger={['click']}
                  >
                     <Button>
                        <Space>
                           All sources
                           <DownOutlined />
                        </Space>
                     </Button>
                  </Dropdown>
                  <Dropdown
                     menu={{
                        items: destinations,
                        onClick: handleMenuClick,
                     }}
                     trigger={['click']}
                  >
                     <Button>
                        <Space>
                           All Destination
                           <DownOutlined />
                        </Space>
                     </Button>
                  </Dropdown>
                  <Dropdown
                     menu={{
                        items: statuses,
                        onClick: handleMenuClick,
                     }}
                     trigger={['click']}
                  >
                     <Button>
                        <Space>
                           All Status
                           <DownOutlined />
                        </Space>
                     </Button>
                  </Dropdown>
               </div>
               <Table
                  rowSelection={{ type: 'checkbox', ...rowSelection }}
                  columns={columns}
                  dataSource={data}
               />
            </div>
         </div>
      </div>
   );
};

export default Pipelines;
