import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import '../App.css';
import AppRoutes from '../routes/AppRoutes';
import { ConfigProvider, message } from 'antd';
import { useGlobalState } from '../context/GlobalContext';
import { ANTD_THEME } from './AntdTheme';
import { LocalStorage } from '../utils/LocalStorage';
import { KEY_AWS_CONFIG, KEY_CURRENT_THEME } from '../utils/LocalStorageKeys';
import ApiConstants from '../api/ApiConstants';
import { THEME_DARK } from '../utils/Constants';
import { signinRedirect, signoutRedirect } from '../utils/Utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import Loading from '../components/Loading';

const Main: React.FC = () => {
   const [messageApi, contextHolder] = message.useMessage();
   const { setMessageApi, currentTheme, setCurrentTheme } = useGlobalState();
   const [isConfigure, setIsConfigure] = useState(false);

   useEffect(() => {
      setMessageApi(messageApi);
   }, [messageApi]);

   useEffect(() => {
      console.log('Build :: 20 Sep');
      const config = LocalStorage.get(KEY_AWS_CONFIG);
      if (config) {
         const configData = JSON.parse(config);
         Amplify.configure({
            Auth: {
               Cognito: {
                  userPoolClientId: configData.config.client_id,
                  userPoolId: configData?.user_pool_id,
                  loginWith: {
                     oauth: {
                        domain: configData?.domain,
                        scopes: ApiConstants.cognito.loginWith.oauth.scopes,
                        redirectSignIn: signinRedirect(),
                        redirectSignOut: signoutRedirect(),
                        responseType: 'token',
                     },
                  },
               },
            },
         });
      }
      void setAwsConfiguration();
   }, []);

   const setAwsConfiguration = async (): Promise<void> => {
      await fetchAuthSession();
      setIsConfigure(true);
   };

   useEffect(() => {
      const theme = LocalStorage.get(KEY_CURRENT_THEME);
      if (theme !== null) {
         setCurrentTheme(theme);
      }
   }, []);

   return (
      <>
         {isConfigure ? (
            <ConfigProvider theme={ANTD_THEME(currentTheme)}>
               <div
                  className={`${currentTheme} ${currentTheme === THEME_DARK && 'dark'}`}
               >
                  {contextHolder}
                  <AppRoutes />
               </div>
            </ConfigProvider>
         ) : (
            <Loading messages="Loading App..." />
         )}
      </>
   );
};

export default Main;
