/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { type Key, useState, useEffect } from 'react';
import { Button, Dropdown, Menu, Tooltip, Tree, message } from 'antd';
import {
   CaretDownOutlined,
   DeleteOutlined,
   DownOutlined,
   EditOutlined,
   FileAddOutlined,
   FolderAddOutlined,
   FundProjectionScreenOutlined,
   MoreOutlined,
   SyncOutlined,
} from '@ant-design/icons';
import { type ITab, type TabData } from './ProjectData';
import TreeConfig, {
   getIconPathForFileType,
} from '../../utils/TreeConfig.util';
import AddFolderInput from './AddFolderInput/AddFolderInput';
import { type DataNode } from 'antd/es/tree';
import { execSearch } from '../../utils/helpers';
import AddFileInput from './AddFileInput/AddFileInput';
import { useUpdateFile } from '../../providers/useProjectFiles';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { useGlobalState } from '../../context/GlobalContext';
import DeleteFileModal from './DeleteFileModal';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IDBTProjectPayload,
   type IDBTRunJobResponse,
} from '../../providers/useDBTJob';
import { type IProjectInfo } from '../../api/models/IProject';
import { ReactComponent as ProjectIcon } from '../../assets/svgs/selected-project-icon.svg';
import { type IFilesResponse } from '../../providers/models/IFiles';

const { DirectoryTree } = Tree;

interface IProjectTree {
   treeData: any;
   activeTabs: TabData;
   setActiveTabs: (activeTabs: TabData) => void;
   expandedKeys: any[];
   setExpandedKeys: (dat: any[]) => void;
   projectInfoRefetch: () => void;
   runDbtProjectJob: UseMutateFunction<
      IDBTRunJobResponse,
      Error,
      IDBTProjectPayload,
      unknown
   >;
   selectedProject: IProjectInfo | undefined;
   projectInfoData: IFilesResponse | undefined;
}
const ProjectTree: React.FC<IProjectTree> = (props) => {
   const {
      treeData,
      activeTabs,
      setActiveTabs,
      expandedKeys,
      setExpandedKeys,
      projectInfoRefetch,
      runDbtProjectJob,
      selectedProject,
      projectInfoData,
   } = props;
   const { messageApi } = useGlobalState();
   const [previousExpandedKey, setPreviousExpandedKey] = useState<any[]>([]);
   const [treeSource, setTreeSource] = useState<any>([]);
   //    const [expandedKeys, setExpandedKeys] = useState<any>([]);
   const [isDeleteModalVisible, setIsDeleteModalVisible] = useState({
      isModalVisible: false,
      relativeFilePaths: '',
      isFolder: false,
   });
   const [isAddFolder, setIsAddFolder] = useState({
      actionState: false,
      folderPath: '',
   });
   const [isAddFile, setIsAddFile] = useState({
      actionState: false,
      folderPath: '',
   });
   const [isAddGeneralFile, setIsAddGeneralFile] = useState({
      type: '',
      isFolderAdded: false,
   });
   const [filePath, setFilePath] = useState('');
   const { id: projectName } = useParams();

   const noTitledKey = (path: string): string => `${path}/noTitle`;

   const {
      mutate: updateFile,
      data: updateFileData,
      isError: isUpdateFileError,
      error: updateError,
      isPending: isUpdateFileLoading,
   } = useUpdateFile();

   useEffect(() => {
      if (expandedKeys[1] === 'tabs' && expandedKeys[2] === 'tabs/main') {
         console.log(activeTabs.activeTabKey);
      } else {
         setPreviousExpandedKey(expandedKeys);
      }
   }, [expandedKeys]);

   useEffect(() => {
      if (
         treeData !== undefined &&
         !(
            isAddFile.actionState ||
            isAddFolder.actionState ||
            isAddGeneralFile.isFolderAdded ||
            isAddGeneralFile.type !== ''
         )
      ) {
         setTreeSource(new TreeConfig().transform(treeData));
      }
   }, [treeData]);

   useEffect(() => {
      if (isAddFile.actionState) {
         generateNewEntity(isAddFile.folderPath, addFileEntity);
      }

      if (isAddFolder.actionState) {
         generateNewEntity(isAddFolder.folderPath, addFolderEntity);
      }
   }, [isAddFolder, isAddFile]);

   useEffect(() => {
      if (isUpdateFileError) {
         void messageApi?.open({
            type: 'error',
            content: `${updateError?.message}`,
         });
      }

      if (updateFileData !== null && updateFileData !== undefined) {
         if (updateFileData.ack === 'ok') projectInfoRefetch();
      }
   }, [updateFileData, isUpdateFileLoading]);

   useEffect(() => {
      if (
         projectInfoData?.content?.length &&
         projectInfoData?.content?.length > 0
      ) {
         // if (filePath !== '') {
         //    const fileName = filePath.split('/').pop() ?? '';
         //    const newTab = {
         //       title: fileName,
         //       key: filePath,
         //       modified: false,
         //       icon: getIconPathForFileType(fileName),
         //    };
         //    setActiveTabs({
         //       tabs: [...activeTabs.tabs, newTab],
         //       activeTabKey: filePath,
         //    });
         //    setFilePath('');
         // }
      }
   }, [projectInfoData]);

   const showSuccessMessage = (message: string): void => {
      void messageApi?.open({
         type: 'success',
         content: message,
      });
   };

   const generateNewEntity = (folderPath: any, entityContext: any): void => {
      if (folderPath) {
         const nextState = [...treeSource];
         const foundItem = execSearch(nextState, folderPath);
         foundItem.children = [
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            ...(foundItem.children || []),
            entityContext(folderPath),
         ];
         setTreeSource(nextState);
         onExpandProjectTree([...expandedKeys, folderPath]);
      }
   };

   const onAddFolder = (folderName: string): void => {
      const nextState = [...treeSource];
      const foundItem = execSearch(
         nextState,
         noTitledKey(isAddFolder.folderPath),
      );
      const newKey = `${isAddFolder.folderPath}/${folderName}`;
      Object.assign(foundItem, {
         title: folderName,
         key: newKey,
         isLeaf: false,
         disabled: false,
      });
      setTreeSource(nextState);
      setIsAddFolder({ actionState: false, folderPath: '' });
   };

   const onAddFile = (fileName: string): void => {
      const nextState = [...treeSource];
      const newFileName = fileName || 'new_file';
      const foundItem = execSearch(
         nextState,
         noTitledKey(isAddFile.folderPath),
      );
      const newKey = `${isAddFile.folderPath}/${fileName}`;
      if (
         treeData?.find(
            (file: any) =>
               file.name === fileName &&
               file.relative_path === isAddFile.folderPath,
         )
      ) {
         void message.error('File with this name already exists.', 3);
         setTreeSource(new TreeConfig().transform(treeData));
      } else {
         Object.assign(foundItem, {
            title: fileName,
            key: newKey,
            isLeaf: true,
            disabled: false,
         });
         const name = projectName ?? '';
         updateFile({
            path: newKey,
            content: '',
            projectName: name,
         });
         setIsAddFile({ actionState: false, folderPath: '' });
         setTreeSource(nextState);
         setFilePath(newKey);
      }
   };

   const deleteEmptyEntity = (
      folderPath: string,
      isFolderEntity = false,
   ): void => {
      console.log('foundItem 2', [...treeSource]);

      const nextState = [...treeSource];
      const foundItem = execSearch(nextState, folderPath);
      const arr = foundItem.children?.filter(
         (child) => child.key !== noTitledKey(folderPath),
      );
      console.log('foundItem', [foundItem.children]);

      if (arr !== undefined) foundItem.children = [...arr];
      console.log('foundItem', [foundItem.children]);
      console.log('foundItem 1', nextState);
      setTreeSource(nextState);
      if (isFolderEntity) {
         setIsAddFolder({ actionState: false, folderPath: '' });
      } else {
         setIsAddFile({ actionState: false, folderPath: '' });
      }
   };

   const addFolderEntity = (folderPath: string): DataNode => {
      return {
         title: () => (
            <AddFolderInput
               onAddFolder={onAddFolder}
               onBlurFolder={() => {
                  deleteEmptyEntity(isAddFolder.folderPath, true);
               }}
            />
         ),
         key: noTitledKey(folderPath),
         isLeaf: false,
         disabled: true,
      };
   };

   const addFileEntity = (folderPath: string): DataNode => {
      return {
         title: () => (
            <AddFileInput
               onAddFile={onAddFile}
               onBlurFile={() => {
                  deleteEmptyEntity(isAddFile.folderPath);
               }}
            />
         ),
         key: noTitledKey(folderPath),
         isLeaf: true,
         disabled: true,
      };
   };

   const onSelect = (
      selectedKeys: any,
      selected: any,
      title: any,
      isLeaf: any,
      actTabs: TabData,
      info: any,
   ): void => {
      const isActiveTabExist = actTabs.tabs.some(
         (activeTab: any) => activeTab.key === selectedKeys[0],
      );

      if (selected && selectedKeys[0] !== '/') {
         if (!isActiveTabExist && isLeaf) {
            const newTab = {
               title,
               key: selectedKeys[0],
               modified: false,
               icon: getIconPathForFileType(title),
            };
            setActiveTabs({
               activeTabKey: selectedKeys[0],
               tabs: [...actTabs.tabs, newTab],
            });
            console.log({ actTabs }, selectedKeys[0]);
            console.log(
               { actTabs: [...actTabs.tabs, newTab] },
               isLeaf,
               isActiveTabExist,
            );
         } else if (isLeaf) {
            setActiveTabs({
               ...activeTabs,
               activeTabKey: selectedKeys[0],
            });
         }
      }
   };

   const onExpandProjectTree = (expKey: Key[]): void => {
      setExpandedKeys(Array.from(new Set(expKey)));
   };

   const hanldeCopyTableRef = (path: string): void => {
      const modelPathArr = path.split('/');
      const modelNameWithExt = modelPathArr[modelPathArr?.length - 1];
      const clearedModelName = modelNameWithExt.split('.')[0];
      if (clearedModelName?.length) {
         const refText = `select *  from {{ ref('${clearedModelName}') }}`;
         void navigator.clipboard.writeText(refText);
         showSuccessMessage('Copied!');
      }
   };
   const isModelOpened = (path: string): boolean => {
      const modelPathArr = path.split('/');
      const modelName = modelPathArr[modelPathArr?.length - 1];

      const activeTabPathArr = activeTabs.activeTabKey?.split('/');
      if (activeTabPathArr !== undefined) {
         const activeTabName = activeTabPathArr[activeTabPathArr.length - 1];
         return modelName === activeTabName;
      }
      return false;
   };

   const hanldeCopyName = (path: string): void => {
      const modelPathArr = path.split('/');
      const modelNameWithExt = modelPathArr[modelPathArr?.length - 1];
      if (modelNameWithExt?.length) {
         void navigator.clipboard.writeText(modelNameWithExt);
         // setTooltipVisible(true);
         // setTimeout(() => {
         //    setTooltipVisible(false);
         // }, 1000);
      }
   };

   const renderTitle = (
      title: string,
      menuContent: any,
      nodeData: any,
   ): React.ReactNode => {
      return (
         <div
            key={title}
            className={`flex group items-center justify-between w-full relative transition-none hover:bg-transparent
            ${nodeData.key === '0-0' && 'my-1'}`}
         >
            <span className="text-ellipsis whitespace-nowrap overflow-hidden">
               {title}
            </span>
            {nodeData.key === '0-1' && (
               <Button
                  type="text"
                  className="invisible group-hover:visible relative "
                  onClick={(e) => {
                     projectInfoRefetch();
                     e.stopPropagation();
                  }}
               >
                  <SyncOutlined />
               </Button>
            )}
            {nodeData.key !== '0-1' && (
               <Dropdown overlay={menuContent}>
                  <Button
                     type="text"
                     className="invisible group-hover:visible relative "
                     onClick={(e) => {
                        e.stopPropagation();
                     }}
                  >
                     <MoreOutlined />
                  </Button>
               </Dropdown>
            )}
         </div>
      );
   };

   const runDbtWithFilePath = (type: string, path: string): void => {
      // const _type = type === 'COMPILE' ? type.slice(0, -1) : type;
      // setDbtCurrentType(_type.toLowerCase() + 'ing');
      const arr = path.split('/');
      const filname = arr[arr.length - 1];
      const file = filname.split('.')[0];
      const name = projectName ?? '';
      runDbtProjectJob({
         command: {
            type,
            args: {
               type,
               select: file,
            },
         },
         projectId: name,
      });
   };

   const projectMenu = (isFolder: any, path: any): any => {
      if (!isFolder) {
         return (
            <Menu>
               <Menu.Item disabled icon={<EditOutlined />}>
                  Rename
               </Menu.Item>
               {path?.endsWith('.sql') && (
                  <>
                     <Menu.Item
                        onClick={(e) => {
                           e.domEvent.stopPropagation();
                           hanldeCopyTableRef(path);
                        }}
                        // icon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                     >
                        Copy Ref
                     </Menu.Item>
                     <Menu.Item
                        onClick={(e) => {
                           e.domEvent.stopPropagation();
                           runDbtWithFilePath('RUN', path);
                        }}
                     >
                        Run
                     </Menu.Item>
                     <Menu.Item
                        onClick={(e) => {
                           e.domEvent.stopPropagation();
                           runDbtWithFilePath('COMPILE', path);
                        }}
                     >
                        Build
                     </Menu.Item>
                  </>
               )}
               {/* <Menu.Item
                  onClick={(e) => {
                     e.domEvent.stopPropagation();
                     hanldeCopyName(path);
                  }}
                  // icon={<FontAwesomeIcon icon={['fal', 'copy']} />}
               >
                  <Tooltip open={isTooltipVisible} title="copied">
                     Copy Name
                  </Tooltip>
               </Menu.Item> */}
               <Menu.Item
                  onClick={(e) => {
                     e.domEvent.stopPropagation();
                     setIsDeleteModalVisible({
                        isModalVisible: true,
                        relativeFilePaths: path,
                        isFolder,
                     });
                  }}
                  danger
                  icon={<DeleteOutlined />}
               >
                  Delete
               </Menu.Item>
            </Menu>
         );
      }
      return (
         <Menu>
            <Menu.Item
               icon={<FolderAddOutlined />}
               onClick={(e) => {
                  e.domEvent.stopPropagation();
                  setIsAddFolder({ actionState: true, folderPath: path });
               }}
            >
               <Tooltip
                  placement="right"
                  title="Empty folders that you create will be removed after refresh if you won't create files inside"
               >
                  Add Folder
               </Tooltip>
            </Menu.Item>
            <Menu.Item
               onClick={(e) => {
                  e.domEvent.stopPropagation();
                  setIsAddFile({ actionState: true, folderPath: path });
               }}
               icon={<FileAddOutlined />}
            >
               Add File
            </Menu.Item>

            <Menu.Item disabled icon={<EditOutlined />}>
               Rename
            </Menu.Item>
            <Menu.Item
               onClick={(e) => {
                  e.domEvent.stopPropagation();
                  setIsDeleteModalVisible({
                     isModalVisible: true,
                     relativeFilePaths: path,
                     isFolder,
                  });
               }}
               danger
               icon={<DeleteOutlined />}
            >
               Delete
            </Menu.Item>
         </Menu>
      );
   };

   return (
      <>
         {isUpdateFileLoading && <Loader />}
         <DirectoryTree
            showIcon
            multiple
            virtual
            showLine
            className="bg-default h-[calc(100vh-150px)] overflow-auto "
            treeData={[
               {
                  title: selectedProject?.projectName,
                  key: '0-1',
                  children: treeSource,
                  icon: (
                     <ProjectIcon className="text-main opacity-80 mt-1 me-1" />
                  ),
               },
               // {
               //    title: 'Data Source',
               //    key: '0-0',
               //    children: [
               //       { title: 'Users', key: '0-0-0', isLeaf: true },
               //       { title: 'columns', key: '0-0-1', isLeaf: true },
               //    ],
               // },
            ]}
            onExpand={onExpandProjectTree}
            switcherIcon={<DownOutlined />}
            selectedKeys={[activeTabs.activeTabKey!]}
            expandedKeys={
               expandedKeys[1] === 'tabs' && expandedKeys[2] === 'tabs/main'
                  ? previousExpandedKey
                  : expandedKeys
            }
            onSelect={(selectedKeys, e) => {
               onSelect(
                  selectedKeys,
                  e.selected,
                  e.node.title,
                  e.node.isLeaf,
                  activeTabs,
                  e,
               );
            }}
            titleRender={(nodeData: any) =>
               renderTitle(
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  nodeData.title,
                  projectMenu(!nodeData.isLeaf, nodeData.key),
                  nodeData,
               )
            }
         />
         {isDeleteModalVisible.isModalVisible && (
            <DeleteFileModal
               isModalOpen={isDeleteModalVisible.isModalVisible}
               isFolder={isDeleteModalVisible.isFolder}
               path={isDeleteModalVisible.relativeFilePaths}
               onCancel={() => {
                  setIsDeleteModalVisible({
                     ...isDeleteModalVisible,
                     isModalVisible: false,
                  });
               }}
               projectInfoRefetch={() => {
                  const arr = [...activeTabs.tabs];
                  const index = arr.findIndex(
                     (data) =>
                        data.key === isDeleteModalVisible.relativeFilePaths,
                  );
                  if (index !== -1) {
                     arr.splice(index, 1);
                     if (arr.length >= 0) {
                        let dataFile: ITab;
                        if (index === 0) dataFile = arr[index];
                        else dataFile = arr[index - 1];
                        setActiveTabs({
                           activeTabKey: dataFile?.key,
                           tabs: arr,
                        });
                     }
                  }
                  projectInfoRefetch();
               }}
            />
         )}
      </>
   );
};

export default ProjectTree;
