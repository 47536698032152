/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify/utils';
import { Button, Input } from 'antd';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import {
   signIn,
   signInWithRedirect,
   fetchAuthSession,
   confirmSignIn,
   signOut,
} from 'aws-amplify/auth';
import { useGlobalState } from '../../context/GlobalContext';
import UpdatePasswordModal from './UpdatePasswordModal';
import { AuthNextType } from '../../utils/Constants';
import { LocalStorage } from '../../utils/LocalStorage';
import {
   KEY_AWS_CONFIG,
   KEY_RECENT_DOMAINS,
   KEY_USER,
   KEY_USER_EMAIL,
} from '../../utils/LocalStorageKeys';
import { useTenantConfig, useUserSignin } from './useSignin';

import { showError, signinRedirect, signoutRedirect } from '../../utils/Utils';
import ApiConstants from '../../api/ApiConstants';
import { Amplify } from 'aws-amplify';
import { ArrowLeftOutlined } from '@ant-design/icons';
import SubDomains from './SubDoamins';
import { type ITenantConfig } from '../../api/models/ISignin';

const Signin: React.FC = () => {
   const [username, setUsername] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const [domain, setDomain] = useState<string>('');
   const [recentDomains, setRecentDomains] = useState<string[]>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const [isAuthConfigAvailable, setIsAuthConfigAvailable] =
      useState<boolean>(false);
   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
   const { messageApi, currentTheme } = useGlobalState();
   const navigate = useNavigate();

   const {
      data: configData,
      isError: isConfigError,
      isFetching: isConfigLoading,
      error: configError,
      refetch: configDataReftech,
   } = useTenantConfig(domain);

   const {
      data: userLoginData,
      isError: isLoginError,
      isPending: isLoginLoading,
      error: loginError,
      mutate: loginUser,
   } = useUserSignin();

   useEffect(() => {
      if (isConfigError) {
         showError(messageApi, `${configError.message}`);
      }
      if (configData !== undefined && configData !== null) {
         LocalStorage.set(KEY_AWS_CONFIG, JSON.stringify(configData));
         const _recentDomains = [...recentDomains];
         if (!_recentDomains.includes(domain)) {
            _recentDomains.push(domain);
            LocalStorage.set(KEY_RECENT_DOMAINS, _recentDomains);
         }

         Amplify.configure({
            Auth: {
               Cognito: {
                  userPoolClientId: configData.config.client_id,
                  userPoolId: configData?.user_pool_id,
                  loginWith: {
                     oauth: {
                        domain: configData?.domain,
                        scopes: ApiConstants.cognito.loginWith.oauth.scopes,
                        redirectSignIn: signinRedirect(),
                        redirectSignOut: signoutRedirect(),
                        responseType: 'token',
                     },
                  },
               },
            },
         });
         setIsAuthConfigAvailable(true);
      }
   }, [configData, isConfigLoading]);

   useEffect(() => {
      void checkPreviousSession();
      const authConfig = LocalStorage.get(KEY_AWS_CONFIG);
      if (authConfig !== null) {
         const config = JSON.parse(authConfig) as ITenantConfig;
         setDomain(config.domain);
      }
      setIsAuthConfigAvailable(authConfig !== null);
      getRecentDomains();
   }, []);

   useEffect(() => {
      const unsubscribe = Hub.listen('auth', ({ payload }) => {
         setLoading(false);
         switch (payload.event) {
            case 'signInWithRedirect':
               void setAuth();
               break;
            case 'signInWithRedirect_failure':
               showError(
                  messageApi,
                  'An error has occurred during the OAuth flow.',
               );
               break;
            case 'customOAuthState':
               showError(messageApi, payload.data); // this is the customState provided on signInWithRedirect function
               break;
         }
      });
      // void setAuth();
      return unsubscribe;
   }, []);

   const getRecentDomains = (): void => {
      const jsonDomain = LocalStorage.get(KEY_RECENT_DOMAINS);
      if (jsonDomain !== null) {
         const domains = JSON.parse(jsonDomain);
         setRecentDomains(domains);
      }
   };

   const checkPreviousSession = async (): Promise<void> => {
      const token = await fetchAuthSession();
      console.log('checkPreviousSession ***NN', token);
      const { idToken } = token.tokens ?? { idToken: undefined };
      console.log('checkPreviousSession *** 2', idToken);
      if (idToken !== undefined) {
         console.log('checkPreviousSession *** 33');
         await signOut();
      }
   };

   const setAuth = async (): Promise<void> => {
      try {
         const auth = await fetchAuthSession();
         if (auth.tokens?.accessToken !== undefined) {
            loginUser(auth.tokens?.idToken?.toString());
            // navigate('/');
         }
      } catch (error) {
         console.log('Auth Flow', error);
         console.error(error);
      }
   };

   useEffect(() => {
      console.log('Login User Data', userLoginData);
      if (userLoginData && !isLoginLoading) {
         LocalStorage.set(KEY_USER, userLoginData.id ?? 'noUserId');
         LocalStorage.set(KEY_USER_EMAIL, userLoginData.email ?? 'noUserEmail');
         navigate('/');
      }
   }, [userLoginData]);

   useEffect(() => {
      if (isLoginError) {
         void checkPreviousSession();
         showError(
            messageApi,
            `${loginError?.message ?? 'User login failed.'}`,
         );
      }
   }, [isLoginError, loginError]);

   const signinClicked = async (): Promise<void> => {
      setLoading(true);
      try {
         const signInResult = await signIn({ username, password });
         const { signInStep } = signInResult?.nextStep;
         switch (signInStep) {
            case AuthNextType.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED: {
               setIsModalOpen(true);
               break;
            }
            case AuthNextType.RESET_PASSWORD: {
               break;
            }
            default: {
               void setAuth();
            }
         }
         // if (
         //    output?.nextStep?.signInStep ===
         //    'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
         // ) {
         //    setIsModalOpen(true);
         // } else if (output?.nextStep?.signInStep === 'RESET_PASSWORD') {
         //    // await confirmResetPasswordClicked();
         // } else {
         //    void setAuth();
         // }
      } catch (error: any) {
         console.log('Auth Flow 2', error);
         showError(messageApi, error.toString());
      }
      setLoading(false);
   };

   const confirmSigninClicked = async (password: string): Promise<void> => {
      setLoading(true);
      try {
         const confirmSignInResult = await confirmSignIn({
            challengeResponse: password,
         });
         const { signInStep } = confirmSignInResult?.nextStep;
         if (signInStep === 'DONE') {
            void setAuth();
         }
      } catch (error: any) {
         showError(messageApi, error.toString());
      }
      setLoading(false);
   };

   const onGoogleClick = async (): Promise<void> => {
      setLoading(true);
      void signInWithRedirect({
         provider: 'Google',
      })
         .then((data) => {
            console.log('**onGoogleClick**: done', data);
         })
         .catch((e) => {
            console.log('**onGoogleClick** error', e);
         });
   };

   const onSubDomainClicked = (subdomain: string): void => {
      setDomain(subdomain);
      setTimeout(() => {
         void configDataReftech();
      }, 50);
   };

   return (
      <div className={'min-h-screen flex flex-row justify-center bg-default'}>
         {(loading || isConfigLoading) && <Loader description="Loading..." />}
         <div className="flex flex-col w-1/2 justify-center">
            <div className="flex flex-row justify-center">
               <div className="flex flex-col w-[60%] items-center">
                  <img
                     src={require(`../../assets/png/logo-${currentTheme}.png`)}
                     alt="logo"
                     className="size-16"
                  />
                  <div className="text-border text-2xl mt-6">Welcome back</div>
                  <div className="text-main text-[30px] mt-6">
                     Sign in to Data Workspace
                  </div>
                  {/* <p className="text-main opacity-50 text-md mb-16">
                     Use your Google account or email address to log in
                  </p>
                  <Button
                     size="large"
                     className="w-full text-sm font-semibold flex items-center justify-center gap-1"
                     onClick={() => {
                        void onGoogleClick();
                     }}
                  >
                     <img src={GoogleIcon} alt="logo" className="w-4 h-4" />
                     CONTINUE WITH GOOGLE
                  </Button>
                  <Divider plain>
                     <span className="text-border">OR</span>
                  </Divider> */}
                  {!isAuthConfigAvailable ? (
                     <SubDomains
                        recentDomains={recentDomains}
                        onSubDomainClicked={onSubDomainClicked}
                        configDataReftech={() => {
                           void configDataReftech();
                        }}
                        domain={domain}
                        setDomain={(_domain) => {
                           setDomain(_domain);
                        }}
                     />
                  ) : (
                     <>
                        <p className="text-main font-semibold mt-6">
                           You&apos;re signing into {domain}
                        </p>
                        <div className="flex flex-row w-full mb-6 mt-6">
                           <span
                              className="text-border text-sm cursor-pointer"
                              onClick={() => {
                                 getRecentDomains();
                                 LocalStorage.remove(KEY_AWS_CONFIG);
                                 setIsAuthConfigAvailable(false);
                              }}
                           >
                              <ArrowLeftOutlined className="text-[13px] me-1" />
                              Sign into a different domain
                           </span>
                        </div>
                        <Input
                           placeholder="Username"
                           className="mb-6"
                           size="large"
                           onChange={(e) => {
                              setUsername(e.target.value);
                           }}
                           value={username}
                        />
                        <Input.Password
                           placeholder="Password"
                           className="mb-6"
                           size="large"
                           onChange={(e) => {
                              setPassword(e.target.value);
                           }}
                           value={password}
                        />
                        <Button
                           onClick={() => {
                              void signinClicked();
                           }}
                           type="primary"
                           size="large"
                           className="w-full"
                        >
                           Sign in
                        </Button>
                        <p className="text-border text-sm mb-8 w-full text-end">
                           <span
                              className="text-primary cursor-pointer"
                              onClick={() => {
                                 navigate('/resetpassword');
                              }}
                           >
                              Forgot password?
                           </span>
                        </p>
                     </>
                  )}
                  {/* <p className="text-border text-sm mb-8">
                     Don&apos;t have an account?{' '}
                     <span
                        className="text-primary cursor-pointer"
                        onClick={() => {
                           navigate('/signup');
                        }}
                     >
                        Sign up now
                     </span>
                  </p> */}
               </div>
            </div>
         </div>
         {isModalOpen && (
            <UpdatePasswordModal
               isModalOpen={isModalOpen}
               onCancel={() => {
                  setIsModalOpen(false);
               }}
               onResetClicked={(password: string) => {
                  void confirmSigninClicked(password);
               }}
            />
         )}
      </div>
   );
};

export default Signin;
