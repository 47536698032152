import React, { type ReactElement } from 'react';

import { type Orientation } from './Orientation';

interface IProps {
   orientation: Orientation;
   context?: string;
}

export default function Handler({
   orientation,
   context = '',
}: IProps): ReactElement {
   const isHandlerVisible = (): boolean => {
      if (
         context === 'p-layout' &&
         (orientation === 'left' || orientation === 'right')
      ) {
         return false;
      }
      if (context === 'p-layout-schema' && orientation === 'top') {
         return false;
      }
      return true;
   };
   return (
      <>
         {isHandlerVisible() ? (
            <div className={'splitter-handler-wrapper'}>
               <div className="splitter-handler" />
            </div>
         ) : null}
      </>
   );
}
