import { Button, Steps } from 'antd';
import React, {
   type Dispatch,
   type SetStateAction,
   useState,
   useEffect,
} from 'react';
import Step2Configuration from './Step2Configuration';
import { DataSourcePageType } from './DataSource';
import DataSourceSidePanel from './DataSourceSidePanel';
import {
   useCreateDataSource,
   useGetConnectors,
   useGetDrivers,
   useGetProperties,
   useTestConnection,
   useUpdateDataSource,
} from '../../providers/useDataSources';
import { useGlobalState } from '../../context/GlobalContext';
import { showError, showSuccess } from '../../utils/Utils';
import {
   type IProperty,
   type IConnector,
   type IDriver,
} from '../../providers/models/IConnector';
import Loader from '../../components/Loader';
import {
   type IDataSource,
   type ICreateDataSourcePayload,
   type ITestConnectionPayload,
   type IUpdateDataSourcePayload,
} from '../../providers/models/IDataSource';
import { useDBTJobPoll } from '../../providers/useDBTJob';
import Step1Configuration from './Step1Configuration';

interface ICreateDataSourceProps {
   setDataSourcePageType: Dispatch<SetStateAction<DataSourcePageType>>;
   dataSourcePageType: DataSourcePageType;
   editDataSource: IDataSource | undefined;
}
const CreateDataSource: React.FC<ICreateDataSourceProps> = (props) => {
   const { setDataSourcePageType, dataSourcePageType, editDataSource } = props;
   const { messageApi } = useGlobalState();
   const [current, setCurrent] = useState(0);
   const [isConnected, setIsConnected] = useState(false);
   const [selectedConnector, setSelectedConnector] = useState<
      IConnector | undefined
   >(undefined);
   const [selectedDriver, setSelectedDriver] = useState<IDriver | undefined>(
      undefined,
   );
   const [inputProperties, setInputProperties] = useState<IProperty[]>([]);
   const [testConnectionId, setTestConnectionId] = useState<string>('');
   const [saveDataSourceJobId, setSaveDataSourceJobId] = useState<string>('');

   const {
      data: connectors,
      isError: isConnectorsError,
      isPending: isConnectorsLoading,
      error: connectorsError,
   } = useGetConnectors();

   const {
      data: drivers,
      isError: isDriversError,
      isPending: isDriversLoading,
      error: driversError,
   } = useGetDrivers(selectedConnector?.id ?? '');

   const {
      data: properties,
      isError: isPropertiesError,
      isPending: isPropertiesLoading,
      error: propertiesError,
   } = useGetProperties(
      selectedConnector?.id ?? '',
      selectedConnector?.versions[0].id ?? '',
   );

   const {
      data: testConectionData,
      isError: isTestConectionError,
      isPending: isTestConectionLoading,
      error: testConectionError,
      mutate: mutateTestConection,
   } = useTestConnection();

   const {
      data: dataSourceData,
      isError: isCreateDataSourceError,
      isPending: isCreateDataSourceLoading,
      error: createDataSourceError,
      mutate: createDataSource,
   } = useCreateDataSource();

   const {
      data: updatedDatasourceData,
      isError: isUpdatedDatasourceError,
      isPending: isUpdatedDatasourceLoading,
      error: updatedDatasourceError,
      mutate: updatedDatasource,
   } = useUpdateDataSource();

   const {
      data: dbtJobPollData,
      isError: isDbtJobPollError,
      isPending: isDbtJobPollLoading,
      error: dbtJobPollError,
   } = useDBTJobPoll(testConnectionId);

   const {
      data: saveDbtJobPollData,
      isError: isSaveDbtJobPollError,
      isPending: isSaveDbtJobPollLoading,
      error: saveDbtJobPollError,
   } = useDBTJobPoll(saveDataSourceJobId);

   useEffect(() => {
      if (isConnectorsError) {
         showError(messageApi, connectorsError.message);
      }
      if (connectors !== null && connectors !== undefined) {
         if (
            dataSourcePageType === DataSourcePageType.EDIT &&
            selectedConnector === undefined
         ) {
            setSelectedConnector(
               connectors.find(
                  (connector) => connector.id === editDataSource?.connector_id,
               ),
            );
         }
      }
   }, [isConnectorsLoading, connectors]);

   useEffect(() => {
      if (isDriversError) {
         showError(messageApi, driversError.message);
      }
      if (drivers !== null && drivers !== undefined) {
         if (
            dataSourcePageType === DataSourcePageType.EDIT &&
            selectedDriver === undefined
         ) {
            setSelectedDriver(
               drivers.find(
                  (driver) => driver.id === editDataSource?.driver_id,
               ) ?? drivers[0],
            );
         } else {
            setSelectedDriver(drivers[0]);
         }
      }
   }, [isDriversLoading, drivers]);

   useEffect(() => {
      if (isPropertiesError) {
         showError(messageApi, propertiesError.message);
      }
      if (properties !== null && properties !== undefined) {
         const _properties = properties.filter(
            (property) =>
               property.name !== 'driver' && property.name !== 'type',
         );
         if (editDataSource !== undefined) {
            const map = new Map(Object.entries(editDataSource?.configs));
            if (dataSourcePageType === DataSourcePageType.EDIT) {
               _properties.forEach((property) => {
                  if (map.has(property.name))
                     property.value = map.get(property.name);
               });
            }
         }
         setInputProperties(_properties);
      }
   }, [isPropertiesLoading, properties]);

   useEffect(() => {
      if (isTestConectionError) {
         showError(messageApi, testConectionError.message);
      }
      if (testConectionData !== undefined && testConectionData !== null) {
         setTestConnectionId(testConectionData.id);
      }
   }, [isTestConectionLoading, testConectionData]);

   useEffect(() => {
      if (isCreateDataSourceError) {
         showError(messageApi, createDataSourceError.message);
      }
      if (dataSourceData !== undefined && dataSourceData !== null) {
         setSaveDataSourceJobId(dataSourceData.id ?? '');
      }
   }, [isCreateDataSourceLoading, dataSourceData]);

   useEffect(() => {
      if (isUpdatedDatasourceError) {
         showError(messageApi, updatedDatasourceError.message);
      }
      if (
         updatedDatasourceData !== undefined &&
         updatedDatasourceData !== null
      ) {
         setSaveDataSourceJobId(updatedDatasourceData.id ?? '');
      }
   }, [isUpdatedDatasourceLoading, updatedDatasourceData]);

   useEffect(() => {
      if (isDbtJobPollError) {
         showError(messageApi, dbtJobPollError.message);
      }
      if (dbtJobPollData !== undefined && dbtJobPollData !== null) {
         if (dbtJobPollData.job_status === 'FAILED') {
            showError(messageApi, dbtJobPollData.message);
         } else {
            showSuccess(messageApi, 'Test Connection successful');
            setIsConnected(true);
         }
      }
   }, [isDbtJobPollLoading, dbtJobPollData]);

   useEffect(() => {
      if (isSaveDbtJobPollError) {
         showError(messageApi, saveDbtJobPollError.message);
      }
      if (saveDbtJobPollData !== undefined && saveDbtJobPollData !== null) {
         if (saveDbtJobPollData.job_status === 'FAILED') {
            showError(messageApi, saveDbtJobPollData.message);
         } else {
            onNextClick();
         }
      }
   }, [isSaveDbtJobPollLoading, saveDbtJobPollData]);

   useEffect(() => {
      if (
         selectedConnector !== null &&
         selectedConnector !== undefined &&
         current === 0
      ) {
         onNextClick();
      }
   }, [selectedConnector]);

   const onNextClick = (): void => {
      setCurrent((preValue) => preValue + 1);
   };
   const onSaveClick = (): void => {
      const map = new Map();
      const dbtPropertiesMap = new Map();
      let name = '';

      let passwordFieldValue = '';
      if (editDataSource !== undefined) {
         const map = new Map(Object.entries(editDataSource?.configs));
         passwordFieldValue = map.get('password');
      }
      inputProperties.forEach((property) => {
         if (
            property.name === 'password' &&
            passwordFieldValue === property.value &&
            dataSourcePageType === DataSourcePageType.EDIT
         ) {
            return;
         }
         map.set(property.name, property.value);
         if (property.name === 'name') name = property.value;
         if (
            property.dbt_property !== undefined &&
            property.dbt_property !== ''
         ) {
            dbtPropertiesMap.set(property.dbt_property, property.value);
         }
      });
      if (!map.get('queryTag')) {
         map.set('queryTag', 'DW_Workspace_' + map?.get('name'));
      }

      if (!dbtPropertiesMap.get('query_tag')) {
         dbtPropertiesMap.set('query_tag', 'DW_Workspace_' + map?.get('name'));
      }

      selectedConnector?.type.toLocaleLowerCase();
      const payload: ICreateDataSourcePayload = {
         name,
         type: selectedConnector?.type ?? 'SNOWFLAKE',
         connector_id: selectedConnector?.id ?? '',
         connector_version_id: selectedConnector?.versions[0].id ?? '',
         driver_id: selectedDriver?.id ?? '',
         driver_version_id: selectedDriver?.versions[0].id ?? '',
         properties: Object.fromEntries(map.entries()),
      };
      if (dbtPropertiesMap.size !== 0) {
         payload.dbt_mapping = Object.fromEntries(dbtPropertiesMap.entries());
      }
      if (dataSourcePageType === DataSourcePageType.EDIT) {
         const updatedPayload: IUpdateDataSourcePayload = {
            ...payload,
            datasource_id: editDataSource?.id ?? '',
         };
         updatedDatasource(updatedPayload);
      } else {
         createDataSource(payload);
      }
   };

   const onTestConnectionClicked = (): void => {
      const propertiesMap = new Map();
      const dbtPropertiesMap = new Map();

      let passwordFieldValue = '';
      if (editDataSource !== undefined) {
         const map = new Map(Object.entries(editDataSource?.configs));
         passwordFieldValue = map.get('password');
      }
      inputProperties.forEach((property) => {
         if (
            property.name === 'password' &&
            passwordFieldValue === property.value &&
            dataSourcePageType === DataSourcePageType.EDIT
         ) {
            return;
         }
         propertiesMap.set(property.name, property.value);
         if (
            property.dbt_property !== undefined &&
            property.dbt_property !== ''
         ) {
            dbtPropertiesMap.set(property.dbt_property, property.value);
         }
      });
      if (!propertiesMap.get('queryTag')) {
         propertiesMap.set(
            'queryTag',
            'DW_Workspace_' + propertiesMap?.get('name'),
         );
      }
      if (!dbtPropertiesMap.get('query_tag')) {
         dbtPropertiesMap.set(
            'query_tag',
            'DW_Workspace_' + propertiesMap?.get('name'),
         );
      }
      propertiesMap.set('type', selectedConnector?.type);
      const payload: ITestConnectionPayload = {
         connector_id: selectedConnector?.id ?? '',
         connector_version_id: selectedConnector?.versions[0].id ?? '',
         driver_version_id: selectedDriver?.versions[0].id ?? '',
         driver_id: selectedDriver?.id ?? '',
         properties: Object.fromEntries(propertiesMap.entries()),
      };
      if (dataSourcePageType === DataSourcePageType.EDIT) {
         payload.datasource_id = editDataSource?.id;
      }
      if (dbtPropertiesMap.size !== 0) {
         payload.dbt_mapping = Object.fromEntries(dbtPropertiesMap.entries());
      }
      mutateTestConection(payload);
   };

   return (
      <>
         {(isConnectorsLoading ||
            isDriversLoading ||
            isPropertiesLoading ||
            isTestConectionLoading ||
            isCreateDataSourceLoading ||
            isDbtJobPollLoading ||
            isUpdatedDatasourceLoading) && <Loader />}
         <div className="flex flex-row justify-center">
            <div className="flex flex-col w-[60%] p-5 pr-8 ">
               <div className="flex flex-row items-center justify-between ">
                  <div className="text-3xl text-main">
                     {dataSourcePageType === DataSourcePageType.CREATE &&
                        'Create Datasource'}
                     {dataSourcePageType === DataSourcePageType.EDIT &&
                        'Edit Datasource'}
                  </div>
               </div>

               <Steps
                  className="mt-3 text-xs"
                  current={current}
                  items={[
                     {
                        subTitle: 'Select Datasource type',
                     },
                     {
                        subTitle: 'Configure Datasource',
                     },
                     {
                        subTitle: 'Review',
                     },
                  ]}
                  size="small"
               />
               {current === 0 && (
                  <Step1Configuration
                     onNextClick={onNextClick}
                     connectors={connectors}
                     setSelectedConnector={setSelectedConnector}
                     setDataSourcePageType={setDataSourcePageType}
                     selectedConnector={selectedConnector}
                  />
               )}
               {current === 1 && (
                  <>
                     <Step2Configuration
                        onNextClick={onNextClick}
                        drivers={drivers}
                        connectors={connectors}
                        selectedConnector={selectedConnector}
                        setSelectedConnector={(connector) => {
                           setSelectedDriver(undefined);
                           setInputProperties([]);
                           setSelectedConnector(connector);
                        }}
                        properties={inputProperties}
                        setInputProperties={setInputProperties}
                        selectedDriver={selectedDriver}
                        setSelectedDriver={setSelectedDriver}
                        onTestConnectionClicked={onTestConnectionClicked}
                        onSaveClick={onSaveClick}
                        setDataSourcePageType={setDataSourcePageType}
                        isConnected={isConnected}
                        isLoading={
                           isConnectorsLoading ||
                           isDriversLoading ||
                           isPropertiesLoading ||
                           isTestConectionLoading ||
                           isCreateDataSourceLoading ||
                           isDbtJobPollLoading ||
                           isUpdatedDatasourceLoading
                        }
                     />
                  </>
               )}
               {/* {current === 1 && (
                  <>
                     <Step2Configuration
                        properties={inputProperties}
                        setInputProperties={setInputProperties}
                     />
                     <div className="flex flex-row justify-between w-full mt-7">
                        <Button
                           className="ps-0"
                           key="back"
                           type="link"
                           onClick={() => {
                              setDataSourcePageType(DataSourcePageType.LIST);
                           }}
                        >
                           Cancel
                        </Button>
                        <div className="flex flex-row gap-3">
                           <Button
                              type="primary"
                              onClick={() => {
                                 onNextClick();
                              }}
                           >
                              Skip
                           </Button>

                           <Button
                              type="primary"
                              // disabled={!isConnected || !isValid()}
                              onClick={() => {
                                 onNextClick();
                              }}
                           >
                              Save
                           </Button>
                        </div>
                     </div>
                  </>
               )} */}

               {current === 2 && (
                  <div className="flex flex-col justify-center items-center mt-5">
                     <div className="text-xl text-main">
                        DataSource Successfully Created
                     </div>
                     <Button
                        onClick={() => {
                           setDataSourcePageType(DataSourcePageType.LIST);
                        }}
                        type="primary"
                        size="middle"
                        className="mt-4"
                     >
                        Go To DataSource
                     </Button>
                  </div>
               )}
            </div>
            {selectedConnector !== null && selectedConnector !== undefined && (
               <DataSourceSidePanel />
            )}
         </div>
      </>
   );
};

export default CreateDataSource;
