import React, { useState } from 'react';
import { Button, Input } from 'antd';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { useGlobalState } from '../../context/GlobalContext';
import { showError, validatePassword } from '../../utils/Utils';
import { AuthNextType } from '../../utils/Constants';
import { LeftOutlined } from '@ant-design/icons';

const ResetPassword: React.FC = () => {
   const [username, setUsername] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const [confirmPassword, setConfirmPassword] = useState<string>('');
   const [message, setMessage] = useState<string>('');
   const [code, setCode] = useState<string>('');
   const [step, setStep] = useState<number>(1);

   const [loading, setLoading] = useState<boolean>(false);
   const { messageApi, currentTheme } = useGlobalState();
   const navigate = useNavigate();

   const handleResetPassword = async (): Promise<void> => {
      setLoading(true);
      try {
         const output = await resetPassword({ username });
         const { nextStep } = output;
         switch (nextStep.resetPasswordStep) {
            case AuthNextType.CONFIRM_RESET_PASSWORD_WITH_CODE: {
               setMessage(
                  `Confirmation code was sent to ${username} `, // ${codeDeliveryDetails.deliveryMedium}
               );
               setStep(2);
               break;
            }
            case AuthNextType.DONE:
               console.log('Successfully reset password.');
               break;
         }
      } catch (error) {
         console.log(error);
      }
      setLoading(false);
   };

   const confirmResetPasswordClicked = async (): Promise<void> => {
      setLoading(true);
      try {
         await confirmResetPassword({
            username,
            confirmationCode: code,
            newPassword: password,
         });
         navigate(-1);
      } catch (error: any) {
         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
         showError(messageApi, error.toString());
      }
      setLoading(false);
   };
   const isValid = (): boolean => {
      if (code.length < 6) {
         showError(messageApi, 'Please enter valid code');
         return false;
      }
      if (password !== confirmPassword) {
         showError(messageApi, 'Password not matched');
         return false;
      }
      const message = validatePassword(password);
      if (message !== '') {
         showError(messageApi, message);
         return false;
      }
      return true;
   };

   return (
      <div className={'min-h-screen flex flex-row justify-center bg-default'}>
         {loading && <Loader description="Loading..." />}
         <div className="flex flex-col w-1/2 justify-center">
            <div className="flex flex-row justify-center">
               <div className="flex flex-col w-[60%] items-center">
                  <img
                     src={require(`../../assets/png/logo-${currentTheme}.png`)}
                     alt="logo"
                     className="size-16"
                  />
                  <p className="text-main text-[30px] mb-12">Reset Password</p>
                  {step === 1 && (
                     <>
                        <p className="text-base text-main w-full">
                           Enter your email or username
                        </p>
                        <Input
                           placeholder="Username"
                           className="mb-6"
                           size="large"
                           onChange={(e) => {
                              setUsername(e.target.value);
                           }}
                           value={username}
                        />
                     </>
                  )}
                  {step === 2 && (
                     <>
                        <div className="w-full text-main gap-2">
                           <div
                              className="flex text-main gap-2 cursor-pointer"
                              onClick={() => {
                                 setStep(1);
                              }}
                           >
                              <LeftOutlined className="text-xl" />
                              Back
                           </div>
                        </div>
                        <p className="text-sm text-main w-full mt-6 overflow-hidden whitespace-nowrap text-ellipsis">
                           {message}
                        </p>
                        <Input
                           placeholder="Code"
                           className="mb-4"
                           size="large"
                           autoComplete="new-password"
                           onChange={(e) => {
                              setCode(e.target.value);
                           }}
                           value={code}
                        />
                        <Input.Password
                           placeholder="Password"
                           className="mb-4"
                           size="large"
                           autoComplete="new-password"
                           onChange={(e) => {
                              setPassword(e.target.value);
                           }}
                           value={password}
                        />
                        <Input.Password
                           placeholder="Confirm Password"
                           className="mb-4"
                           size="large"
                           autoComplete="new-password"
                           onChange={(e) => {
                              setConfirmPassword(e.target.value);
                           }}
                           value={confirmPassword}
                        />
                     </>
                  )}
                  <Button
                     onClick={() => {
                        if (step === 1) {
                           void handleResetPassword();
                        } else {
                           if (isValid()) void confirmResetPasswordClicked();
                        }
                     }}
                     type="primary"
                     size="large"
                     className="w-full"
                  >
                     {step === 1 ? 'Next' : 'Reset'}
                  </Button>
                  <p className="text-border text-sm mb-8 w-full text-end">
                     <span
                        className="text-primary cursor-pointer"
                        onClick={() => {
                           navigate('/signin', {
                              replace: true,
                           });
                        }}
                     >
                        Sign in?
                     </span>
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ResetPassword;
