import React, { type ReactElement, type ReactNode } from 'react';
// import clsx from 'clsx';
import { Button } from 'antd';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   IconDefinition,
//   faArrowAltToLeft,
//   faArrowAltToRight,
//   faArrowAltToBottom,
//   faArrowAltToTop
// } from "@fortawesome/pro-solid-svg-icons";
import {
   LeftOutlined,
   RightOutlined,
   UpOutlined,
   DownOutlined,
} from '@ant-design/icons';
// import topSideSplitterIcon from "src/pages/PLayout/images/topSideSplitter.svg";
// import bottomSideSplitterIcon from "src/pages/PLayout/images/bottomSideSplitter.svg";

import { Orientation } from './Orientation';

interface IProps {
   children: ReactNode;
   orientation: Orientation;
   onExpand: () => void;
   disabled?: boolean;
   context?: string;
}

export default function CollapsedPanel({
   children,
   orientation,
   onExpand,
   disabled,
   context,
}: IProps): ReactElement {
   function getToggleIcon(): any {
      switch (orientation) {
         case Orientation.Left:
            return <LeftOutlined />;

         case Orientation.Right:
            return <RightOutlined />;

         case Orientation.Top:
            return <UpOutlined className="text-main" />;

         case Orientation.Bottom:
            return <DownOutlined className="text-main" />;

         default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Unknonw "${orientation}" orientation type`);
      }
   }
   // const getPLayoutToggleIcon = (): JSX.IntrinsicElements["img"] | null => {
   //   const getIcon = (icon: any, size = 14): JSX.IntrinsicElements["img"] =>
   // (<img src={icon} style={{ width: size, height: size }} alt="p-layout-toggle-icon" />);
   //   if(orientation === Orientation.Bottom){
   //     return getIcon(bottomSideSplitterIcon);
   //   }
   //   if(orientation === Orientation.Top){
   //     return getIcon(topSideSplitterIcon);
   //   }
   //   return null;
   // };
   const isCollapsedPanelVisible = (): boolean => {
      if (context === 'p-layout' && orientation === 'left') {
         return false;
      }
      if (orientation === 'right' && context !== 'p-layout') {
         return false;
      }
      if (context === 'p-layout-schema' && orientation === 'top') {
         return true;
      }
      return true;
   };

   return (
      <div
         className={`splitter-collapsed-panel ${orientation} ${context} !bg-default`}
         style={{ display: !isCollapsedPanelVisible() ? 'none' : '' }}
      >
         {context !== 'p-layout' ||
         (context === 'p-layout' && orientation !== 'right') ? (
            <Button
               size="small"
               type="link"
               onClick={onExpand}
               disabled={disabled}
               className="!h-full"
            >
               {/* <LeftOutlined /> */}
               {getToggleIcon()}
               {/* <FontAwesomeIcon
                  className="toggle-button"
                  icon={getToggleIcon()}
               /> */}
            </Button>
         ) : null}
         <div className="splitter-content-panel-content">{children}</div>
      </div>
   );
}
