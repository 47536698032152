/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Alert, Button, Card, Dropdown, type MenuProps } from 'antd';
import React, { type SetStateAction, type Dispatch, useState } from 'react';
import serverDatabaseIcon from '../../assets/svgs/server-database-icon.svg';
import defaultDatasourceImage from '../../assets/svgs/default_datasource.svg';
import Meta from 'antd/es/card/Meta';
import moment from 'moment';
import { type IDataSource } from '../../providers/models/IDataSource';
import { DataSourcePageType } from './DataSource';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import DeleteDataSourceModal from '../../modals/DeleteDataSourceModal';

interface IDataSourceList {
   setEditDataSource: Dispatch<SetStateAction<IDataSource | undefined>>;
   setDataSourcePageType: Dispatch<SetStateAction<DataSourcePageType>>;
   dataSources: IDataSource[] | null | undefined;
   deleteDS: (dataSourceId: string) => void;
   deleteDataSourceMessage: string | undefined;
}

const DataSourceList: React.FC<IDataSourceList> = (props) => {
   const {
      dataSources,
      setEditDataSource,
      setDataSourcePageType,
      deleteDS,
      deleteDataSourceMessage,
   } = props;

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedDataSource, setSelectedDataSource] = useState<
      IDataSource | undefined
   >(undefined);

   const items = (dataSource: IDataSource): MenuProps['items'] => [
      {
         label: (
            <div
               className="text-red-700"
               onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDataSource(dataSource);
                  setIsModalOpen(true);
               }}
            >
               Delete
            </div>
         ),
         key: 'delete',
      },
   ];
   return (
      <div className="p-5">
         <div className="flex flex-row items-center justify-between">
            <div className="text-3xl text-main">DataSources</div>
            {dataSources?.length !== 0 && (
               <Button
                  onClick={() => {
                     setEditDataSource(undefined);
                     setDataSourcePageType(DataSourcePageType.CREATE);
                  }}
                  type="primary"
                  size="large"
               >
                  Create Datasource
               </Button>
            )}
         </div>
         {deleteDataSourceMessage && (
            <Alert
               description={deleteDataSourceMessage}
               type="error"
               showIcon
               className="w-1/2 mt-3"
               closable={true}
            />
         )}
         <div className="grid grid-cols-4 gap-4 mt-6">
            {dataSources?.map((dataSource, index) => (
               <Card
                  key={index}
                  className="!shadow-lg cursor-pointer pe-0"
                  onClick={() => {
                     setEditDataSource(dataSource);
                     setDataSourcePageType(DataSourcePageType.EDIT);
                  }}
                  styles={{
                     body: {
                        paddingRight: '12px',
                        paddingTop: '15px',
                     },
                  }}
               >
                  <div
                     className="flex flex-row-reverse "
                     onClick={(e) => {
                        // to avoid triger card click
                        e.stopPropagation();
                     }}
                  >
                     <Dropdown
                        menu={{ items: items(dataSource) }}
                        trigger={['click']}
                     >
                        <MoreOutlined className="text-main text-xl cursor-pointer hover:text-primary" />
                     </Dropdown>
                  </div>
                  <div
                     className={`flex flex-row justify-center mb-5 me-5 ${dataSource.type === 'SNOWFLAKE' && 'mb-10'}`}
                  >
                     <img
                        width={80}
                        height={dataSource.type !== 'SNOWFLAKE' ? 100 : 80}
                        src={dataSource.icon}
                     />
                  </div>
                  <Meta
                     avatar={
                        <img width={20} height={30} src={serverDatabaseIcon} />
                     }
                     title={dataSource.name}
                     description={
                        <div>
                           <span className="">Type: &nbsp;</span>
                           {dataSource.type}
                        </div>
                     }
                  />
                  <div className="text-main flex mt-4 flex-row ms-[35px]">
                     Last updated{' '}
                     {moment
                        .duration(
                           moment().diff(
                              moment(
                                 dataSource.last_modified_at,
                                 moment.ISO_8601,
                              ),
                           ),
                        )
                        .humanize() + ' ago'}
                  </div>
               </Card>
            ))}
         </div>
         {dataSources?.length === 0 && (
            <div className="flex flex-row justify-center h-[calc(100vh-100px)]">
               <div className="flex flex-col justify-center w-1/2 px-16  gap-2">
                  {/* <Alert
                     className="py-2 mb-16 flex items-center gap-2"
                     message={
                        <div className="text-sm font-semibold mb-0">
                           No Datasource available
                        </div>
                     }
                     description={
                        <div className="text-sm mt-0">
                           You need at least one datasource to create a
                           project.&nbsp;
                        </div>
                     }
                     type="warning"
                     showIcon
                     icon={<WarningFilled />}
                  /> */}
                  <div className="text-primary text-xs uppercase font-bold">
                     Datasources
                  </div>
                  <div className="text-main text-base uppercase font-bold">
                     Create a single source of truth for all the data in your
                     organization
                  </div>
                  <div className="text-border text-xs">
                     Bring data from multiple Sources in near real-time and
                     analyze it using your favorite BI tool.
                     <br />
                     <br />
                     {/* <Link
                        to="https://docs.data.com/destinations/data-warehouses/snowflake/
                                 #create-and-configure-your-snowflake-warehouse"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary text-sm"
                     >
                        Learn More <ExportOutlined className="ms-1" />
                     </Link> */}
                  </div>

                  <div>
                     <Button
                        type="primary"
                        className="mt-4"
                        onClick={() => {
                           setEditDataSource(undefined);
                           setDataSourcePageType(DataSourcePageType.CREATE);
                        }}
                     >
                        <PlusOutlined /> Add Datasource
                     </Button>
                  </div>
               </div>
               <div className="w-1/2 flex flex-col justify-center">
                  <div className="w-10/12 flex justify-center py-16 rounded-md bg-border bg-opacity-10">
                     <img src={defaultDatasourceImage} />
                  </div>
               </div>
            </div>
         )}
         {isModalOpen && (
            <DeleteDataSourceModal
               isModalOpen={isModalOpen}
               onOk={() => {
                  deleteDS(selectedDataSource?.id ?? '');
                  setIsModalOpen(false);
               }}
               onCancel={() => {
                  setIsModalOpen(false);
               }}
               dataSourceName={selectedDataSource?.name ?? ''}
            />
         )}
      </div>
   );
};

export default DataSourceList;
