/* eslint-disable @typescript-eslint/dot-notation */
import React, { useEffect } from 'react';
import { Button, Modal } from 'antd';
import {
   type IDBTRunJobResponse,
   useProjectJobLogFiles,
} from '../../../providers/useDBTJob';
import { useGlobalState } from '../../../context/GlobalContext';
import moment from 'moment';
import { CopyOutlined } from '@ant-design/icons';
import Loader from '../../../components/Loader';
import { Editor } from '@monaco-editor/react';
import { getEditorTheme } from '../../../utils/Utils';

const options = {
   readOnly: true,
   domReadOnly: true,
   minimap: { enabled: false },
};

interface IJobHistoryLogsModal {
   jobHistoryData: IDBTRunJobResponse;
   isModalOpen: boolean;
   onOk: () => void;
}
const JobHistoryLogsModal: React.FC<IJobHistoryLogsModal> = (props) => {
   const { jobHistoryData, isModalOpen, onOk } = props;
   const { messageApi, currentTheme } = useGlobalState();
   const {
      data: dbtJobLogData,
      isError: isDbtJobLogError,
      isPending: isDbtJobLogLoading,
      error: dbtJobLogError,
   } = useProjectJobLogFiles(jobHistoryData.id);

   useEffect(() => {
      if (isDbtJobLogError) {
         showError(`${dbtJobLogError.message}`);
      }
      if (dbtJobLogData !== undefined) {
         console.log(dbtJobLogData);
      }
   }, [isDbtJobLogLoading, dbtJobLogData]);

   const showError = (message: string): void => {
      void messageApi?.open({
         type: 'error',
         content: message,
      });
   };

   const showSuccessMessage = (message: string): void => {
      void messageApi?.open({
         type: 'success',
         content: message,
      });
   };

   return (
      <>
         {isDbtJobLogLoading && <Loader />}
         <Modal
            title="Job Details"
            open={isModalOpen}
            onOk={() => {
               onOk();
            }}
            onCancel={() => {
               onOk();
            }}
            footer={[
               <Button key="submit" type="primary" onClick={onOk}>
                  Ok
               </Button>,
            ]}
            width={'65%'}
            className={`${currentTheme}`}
         >
            <div className="flex flex-row my-5">
               <div className="flex flex-col gap-2 w-[15%]">
                  <div className="">Status</div>
                  <div className="">User</div>
                  <div className="">Start time</div>
                  <div className="">Type</div>
               </div>
               <div className="flex flex-col gap-2 w-1/2">
                  <div>{jobHistoryData.job_status}</div>
                  <div>{jobHistoryData.created_by}</div>
                  <div>
                     {moment
                        .duration(
                           moment().diff(
                              moment(
                                 jobHistoryData.created_at,
                                 moment.ISO_8601,
                              ),
                           ),
                        )
                        .humanize() + ' ago'}
                  </div>
                  <div>{jobHistoryData.name}</div>
               </div>
            </div>
            <div className="flex flex-col w-[98%] border-solid border-[0.5px] border-gray-400 p-2 pb-3">
               <div className="mb-2 text-[17px]">
                  Logs
                  <CopyOutlined
                     className="ms-2 cursor-pointer"
                     onClick={() => {
                        let content = '';
                        if (dbtJobLogData !== undefined) {
                           content = dbtJobLogData['content'];
                        }
                        void navigator.clipboard.writeText(content);
                        showSuccessMessage('Copied Succesfully');
                     }}
                  />
               </div>
               <div>
                  {dbtJobLogData !== undefined && (
                     <Editor
                        height="40vh"
                        width="100%"
                        theme={getEditorTheme(currentTheme)}
                        defaultLanguage="sql"
                        defaultValue={
                           dbtJobLogData !== undefined
                              ? dbtJobLogData['content']
                              : ''
                        }
                        options={options}
                     />
                  )}
               </div>
            </div>
         </Modal>
      </>
   );
};

export default JobHistoryLogsModal;
