import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { showError } from '../../../utils/Utils';
import { useGlobalState } from '../../../context/GlobalContext';
import { LocalStorage } from '../../../utils/LocalStorage';
import {
   KEY_GIT_TOKEN,
   KEY_GIT_USERNAME,
} from '../../../utils/LocalStorageKeys';
import GitUsernameModal from './GitUsernameModal';

interface ICreateBranchModal {
   isModalOpen: boolean;
   onCancel: () => void;
   onOk: (branchName: string) => void;
}
const CreateBranchModal: React.FC<ICreateBranchModal> = (props) => {
   const { isModalOpen, onCancel, onOk } = props;
   const [branchName, setBranchName] = useState('');
   const [isGitUsernameModalOpen, setIsGitUsernameModalOpen] =
      useState<boolean>(false);
   const { messageApi } = useGlobalState();

   const checkGitUsername = (): string[] | null => {
      const username = LocalStorage.get(KEY_GIT_USERNAME);
      const token = LocalStorage.get(KEY_GIT_TOKEN);
      if (username !== null && token !== null) {
         return [username, token];
      }
      setIsGitUsernameModalOpen(true);
      return null;
   };
   return (
      <>
         <Modal
            title={`Create new branch`}
            open={isModalOpen}
            onCancel={() => {
               onCancel();
            }}
            footer={(_) => (
               <>
                  <Button type="text" onClick={onCancel}>
                     Cancel
                  </Button>

                  <Button
                     key="submit"
                     type="primary"
                     onClick={() => {
                        if (branchName === '') {
                           showError(messageApi, 'Please Enter branch name');
                           return;
                        }
                        onOk(branchName);
                     }}
                  >
                     Create
                  </Button>
               </>
            )}
            width={'30%'}
         >
            <div className="flex flex-col my-4">
               <Input
                  placeholder="Branch Name"
                  className=""
                  size="large"
                  onChange={(e) => {
                     setBranchName(e.target.value);
                  }}
                  value={branchName}
               />
            </div>
         </Modal>
         {isGitUsernameModalOpen && (
            <GitUsernameModal
               isModalOpen={isGitUsernameModalOpen}
               onCancel={() => {
                  setIsGitUsernameModalOpen(false);
               }}
               onOk={() => {
                  setIsGitUsernameModalOpen(false);
               }}
            />
         )}
      </>
   );
};

export default CreateBranchModal;
