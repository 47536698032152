/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { THEME_DARK } from './Constants';
import postgreSqlIcon from '../assets/svgs/postgresql-icon.svg';
import mySqlIcon from '../assets/svgs/mysql-icon.svg';
import snowflakeIcon from '../assets/svgs/snowflake-icon.svg';
import sqlServerIcon from '../assets/svgs/sql-server-icon.svg';
import genericIcon from '../assets/png/connector-generic.png';
import { type MessageInstance } from 'antd/es/message/interface';
import { type ISchemaResponse } from '../providers/models/ISchema';
import moment from 'moment';
import { KEY_AWS_CONFIG } from './LocalStorageKeys';
import { LocalStorage } from './LocalStorage';

export const numberWithCommas = (value: string | number): string => {
   if (typeof value !== 'string') value = String(value);
   const num = value.split('.');
   return (
      num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      (num.length === 2 ? `.${num[1]}` : '')
   );
};

export const getFlooredFixed = (
   value: string | number,
   decimal: number = 2,
): string => {
   let _value = value;
   if (typeof _value === 'string') _value = Number(value);
   return (
      Math.floor(_value * Math.pow(10, decimal)) / Math.pow(10, decimal)
   ).toFixed(decimal);
};

export const validateEmail = (email: string): boolean => {
   const re = /\S+@\S+\.\S+/;
   return re.test(email);
};

export const capitalizeFirstLetter = (text: string): string => {
   return text.charAt(0).toUpperCase() + text.slice(1);
};

export function validatePassword(password: string): string {
   const passwordRegex: RegExp =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;

   if (!passwordRegex.test(password)) {
      if (password.length < 8) {
         return 'Password should be at least 8 characters long';
      }
      if (!/(?=.*[0-9])/.test(password)) {
         return 'Password should contain at least 1 number';
      }
      if (!/(?=.*[!@#$%^&*])/.test(password)) {
         return 'Password should contain at least 1 special character';
      }
      if (!/(?=.*[A-Z])/.test(password)) {
         return 'Password should contain at least 1 uppercase letter';
      }
      if (!/(?=.*[a-z])/.test(password)) {
         return '{assword should contain at least 1 lowercase letter';
      }
   }

   return ''; // Password meets all requirements
}

export const getEditorTheme = (currentTheme: string): string => {
   if (currentTheme === THEME_DARK) return 'vs-dark';
   else return 'vs-light';
};

export const getDatabaseIcon = (databaseName: string): any => {
   switch (databaseName) {
      case 'PostgreSQL': {
         return postgreSqlIcon;
      }
      case 'MySQL': {
         return mySqlIcon;
      }
      case 'SQLServer': {
         return sqlServerIcon;
      }
      case 'Snowflake': {
         return snowflakeIcon;
      }
   }
};

export const getConnectorIconbyType = (type: string): any => {
   switch (type) {
      case 'SNOWFLAKE':
         return snowflakeIcon;
      case 'MYSQL':
         return mySqlIcon;
      default:
         return genericIcon;
   }
};

export const getDriverIconbyType = (type: string): any => {
   switch (type) {
      case 'snowflake':
         return snowflakeIcon;
      case 'postgress':
         return postgreSqlIcon;
      case 'mysql':
         return mySqlIcon;
      default:
         return genericIcon;
   }
};

export const showError = (
   messageApi: MessageInstance | undefined,
   message: string,
): void => {
   void messageApi?.open({
      type: 'error',
      content: message,
   });
};

export const showSuccess = (
   messageApi: MessageInstance | undefined,
   message: string,
): void => {
   void messageApi?.open({
      type: 'success',
      content: message,
   });
};

export const convertToCatalogStructure = (arr: ISchemaResponse[]): any => {
   const catalogs = new Map();

   for (const item of arr) {
      // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
      const { catalog, schema, table_name } = item;

      // Get or create catalog entry
      const catalogEntry = catalogs.get(catalog) || { schema: new Map() };

      // Get or create schema entry
      const schemaEntry = catalogEntry.schema.get(schema) || [];

      schemaEntry.push(table_name);
      catalogEntry.schema.set(schema, schemaEntry);
      catalogs.set(catalog, catalogEntry);
   }

   // Convert Map to desired structure
   console.log({ catalogs: Object.fromEntries(catalogs.entries()) });
   const catalogsArray = [];
   let i = 0;
   for (const [catalogName, catalogEntry] of Array.from(catalogs.entries())) {
      const schemaOptions = [];
      let j = 0;

      for (const [schema] of catalogEntry.schema.entries()) {
         schemaOptions.push({
            label: schema,
            value: schema,
            key: `${j}${i}`,
         });
         j++;
      }
      catalogsArray.push({
         label: catalogName,
         value: catalogName,
         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
         schema: schemaOptions,
         key: i,
      });
      i++;
   }
   // for (const catalog of Array.from(catalogs.entries())) {
   //    const { catalog: label, schema } = catalog; // Destructuring for clarity
   //    const options = schema.map((schemaName) => schemaName); // Extract schema names

   //    catalogsArray.push({
   //       label,
   //       title: '', // Assuming title is not provided in original data
   //       options,
   //    });
   // }

   console.log({ catalogsArray });

   return catalogsArray;
};

export const convertToCatalogStructure1 = (arr: ISchemaResponse[]): any => {
   const catalogs = new Map();

   for (const item of arr) {
      // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
      const { catalog, schema, table_name } = item;

      // Get or create catalog entry
      const catalogEntry = catalogs.get(catalog) || { schema: new Map() };

      // Get or create schema entry
      const schemaEntry = catalogEntry.schema.get(schema) || [];

      schemaEntry.push(table_name);
      catalogEntry.schema.set(schema, schemaEntry);
      catalogs.set(catalog, catalogEntry);
   }

   // Convert Map to desired structure
   console.log({ catalogs: Object.fromEntries(catalogs.entries()) });
   const catalogsArray = [];
   let i = 0;
   for (const [catalogName, catalogEntry] of Array.from(catalogs.entries())) {
      const schemaOptions: any[] = [];

      const schemas = Array.from(catalogEntry.schema.entries());
      schemas.forEach((schema, j) => {
         schemaOptions.push({
            label: schema,
            value: schema,
            key: `${j}${i}`,
         });
      });
      catalogsArray.push({
         title: catalogName,
         value: catalogName,
         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
         schema: schemaOptions,
         key: i,
      });
      i++;
   }

   return catalogsArray;
};

export const calculateTimeDifference = (
   _startTime: number,
   _endTime: number,
): string => {
   const startTime = moment(_startTime);
   const endTime = moment(_endTime);
   const duration = moment.duration(endTime.diff(startTime));
   return duration.asSeconds().toString();
};

export const isLocalhost = (): boolean => {
   return ['localhost', '127.0.0.1', ''].includes(window.location.hostname);
};

export const getSubdomain = (): string => {
   if (isLocalhost()) {
      return 'd2e0j30sfx5csv.cloudfront.net';
   }
   return window.location.host;
};

export const getDomnainUrl = (): string => {
   const protocol = isLocalhost() ? 'http' : 'https';
   const subdomain = getSubdomain();
   return `${protocol}://${subdomain}`;
};

export const signinRedirect = (): string[] => {
   const url = `${getDomnainUrl()}/`;
   return [url];
};

export const signoutRedirect = (): string[] => {
   const url = `${getDomnainUrl()}/signin`;
   return [url];
};

export const removeTokenAndNavigate = (): void => {
   LocalStorage.remove(KEY_AWS_CONFIG);
   window.location.replace('/signin');
};
