import React from 'react';
import { Button, Modal } from 'antd';

interface IDefaultSmallModal {
   title: string;
   description: string;
   cancelButtonText: string;
   okButtonText: string;
   isModalOpen: boolean;
   onCancel: () => void;
   onOk: () => void;
   isDanger?: boolean;
}
const DefaultSmallModal: React.FC<IDefaultSmallModal> = (props) => {
   const {
      title,
      description,
      cancelButtonText,
      okButtonText,
      isModalOpen,
      onCancel,
      onOk,
      isDanger = false,
   } = props;

   return (
      <>
         <Modal
            title={title}
            open={isModalOpen}
            onCancel={() => {
               onCancel();
            }}
            footer={(_, { OkBtn, CancelBtn }) => (
               <>
                  <Button type="text" onClick={onCancel}>
                     {cancelButtonText}
                  </Button>

                  <Button
                     key="submit"
                     type="primary"
                     onClick={onOk}
                     danger={isDanger}
                  >
                     {okButtonText}
                  </Button>
               </>
            )}
            width={'25%'}
         >
            <div className="flex flex-col items-center ">{description}</div>
         </Modal>
      </>
   );
};

export default DefaultSmallModal;
