/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconDataTypes } from '../components/ColumnTypeIcon';
import {
   binaryTypesListByJavaType,
   booleanTypesListByJavaType,
   dateTypesListByJavaType,
   noGroupListByJavaType,
   numericTypesListByJavaType,
   integerTypesListByJavaType,
   decimalNumTypesListByJavaType,
   stringTypesListByJavaType,
} from './Graph.service';

export const JavaDataTypes = {
   BYTE: 'byte[]',
   BOOLEAN: 'java.lang.Boolean',
   DOUBLE: 'java.lang.Double',
   FLOAT: 'java.lang.Float',
   INTEGER: 'java.lang.Integer',
   BIG_DECIMAL: 'java.math.BigDecimal',
   LONG: 'java.lang.Long',
   STRING: 'java.lang.String',
   OFFSET_DATE_TIME: 'java.time.OffsetDateTime',
   OFFSET_TIME: 'java.time.OffsetTime',
   SQL_TIMESTAMP: 'java.sql.Timestamp',
   SQL_TIME: 'java.sql.Time',
   SQL_DATE: 'java.sql.Date',
   OBJECT: 'java.lang.Object',
   ARRAY: 'java.sql.Array',
   NUMERIC: 'java.lang.Integer',
   DATE: 'java.sql.Date',
   'NUMERIC(18,2)': 'java.math.BigDecimal',
   long: 'java.lang.Long',
};

const STRING = stringTypesListByJavaType.map((str) => str.toLowerCase());
const NUMBER = [
   ...numericTypesListByJavaType.map((int) => int.toLowerCase()),
   ...binaryTypesListByJavaType.map((fl) => fl.toLowerCase()),
];
const DECIMAL = [
   ...decimalNumTypesListByJavaType.map((type) => type.toLowerCase()),
];
const NON_DECIMAL = [
   ...integerTypesListByJavaType.map((int) => int.toLowerCase()),
   ...binaryTypesListByJavaType.map((type) => type.toLowerCase()),
];
const DATE = dateTypesListByJavaType.map((d) => d.toLowerCase());
const BOOLEAN = booleanTypesListByJavaType.map((str) => str.toLowerCase());
const OTHEROBJECTS = noGroupListByJavaType.map((str) => str.toLowerCase());

export function uniformDataType(dataType: any) {
   const lowerDataType = dataType.toLowerCase().split('(')[0];
   if (STRING.includes(lowerDataType)) return 'string';
   if (NUMBER.includes(lowerDataType)) return 'number';
   if (DATE.includes(lowerDataType)) return 'date';
   if (BOOLEAN.includes(lowerDataType)) return 'boolean';
   if (OTHEROBJECTS.includes(lowerDataType)) return 'otherObjects';
   return lowerDataType;
}

export function uniformDataTypeForIcon(dataType: any) {
   const lowerDataType = dataType.toLowerCase().split('(')[0];
   if (STRING.includes(lowerDataType)) return IconDataTypes.STRING;
   if (NON_DECIMAL.includes(lowerDataType)) return IconDataTypes.NON_DECIMAL;
   if (DECIMAL.includes(lowerDataType)) return IconDataTypes.DECIMAL;
   if (DATE.includes(lowerDataType)) return IconDataTypes.DATE;
   if (BOOLEAN.includes(lowerDataType)) return IconDataTypes.BOOLEAN;
   if (OTHEROBJECTS.includes(lowerDataType)) return IconDataTypes.OTHER_OBJECTS;
   return lowerDataType;
}

export const getTypeByJavaType = (type: string): string => {
   switch (type) {
      case JavaDataTypes.STRING:
         return 'string';

      case JavaDataTypes.BYTE:
      case JavaDataTypes.DOUBLE:
      case JavaDataTypes.FLOAT:
      case JavaDataTypes.INTEGER:
         return 'numeric';

      case JavaDataTypes.BIG_DECIMAL:
      case JavaDataTypes.LONG:
         return 'long';

      case JavaDataTypes.BOOLEAN:
         return 'boolean';

      case JavaDataTypes.OFFSET_DATE_TIME:
      case JavaDataTypes.OFFSET_TIME:
      case JavaDataTypes.SQL_TIMESTAMP:
      case JavaDataTypes.SQL_DATE:
      case JavaDataTypes.SQL_TIME:
         return 'date';

      default:
         return 'default';
   }
};
