/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useCallback, useEffect, useState } from 'react';
import { Position, type Edge, type Node, MarkerType } from 'reactflow';
import { type ICatalogModel, type IDBTCatalog } from './IModelCatalog';

interface IRelation {
   from: string;
   to: string;
   id: string;
}

export default function useFlowElements(catalog: IDBTCatalog | undefined): any {
   const [elements, setElements] = useState<any>([]);

   const buildElements = useCallback((): any => {
      const getNodesArray = (): ICatalogModel[] => {
         console.log('catalog 13', catalog);

         if (catalog?.nodes) {
            return Object.values(catalog.nodes);
         }
         return [];
      };

      const getNodesWithChildren = (): IRelation[] => {
         console.log('catalog 12', catalog);
         if (catalog?.parentMap) {
            const relations: IRelation[] = [];
            const parentsKeys = Object.keys(catalog?.parentMap);
            const parentArrData = Object.entries(catalog?.parentMap);

            parentsKeys.forEach((parentKey) => {
               parentArrData
                  .filter((el) => el[0] !== parentKey)
                  .forEach((parentArr: any) => {
                     if (parentArr[1].indexOf(parentKey) !== -1) {
                        relations.push({
                           from: parentKey,
                           to: parentArr[0],
                           id: `e${parentKey}-${parentArr[0]}`,
                        });
                     }
                  });
            });

            return relations;
         }
         return [];
      };

      const nodesInfo = getNodesArray();
      const edgesRaw = getNodesWithChildren();

      if (nodesInfo.length === 0) {
         return { nodes: [], edges: [] };
      }

      const nodes: Node[] = [];

      // const transNodeVOffest = 21.5;
      const vStep = 80;
      const hStep = 200;
      // const vBaseTransNode = vStep - transNodeVOffest;
      let vOffset = vStep;
      let hOffset = hStep;

      nodesInfo.forEach((nodeInfo) => {
         nodes.push({
            id: nodeInfo.uniqueId,
            sourcePosition: Position.Right,
            draggable: true,
            type: 'basic',
            data: nodeInfo,
            position: { x: hOffset, y: vOffset },
         });

         console.log('hOffset', hOffset);
         hOffset += hStep;
         vOffset += vStep;
      });

      const edges: Edge[] = edgesRaw.map((rawEdge) => ({
         type: 'default',
         markerEnd: {
            type: MarkerType.ArrowClosed,
         },
         data: {
            hasArrowClosed: true,
            highlight: true,
         },
         source: rawEdge.from,
         target: rawEdge.to,
         id: rawEdge.id,
      }));

      return { nodes: [...nodes], edges: [...edges] };
   }, [catalog]);

   useEffect(() => {
      setElements(buildElements());
   }, [buildElements]);

   return elements;
}
