/* eslint-disable @typescript-eslint/ban-tslint-comment */
import React, {
   type Dispatch,
   type SetStateAction,
   useEffect,
   useRef,
   useState,
} from 'react';
import { useProjectJobLogFiles } from '../../providers/useDBTJob';
import { useGlobalState } from '../../context/GlobalContext';
import { Editor, type Monaco } from '@monaco-editor/react';
import { getEditorTheme } from '../../utils/Utils';
import Loading from '../../components/Loading';
import { Alert, Table, Tabs, type TabsProps } from 'antd';
import JobHistory from './jobHistory/JobHistory';

interface IFileTabFooter {
   jobId: string | null;
   footerSize: number;
   isDbtJobPollLoading: boolean;
   isFooterRefetch: boolean;
   setIsFooterRefetch: (value: boolean) => void;
   dbtCurrentType: string;
   isModelJobLoading: boolean;
   previewQueryJobData: any;
   errorText?: string;
   setRowsAndTimeData: Dispatch<SetStateAction<string>>;
   previewQueryError: Error | null;
}
const FileTabFooter: React.FC<IFileTabFooter> = (props) => {
   const {
      jobId: id,
      footerSize,
      isDbtJobPollLoading,
      isFooterRefetch,
      setIsFooterRefetch,
      dbtCurrentType,
      isModelJobLoading,
      previewQueryJobData,
      errorText,
      setRowsAndTimeData,
      previewQueryError,
   } = props;
   const [jobId, setJobId] = useState<string | null>(null);
   const [content, setContent] = useState<string>('Execute dbt command');
   const [activekey, setActiveKey] = useState<string>('logs');
   const { messageApi, currentTheme } = useGlobalState();
   const editorRef = useRef<any>();

   const {
      data: dbtJobLogData,
      isError: isDbtJobLogError,
      isPending: isDbtJobLogLoading,
      error: dbtJobLogError,
      refetch: dbtJobLogDataRefetch,
   } = useProjectJobLogFiles(jobId);

   useEffect(() => {
      if (jobId !== '' && isFooterRefetch) {
         setActiveKey('logs');
      }
   }, [jobId, isFooterRefetch]);

   useEffect(() => {
      const intervalId = setInterval(() => {
         if (jobId !== '' && isFooterRefetch) {
            setJobId(id);
            void dbtJobLogDataRefetch();
         } else {
            clearInterval(intervalId);
         }
      }, 2000);

      return () => {
         clearInterval(intervalId);
      };
   }, [jobId, isFooterRefetch]);

   useEffect(() => {
      if (isDbtJobPollLoading) {
         setContent('executing dbt ' + dbtCurrentType + '...');
      }
   }, [isDbtJobPollLoading]);

   useEffect(() => {
      if (isModelJobLoading) {
         setActiveKey('results');
      }
   }, [isModelJobLoading]);

   useEffect(() => {
      setRowsAndTimeData('');
   }, [activekey]);

   useEffect(() => {
      if (isDbtJobLogError) {
         showError(`${dbtJobLogError.message}`);
      }

      if (
         dbtJobLogData !== undefined &&
         dbtJobLogData !== null &&
         dbtJobLogData.content !== null
      ) {
         if (dbtJobLogData.content !== '') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setContent(dbtJobLogData.content);
            setTimeout(() => {
               editorRef?.current?.revealLineInCenter(
                  editorRef?.current?.getModel().getLineCount(),
               );
            }, 200);
         }
      }
   }, [isDbtJobLogLoading, dbtJobLogData]);

   const showError = (message: string): void => {
      void messageApi?.open({
         type: 'error',
         content: message,
      });
   };

   const onChange = (key: string): void => {
      setActiveKey(key);
   };

   const getFooterSize = (): string => {
      return 'overflow-auto h-[' + (footerSize - 65) + 'px]';
   };

   const items: TabsProps['items'] = [
      {
         key: 'logs',
         label: <span className="mx-5">Logs</span>,
         children: (
            <Editor
               height={footerSize}
               width="100%"
               theme={getEditorTheme(currentTheme)}
               defaultLanguage="sql"
               value={content}
               options={{
                  readOnly: true,
                  domReadOnly: true,
                  guides: {
                     indentation: true,
                  },
                  renderLineHighlight: false,
                  minimap: { enabled: false },
               }}
               onMount={(editor: any, monaco: any) => {
                  editorRef.current = editor;
               }}
            />
         ),
      },
      {
         key: 'results',
         label: <span className="mx-5">Results</span>,
         children: (
            <div className="mx-3 mt-3">
               {isModelJobLoading && (
                  <div className="flex flex-row items-center bg-primary bg-opacity-40 rounded-md p-2">
                     <span className="text-opacity-100 text-main">
                        Executing Query &nbsp;
                     </span>
                     <span className="loading-bars text-primary" />
                  </div>
               )}
               {previewQueryError && (
                  <div className="flex flex-col">
                     <Alert message={previewQueryError.message} type="error" />
                  </div>
               )}
               <div
                  style={{ height: `${footerSize - 65}px` }}
                  className={getFooterSize()}
               >
                  {previewQueryJobData !== undefined &&
                     previewQueryJobData !== null &&
                     !isModelJobLoading && (
                        <Table
                           dataSource={previewQueryJobData?.rows ?? []}
                           columns={previewQueryJobData?.columns ?? []}
                           pagination={false}
                           bordered
                        />
                     )}
                  {errorText && !previewQueryJobData && !isModelJobLoading && (
                     <Alert
                        type="error"
                        message={errorText ?? 'Query failed'}
                        closable
                     />
                  )}
               </div>
            </div>
         ),
      },
      {
         key: 'jobHistory',
         label: <span className="mx-5">Job History</span>,

         children: (
            <div className="mx-3 mt-3">
               <div
                  style={{ height: `${footerSize - 65}px` }}
                  className={getFooterSize()}
               >
                  <JobHistory selectedTabKey={activekey} />
               </div>
            </div>
         ),
      },
   ];

   return (
      <div className="footer-tab">
         <div className="flex flex-row h-10  absolute right-14 top-0.5">
            {(isDbtJobPollLoading || isDbtJobLogLoading) && <Loading />}
         </div>
         <Tabs
            tabBarStyle={{
               marginBottom: '0px',
               paddingLeft: '40px',
            }}
            defaultActiveKey="logs"
            activeKey={activekey}
            items={items}
            onChange={onChange}
            tabBarGutter={8}
            className=""
         />
      </div>
   );
};

export default FileTabFooter;
