import React, { type Dispatch, type SetStateAction } from 'react';
import { Button, Card } from 'antd';
import { type IConnector } from '../../providers/models/IConnector';
import { DataSourcePageType } from './DataSource';

interface IStep1Configuration {
   onNextClick: () => void;
   connectors: IConnector[] | undefined | null;
   setSelectedConnector: Dispatch<SetStateAction<IConnector | undefined>>;
   setDataSourcePageType: Dispatch<SetStateAction<DataSourcePageType>>;
   selectedConnector: IConnector | undefined;
}
const Step1Configuration: React.FC<IStep1Configuration> = (props) => {
   const {
      connectors,
      setSelectedConnector,
      onNextClick,
      setDataSourcePageType,
      selectedConnector,
   } = props;

   return (
      <div>
         <div className="grid grid-cols-4 gap-4 mt-5">
            {connectors?.map((connector, index) => (
               <Card
                  key={index}
                  className={`flex flex-col justify-center items-center !shadow-lg cursor-pointer ${
                     selectedConnector !== undefined &&
                     selectedConnector.id === connector.id &&
                     'border-primary'
                  }`}
                  onClick={() => {
                     setSelectedConnector(connector);
                  }}
               >
                  <div className="flex flex-row justify-center">
                     <img width={80} height={80} src={connector.icon} />
                  </div>
                  <div className="text-center mt-3">{connector.name}</div>
               </Card>
            ))}
         </div>
         <div className="flex flex-row justify-between w-full mt-7">
            <Button
               className="ps-0"
               key="back"
               type="link"
               onClick={() => {
                  setDataSourcePageType(DataSourcePageType.LIST);
               }}
            >
               Cancel
            </Button>
            <Button
               type="primary"
               disabled={selectedConnector === undefined}
               onClick={() => {
                  onNextClick();
               }}
            >
               Next
            </Button>
         </div>
      </div>
   );
};

export default Step1Configuration;
