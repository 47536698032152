/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useRef, useState } from 'react';
import {
   ICreateProjectAction,
   useCreateProject,
} from '../../providers/useProjects';
import {
   Alert,
   Button,
   Checkbox,
   Input,
   Modal,
   Select,
   Steps,
   Switch,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
   useDataSources,
   useMetaData,
   useResultPathURL,
   useResultsPreviewDataJson,
} from '../../providers/useDataSources';
import { useGlobalState } from '../../context/GlobalContext';
import { type IDataSource } from '../../providers/models/IDataSource';
import { useDBTJobPoll } from '../../providers/useDBTJob';
import { convertToCatalogStructure, showError } from '../../utils/Utils';
import Loading from '../../components/Loading';
import TimerAlert from '../../components/TimerAlert';
import { useGetUserSecrets } from '../signin/useSignin';
import { SECRETS_INITIAL_PAYLOAD } from '../../utils/Constants';
import UserSecretModal from '../../modals/UserSecretModal';

interface ICreateProjectModal {
   isModalOpen: boolean;
   onProjectCreated: () => void;
   onCancel: () => void;
}
const CreateProjectModal: React.FC<ICreateProjectModal> = (props) => {
   const { isModalOpen, onProjectCreated, onCancel } = props;
   const [current, setCurrent] = useState(0);
   const [dataSourceList, setDataSourceList] = useState<IDataSource[]>([]);
   const [schemaList, setSchemaList] = useState<any[]>([]);
   const [catalogList, setCatalogList] = useState<any[]>([]);
   const [dSchemaList, setDSchemaList] = useState<any[]>([]);
   const [userSecrets, setUserSecrets] = useState<any[]>([]);
   const [isAlertVisible, setIsAlertVisible] = useState({
      isAlertVisible: false,
      message: '',
   });
   const [isUserSecretModalOpen, setisUserSecretModalOpen] = useState(false);
   const [isValidProjectName, setIsValidProjectName] = useState(true);
   const [projectData, setProjectData] = useState({
      projectName: '',
      catalog: '',
      schema: '',
      sourceDatabase: '',
      sourceId: '',
      destinationDatabase: '',
      destinationId: '',
      destinationSchema: '',
      isDataDiffEnabled: false,
      gitUrl: '',
      dbtVersion: 'default',
      secret: '',
   });
   const [jobId, setJobId] = useState<string | null>(null);
   const schemaSelectRef = useRef<any>();

   const navigate = useNavigate();
   const { currentTheme, messageApi } = useGlobalState();

   const {
      mutate: createProject,
      isPending: isCreateProjectLoading,
      data: createProjectData,
      isError: isCreateProjectError,
      error: createProjectError,
   } = useCreateProject();

   const {
      data: dataSources,
      isError: isDataSourcesError,
      isPending: isDataSourcesLoading,
      error: dataSourcesError,
   } = useDataSources();

   const {
      mutate: metaDataObjects,
      data: metaQueryJobData,
      isError: isMetaQueryError,
      error: metaQueryError,
      isPending: isMetaQueryLoading,
   } = useMetaData();

   const {
      mutate: getResultsPathData,
      data: resultsPathData,
      isError: isResultsPathError,
      error: resultPathError,
      isPending: isResultPathLoading,
   } = useResultPathURL();

   const {
      mutate: getPreviewData,
      data: previewQueryJobData,
      isError: isPreviewQueryError,
      error: previewQueryError,
      isPending: isPreviewQueryLoading,
   } = useResultsPreviewDataJson();

   const {
      data: schemaJobPollData,
      isError: isSchemaJobPollError,
      isPending: isSchemaJobPollLoading,
      error: schemaJobPollError,
   } = useDBTJobPoll(jobId);

   const {
      data: userSecretsData,
      isError: isUserSecretsError,
      isPending: isUserSecretsLoading,
      error: userSecretsError,
      mutate: userSecretsMutate,
   } = useGetUserSecrets();

   useEffect(() => {
      userSecretsMutate(SECRETS_INITIAL_PAYLOAD);
   }, []);

   useEffect(() => {
      if (isCreateProjectError) {
         setIsAlertVisible({
            isAlertVisible: true,
            message: createProjectError.message,
         });
         // showError(messageApi, `${createProjectError.message}`);
      }
      if (createProjectData !== undefined) {
         navigateToProject();
         // setCurrent(2);
      }
   }, [isCreateProjectLoading, createProjectData]);

   useEffect(() => {
      if (isDataSourcesError) {
         showError(messageApi, dataSourcesError.message);
      }
      if (dataSources !== null && dataSources !== undefined) {
         const arr = dataSources?.results.map((dataSource) => {
            return {
               value: dataSource.id,
               label: (
                  <span className="flex flex-row items-center gap-2">
                     <img className="w-6 h-6" src={dataSource.icon} />
                     {dataSource.name}
                  </span>
               ),
               key: dataSource.id,
               ...dataSource,
            };
         });
         setDataSourceList(arr);
      }
   }, [isDataSourcesLoading, dataSources]);

   useEffect(() => {
      if (isMetaQueryError) {
         showError(messageApi, metaQueryError.message);
      }
      if (metaQueryJobData !== null && metaQueryJobData !== undefined) {
         setJobId(metaQueryJobData.id);
      }
   }, [isMetaQueryLoading, metaQueryJobData]);

   useEffect(() => {
      if (isSchemaJobPollError) {
         showError(messageApi, schemaJobPollError.message);
      }
      if (schemaJobPollData !== undefined && schemaJobPollData !== null) {
         console.log(schemaJobPollData.message);
         // resultsPathURLData(schemaJobPollData.message);
         if (
            schemaJobPollData.message &&
            schemaJobPollData.job_status === 'COMPLETED'
         ) {
            getResultsPathData(schemaJobPollData.message);
         } else if (schemaJobPollData.job_status === 'FAILED') {
            console.log('schemaJobPollData', schemaJobPollData.message);
            showError(messageApi, schemaJobPollData.message);
         }
      }
   }, [isSchemaJobPollLoading, schemaJobPollData]);

   useEffect(() => {
      if (isResultsPathError) {
         showError(messageApi, resultPathError.message);
      }
      if (resultsPathData !== undefined && resultsPathData !== null) {
         if (resultsPathData?.url !== undefined) {
            getPreviewData(resultsPathData?.url);
         }
      }
   }, [isResultPathLoading, resultsPathData]);

   useEffect(() => {
      if (isPreviewQueryError) {
         showError(messageApi, previewQueryError.message);
      }
      if (previewQueryJobData !== undefined && previewQueryJobData !== null) {
         const arr = convertToCatalogStructure(previewQueryJobData);
         setCatalogList([...arr]);
      }
   }, [isPreviewQueryLoading, previewQueryJobData]);

   useEffect(() => {
      if (isUserSecretsError) {
         showError(messageApi, `${userSecretsError.message}`);
      }
      if (userSecretsData !== undefined && userSecretsData !== null) {
         const secrets = userSecretsData.results.map((secret) => {
            return {
               value: secret.id,
               label: secret.name,
            };
         });
         setUserSecrets(secrets);
      }
   }, [isUserSecretsLoading, userSecretsData]);

   const updateSchemaForDestDS = (selectedDSId: string): void => {
      console.log('option DSI', selectedDSId);
      const ds = dataSourceList?.find((ds) => ds.id === selectedDSId);
      const schemaOfDS = ds?.configs?.schema ?? ''; // if array then use directly
      console.log('option DS', schemaOfDS, ds);
      setDSchemaList([
         {
            value: schemaOfDS,
            label: schemaOfDS,
         },
      ]);
   };

   const updateSchemaForDS = (selectedDSId: string): void => {
      const ds = dataSourceList?.find((ds) => ds.id === selectedDSId);
      const schemaOfDS = ds?.configs?.schema ?? ''; // if array then use directly
      if (selectedDSId) {
         metaDataObjects({
            source_id: selectedDSId,
            filter: {},
         });
      }
   };

   // const updateDBForDS = (selectedDSId: string): string => {
   //    const ds = dataSourceList?.find((ds) => ds.id === selectedDSId);
   //    const dsDB = ds?.configs?.database ?? ''; // if array then use directly
   //    console.log('option DSIB', dsDB, ds);

   //    return dsDB;
   // };

   const navigateToProject = (): void => {
      navigate('/project/' + createProjectData.data.id, {
         state: {
            projectData: createProjectData.data,
         },
      });
      onProjectCreated();
   };
   const hideAlert = (): void => {
      setIsAlertVisible({
         isAlertVisible: false,
         message: '',
      });
   };

   const handleCreateProject = (): void => {
      if (current === 1) {
         hideAlert();
         if (projectData.gitUrl !== '' && projectData.secret === '') {
            setIsAlertVisible({
               isAlertVisible: true,
               message: 'Please add secret from user profile setting',
            });
            return;
         }

         const payload = {
            projectName: projectData.projectName,
            schema: projectData.schema !== '' ? projectData.schema : 'PUBLIC',
            sourceDatabase: projectData.sourceDatabase,
            materializationType: 'view',
            sourceId: projectData.sourceId,
            actionType: ICreateProjectAction.CREATE,
            destinationDatabase: projectData.isDataDiffEnabled
               ? projectData.destinationDatabase
               : '',
            destinationId:
               projectData.destinationId ??
               '23aea951-5001-43a0-9a56-d943f1d1b318',
            destinationSchema: projectData.isDataDiffEnabled
               ? projectData.destinationSchema
               : '',
            location: 'SampleLocation',
            gitUrl: projectData.gitUrl,
            type: projectData.gitUrl !== '' ? 'GIT' : 'DEFAULT',
            dbtVersion: projectData.gitUrl !== '' ? projectData.dbtVersion : '',
            secretId: projectData.secret,
         };
         createProject(payload);
         return;
      }

      if (current === 2) {
         onProjectCreated();
         return;
      }

      if (
         isValidProjectName &&
         projectData.projectName !== '' &&
         projectData.sourceDatabase !== '' &&
         projectData.schema !== '' &&
         (projectData.isDataDiffEnabled
            ? projectData.destinationSchema !== '' &&
              projectData.destinationDatabase !== ''
            : true)
      ) {
         setCurrent(current + 1);
      } else {
         showError(messageApi, 'Please fill out the complete form');
      }
   };

   return (
      <>
         <Modal
            title={
               <div className="text-main text-xl opacity-80">
                  Create Project
               </div>
            }
            open={isModalOpen}
            maskClosable={false}
            onCancel={() => {
               onCancel();
            }}
            footer={() => (
               <div className="flex flex-row justify-between items-center">
                  {/* {current === 0 && (
                     <div className="flex items-center gap-3 text-xs text-gray-400">
                        Data Diff
                        <Switch
                           checked={projectData.isDataDiffEnabled}
                           onChange={(checked) => {
                              setProjectData((prev) => {
                                 return { ...prev, isDataDiffEnabled: checked };
                              });
                           }}
                        />
                     </div>
                  )} */}
                  {/* {current !== 1 &&  */}
                  <div></div>
                  {/* } */}
                  {/* {current === 1 && (
                     <div className="flex items-center gap-2 text-xs text-gray-400">
                        <Checkbox
                           value={isPrivateRepository}
                           onChange={(e) => {
                              setIsPrivateRepository(e.target.checked);
                           }}
                        />
                        Private Repository
                     </div>
                  )} */}

                  <div className="flex gap-4">
                     <Button
                        key="back"
                        onClick={() => {
                           if (current === 1) {
                              hideAlert();
                              setCurrent(current - 1);
                           } else {
                              onCancel();
                           }
                        }}
                     >
                        {current === 1 ? 'Back' : 'Cancel'}
                     </Button>

                     <Button
                        key="submit"
                        type="primary"
                        onClick={handleCreateProject}
                        loading={isCreateProjectLoading}
                     >
                        {current === 0 && 'Next'}
                        {current === 1 && 'Create Project'}
                        {current === 2 && 'Done'}
                     </Button>
                  </div>
               </div>
            )}
            width={'50%'}
            className={`${currentTheme}`}
         >
            <div className="flex flex-col items-center mt-6">
               <Steps
                  current={current}
                  className="w-4/5 mb-5"
                  items={[
                     {
                        subTitle: 'Project Config',
                     },
                     {
                        subTitle: 'Optionally Clone Project',
                     },
                     // {
                     //    subTitle: 'Finished',
                     // },
                  ]}
               />
               {isAlertVisible.isAlertVisible && (
                  <div
                     style={{
                        width: '100%',
                        position: 'absolute',
                        top: '-24px',
                     }}
                  >
                     <TimerAlert
                        context="error-alert-create-project-modal"
                        message={isAlertVisible.message}
                        callback={() => {
                           hideAlert();
                        }}
                     />
                  </div>
               )}

               {current === 0 && (
                  <>
                     <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Project Name
                     </div>
                     <Input
                        placeholder="Project Name"
                        className="mb-4"
                        size="large"
                        onChange={(e) => {
                           const isAllowed =
                              /^[a-zA-Z0-9_ ]{1,}(?: [a-zA-Z0-9_ ]{1,})?$/.test(
                                 e.target.value,
                              );
                           setIsValidProjectName(isAllowed);
                           setProjectData((prev) => {
                              return {
                                 ...prev,
                                 projectName: e.target.value,
                              };
                           });
                        }}
                        status={isValidProjectName ? '' : 'error'}
                        value={projectData.projectName}
                     />
                     {!isValidProjectName && (
                        <div className="text-xs text-red-500 w-full text-start mt-[-12px] mb-2">
                           Project name must contains only letters, numbers, and
                           underscores
                        </div>
                     )}
                     <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Data Source
                     </div>
                     <Select
                        placeholder="Data Source"
                        size="large"
                        className="w-full mb-4"
                        onChange={(value: any, options: any) => {
                           // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                           updateSchemaForDS(options.id);
                           setProjectData((prev) => {
                              return {
                                 ...prev,
                                 // sourceDatabase: updateDBForDS(
                                 //    options.id as string,
                                 // ),
                                 sourceId: options.id,
                              };
                           });
                           console.log('option P', options);
                        }}
                        value={
                           projectData.sourceId === ''
                              ? undefined
                              : projectData.sourceId
                        }
                        options={dataSourceList}
                     />
                     {(isSchemaJobPollLoading ||
                        isPreviewQueryLoading ||
                        isResultPathLoading ||
                        isMetaQueryLoading) && (
                        <Loading messages="Hang on while we fetch your database schema, this may take a few seconds" />
                     )}
                     <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Database
                     </div>
                     <Select
                        placeholder="Database"
                        size="large"
                        className="w-full mb-4"
                        loading={
                           isSchemaJobPollLoading ||
                           isPreviewQueryLoading ||
                           isResultPathLoading ||
                           isMetaQueryLoading
                        }
                        onChange={(value) => {
                           const catalog = catalogList.find(
                              (catalog) => catalog.value === value,
                           );
                           setSchemaList([...catalog.schema]);
                           setProjectData((prev) => {
                              return { ...prev, sourceDatabase: value };
                           });
                        }}
                        value={
                           projectData.sourceDatabase === ''
                              ? undefined
                              : projectData.sourceDatabase
                        }
                        options={catalogList}
                     />
                     <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Schema
                     </div>
                     <Select
                        ref={schemaSelectRef}
                        placeholder="Schema"
                        size="large"
                        className="w-full mb-4"
                        mode="tags"
                        maxCount={1}
                        loading={
                           isSchemaJobPollLoading ||
                           isPreviewQueryLoading ||
                           isResultPathLoading ||
                           isMetaQueryLoading
                        }
                        onChange={(value) => {
                           setProjectData((prev) => {
                              return { ...prev, schema: value[0] };
                           });
                           schemaSelectRef.current.blur();
                        }}
                        value={
                           projectData.schema === '' ? [] : projectData.schema
                        }
                        options={schemaList}
                     />
                     <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Dbt Version
                     </div>
                     <Select
                        placeholder="Database"
                        size="large"
                        className="w-full mb-4"
                        onChange={(value) => {
                           setProjectData((prev) => {
                              return { ...prev, dbtVersion: value };
                           });
                        }}
                        value={projectData.dbtVersion}
                        options={[
                           {
                              value: 'default',
                              label: 'Default',
                           },
                           {
                              value: '1.6',
                              label: '1.6',
                           },
                           {
                              value: '1.7',
                              label: '1.7',
                           },
                           {
                              value: '1.8',
                              label: '1.8',
                           },
                        ]}
                     />
                     {(schemaJobPollData?.job_status === 'FAILED' ||
                        isMetaQueryError ||
                        isSchemaJobPollError ||
                        isResultsPathError ||
                        isPreviewQueryError) && (
                        <div>
                           <Alert
                              type="error"
                              message={
                                 schemaJobPollData?.message ?? 'Objects failed'
                              }
                           />
                        </div>
                     )}
                     {/* <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Destination DS
                     </div>
                     <Select
                        disabled={!projectData.isDataDiffEnabled}
                        placeholder="Destination DS"
                        size="large"
                        className="w-full mb-6"
                        onChange={(value, options: any) => {
                           // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                           updateSchemaForDestDS(options.id);
                           setProjectData((prev) => {
                              return {
                                 ...prev,
                                 destinationDatabase: updateDBForDS(
                                    options.id as string,
                                 ),
                                 destinationId: options.id,
                              };
                           });
                        }}
                        options={dataSourceList}
                     />
                     <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Destination Schema
                     </div>
                     <Select
                        disabled={!projectData.isDataDiffEnabled}
                        placeholder="Destination Schema"
                        size="large"
                        className="w-full mb-6"
                        onChange={(value) => {
                           setProjectData((prev) => {
                              return { ...prev, destinationSchema: value };
                           });
                        }}
                        options={schemaList}
                     /> */}
                  </>
               )}
               {current === 1 && (
                  <>
                     <div className="text-sm text-gray-400 w-full text-start mb-4">
                        Optionally you can add git URL to clone existing project
                     </div>
                     <div className="text-xs text-gray-400 w-full text-start mb-2">
                        Git Url
                     </div>
                     <Input
                        placeholder="Git Url"
                        className="mb-4"
                        size="large"
                        onChange={(e) => {
                           setProjectData((prev) => {
                              return { ...prev, gitUrl: e.target.value };
                           });
                        }}
                        value={projectData.gitUrl}
                     />

                     {userSecrets.length > 0 && (
                        <>
                           <div className="text-xs text-gray-400 w-full text-start mb-2">
                              Git Credentials
                           </div>
                           <Select
                              placeholder="Git Credentials "
                              size="large"
                              className="w-full mb-4"
                              onChange={(value) => {
                                 setProjectData((prev) => {
                                    return { ...prev, secret: value };
                                 });
                              }}
                              value={
                                 projectData.secret === ''
                                    ? undefined
                                    : projectData.secret
                              }
                              options={userSecrets}
                           />
                        </>
                     )}

                     <div
                        className="w-full text-border text-sm cursor-pointer underline"
                        onClick={() => {
                           setisUserSecretModalOpen(true);
                        }}
                     >
                        Add new Git Credentials
                     </div>
                  </>
               )}
               {current === 2 && (
                  <>
                     <div className="text-xl">Project Successfully Created</div>
                     <Button
                        onClick={() => {
                           navigateToProject();
                        }}
                        type="primary"
                        size="middle"
                        className="mt-4"
                     >
                        Go To Project
                     </Button>
                  </>
               )}
            </div>
         </Modal>
         {isUserSecretModalOpen && (
            <UserSecretModal
               isModalOpen={isUserSecretModalOpen}
               onOk={() => {
                  setisUserSecretModalOpen(false);
               }}
               onCancel={() => {
                  setisUserSecretModalOpen(false);
               }}
               refetchSecrets={() => {
                  userSecretsMutate(SECRETS_INITIAL_PAYLOAD);
               }}
            />
         )}
      </>
   );
};

export default CreateProjectModal;
