/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import { type DataNode } from 'antd/lib/tree';
import defaultFileIcon from '../assets/svgs/projectDefaultFileIcon.svg';
import darkSqlIcon from '../assets/svgs/darkSqlIcon.svg';
import gitFileIcon from '../assets/svgs/projectGitFileIcon.svg';
import mdFileIcon from '../assets/svgs/projectMdFileIcon.svg';
import jsonFileIcon from '../assets/svgs/projectJsonFileIcon.svg';
import htmlFileIcon from '../assets/svgs/projectHtmlFileIcon.svg';
import ymlFileIcon from '../assets/svgs/projectYmlFileIcon.svg';
import logFileIcon from '../assets/svgs/projectLogFileIcon.svg';
import javaFileIcon from '../assets/svgs/projectJavaFileIcon.svg';
import sqlFileIcon from '../assets/png/sql-file-icon.png';
import { type IFile } from './types/files';
import { type ITreeParsedRawFile, type ITreeRawDirectory } from './types/tree';
import {
   getObjectByPath,
   getParsedRawFile,
   getSortedByAlphabet,
   getSortedByLeaf,
} from './helpers';

const getIcon = (iconImg: any, size = 12): any => (
   <img src={iconImg} height={size} width={size} alt="tree-icon-img" />
);

export const getIconPathForFileType = (fileTitle: string): any => {
   if (fileTitle.endsWith('.sql')) {
      return sqlFileIcon;
   }
   if (
      fileTitle.endsWith('.csv') ||
      fileTitle.endsWith('.msgpack') ||
      fileTitle.endsWith('.gpickle')
   ) {
      return defaultFileIcon;
   }
   if (fileTitle.endsWith('.gitkeep')) {
      return gitFileIcon;
   }
   if (fileTitle.endsWith('.html')) {
      return htmlFileIcon;
   }
   if (fileTitle.endsWith('.json')) {
      return jsonFileIcon;
   }
   if (fileTitle.endsWith('.md')) {
      return mdFileIcon;
   }
   if (fileTitle.endsWith('.yml')) {
      return ymlFileIcon;
   }
   if (fileTitle.endsWith('.log')) {
      return logFileIcon;
   }
   if (fileTitle.endsWith('.java')) {
      return javaFileIcon;
   }
   return defaultFileIcon;
};
class TreeConfig {
   private structure: ITreeRawDirectory = { FILES: [] };

   private sortedElement(data: any): any {
      return getSortedByAlphabet(getSortedByLeaf(data));
   }

   public transform(sourceData: IFile[]): any {
      this.structure = this.createNewStructure();

      sourceData.forEach((rawFile) => {
         this.addFileToStructure(getParsedRawFile(rawFile));
      });

      return this.sortedElement(this.formatStructure());
   }

   private formatStructure(): any {
      const formatFile = (
         file: ITreeParsedRawFile,
         pathParts: string[],
      ): DataNode => {
         const iconPathForFile = getIconPathForFileType(file.title);
         return {
            title: file.title,
            key: [...pathParts, file.title].join('/'),
            isLeaf: file.type.toLowerCase() === 'file',
            icon:
               file.type.toLowerCase() === 'file'
                  ? getIcon(iconPathForFile)
                  : undefined,
         };
      };

      const formatObject = (
         obj: ITreeRawDirectory,
         pathParts: string[],
      ): any => {
         const result: any[] = [];

         if (obj.FILES) {
            const formattedFiles: any[] = obj.FILES.map((file) =>
               formatFile(file, pathParts),
            );
            result.push(...formattedFiles);
            // eslint-disable-next-line no-param-reassign
            delete obj.FILES;
         }

         for (const key in obj) {
            if (obj[key]) {
               result.push({
                  title: key,
                  key: [...pathParts, key].join('/'),
                  isLeaf: false,
                  children: this.sortedElement(
                     formatObject(obj[key] as ITreeRawDirectory, [
                        ...pathParts,
                        key,
                     ]),
                  ),
               });
            }
         }

         return result;
      };

      return formatObject(this.structure, []);
   }

   private addFileToStructure(file: ITreeParsedRawFile): void {
      if (file.pathParts) {
         this.fillStructure(file.pathParts);
      }
      const obj = getObjectByPath(this.structure, file.pathParts);
      obj.FILES!.push(file);
   }

   private fillStructure(pathParts: string[]): void {
      pathParts.reduce((walkedPathParts, pathPart): any => {
         const obj = getObjectByPath(this.structure, [...walkedPathParts]);

         if (!obj[pathPart]) {
            obj[pathPart] = this.createNewStructure();
         }

         return [...walkedPathParts, pathPart];
      }, []);
   }

   private createNewStructure(): ITreeRawDirectory {
      return {
         FILES: [],
      };
   }
}

export default TreeConfig;
