import React, { useEffect, useMemo, useState } from 'react';
import Settings from './Settings';
import { useDataSources } from '../../providers/useDataSources';
import { showError } from '../../utils/Utils';
import { useGlobalState } from '../../context/GlobalContext';
import { type IDataSource } from '../../providers/models/IDataSource';
import { type IProjectInfo } from '../../api/models/IProject';
import { useGitProjectBranches } from '../../providers/useGitProjects';
import { useParams } from 'react-router-dom';

interface ISettingsContainer {
   selectedProject: IProjectInfo | undefined;
}
const SettingsContainer: React.FC<ISettingsContainer> = (props) => {
   const { selectedProject } = props;
   const [dataSourceList, setDataSourceList] = useState<IDataSource[]>([]);
   const { id: projectId } = useParams();

   const { messageApi } = useGlobalState();
   const {
      data: dataSources,
      isError: isDataSourcesError,
      isPending: isDataSourcesLoading,
      error: dataSourcesError,
   } = useDataSources();

   const { data: gitBranches } = useGitProjectBranches(
      projectId,
      selectedProject,
   );

   const selectedBranch = useMemo(() => {
      return gitBranches?.find((branch) => branch.is_current_branch);
   }, [gitBranches]);

   useEffect(() => {
      if (isDataSourcesError) {
         showError(messageApi, dataSourcesError.message);
      }
      if (dataSources !== null && dataSources !== undefined) {
         const arr = dataSources?.results.map((dataSource) => {
            return {
               value: dataSource.id,
               label: (
                  <span className="flex flex-row items-center gap-2">
                     <img className="w-6 h-6" src={dataSource.icon} />
                     {dataSource.name}
                  </span>
               ),
               key: dataSource.id,
               ...dataSource,
            };
         });
         setDataSourceList(arr);
      }
   }, [isDataSourcesLoading, dataSources]);

   return (
      <Settings
         dataSourceList={dataSourceList}
         selectedProject={selectedProject}
         selectedBranch={selectedBranch}
      />
   );
};

export default SettingsContainer;
