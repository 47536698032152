import {
   CheckOutlined,
   CloseOutlined,
   CopyOutlined,
   DownOutlined,
   SyncOutlined,
} from '@ant-design/icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
   type GetProps,
   Tree,
   type TreeDataNode,
   Menu,
   Tooltip,
   Button,
   Drawer,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../../context/GlobalContext';
import { type IProjectInfo } from '../../../api/models/IProject';
import Loading from '../../../components/Loading';
import { type IDataSource } from '../../../providers/models/IDataSource';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IField,
   type IMetaDataFieldsPayload,
   type IMetaDataFieldsResponse,
} from '../../../providers/models/IMetaDataFields';
import { PollType } from './DatabasesContainer';

import ColumnTypeIcon, {
   type ColumnFieldType,
} from '../../../components/ColumnTypeIcon';
import { getTypeByJavaType } from '../../../utils/normalizeData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

library.add(faFileExcel);

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;
const { DirectoryTree } = Tree;

interface IDatabases {
   catalogDirectoryArray?: TreeDataNode[];
   currentProject: IProjectInfo | undefined;
   isLoading: boolean;
   isMetaDataFieldsLoading: boolean;
   dataSource: IDataSource | undefined;
   metaDataFieldsRequest: UseMutateFunction<
      IMetaDataFieldsResponse,
      Error,
      IMetaDataFieldsPayload,
      unknown
   >;
   tablefields: IField[];
   currentPollType: PollType;
   refetchDataSources: () => void;
}
const Databases: React.FC<IDatabases> = (props) => {
   const {
      catalogDirectoryArray,
      isLoading,
      dataSource,
      metaDataFieldsRequest,
      isMetaDataFieldsLoading,
      tablefields,
      currentPollType,
      refetchDataSources,
   } = props;
   const { messageApi } = useGlobalState();
   const [isTooltipVisible, setTooltipVisible] = useState(false);
   const [open, setOpen] = useState(false);
   const [selectedTableName, setSelectedTableName] = useState<string>('');
   const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
   const [copiedId, setCopiedId] = useState<string | null>(null);

   useEffect(() => {
      setExpandedKeys(['datasource']);
   }, [catalogDirectoryArray]);

   const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
      if (info.node.isLeaf) {
         const _node: any = info.node;
         const data = info.node.key.toString().split('/');
         const payload: IMetaDataFieldsPayload = {
            source_id: dataSource?.id ?? '',
            object: {
               catalog: data[0],
               schema: data[1],
               table_name: data[2],
               table_name_fqn: _node.table_name_fqn ?? '',
               description: _node.description ?? '',
               type: _node.type ?? '',
            },
         };
         metaDataFieldsRequest(payload);
         setSelectedTableName(data[2]);
         setOpen(true);
      }
      console.log('Trigger Select', keys, info);
   };

   const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
      setExpandedKeys(Array.from(new Set(keys)));
   };

   const hanldeCopyName = (path: string): void => {
      const modelPathArr = path.split('/');
      // const modelNameWithExt = modelPathArr[modelPathArr?.length - 1];
      let fullName = '';
      modelPathArr.forEach((value, index) => {
         fullName += `"${value}"${modelPathArr.length - 1 !== index ? '.' : ''}`;
      });
      if (fullName?.length) {
         void navigator.clipboard.writeText(fullName);
         setCopiedId(path);
         setTimeout(() => {
            setCopiedId(null);
         }, 2000);
      }
   };

   const projectMenu = (isFolder: any, path: string): any => {
      return (
         <Menu>
            <Menu.Item
               onClick={(e) => {
                  e.domEvent.stopPropagation();
                  hanldeCopyName(path);
               }}
               // icon={<FontAwesomeIcon icon={['fal', 'copy']} />}
            >
               <Tooltip title={isTooltipVisible ? 'copied' : ''}>
                  Copy Name
               </Tooltip>
            </Menu.Item>
         </Menu>
      );
   };

   const renderTitle = (
      title: string,
      menuContent: any,
      nodeData: any,
   ): React.ReactNode => {
      return (
         <div
            key={title}
            className={`flex group items-center justify-between w-full relative transition-none hover:bg-transparent
          ${nodeData.key === '0-0' && 'my-1'}`}
         >
            <span className="text-ellipsis whitespace-nowrap overflow-hidden">
               {title}
            </span>
            {nodeData.key === 'datasource' && (
               <Button
                  type="text"
                  className="invisible group-hover:visible relative "
                  onClick={(e) => {
                     refetchDataSources();
                     e.stopPropagation();
                  }}
               >
                  <SyncOutlined />
               </Button>
            )}
            {nodeData.key !== 'datasource' && (
               <Button
                  type="text"
                  className="invisible group-hover:visible relative "
                  onClick={(e) => {
                     hanldeCopyName(nodeData.key as string);
                     e.stopPropagation();
                  }}
               >
                  {copiedId === nodeData.key ? (
                     <div className=" text-[10px] text-[#52c41a]">
                        Copied <CheckOutlined />
                     </div>
                  ) : (
                     <CopyOutlined />
                  )}
               </Button>
            )}
         </div>
      );
   };

   // const getIcon = (column: any): any => {
   //    if (column?.type) {
   //       return getTypeIcon(column.type);
   //    }
   //    if (!column?.type && fact?.fields[column?.name]?.javaType) {
   //       return getTypeIcon(
   //          getTypeByJavaType(fact.fields[column?.name]?.javaType),
   //       );
   //    }
   //    return getTypeIcon('string');
   // };

   return (
      <>
         {currentPollType.valueOf() === PollType.Schema && isLoading && (
            <Loading messages="Hang on while we fetch your database schema" />
         )}
         <DirectoryTree
            showIcon
            multiple
            virtual
            showLine
            className="bg-default h-[calc(100vh-110px)] overflow-auto "
            expandedKeys={expandedKeys}
            onSelect={onSelect}
            onExpand={onExpand}
            switcherIcon={<DownOutlined />}
            treeData={catalogDirectoryArray}
            titleRender={(nodeData: any) =>
               renderTitle(
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  nodeData.title,
                  projectMenu(!nodeData.isLeaf, nodeData.key as string),
                  nodeData,
               )
            }
         />
         <Drawer
            title={
               <div className="flex flex-row justify-between">
                  <div className="text-main text-sm">{selectedTableName}</div>
                  <CloseOutlined
                     className="cursor-pointer"
                     onClick={() => {
                        setOpen(false);
                     }}
                  />
               </div>
            }
            placement="bottom"
            closable={false}
            styles={{
               header: { display: 'flex', flexDirection: 'row-reverse' },
            }}
            // closeIcon={
            //    <CloseOutlined
            //       className="cursor-pointer"
            //       onClick={() => {
            //          setOpen(false);
            //       }}
            //    />
            // }
            mask={false}
            open={open}
            getContainer={false}
         >
            {currentPollType.valueOf() === PollType.MetaData &&
               isMetaDataFieldsLoading && <Loading />}
            {!isLoading && tablefields.length === 0 && (
               <div className="flex flex-col justify-center items-center h-full">
                  <FontAwesomeIcon
                     icon={faFileExcel}
                     className="text-primary text-4xl border-[2px] border-solid p-3 px-4 rounded-full"
                  />
                  <div className="text-main text-lg mt-2">No Column Found</div>
               </div>
            )}
            {tablefields.map((field, index) => {
               return (
                  <div
                     key={index}
                     className="flex flex-row justify-between items-center"
                  >
                     <div className="gap-2 flex flex-row">
                        <div className="text-xs text-primary w-6">
                           <ColumnTypeIcon
                              fieldType={(field.type as ColumnFieldType) || ''}
                           />
                        </div>
                        <div className="text-main">{field.name}</div>
                     </div>
                     <div className="text-un-selected text-xs">
                        {getTypeByJavaType(field.type)}
                     </div>
                  </div>
               );
            })}
         </Drawer>
      </>
   );
};

export default Databases;
