/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { type IDBTCatalogJson } from './Json/IModelCatalogJson';
import { type IDBTManifestJson } from './Json/IModelManifestJson';
import type Data from '../Data';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEnv {}

interface ICatalogMetadata {
   dbtSchemaVersion: string;
   dbtVersion: string;
   generatedAt: Date | null;
   invocationId: string;
   env: IEnv;
}

interface IModelMetadata {
   type: string;
   schema: string;
   name: string;
   database: string;
   comment?: any;
   owner: string;
}

interface ICatalogColumn {
   type: string;
   index: number;
   name: string;
   comment?: any;
   description: string;
}

interface IRowCount {
   id: string;
   label: string;
   value: number;
   include: boolean;
   description: string;
}

interface ILastModified {
   id: string;
   label: string;
   value: string;
   include: boolean;
   description: string;
}

interface IBytes {
   id: string;
   label: string;
   value: number;
   include: boolean;
   description: string;
}

interface IHasStats {
   id: string;
   label: string;
   value: boolean;
   include: boolean;
   description: string;
}

interface IStats {
   rowCount: IRowCount;
   lastModified: ILastModified;
   bytes: IBytes;
   hasStats: IHasStats;
}

interface IModelDependsOn {
   macros: any[];
   nodes: string[];
}

interface IDocs {
   show: boolean;
}

// following old structure here to match ITableMeta for explorer component
interface IField {
   description: string;
   auto_increment: string;
   column_size: number;
   data_type: number;
   decimal_digits: number;
   java_type: string;
   name: string;
   nullable: string;
   sql_type: string;
   tags: string[];
   type_name: string;
}

export interface ICatalogModel {
   metadata: IModelMetadata;
   columns: Data<ICatalogColumn>;
   stats: IStats;
   uniqueId: string;
   rawSql: string;
   compiled: boolean;
   resourceType: string;
   dependsOn: IModelDependsOn;
   tags: any[];
   refs: string[][];
   description: string;
   docs: IDocs;
   compiledSql: string;
   relationName: string;
   // explorer ITableMeta to support explorer as well
   field_count: number;
   id: string;
   score: number;
   average_rating: number;
   answer_count: number;
   created_by: string;
   created_ts: number;
   query_count: number;
   vendor_name: string;
   schema: string;
   warehouse: string;
   table_name: string;
   fields: IField[];
   source_name: string;
   database_name: string;
   location: string;
   type: string;
   // table_name_fqn: string;
   score_breakdown: any;
   tag_count: number;
   comment_count: number;
   source_id: string;
   // user_activity: IUserActivityState;
   modelType: 'dbt';
   factId: string | null;
   state: string;
   topic_ids: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISources {}

interface IModelDocsOverview {
   uniqueId: string;
   packageName: string;
   rootPath: string;
   path: string;
   originalFilePath: string;
   name: string;
   blockContents: string;
}

export interface IDBTCatalog {
   metadata: ICatalogMetadata | null;
   nodes: Data<ICatalogModel> | null;
   sources: ISources | null;
   errors?: any | null;
   parentMap: Data<string[]>;
   childMap: Data<string[]>;
   docs: Data<IModelDocsOverview> | null;
}

// this func uses catalog json as primary and get required data from manifest and append to
// catalogModel. like docs, sql, raw, compiled and other cols description, model desc,
// depends model, parent, child map
export function decodeCatalogModel(
   json: IDBTCatalogJson | null,
   manifest: IDBTManifestJson | null,
   ds: any | null,
   factsJson: any[],
): IDBTCatalog {
   const modelHash: Data<ICatalogModel> = {};
   const parentHash: Data<string[]> = {};
   const childHash: Data<string[]> = {};
   console.log('***FactsJson', factsJson);

   if (manifest?.nodes) {
      Object.values(manifest.nodes).forEach((manifestNode) => {
         if (
            manifestNode &&
            (manifestNode.resource_type.toLowerCase() === 'model' ||
               manifestNode.resource_type.toLowerCase() === 'seed')
         ) {
            let nod: any;
            if (json?.nodes) {
               nod = json.nodes[manifestNode.unique_id];
            }

            if (manifest?.parent_map) {
               const nodeParentList =
                  manifest.parent_map[manifestNode.unique_id];
               if (nodeParentList) {
                  parentHash[manifestNode.unique_id] = nodeParentList;
               }
            }
            if (manifest?.child_map) {
               const nodeChildList = manifest.child_map[manifestNode.unique_id];
               if (nodeChildList) {
                  childHash[manifestNode.unique_id] = nodeChildList;
               }
            }

            const factForNode = factsJson.find(
               (f) =>
                  f.ref_project_artifact_unique_id === manifestNode?.unique_id,
            );
            const materializedType =
               manifestNode.config?.materialized === 'view' ? 'VIEW' : 'TABLE';
            console.log('*type', materializedType);
            let factState = `DBT RUN REQUIRED`;
            if (factForNode?.state) {
               factState = factForNode.state;
            } else if (factForNode) {
               factState = 'NEW';
            }

            const colsHash: Data<ICatalogColumn> = {};
            const fields: IField[] = [];
            if (manifestNode.columns || factForNode?.fields) {
               if (
                  (!manifestNode.columns ||
                     Object.values(manifestNode.columns)?.length === 0) &&
                  factForNode?.fields
               ) {
                  Object.values(factForNode.fields).forEach((fld: any) => {
                     let col;
                     if (nod?.columns) {
                        col =
                           nod.columns[fld.name] ??
                           nod.columns[fld.name.toLowerCase()];
                     }
                     if (fld) {
                        colsHash[fld.name] = {
                           index: col?.index,
                           name: fld.name,
                           type: fld.java_type ?? col?.type,
                           comment: col?.comment,
                           description: col?.description ?? '',
                        };
                        // add to fields as well
                        const field: IField = {
                           description: col?.description ?? '',
                           auto_increment: fld.auto_increment ?? '',
                           column_size: fld.column_size ?? 0,
                           data_type: fld.data_type ?? 0,
                           decimal_digits: fld.decimal_digits ?? 0,
                           java_type: fld.java_type ?? col?.type,
                           name: fld.name ?? col?.name,
                           nullable: fld.nullable ?? '',
                           sql_type: fld.sql_type ?? col?.type,
                           tags: fld.tags,
                           type_name: fld.type_name ?? col?.type,
                        };
                        fields.push(field);
                     }
                  });
               } else {
                  Object.values(manifestNode.columns).forEach((manifestCol) => {
                     const factField = factForNode?.fields?.find(
                        (f: any) =>
                           f.name.toLowerCase() ===
                           manifestCol.name.toLowerCase(),
                     );
                     let col;
                     if (nod?.columns) {
                        col =
                           nod.columns[manifestCol.name] ??
                           nod.columns[manifestCol.name.toLowerCase()];
                     }
                     if (manifestCol) {
                        colsHash[
                           factField?.name ?? col?.name ?? manifestCol.name
                        ] = {
                           index: col?.index,
                           name:
                              factField?.name ?? col?.name ?? manifestCol.name,
                           type:
                              factField?.java_type ??
                              col?.type ??
                              manifestCol.data_type,
                           comment: col?.comment,
                           description: manifestCol?.description ?? '',
                        };
                        // add to fields as well
                        const field: IField = {
                           description: manifestCol?.description ?? '',
                           auto_increment: factField?.auto_increment ?? '',
                           column_size: factField?.column_size ?? 0,
                           data_type: factField?.data_type ?? 0,
                           decimal_digits: factField?.decimal_digits ?? 0,
                           java_type: factField?.java_type ?? col?.type,
                           name: col?.name ?? manifestCol.name,
                           nullable: factField?.nullable ?? '',
                           sql_type:
                              factField?.sql_type ??
                              col?.type ??
                              manifestCol.data_type,
                           tags: factField?.tags ?? manifestCol?.tags,
                           type_name:
                              factField?.type_name ??
                              col?.type ??
                              manifestCol.data_type,
                        };
                        fields.push(field);
                     }
                  });
               }
            }

            modelHash[manifestNode.unique_id] = {
               columns: colsHash,
               metadata: nod ? { ...nod.metadata } : {},
               stats: {
                  bytes: nod?.stats.bytes,
                  hasStats: nod?.stats.has_stats,
                  lastModified: nod?.stats.last_modified,
                  rowCount: nod?.stats.row_count,
               },
               uniqueId: manifestNode.unique_id ?? nod?.unique_id,
               rawSql: manifestNode?.raw_sql,
               compiled: manifestNode?.compiled,
               resourceType: manifestNode?.resource_type,
               dependsOn: { ...manifestNode?.depends_on },
               tags: manifestNode?.tags,
               refs: manifestNode?.refs,
               description: manifestNode?.description,
               docs: {
                  show: manifestNode?.docs.show,
               },
               compiledSql: manifestNode?.compiled_sql,
               relationName: manifestNode?.relation_name,
               // ItableMeta prop
               score: 0,
               average_rating: 0,
               answer_count: 0,
               created_by: '',
               created_ts: manifestNode?.created_at,
               query_count: 0,
               vendor_name: ds?.type || '', // get here from ds vendor
               schema: nod?.metadata?.schema ?? manifestNode?.schema,
               warehouse: '',
               table_name:
                  factForNode?.table_name ??
                  nod?.metadata?.name ??
                  manifestNode?.name,
               fields,
               source_name: ds?.name ?? '',
               database_name: nod?.metadata?.database ?? manifestNode?.database,
               location: manifestNode?.root_path,
               type: manifestNode?.config?.materialized,
               // table_name_fqn: string;
               score_breakdown: null,
               tag_count: manifestNode?.tags?.length,
               comment_count: 0,
               source_id: ds?.id ?? '',
               // user_activity: IUserActivityState;
               modelType: 'dbt',
               field_count: fields?.length || 0,
               id: factForNode?.id ?? manifestNode?.unique_id,
               factId: factForNode?.id ?? null,
               state: factState,
               topic_ids: factForNode?.topic_ids ?? [],
            };
         }
      });
   }

   const docsHash: Data<IModelDocsOverview> = {};
   if (manifest?.docs) {
      Object.values(manifest.docs).forEach((doc) => {
         docsHash[doc.unique_id] = {
            uniqueId: doc.unique_id,
            packageName: doc.package_name,
            rootPath: doc.root_path,
            path: doc.path,
            originalFilePath: doc.original_file_path,
            blockContents: doc.block_contents,
            name: doc.name,
         };
      });
   }

   const catalogModel = {
      metadata: {
         dbtSchemaVersion: json?.metadata?.dbt_schema_version ?? '',
         dbtVersion: json?.metadata?.dbt_version ?? '',
         generatedAt: json?.metadata?.generated_at ?? null,
         invocationId: json?.metadata?.invocation_id ?? '',
         env: json?.metadata?.env ?? '',
      },
      nodes: modelHash,
      sources: json?.sources ?? null,
      errors: json?.errors,
      parentMap: parentHash,
      childMap: childHash,
      docs: docsHash,
   };

   return catalogModel;
}
