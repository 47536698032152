/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, {
   useEffect,
   useState,
   type Dispatch,
   type SetStateAction,
} from 'react';
import { DiffEditor } from '@monaco-editor/react';
import { Breadcrumb, Button, Tooltip } from 'antd';
import { PlayButtonState, type TabData } from '../ProjectData';
import { useParams } from 'react-router-dom';
import {
   useProjectFiles,
   useUpdateFile,
} from '../../../providers/useProjectFiles';
import Loader from '../../../components/Loader';
import { useGlobalState } from '../../../context/GlobalContext';
import { type IProjectFile } from '../../../api/models/IProjectFile';
import { getEditorTheme } from '../../../utils/Utils';
import Loading from '../../../components/Loading';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IDBTProjectPayload,
   type IDBTRunJobResponse,
} from '../../../providers/useDBTJob';
import { useGitBranchDiff } from '../../../providers/useGitProjects';
import { SaveOutlined } from '@ant-design/icons';

interface IDiffEditorTab {
   activeTabs: TabData;
   fileContent: any;
   runDbtProjectJob: UseMutateFunction<
      IDBTRunJobResponse,
      Error,
      IDBTProjectPayload,
      unknown
   >;
   runQueryPreview: (type: string, fileValue: string) => void;
   playButtonState: PlayButtonState;
   setPlayButtonState: Dispatch<SetStateAction<PlayButtonState>>;
   projectName: string;
}

const DiffEditorTab: React.FC<IDiffEditorTab> = (props) => {
   const {
      activeTabs,
      runDbtProjectJob,
      runQueryPreview,
      playButtonState,
      setPlayButtonState,
      projectName,
   } = props;
   const [fileValue, setFileValue] = useState<string>('');
   const [queryIsDirty, setQueryIsDirty] = useState<boolean>(false);
   const [playButtonTargetfileData, setPlayButtonTargetFileData] =
      useState<IProjectFile | null>(null);
   const { messageApi, currentTheme } = useGlobalState();
   const { id } = useParams();

   const {
      mutate: updateFile,
      data: updateFileData,
      isError: isUpdateFileError,
      error: updateError,
      isPending: isUpdateFileLoading,
   } = useUpdateFile();

   const {
      mutate: getDiffData,
      data: diffData,
      isError: isDsiffDataError,
      error: diffDataError,
      isPending: isDiffDataLoading,
   } = useGitBranchDiff();

   const {
      data: playButtonTargetFileContent,
      isError: isPlayButtonTargetFileContentError,
      isPending: isPlayButtonTargetFileContentLoading,
      error: playButtonTargetFileContentError,
   } = useProjectFiles(playButtonTargetfileData);

   useEffect(() => {
      const filePath = activeTabs?.activeTabKey?.replace('diff/', '') ?? '';
      getDiffData({
         id: id!,
         file_path: filePath ?? '',
      });
   }, []);

   useEffect(() => {
      setQueryIsDirty(diffData?.new_file !== fileValue);
   }, [fileValue]);

   useEffect(() => {
      if (isPlayButtonTargetFileContentError) {
         void messageApi?.open({
            type: 'error',
            content: `${playButtonTargetFileContentError?.message}`,
         });
      }
      if (
         playButtonTargetFileContent !== null &&
         playButtonTargetFileContent !== undefined
      ) {
         // if (playButtonState.valueOf() === PlayButtonState.RefetchData) {
         setPlayButtonState(PlayButtonState.NotStarted);

         const content = playButtonTargetFileContent?.content;
         runQueryPreview('PREVIEW', content);
         // }
      }
   }, [playButtonTargetFileContent, isPlayButtonTargetFileContentLoading]);

   useEffect(() => {
      if (isDsiffDataError) {
         void messageApi?.open({
            type: 'error',
            content: `${diffDataError?.message}`,
         });
      }

      if (diffData !== null && diffData !== undefined) {
         setFileValue(diffData.new_file);
      }
   }, [diffData, isDiffDataLoading]);

   useEffect(() => {
      if (isUpdateFileError) {
         void messageApi?.open({
            type: 'error',
            content: `${updateError?.message}`,
         });
      }

      if (updateFileData !== null && updateFileData !== undefined) {
         // if (updateFileData.ack === 'ok') setApiFileValue(fileValue); // void fileContentRefetch();
      }
   }, [updateFileData, isUpdateFileLoading]);

   useEffect(() => {
      if (playButtonState.valueOf() === PlayButtonState.RefetchData) {
         const arr = activeTabs?.activeTabKey?.split('/');
         if (arr !== undefined) {
            const filnameWithExtention = arr[arr.length - 1];
            setPlayButtonTargetFileData({
               projectName: id!,
               path: `target/compiled/${projectName}/models/${filnameWithExtention}`,
            });
         }
      }
   }, [playButtonState]);

   const handleEditorMount = (editor: any): void => {
      const modifiedEditor = editor.getModifiedEditor();
      modifiedEditor.onDidChangeModelContent((_: any) => {
         setFileValue(`${modifiedEditor.getValue()}`);
      });
   };

   return (
      <div className="flex flex-col w-[100%] ">
         {(isUpdateFileLoading || isPlayButtonTargetFileContentLoading) && (
            <Loader />
         )}
         {isDiffDataLoading && <Loading />}
         <div className="flex flex-row justify-between items-center my-3">
            <Breadcrumb
               className="w-full ms-4 "
               separator=">"
               items={activeTabs.activeTabKey
                  ?.split('/')
                  .map((value: string) => {
                     return { title: value };
                  })}
            />
            <div className="flex flex-row-reverse w-full p-2 gap-4">
               <Tooltip title="Save">
                  <Button
                     onClick={() => {
                        const filePath =
                           activeTabs?.activeTabKey?.replace('diff/', '') ?? '';
                        updateFile({
                           path: filePath,
                           content: fileValue,
                           projectName: id!,
                        });
                     }}
                     type="primary"
                     size="middle"
                     disabled={!queryIsDirty}
                     loading={isUpdateFileLoading}
                  >
                     <SaveOutlined />
                  </Button>
               </Tooltip>
            </div>
         </div>
         <DiffEditor
            height="calc(100vh - 110px)"
            width="100%"
            theme={getEditorTheme(currentTheme)}
            original={diffData?.old_file}
            modified={diffData?.new_file}
            options={{ renderSideBySide: true }}
            onMount={(editor) => {
               handleEditorMount(editor);
            }}
         />
      </div>
   );
};

export default DiffEditorTab;
