import React, { useState } from 'react';
import Sider from 'antd/es/layout/Sider';
import {
   LockOutlined,
   MoonOutlined,
   PoweroffOutlined,
   UserOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Dropdown, type MenuProps, Switch, Tooltip } from 'antd';
import { useGlobalState } from '../context/GlobalContext';
import {
   DATA_SOURCE_STATE,
   PIPELINES_STATE,
   PROJECT_DIRECTORY_TREE_STATE,
   PROJECT_HOME_STATE,
   PROJECT_JOBS_STATE,
   THEME_DARK,
   THEME_LIGHT,
} from '../utils/Constants';
import { LocalStorage } from '../utils/LocalStorage';
import { KEY_CURRENT_THEME } from '../utils/LocalStorageKeys';
import { signOut } from 'aws-amplify/auth';
import { ReactComponent as ProjectIcon } from '../assets/svgs/project-icon.svg';
import { ReactComponent as DatabasesIcon } from '../assets/svgs/databases-icon.svg';
import { ReactComponent as PipelineIcon } from '../assets/svgs/pipeline-icon.svg';
import { ReactComponent as JobsIcon } from '../assets/svgs/jobs-icon.svg';
import { ReactComponent as HomeIcon } from '../assets/svgs/home-icon.svg';
import { ReactComponent as SideBarActive } from '../assets/svgs/sidebar-active.svg';
import UserSecretModal from '../modals/UserSecretModal';
import Loader from './Loader';
import { removeTokenAndNavigate } from '../utils/Utils';

const CustomSidebar: React.FC = () => {
   const navigate = useNavigate();
   const {
      isCollapsed,
      setIsCollapsed,
      selectedProjectState,
      setSelectedProjectState,
      currentTheme,
      setCurrentTheme,
   } = useGlobalState();
   const { id: projectId } = useParams();
   const [isUserSecretModalOpen, setisUserSecretModalOpen] = useState(false);
   const [isLodaing, setLoading] = useState(false);

   const handleSignOut = async (): Promise<void> => {
      try {
         setLoading(true);
         await signOut();
         // const user = await getCurrentUser();
         // if (!user.username.includes('google')) {
         // }
      } catch (error) {
         console.log('error signing out: ', error);
      }
      removeTokenAndNavigate();
      setLoading(false);
   };

   const items: MenuProps['items'] = [
      {
         label: (
            <div className="flex items-center gap-10">
               <div className="flex items-center gap-2">
                  <MoonOutlined />
                  <span className="text-sm font-semibold">Dark</span>
               </div>
               <Switch
                  value={currentTheme === THEME_DARK}
                  onChange={(isChecked) => {
                     const theme = isChecked ? THEME_DARK : THEME_LIGHT;
                     setCurrentTheme(theme);
                     LocalStorage.set(KEY_CURRENT_THEME, theme);
                  }}
               />
            </div>
         ),
         key: '0',
      },
      {
         label: (
            <div
               className="flex items-center gap-2"
               onClick={() => {
                  setisUserSecretModalOpen(true);
               }}
            >
               <LockOutlined />
               <span className="text-sm font-semibold">User Profile</span>
            </div>
         ),
         key: 1,
      },
      {
         label: (
            <div
               className="flex items-center gap-2"
               onClick={() => {
                  void handleSignOut();
               }}
            >
               <PoweroffOutlined />
               <span className="text-sm font-semibold">Logout</span>
            </div>
         ),
         key: 2,
      },
   ];
   return (
      <Sider className="!bg-sidebar justify-between !w-20 !max-w-20 !min-w-20 min-h-[calc(100vh-0px)]">
         {isLodaing && <Loader description="Signing out" />}
         <div className="flex flex-col justify-between min-h-screen">
            <div className="flex flex-col my-2">
               <div className="flex flex-col items-center cursor-pointer my-1">
                  <img
                     src={require(`../assets/png/logo-${currentTheme}.png`)}
                     alt="logo"
                     className="w-10 h-10"
                     onClick={() => {
                        navigate('/');
                     }}
                  />
               </div>
               <div
                  className={`flex flex-row items-center mt-10 text-un-selected cursor-pointer
                     ${
                        selectedProjectState === PROJECT_HOME_STATE &&
                        '!text-selected'
                     }`}
                  onClick={() => {
                     setSelectedProjectState(PROJECT_HOME_STATE);
                     navigate('/');
                  }}
               >
                  <SideBarActive
                     className={` ${
                        selectedProjectState === PROJECT_HOME_STATE
                           ? 'visible'
                           : 'invisible'
                     }`}
                  />
                  <div className="flex flex-col items-center w-full">
                     <HomeIcon className="size-8" />
                     <div className="text-[10px] font-bold mt-2">Home</div>
                  </div>
               </div>
               {projectId !== undefined && (
                  <div
                     className={`flex flex-row items-center mt-8 text-un-selected
                     ${
                        projectId !== undefined &&
                        selectedProjectState === PROJECT_DIRECTORY_TREE_STATE &&
                        '!text-selected'
                     }
                     ${
                        projectId === undefined &&
                        selectedProjectState === PROJECT_DIRECTORY_TREE_STATE &&
                        !isCollapsed &&
                        '!text-selected'
                     } cursor-pointer `}
                     onClick={() => {
                        if (projectId === undefined) {
                           setIsCollapsed(!isCollapsed);
                        }
                        if (projectId !== undefined) {
                           setSelectedProjectState(
                              PROJECT_DIRECTORY_TREE_STATE,
                           );
                        }
                     }}
                  >
                     <SideBarActive
                        className={` ${
                           selectedProjectState === PROJECT_DIRECTORY_TREE_STATE
                              ? 'visible'
                              : 'invisible'
                        }`}
                     />
                     <Tooltip
                        title={
                           projectId !== undefined
                              ? ''
                              : 'Create a new project or open an existing one to explore the files.'
                        }
                     >
                        <div className="flex flex-col items-center w-full">
                           <ProjectIcon className="size-8" />
                           <div className="text-[10px] font-bold mt-2">
                              Explorer
                           </div>
                        </div>
                     </Tooltip>
                  </div>
               )}
               {projectId !== undefined && (
                  <div
                     className={`flex flex-row items-center mt-8 text-un-selected cursor-pointer
                  ${
                     projectId !== undefined &&
                     selectedProjectState === PROJECT_JOBS_STATE &&
                     '!text-selected'
                  }
                  `}
                     onClick={() => {
                        if (projectId !== undefined) {
                           setSelectedProjectState(PROJECT_JOBS_STATE);
                        }
                     }}
                  >
                     <SideBarActive
                        className={` ${
                           selectedProjectState === PROJECT_JOBS_STATE
                              ? 'visible'
                              : 'invisible'
                        }`}
                     />
                     <Tooltip
                        title={
                           projectId !== undefined
                              ? ''
                              : 'Create a new project or open an existing one to see available jobs.'
                        }
                     >
                        <div className="flex flex-col items-center w-full">
                           <JobsIcon className="size-8" />
                           <div className="text-[10px] font-bold mt-2">
                              Jobs
                           </div>
                        </div>
                     </Tooltip>
                  </div>
               )}
               {projectId !== undefined && (
                  <div
                     className={`flex flex-row items-center mt-8 text-un-selected cursor-pointer
                  ${selectedProjectState === PIPELINES_STATE && '!text-selected !border-primary'}`}
                     onClick={() => {
                        setSelectedProjectState(PIPELINES_STATE);
                     }}
                  >
                     <SideBarActive
                        className={` ${
                           selectedProjectState === PIPELINES_STATE
                              ? 'visible'
                              : 'invisible'
                        }`}
                     />
                     <div className="flex flex-col items-center w-full">
                        <PipelineIcon className="size-8" />
                        <div className="text-[10px] font-bold mt-2">
                           Pipelines
                        </div>
                     </div>
                  </div>
               )}
               <div
                  className={`flex flex-row items-center mt-8 text-un-selected cursor-pointer
                  ${selectedProjectState === DATA_SOURCE_STATE && '!text-selected !border-primary'}`}
                  onClick={() => {
                     setSelectedProjectState(DATA_SOURCE_STATE);
                  }}
               >
                  <SideBarActive
                     className={` ${
                        selectedProjectState === DATA_SOURCE_STATE
                           ? 'visible'
                           : 'invisible'
                     }`}
                  />
                  <div className="flex flex-col items-center w-full">
                     <DatabasesIcon className="size-8" />

                     <div className="text-[10px] font-bold mt-2">
                        Datasources
                     </div>
                  </div>
               </div>
            </div>

            <div className="flex flex-col items-center  mb-6 ">
               <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  rootClassName={`${currentTheme}`}
               >
                  <Avatar
                     className="cursor-pointer bg-un-selected"
                     size={42}
                     icon={
                        <UserOutlined className="cursor-pointer text-selecte" />
                     }
                     // onClick={() => {
                     //    navigate('/');
                     // }}
                  />
               </Dropdown>
            </div>
         </div>
         {isUserSecretModalOpen && (
            <UserSecretModal
               isModalOpen={isUserSecretModalOpen}
               onOk={() => {
                  setisUserSecretModalOpen(false);
               }}
               onCancel={() => {
                  setisUserSecretModalOpen(false);
               }}
            />
         )}
      </Sider>
   );
};

export default CustomSidebar;
