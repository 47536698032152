/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
   ArrowDownOutlined,
   ArrowUpOutlined,
   InfoCircleOutlined,
   PullRequestOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Input, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
   type IGitStatusFile,
   type IBranch,
   type IGitStatusResponse,
} from '../../../providers/models/Json/IGitProject';
import {
   type RefetchOptions,
   type QueryObserverResult,
} from '@tanstack/react-query';
import { showError } from '../../../utils/Utils';
import { useGlobalState } from '../../../context/GlobalContext';
import ChangeBranchModal from './ChangeBranchModal';
import GitUsernameModal from './GitUsernameModal';
import { LocalStorage } from '../../../utils/LocalStorage';
import {
   KEY_GIT_TOKEN,
   KEY_GIT_USERNAME,
} from '../../../utils/LocalStorageKeys';
import { type TabData } from '../ProjectData';
import { getIconPathForFileType } from '../../../utils/TreeConfig.util';
import { type SecretData } from '../../../providers/models/IUserSecrets';

interface IGitChanges {
   gitStatuslData: IGitStatusResponse | undefined;
   gitSatusReftech: (
      options?: RefetchOptions | undefined,
   ) => Promise<QueryObserverResult<IGitStatusResponse, Error>>;
   gitBranches: IBranch[] | undefined;
   addAndCommitFiles: (
      files: string[],
      message: string,
      isAddAllFiles: boolean,
      removedFiles: string[],
   ) => void;
   gitBranchCheckout: (branchName: string) => void;
   gitBranchPullOrPush: (isPull: boolean) => void;
   statusMessage: string;
   setActiveTabs: (activeTabs: TabData) => void;
   activeTabs: TabData;
   secrets: SecretData[] | undefined;
   afterCommitMessage: string;
}
const GitChanges: React.FC<IGitChanges> = (props) => {
   const {
      gitStatuslData,
      gitSatusReftech,
      gitBranches,
      addAndCommitFiles,
      gitBranchCheckout,
      gitBranchPullOrPush,
      statusMessage,
      setActiveTabs,
      activeTabs,
      secrets,
      afterCommitMessage,
   } = props;

   const [branch, setBranch] = useState<string>('');
   const [commitMessage, setCommitMessage] = useState<string>('');
   const [unCommitedChanges, setUnCommitedChanges] = useState<IGitStatusFile[]>(
      [],
   );
   const [unTrackedChanges, setUnTrackedChanges] = useState<IGitStatusFile[]>(
      [],
   );
   const [isChangeBranchModalOpen, setIsChangeBranchModalOpen] =
      useState<boolean>(false);
   const [isGitUsernameModalOpen, setIsGitUsernameModalOpen] =
      useState<boolean>(false);
   const [isAddAllFiles, setIsAddAllFiles] = useState<boolean>(false);
   const [isAllUnCommitedChangesSlected, setIsAllUnCommitedChangesSlected] =
      useState<boolean>(false);
   const [isUnCommitedIndeterminate, setIsUnCommitedIndeterminate] =
      useState<boolean>(false);
   const [isAllUnTrackedChangesSlected, setIsAllUnTrackedChangesSlected] =
      useState<boolean>(false);
   const [isUnTrackedIndeterminate, setIsUnTrackedIndeterminate] =
      useState<boolean>(false);
   const { messageApi } = useGlobalState();

   const selectedBranch = useMemo(() => {
      return gitBranches?.find((branch) => branch.is_current_branch);
   }, [gitBranches]);

   useEffect(() => {
      if (gitStatuslData !== undefined && gitStatuslData !== null) {
         setUnCommitedChanges(gitStatuslData.uncommitted_changes);
         setUnTrackedChanges(gitStatuslData.untracked);
      }
   }, [gitStatuslData]);

   const onCheckBoxChange = (
      index: number,
      isSelected: boolean,
      isUnCommited: boolean,
   ): void => {
      const temp = isUnCommited
         ? [...unCommitedChanges]
         : [...unTrackedChanges];
      temp[index].isSelected = isSelected;
      const isAllFilesSelected = temp.every((file) => file.isSelected);
      let isSomeFilesSelected = temp.some((file) => file.isSelected);

      if (isAllFilesSelected) isSomeFilesSelected = false;

      if (isUnCommited) {
         setIsAllUnCommitedChangesSlected(isAllFilesSelected);
         setUnCommitedChanges(temp);
         setIsUnCommitedIndeterminate(isSomeFilesSelected);
      } else {
         setIsAllUnTrackedChangesSlected(isAllFilesSelected);
         setUnTrackedChanges(temp);
         setIsUnTrackedIndeterminate(isSomeFilesSelected);
      }
   };

   const checkGitUsername = (): string[] | null => {
      const username = LocalStorage.get(KEY_GIT_USERNAME);
      const token = LocalStorage.get(KEY_GIT_TOKEN);
      if (username !== null && token !== null) {
         return [username, token];
      }
      setIsGitUsernameModalOpen(true);
      return null;
   };

   const onAllUncommitedFilesChecboxClicked = (
      isChecked: boolean,
      isUnCommited: boolean,
   ): void => {
      const temp = isUnCommited
         ? [...unCommitedChanges]
         : [...unTrackedChanges];

      temp.forEach((file) => {
         file.isSelected = isChecked;
      });

      if (isUnCommited) {
         setIsAllUnCommitedChangesSlected(isChecked);
         setUnCommitedChanges(temp);
         setIsUnCommitedIndeterminate(false);
      } else {
         setIsAllUnTrackedChangesSlected(isChecked);
         setUnTrackedChanges(temp);
         setIsUnTrackedIndeterminate(false);
      }
   };

   const onFileClick = (file: IGitStatusFile): void => {
      if (file.modificationType === 'D') return;
      const key = 'diff/' + file.fileName;
      const isActiveTabExist = activeTabs.tabs.some(
         (activeTab: any) => activeTab.key === key,
      );
      if (!isActiveTabExist) {
         const path = file.fileName.split('/');
         const title = path[path.length - 1] + '(Working tree)';
         const newTab = {
            title,
            key,
            modified: false,
            icon: getIconPathForFileType(file.fileName),
            isDiffTab: true,
         };

         setActiveTabs({
            activeTabKey: key,
            tabs: [...activeTabs.tabs, newTab],
         });
      } else {
         setActiveTabs({
            activeTabKey: key,
            tabs: [...activeTabs.tabs],
         });
      }
   };

   const onCommitBtnClick = (): void => {
      if (secrets === undefined || secrets?.length === 0) {
         showError(messageApi, 'Please add secret from user profile setting');
         return;
      }
      const selectedUnCommitedChanges: string[] = [];
      const selectedUnCommitedRemovedChanges: string[] = [];
      unCommitedChanges.forEach((file) => {
         if (file.isSelected && file.modificationType !== 'D') {
            selectedUnCommitedChanges.push(file.fileName);
         }
         if (file.isSelected && file.modificationType === 'D') {
            selectedUnCommitedRemovedChanges.push(file.fileName);
         }
      });

      const selectedUnTrackedChanges: string[] = [];
      unTrackedChanges.forEach((file) => {
         if (file.isSelected) selectedUnTrackedChanges.push(file.fileName);
      });

      const allSelectedFiles = [
         ...selectedUnCommitedChanges,
         ...selectedUnTrackedChanges,
      ];
      if (
         commitMessage === '' ||
         (isAddAllFiles
            ? false
            : allSelectedFiles.length +
                 selectedUnCommitedRemovedChanges.length ===
              0)
      ) {
         showError(messageApi, 'Please add summary or select files to commit');
         return;
      }
      addAndCommitFiles(
         allSelectedFiles,
         commitMessage,
         isAddAllFiles,
         selectedUnCommitedRemovedChanges,
      );
      setCommitMessage('');
   };

   return (
      <div className="flex flex-col overflow-auto justify-between h-[calc(100vh-155px)]">
         <div className="flex flex-col mt-3">
            <div className="flex flex-row justify-between gap-1 items-center">
               <div className="text-main text-xs">{statusMessage}</div>
               <div className="flex flex-row gap-3 items-center">
                  <Tooltip title="Git status">
                     <PullRequestOutlined
                        className="text-xl hover:text-primary cursor-pointer"
                        onClick={() => {
                           void gitSatusReftech();
                        }}
                     />
                  </Tooltip>
                  <Tooltip title="Pull from remote">
                     <ArrowDownOutlined
                        className="text-xl hover:text-primary cursor-pointer"
                        onClick={() => {
                           gitBranchPullOrPush(true);
                        }}
                     />
                  </Tooltip>
                  <Tooltip title="Push to remote">
                     <ArrowUpOutlined
                        className="text-xl hover:text-primary cursor-pointer"
                        onClick={() => {
                           // const data = checkGitUsername();
                           // if (data !== null) {
                           // const [gitUsername, gitToken] = data;
                           gitBranchPullOrPush(false);
                           // }
                        }}
                     />
                  </Tooltip>
               </div>
            </div>
            <div
               className="flex flex-col p-2 mt-3 overflow-auto
                rounded-md border-solid border-[0.5px] border-gray-400 h-[40vh] "
            >
               {unCommitedChanges.length !== 0 && (
                  <>
                     <div className="flex flex-row gap-2 text-xs font-semibold items-center">
                        <Checkbox
                           checked={isAllUnCommitedChangesSlected}
                           indeterminate={isUnCommitedIndeterminate}
                           onChange={(e) => {
                              onAllUncommitedFilesChecboxClicked(
                                 e.target.checked,
                                 true,
                              );
                           }}
                        />
                        Uncommited Files
                     </div>
                     {unCommitedChanges.map((file, index) => (
                        <Tooltip title={file.fileName} key={index}>
                           <div
                              className={`flex flex-row m-1 ms-5 gap-2 cursor-pointer items-center
                     ${!file && 'rounded-sm border-solid border-[0.5px] border-gray-400'}`}
                           >
                              <div className="flex flex-row gap-2 items-center max-w-[90%]">
                                 <Checkbox
                                    checked={file.isSelected}
                                    onChange={(e) => {
                                       onCheckBoxChange(
                                          index,
                                          e.target.checked,
                                          true,
                                       );
                                    }}
                                 />
                              </div>
                              <div
                                 className="flex flex-row justify-between w-[91%]"
                                 onClick={() => {
                                    onFileClick(file);
                                 }}
                              >
                                 <div className="text-main text-xs whitespace-nowrap text-ellipsis overflow-hidden w-[90%]">
                                    {file.fileName}
                                 </div>
                                 <div className="text-primary text-[9px] border-solid border-[0.5px] border-primary rounded-sm px-1">
                                    {file.modificationType}
                                 </div>
                              </div>
                           </div>
                        </Tooltip>
                     ))}
                  </>
               )}

               {unTrackedChanges.length !== 0 && (
                  <>
                     <div className="flex flex-row gap-2 text-xs font-semibold mt-1 items-center">
                        <Checkbox
                           checked={isAllUnTrackedChangesSlected}
                           indeterminate={isUnTrackedIndeterminate}
                           onChange={(e) => {
                              onAllUncommitedFilesChecboxClicked(
                                 e.target.checked,
                                 false,
                              );
                           }}
                        />
                        Untracked Files
                     </div>
                     {unTrackedChanges.map((file, index) => (
                        <Tooltip title={file.fileName} key={index}>
                           <div
                              className={`flex flex-row m-1 ms-5 gap-2  cursor-pointer items-center
                     ${!file && 'rounded-sm border-solid border-[0.5px] border-gray-400'}`}
                           >
                              <div className="flex flex-row gap-2 items-center max-w-[90%]">
                                 <Checkbox
                                    checked={file.isSelected}
                                    onChange={(e) => {
                                       onCheckBoxChange(
                                          index,
                                          e.target.checked,
                                          false,
                                       );
                                       e.stopPropagation();
                                    }}
                                 />
                              </div>
                              <div
                                 className="flex flex-row justify-between w-[91%]"
                                 onClick={() => {
                                    onFileClick(file);
                                 }}
                              >
                                 <div className="text-main text-xs whitespace-nowrap text-ellipsis overflow-hidden w-[80%]">
                                    {file.fileName}
                                 </div>
                                 <div className="text-primary text-[9px] border-solid border-[0.5px] border-primary rounded-sm px-1">
                                    {file.modificationType}
                                 </div>
                              </div>
                           </div>
                        </Tooltip>
                     ))}
                  </>
               )}

               <div>
                  {unCommitedChanges.length === 0 &&
                     unTrackedChanges.length === 0 &&
                     'Nothing to commit, working tree clean'}
               </div>
            </div>
            <div className="text-main text-xs mt-2">
               {afterCommitMessage !== '' && 'Git commit successfull'}
               <br />
               {afterCommitMessage}
            </div>
         </div>

         <div className="flex flex-col gap-2 pt-2">
            <div className="flex flex-col px-2 py-1 border-solid border-gray-400 border-[0.5px] rounded-md mb-1">
               <div className="text-border text-xs">Current Branch</div>
               {selectedBranch?.name}
            </div>
            <Input
               placeholder="Summary"
               value={commitMessage}
               onChange={(e) => {
                  setCommitMessage(e.target.value);
               }}
               className="mb-1"
            />
            {/* <div className="flex flex-row items-center justify-between">
               <div className="flex flex-row items-center gap-2">
                  <Checkbox
                     value={isAddAllFiles}
                     onChange={(e) => {
                        setIsAddAllFiles(e.target.checked);
                     }}
                  />
                  <div className="text-main text-sm">Git Add All Files</div>
               </div>
               <Tooltip title="Delete files need to select from above list to remove">
                  <InfoCircleOutlined className="text-primary cursor-pointer" />
               </Tooltip>
            </div> */}
            <Button
               type="primary"
               onClick={() => {
                  onCommitBtnClick();
               }}
            >
               <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[90%]">
                  Commit to {selectedBranch?.label}
               </span>
            </Button>
         </div>
         {isChangeBranchModalOpen && (
            <ChangeBranchModal
               isModalOpen={isChangeBranchModalOpen}
               branchName={branch}
               onCancel={() => {
                  setIsChangeBranchModalOpen(false);
               }}
               onOk={() => {
                  setIsChangeBranchModalOpen(false);
                  gitBranchCheckout(branch);
               }}
            />
         )}
         {isGitUsernameModalOpen && (
            <GitUsernameModal
               isModalOpen={isGitUsernameModalOpen}
               onCancel={() => {
                  setIsGitUsernameModalOpen(false);
               }}
               onOk={() => {
                  setIsGitUsernameModalOpen(false);
               }}
            />
         )}
      </div>
   );
};

export default GitChanges;
