import { type ThemeConfig, theme } from 'antd';
import { THEME_DARK } from '../utils/Constants';

export const ANTD_THEME = (currentTheme: string): ThemeConfig => {
   return {
      algorithm:
         currentTheme === THEME_DARK
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
      components: {
         Button: {
            colorPrimaryBorderHover: 'red',
            colorPrimaryHover: 'rgba(var(--color-primary))',
            colorPrimary: 'rgba(var(--color-primary))',
            colorPrimaryActive: 'rgba(var(--color-primary))',
            colorPrimaryTextHover: 'lightgray',
            colorLink: 'rgba(var(--color-primary))',
         },
         Input: {
            colorPrimary: 'rgba(var(--color-primary))',
            colorPrimaryHover: 'rgba(var(--color-primary))',
            colorPrimaryActive: 'rgba(var(--color-primary))',
         },
         Pagination: {
            colorPrimary: 'rgba(var(--color-primary))',
            colorPrimaryHover: 'rgba(var(--color-primary))',
         },
         Select: {
            colorPrimary: 'rgba(var(--color-primary))',
            colorPrimaryHover: 'rgba(var(--color-primary))',
            // optionSelectedBg: 'rgba(var(--color-primary-light))',
         },
         Steps: {
            colorPrimary: 'rgba(var(--color-primary))',
         },
         Switch: {
            colorPrimary: 'rgba(var(--color-primary))',
            colorPrimaryHover: 'rgba(var(--color-primary))',
         },
         Tree: {
            directoryNodeSelectedBg: 'rgba(var(--color-primary))',
         },
         Tabs: {
            inkBarColor: 'rgba(var(--color-primary))',
            itemActiveColor: 'rgba(var(--color-primary))',
            itemSelectedColor: 'rgba(var(--color-primary))',
            itemHoverColor: 'rgba(var(--color-primary))',
         },
         Checkbox: {
            colorPrimary: 'rgba(var(--color-primary))',
            colorPrimaryHover: 'rgba(var(--color-primary))',
         },
         Radio: {
            colorPrimary: 'rgba(var(--color-primary))',
            colorPrimaryHover: 'rgba(var(--color-primary))',
         },
      },
   };
};
