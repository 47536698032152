import { useEffect, useRef, useState } from 'react';

export const useQueryValueDebounce = (value: boolean, delay = 500): boolean => {
   const [debouncedValue, setDebouncedValue] = useState(false);
   const timerRef = useRef<string | number | NodeJS.Timeout | undefined>();

   useEffect(() => {
      timerRef.current = setTimeout(() => {
         setDebouncedValue(value);
      }, delay);

      return () => {
         clearTimeout(timerRef.current);
      };
   }, [value, delay]);

   return debouncedValue;
};
