import { Button, Input, Select } from 'antd';
import React, { useState } from 'react';

interface ISubDomains {
   recentDomains: string[];
   onSubDomainClicked: (recentDomain: string) => void;
   configDataReftech: () => void;
   domain: string;
   setDomain: (domain: string) => void;
}
const SubDomains: React.FC<ISubDomains> = (props) => {
   const {
      recentDomains,
      onSubDomainClicked,
      configDataReftech,
      domain,
      setDomain,
   } = props;
   const [isDifferentDomainEnable, setIsDifferentDomainEnable] =
      useState(false);
   return (
      <>
         {recentDomains.length !== 0 && (
            <>
               <p className="text-main font-semibold mt-12">
                  Select a domain to sign in
               </p>

               <div className="flex flex-col w-full justify-center items-center gap-3">
                  {recentDomains.map((value, index) => {
                     return (
                        <div
                           className="w-full bg-gray-200 text-black text-center border-solid border-[0.5px] border-border rounded-md
               py-2 cursor-pointer"
                           key={index}
                           onClick={() => {
                              onSubDomainClicked(value);
                           }}
                        >
                           {value}
                        </div>
                     );
                  })}
               </div>
               {/* <Select
                  size="large"
                  className="w-full mt-6"
                  placeholder="Choose a Domain"
                  onChange={(value: string) => {
                     onSubDomainClicked(value);
                  }}
                  options={recentDomains.map((recentDomain, index) => {
                     return {
                        value: recentDomain,
                        label: recentDomain,
                     };
                  })}
               /> */}
               {!isDifferentDomainEnable && (
                  <div className="flex flex-row w-full justify-center mb-6 mt-6">
                     <div
                        className="text-border text-sm cursor-pointer underline"
                        onClick={() => {
                           setIsDifferentDomainEnable(true);
                        }}
                     >
                        Sign into a different domain
                     </div>
                  </div>
               )}
            </>
         )}
         {(recentDomains.length === 0 || isDifferentDomainEnable) && (
            <>
               <div className="flex flex-row items-center mb-6 mt-6 w-full gap-2">
                  <Input
                     placeholder="Enter your Domain"
                     size="large"
                     onChange={(e) => {
                        setDomain(e.target.value);
                     }}
                     value={domain}
                  />
               </div>

               <div className="flex flex-row-reverse justify-between w-full">
                  <Button
                     onClick={() => {
                        configDataReftech();
                     }}
                     type="primary"
                     size="large"
                     // className="w-full"
                  >
                     Next
                  </Button>
               </div>
            </>
         )}
      </>
   );
};

export default SubDomains;
