import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface IFallback {
   reset?: () => void;
   title: string;
   description: string;
   error?: string;
}
const Fallback: React.FC<IFallback> = (props) => {
   const { reset, title, description, error } = props;
   return (
      <div className="min-h-screen flex items-center justify-center w-full">
         <div className="max-w-md w-full px-6 py-8 bg-default bg-opacity-10 shadow-lg rounded-lg">
            <div className="flex flex-col items-center text-center">
               <InfoCircleOutlined
                  className="text-[3em] text-red-500 mb-4"
                  aria-hidden="true"
               />
               <h1 className="text-2xl font-bold text-default mb-2">{title}</h1>
               <p className="text-border mb-6">{description}</p>
               {error && <p className="text-border mb-6">{error}</p>}
               {reset && (
                  <Button onClick={reset} className="" type="primary">
                     Try again
                  </Button>
               )}
            </div>
         </div>
      </div>
   );
};

export default Fallback;
