import {
   useMutation,
   type UseMutationResult,
   useQuery,
   type UseQueryResult,
} from '@tanstack/react-query';
import { apiPost, apiGet } from '../api/ApiService';
import ApiConstants from '../api/ApiConstants';
import {
   type IGitBranchCheckoutPayload,
   type IGitBranch,
   type IGitCommit,
   type IGitProjectAddFilePayload,
   type IGitProjectCommitPayload,
   type IGitBranchCheckoutResponse,
   type IProjectGenericResponse,
   type IBranch,
   type IGitBranchPushPayload,
   type IGitStatusResponse,
   type IGitStatusJson,
   type IGitStatusFile,
   type IGitDiff,
   type IGitDiffPayload,
   type IGitCommitted,
} from './models/Json/IGitProject';
import { ACTION_PROJECT } from '../api/ApiRoutes';
import { type IProjectInfo } from '../api/models/IProject';
import { useEffect, useState } from 'react';

const IPROJECTBRANCHES = 'get_projects_branches';
const IPROJECTCOMMITS = 'get_projects_commits';
const IPROJECTSTATUS = 'git/status';

export const useGitProjectsCommit = (): UseMutationResult<
   IGitCommitted | null,
   Error,
   IGitProjectCommitPayload
> => {
   const commitProject = async (
      payload: IGitProjectCommitPayload,
   ): Promise<IGitCommitted | null> => {
      try {
         const responseApi = await apiPost<IGitCommitted>({
            path: `${ACTION_PROJECT}/${payload.id}/git/commits`,
            url: ApiConstants.workspaceBaseUrl,
            body: payload,
         });

         if (responseApi != null) {
            return responseApi.data;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error Commit`);
         }
      } catch (error: any) {
         let errText = 'Commit failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<IGitCommitted | null, Error, IGitProjectCommitPayload>({
      mutationFn: commitProject,
   });
};

export const useGitProjectsAddFile = (): UseMutationResult<
   IProjectGenericResponse,
   Error,
   IGitProjectAddFilePayload
> => {
   const addFileProject = async (
      payload: IGitProjectAddFilePayload,
   ): Promise<any> => {
      try {
         const responseApi = await apiPost<IProjectGenericResponse>({
            path: `${ACTION_PROJECT}/${payload.id}/git/add`,
            url: ApiConstants.workspaceBaseUrl,
            body: payload,
         });

         if (responseApi != null) {
            return responseApi.data;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error Commit`);
         }
      } catch (error: any) {
         let errText = 'Commit failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<
      IProjectGenericResponse,
      Error,
      IGitProjectAddFilePayload
   >({
      mutationFn: addFileProject,
   });
};

export const useGitProjectRemoveFiles = (): UseMutationResult<
   IProjectGenericResponse,
   Error,
   IGitProjectAddFilePayload
> => {
   const addFileProject = async (
      payload: IGitProjectAddFilePayload,
   ): Promise<any> => {
      try {
         const responseApi = await apiPost<IProjectGenericResponse>({
            path: `${ACTION_PROJECT}/${payload.id}/git/remove`,
            url: ApiConstants.workspaceBaseUrl,
            body: payload,
         });

         if (responseApi != null) {
            return responseApi.data;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error Commit`);
         }
      } catch (error: any) {
         let errText = 'Commit failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<
      IProjectGenericResponse,
      Error,
      IGitProjectAddFilePayload
   >({
      mutationFn: addFileProject,
   });
};

export const useGitProjectBranches = (
   projectId: string | undefined,
   selectedProject: IProjectInfo | undefined,
): UseQueryResult<IBranch[], Error> => {
   const [enabled, setEnabled] = useState(false);
   useEffect(() => {
      setEnabled(
         !(
            selectedProject?.gitUrl === undefined ||
            selectedProject?.gitUrl === ''
         ),
      );
   }, [selectedProject]);
   return useQuery({
      queryKey: [IPROJECTBRANCHES, projectId],
      staleTime: 0,
      gcTime: 100,
      queryFn: async () => {
         if (projectId) {
            const response = await apiGet<IGitBranch>({
               path: `${ACTION_PROJECT}/${projectId}/git/branches`,
               url: ApiConstants.workspaceBaseUrl,
               body: null,
            });
            return response.data?.branches.map((branch, index) => {
               return {
                  ...branch,
                  // for dropdown
                  value: branch.name,
                  label: branch.name,
               };
            });
         }
         return null;
      },
      enabled,
   });
};

export const useGetGitProjectCommits = (
   projectId: string | undefined,
): UseQueryResult<IGitCommit[], Error> => {
   return useQuery({
      queryKey: [IPROJECTCOMMITS, projectId],
      staleTime: 0,
      gcTime: 100,
      queryFn: async () => {
         if (projectId) {
            const response = await apiGet({
               path: `${ACTION_PROJECT}/${projectId}/git/commits`,
               url: ApiConstants.workspaceBaseUrl,
               body: null,
            });
            return response.data;
         }
         return null;
      },
   });
};

export const useGitProjectStatus = (
   projectId: string | undefined,
): UseQueryResult<IGitStatusResponse, Error> => {
   return useQuery({
      queryKey: [IPROJECTSTATUS, projectId],
      staleTime: 0,
      gcTime: 100,
      queryFn: async () => {
         if (projectId) {
            const response = await apiGet({
               path: `${ACTION_PROJECT}/${projectId}/git/status`,
               url: ApiConstants.workspaceBaseUrl,
               body: null,
            });
            const data = response.data as IGitStatusJson;
            const uncommittedChanges: IGitStatusFile[] =
               data.uncommitted_changes.map((value: string) => {
                  const isRemovedFile = data.missing.includes(value);
                  return {
                     fileName: value,
                     isSelected: false,
                     modificationType: isRemovedFile ? 'D' : 'M',
                  };
               });
            const untrackedChanges: IGitStatusFile[] = data.untracked.map(
               (value: string) => {
                  return {
                     fileName: value,
                     isSelected: false,
                     modificationType: 'A',
                  };
               },
            );
            const gitStatusResponse: IGitStatusResponse = {
               ...data,
               uncommitted_changes: uncommittedChanges,
               untracked: untrackedChanges,
            };

            return gitStatusResponse;
         }
         return null;
      },
   });
};
// test

export const useGitBranchCheckout = (): UseMutationResult<
   IProjectGenericResponse | null,
   Error,
   IGitBranchCheckoutPayload
> => {
   const commitProject = async (
      payload: IGitBranchCheckoutPayload,
   ): Promise<IProjectGenericResponse | null> => {
      try {
         const responseApi = await apiPost<IProjectGenericResponse>({
            path: `${ACTION_PROJECT}/${payload.id}/git/checkout?name=${payload.name}`,
            url: ApiConstants.workspaceBaseUrl,
            body: null,
         });

         if (responseApi != null) {
            return responseApi.data;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error Commit`);
         }
      } catch (error: any) {
         let errText = 'Branch Checkout failed';
         if (error?.response?.data?.error_messages !== undefined) {
            errText = error.response.data.error_messages;
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<
      IProjectGenericResponse | null,
      Error,
      IGitBranchCheckoutPayload
   >({
      mutationFn: commitProject,
   });
};

export const useGitBranchPull = (): UseMutationResult<
   IProjectGenericResponse | null,
   Error,
   IGitBranchPushPayload
> => {
   const commitProject = async (
      payload: IGitBranchPushPayload,
   ): Promise<IProjectGenericResponse | null> => {
      try {
         const responseApi = await apiPost<IProjectGenericResponse>({
            path: `${ACTION_PROJECT}/${payload.id}/git/pull`,
            url: ApiConstants.workspaceBaseUrl,
            body: {
               git_username: payload.git_username,
               git_token: payload.git_token,
            },
         });

         if (responseApi != null) {
            return responseApi.data;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error Commit`);
         }
      } catch (error: any) {
         let errText = 'Branch Pull failed';
         if (error?.response?.data?.error_messages !== undefined) {
            errText = error.response.data.error_messages;
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<
      IProjectGenericResponse | null,
      Error,
      IGitBranchPushPayload
   >({
      mutationFn: commitProject,
   });
};

export const useGitBranchPush = (): UseMutationResult<
   IProjectGenericResponse | null,
   Error,
   IGitBranchPushPayload
> => {
   const commitProject = async (
      payload: IGitBranchPushPayload,
   ): Promise<IProjectGenericResponse | null> => {
      try {
         const responseApi = await apiPost<IProjectGenericResponse>({
            path: `${ACTION_PROJECT}/${payload.id}/git/push`,
            url: ApiConstants.workspaceBaseUrl,
            body: {
               git_username: payload.git_username,
               git_token: payload.git_token,
            },
         });

         if (responseApi != null) {
            return responseApi.data;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error Commit`);
         }
      } catch (error: any) {
         let errText = 'Branch Push failed';
         if (error?.response?.data?.error_messages !== undefined) {
            errText = error.response.data.error_messages;
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<
      IProjectGenericResponse | null,
      Error,
      IGitBranchPushPayload
   >({
      mutationFn: commitProject,
   });
};

export const useGitBranchDiff = (): UseMutationResult<
   IGitDiff | null,
   Error,
   IGitDiffPayload
> => {
   const commitProject = async (
      payload: IGitDiffPayload,
   ): Promise<IGitDiff | null> => {
      try {
         const responseApi = await apiPost<IGitDiff>({
            path: `${ACTION_PROJECT}/${payload.id}/git/filediff`,
            url: ApiConstants.workspaceBaseUrl,
            body: payload,
         });

         if (responseApi != null) {
            return responseApi.data;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error Git Diff`);
         }
      } catch (error: any) {
         let errText = 'Git Diff failed';
         if (error?.response?.data?.error_messages !== undefined) {
            errText = error.response.data.error_messages;
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<IGitDiff | null, Error, IGitDiffPayload>({
      mutationFn: commitProject,
   });
};
