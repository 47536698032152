import { Radio, type RadioChangeEvent } from 'antd';
import React, { useState } from 'react';
import GitChanges from './GitChanges';
import GitHistory from './GitHistory';
import {
   type IBranch,
   type IGitStatusResponse,
} from '../../../providers/models/Json/IGitProject';
import {
   type RefetchOptions,
   type QueryObserverResult,
} from '@tanstack/react-query';
import { type TabData } from '../ProjectData';
import { type SecretData } from '../../../providers/models/IUserSecrets';

interface IGitActions {
   gitStatuslData: IGitStatusResponse | undefined;
   gitSatusReftech: (
      options?: RefetchOptions | undefined,
   ) => Promise<QueryObserverResult<IGitStatusResponse, Error>>;
   gitBranches: IBranch[] | undefined;
   addAndCommitFiles: (
      files: string[],
      message: string,
      isAddAllFiles: boolean,
      removedFiles: string[],
   ) => void;
   gitBranchCheckout: (branchName: string) => void;
   gitBranchPullOrPush: (isPull: boolean) => void;
   gitUrl: string;
   statusMessage: string;
   setActiveTabs: (activeTabs: TabData) => void;
   activeTabs: TabData;
   secrets: SecretData[] | undefined;
   afterCommitMessage: string;
}
const GitActions: React.FC<IGitActions> = (props) => {
   const [value, setValue] = useState('0');
   const onChange = ({ target: { value } }: RadioChangeEvent): void => {
      setValue(value as string);
   };
   return (
      <div className="flex flex-col px-2">
         <Radio.Group value={value} onChange={onChange} buttonStyle="solid">
            <Radio.Button value="0" style={{ width: '50%' }}>
               Changes
            </Radio.Button>
            <Radio.Button value="1" style={{ width: '50%' }}>
               History
            </Radio.Button>
         </Radio.Group>
         {value === '0' && <GitChanges {...props} />}
         {value === '1' && <GitHistory gitUrl={props.gitUrl} />}
      </div>
   );
};

export default GitActions;
