export const booleanTypesListByJavaType = [
   'BOOLEAN',
   'BIT',
   'JAVA.LANG.BOOLEAN',
   'JAVA.LANG.BIT',
];
export const binaryTypesListByJavaType = ['BINARY', 'BYTE[]'];

export const integerTypesListByJavaType = [
   'INTEGER',
   'JAVA.LANG.INTEGER',
   'LONG',
   'JAVA.LANG.LONG',
];

export const decimalNumTypesListByJavaType = [
   'DOUBLE',
   'FLOAT',
   'JAVA.LANG.DOUBLE',
   'JAVA.LANG.FLOAT',
   'JAVA.MATH.BIGDECIMAL',
   'BIGDECIMAL',
   'MATH.BIGDECIMAL',
];

export const numericTypesListByJavaType = [
   ...integerTypesListByJavaType,
   ...decimalNumTypesListByJavaType,
];

export const stringTypesListByJavaType = [
   'CHAR',
   'LONGNVARCHAR',
   'LONGVARCHAR',
   'NCHAR',
   'NVARCHAR',
   'VARCHAR',
   'STRING',
   'JAVA.LANG.CHAR',
   'JAVA.LANG.LONGNVARCHAR',
   'JAVA.LANG.LONGVARCHAR',
   'JAVA.LANG.NCHAR',
   'JAVA.LANG.NVARCHAR',
   'JAVA.LANG.VARCHAR',
   'JAVA.LANG.STRING',
];

export const dateTypesListByJavaType = [
   'TIMESTAMP_WITH_TIMEZONE',
   'TIME_WITH_TIMEZONE',
   'JAVA.SQL.TIME',
   'JAVA.SQL.TIMESTAMP',
   'JAVA.SQL.DATE',
   'JAVA.TIME.OFFSETDATETIME',
   'JAVA.TIME.OFFSETTIME',
   'TIME',
   'TIMESTAMP',
   'DATE',
   'TIME.OFFSETDATETIME',
   'TIME.OFFSETTIME',
   'OFFSETDATETIME',
   'OFFSETTIME',
];

export const noGroupListByJavaType = [
   'JAVA.LANG.OBJECT',
   'JAVA.LANG.VOID',
   'JAVA.NET.URL',
   'JAVA.SQL.ARRAY',
   'JAVA.SQL.BLOB',
   'JAVA.SQL.CLOB',
   'JAVA.SQL.NCLOB',
   'JAVA.SQL.REF',
   'JAVA.SQL.ROWLD',
   'JAVA.SQL.SQLXML',
   'JAVA.SQL.STRUCT',
   'OBJECT',
   'VOID',
   'URL',
   'ARRAY',
   'BLOB',
   'CLOB',
   'NCLOB',
   'REF',
   'ROWLD',
   'SQLXML',
   'STRUCT',
];
