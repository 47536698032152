import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { showError } from '../../../utils/Utils';
import { useGlobalState } from '../../../context/GlobalContext';
import { useUpdateFile } from '../../../providers/useProjectFiles';
import { useParams } from 'react-router-dom';
import { type TabData } from '../ProjectData';
import Loading from '../../../components/Loading';
import Loader from '../../../components/Loader';

interface ICreateFileModal {
   isModalOpen: boolean;
   onCancel: () => void;
   onOk: (filePath: string) => void;
}
const CreateFileModal: React.FC<ICreateFileModal> = (props) => {
   const { isModalOpen, onCancel, onOk } = props;
   const [fileName, setFileName] = useState<string>('');
   const { messageApi } = useGlobalState();
   const { id: projectId } = useParams();
   const {
      mutate: updateFile,
      data: updateFileData,
      isError: isUpdateFileError,
      error: updateError,
      isPending: isUpdateFileLoading,
   } = useUpdateFile();

   useEffect(() => {
      if (isUpdateFileError) {
         showError(messageApi, updateError?.message);
      }

      if (updateFileData !== null && updateFileData !== undefined) {
         if (updateFileData.ack === 'ok') {
            onOk(fileName);
         }
      }
   }, [updateFileData, isUpdateFileLoading]);

   return (
      <>
         {isUpdateFileLoading && <Loader description="Creating Model..." />}
         <Modal
            title={`Create New Model`}
            open={isModalOpen}
            onCancel={() => {
               onCancel();
            }}
            footer={(_) => (
               <>
                  <Button type="text" onClick={onCancel}>
                     Cancel
                  </Button>

                  <Button
                     key="submit"
                     type="primary"
                     onClick={() => {
                        if (fileName === '') {
                           showError(messageApi, 'Please enter Filename');
                           return;
                        }
                        let _fileName = '';
                        const fileNameSplitted = fileName.split('.');
                        if (fileNameSplitted.length < 2) {
                           _fileName = `${fileName}.sql`;
                        } else {
                           _fileName = fileName;
                        }
                        updateFile({
                           path: 'models/' + _fileName,
                           content: '',
                           projectName: projectId ?? '',
                        });
                     }}
                  >
                     Create
                  </Button>
               </>
            )}
            width={'30%'}
         >
            <div className="flex flex-col gap-4 my-4">
               <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-400 w-1/4">
                     File path
                  </label>
                  <Input
                     placeholder="File path"
                     value={'models/'}
                     size="large"
                     disabled
                  />
               </div>
               <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-400 w-1/4">
                     File Name
                  </label>
                  <Input
                     placeholder="File name"
                     value={fileName}
                     size="large"
                     onChange={(e) => {
                        setFileName(e.target.value);
                     }}
                  />
               </div>
            </div>
         </Modal>
      </>
   );
};

export default CreateFileModal;
