/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
   CaretRightOutlined,
   CheckOutlined,
   DownOutlined,
   MoreOutlined,
   PullRequestOutlined,
} from '@ant-design/icons';
import {
   Dropdown,
   Tabs,
   type TabsProps,
   type MenuProps,
   Tooltip,
   Button,
} from 'antd';
import React, { useState, useMemo, useRef } from 'react';
import { type TabData } from './ProjectData';
import ProjectsListModal from '../dashboard/ProjectsListModal';
import CreateProjectModal from '../dashboard/CreateProjectModal';
import { useNavigate, useParams } from 'react-router-dom';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IDBTProjectPayload,
   type IDBTRunJobResponse,
} from '../../providers/useDBTJob';
import Loading from '../../components/Loading';
import Workspace from './Workspace';
import DatabasesContainer from './databases/DatabasesContainer';
import {
   type IProjectInfo,
   type IProjectDeleteResponse,
   type IProjectSearchData,
} from '../../api/models/IProject';
import DeleteProjectModal from './DeleteProjectModal';
import { SETTINGS_STATE } from '../../utils/Constants';
import { useGlobalState } from '../../context/GlobalContext';
import GitActionsContainer from './git/GitActionsContainer';
import { type IBranch } from '../../providers/models/Json/IGitProject';
import ChangeBranchModal from './git/ChangeBranchModal';
import CreateBranchModal from './git/CreateBranchModal';
import { ReactComponent as GitBranchIcon } from '../../assets/svgs/git-branch-icon.svg';
import { type IFilesResponse } from '../../providers/models/IFiles';
import DefaultSmallModal from '../../modals/DefaultSmallModal';

interface IProjectStructure {
   treeData: any;
   activeTabs: TabData;
   projectSearchData: IProjectSearchData | undefined;
   setActiveTabs: (activeTabs: TabData) => void;
   onProjectCreated: () => void;
   projectInfoRefetch: () => void;
   isRunDbtProjectJobLoading: boolean;
   isDbtJobPollLoading: boolean;
   dbtCurrentType: string;
   setDbtCurrentType: (value: string) => void;
   runDbtProjectJob: UseMutateFunction<
      IDBTRunJobResponse,
      Error,
      IDBTProjectPayload,
      unknown
   >;
   isProjectLoading: boolean;
   projectName: string;
   deleteProject: UseMutateFunction<
      IProjectDeleteResponse | null,
      Error,
      string,
      unknown
   >;
   gitBranches: IBranch[] | undefined;
   gitBranchCheckout: (branchName: string) => void;
   projectInfoData: IFilesResponse | undefined;
}
const ProjectStructure: React.FC<IProjectStructure> = (props) => {
   const {
      treeData,
      activeTabs,
      setActiveTabs,
      projectSearchData,
      onProjectCreated,
      projectInfoRefetch,
      runDbtProjectJob,
      isRunDbtProjectJobLoading,
      isDbtJobPollLoading,
      dbtCurrentType,
      setDbtCurrentType,
      isProjectLoading,
      projectName,
      deleteProject,
      gitBranches,
      gitBranchCheckout,
      projectInfoData,
   } = props;
   const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
   const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] =
      useState(false);
   const [isProjectListModalOpen, setIsProjectListModalOpen] = useState(false);
   const [isModifiedFileModalOpen, setIsModifiedFileModalOpen] =
      useState(false);
   const [branch, setBranch] = useState('');
   const [isChangeBranchModal, setIsChangeBranchModal] =
      useState<boolean>(false);
   const [isCreateBranchModal, setIsCreateBranchModal] =
      useState<boolean>(false);
   const [selectedTabKey, setSelectedTabKey] = useState<string>('1');

   const { setSelectedProjectState, messageApi } = useGlobalState();
   const { id: projectId } = useParams();
   const navigate = useNavigate();
   const dbtDataRef = useRef<any>();

   const selectedBranch = useMemo(() => {
      return gitBranches?.find((branch) => branch.is_current_branch);
   }, [gitBranches]);

   const selectedProject = useMemo(() => {
      const _project = projectSearchData?.data?.find(
         (project: IProjectInfo) => project.id === projectId,
      );
      return _project;
   }, [projectId, projectSearchData]);

   const getFirstFourProjects = (_projectSearchData: any): any[] => {
      return _projectSearchData?.data
         .slice(0, 4)
         .map((data: any, index: number) => ({
            label: (
               <div
                  className=""
                  onClick={() => {
                     onProjectItemClick(data, index);
                  }}
               >
                  {data.projectName}
               </div>
            ),
            key: `${index + 1}`,
         }));
   };

   // const firstFourProjects = useMemo(
   //    () => (projectSearchData ? getFirstFourProjects(projectSearchData) : []),
   //    [projectSearchData],
   // );

   const items: MenuProps['items'] = [
      // {
      //    label: (
      //       <div
      //          className="text-primary"
      //          onClick={() => {
      //             setIsCreateModalOpen(true);
      //          }}
      //       >
      //          Create new project
      //       </div>
      //    ),
      //    key: '0',
      // },
      // ...firstFourProjects,
      // {
      //    label: (
      //       <div
      //          onClick={() => {
      //             setIsProjectListModalOpen(true);
      //          }}
      //       >
      //          More...
      //       </div>
      //    ),
      //    key: 'more',
      // },
      // {
      //    label: (
      //       <div
      //          onClick={() => {
      //             projectInfoRefetch();
      //          }}
      //       >
      //          Refresh
      //       </div>
      //    ),
      //    key: 'refresh',
      // },
      {
         label: (
            <div
               className="text-red-700"
               onClick={() => {
                  setIsDeleteProjectModalOpen(true);
               }}
            >
               Delete
            </div>
         ),
         key: 'delete',
      },
      {
         label: (
            <div
               onClick={() => {
                  setSelectedProjectState(SETTINGS_STATE);
               }}
            >
               Settings
            </div>
         ),
         key: 'settings',
      },
   ];

   const dbtActionitems: MenuProps['items'] = [
      {
         label: (
            <div
               onClick={() => {
                  runDbt('RUN');
               }}
            >
               Run
            </div>
         ),
         key: 'run',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('CLEAN');
               }}
            >
               Clean
            </div>
         ),
         key: 'clean',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('COMPILE');
               }}
            >
               Compile
            </div>
         ),
         key: 'compile',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('BUILD');
               }}
            >
               Build
            </div>
         ),
         key: 'build',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('SEED');
               }}
            >
               Seed
            </div>
         ),
         key: 'seed',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('TEST');
               }}
            >
               Test
            </div>
         ),
         key: 'test',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('DOCS');
               }}
            >
               Generate Docs
            </div>
         ),
         key: 'dbt_docs',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('DEPS');
               }}
            >
               Install Packages
            </div>
         ),
         key: 'install_packages',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('SOURCE', 'freshness');
               }}
            >
               Source Freshness
            </div>
         ),
         key: 'Source',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('SNAPSHOT');
               }}
            >
               Snapshot
            </div>
         ),
         key: 'snapshot',
      },
   ];

   const branches = useMemo(() => {
      return gitBranches
         ? gitBranches?.map((_branch) => {
              return {
                 label: (
                    <div className="flex flex-row gap-1">
                       {_branch.is_current_branch && <CheckOutlined />}
                       {_branch.name}
                    </div>
                 ),
                 key: _branch.name,
              };
           })
         : [];
   }, [gitBranches]);

   const branchItems: MenuProps['items'] = [
      {
         label: <div className="text-primary">Create new branch</div>,
         key: 'create_new_branch',
      },
      ...branches,
   ];

   const runDbt = (type: string, freshness?: string): void => {
      const tabs = activeTabs.tabs;
      const isAnyFileModified = tabs.some((tabData) => {
         return tabData.isModified === true;
      });
      if (isAnyFileModified) {
         dbtDataRef.current = { type, freshness };
         setIsModifiedFileModalOpen(true);
         return;
      }
      rubDbtApi(type, freshness);
   };

   const rubDbtApi = (type: string, freshness?: string): void => {
      let _type = type; // type === 'COMPILE' ? type.slice(0, -1) : type;
      _type = _type === 'DEPS' ? 'INSTALL' : _type;
      setDbtCurrentType(_type);
      runDbtProjectJob({
         command: {
            type,
            args: {
               type,
               freshness,
            },
         },
         projectId: projectId!,
      });
   };

   const onProjectItemClick = (
      record: any,
      rowIndex: number | undefined,
   ): void => {
      navigate('/project/' + record.id, {
         state: {
            projectData: record,
         },
      });
      setIsProjectListModalOpen(false);
   };
   const isGitAvailble = !(
      selectedProject?.gitUrl === undefined || selectedProject?.gitUrl === ''
   );
   const tabItems: TabsProps['items'] = [
      {
         key: '1',
         label: (
            <Tooltip title="Project">
               Files
               {/* <FundProjectionScreenOutlined className="!text-lg px-4" /> */}
            </Tooltip>
         ),
         children: (
            <Workspace
               treeData={treeData}
               projectSearchData={projectSearchData}
               isRunDbtProjectJobLoading={isRunDbtProjectJobLoading}
               isDbtJobPollLoading={isDbtJobPollLoading}
               dbtCurrentType={dbtCurrentType}
               activeTabs={activeTabs}
               setActiveTabs={setActiveTabs}
               projectInfoRefetch={projectInfoRefetch}
               runDbtProjectJob={runDbtProjectJob}
               selectedProject={selectedProject}
               setIsCreateModalOpen={setIsCreateModalOpen}
               projectInfoData={projectInfoData}
            />
         ),
      },
      {
         key: '2',
         label: (
            <Tooltip title="Databases">
               Databases
               {/* <DatabaseOutlined className="!text-lg px-4" /> */}
            </Tooltip>
         ),
         children: <DatabasesContainer projectSearchData={projectSearchData} />,
      },
      {
         key: '3',
         label: (
            <Tooltip
               title={`${isGitAvailble ? 'Version Control' : 'Git not configured'}`}
            >
               Version Control
               {/* <BranchesOutlined className="!text-lg px-4" /> */}
            </Tooltip>
         ),
         disabled: !isGitAvailble,
         children: (
            <GitActionsContainer
               setActiveTabs={setActiveTabs}
               activeTabs={activeTabs}
               selectedProject={selectedProject}
               selectedTabKey={selectedTabKey}
            />
         ),
      },
   ];

   const handleMenuClick: MenuProps['onClick'] = (e) => {
      if (e.key === 'create_new_branch') {
         setIsCreateBranchModal(true);
      } else {
         setBranch(e.key);
         setIsChangeBranchModal(true);
      }
   };

   return (
      <div>
         {isProjectLoading && <Loading messages="Project tree loading" />}
         <div
            className="flex flex-row max-h-[calc(100vh -100px)] overflow-y-scroll items-center justify-between border-0
            p-2 border-b-[0.5px] border-solid border-border"
         >
            <div className="text-base text-main whitespace-nowrap overflow-hidden text-ellipsis me-2">
               {!(
                  selectedProject?.gitUrl === undefined ||
                  selectedProject?.gitUrl === ''
               ) && selectedBranch ? (
                  <Dropdown
                     menu={{
                        items: branchItems,
                        selectable: true,
                        onClick: handleMenuClick,
                        selectedKeys: [selectedBranch?.name ?? ''],
                     }}
                     trigger={['click']}
                     overlayClassName="max-h-[50vh] overflow-auto shadow-md rounded-lg"
                  >
                     <div
                        className="flex flex-row text-main
                   border-1 gap-2 px-2 py-[7px] text-xs cursor-pointer "
                     >
                        <GitBranchIcon className="size-4" />
                        <div className="whitespace-nowrap overflow-hidden ">
                           {selectedBranch?.name}
                        </div>
                        <DownOutlined />
                     </div>
                  </Dropdown>
               ) : (
                  <Button type="link" size="small" disabled>
                     Configure Git
                  </Button>
               )}
            </div>
            <div className="flex flex-row-reverse items-center gap-2 max-w-[65%] ">
               <Dropdown menu={{ items }} trigger={['click']}>
                  <MoreOutlined className="text-main text-xl cursor-pointer hover:text-primary" />
               </Dropdown>
               <Dropdown.Button
                  type="primary"
                  buttonsRender={([leftButton, rightButton]) => [
                     leftButton,
                     // <div
                     //    className=" border-solid border-0 border-l-[1.5px] border-white"
                     //    key="right"
                     // >
                     rightButton,
                     // </div>,
                  ]}
                  trigger={['click']}
                  icon={<DownOutlined className="!text-sm mt-1" />}
                  onClick={() => {
                     runDbt('RUN');
                  }}
                  menu={{ items: dbtActionitems }}
                  className="w-[80px] model-action"
               >
                  <CaretRightOutlined
                     className="text-[14px] border-solid border-[1px] ps-[1px] py-[0.5]
                               border-white rounded-full me-1"
                  />
               </Dropdown.Button>
            </div>
         </div>

         <Tabs
            tabBarStyle={{ paddingLeft: '8px' }}
            defaultActiveKey="1"
            items={tabItems}
            onChange={(activeKey) => {
               setSelectedTabKey(activeKey);
            }}
         />

         <ProjectsListModal
            onItemClick={onProjectItemClick}
            projSearchData={projectSearchData}
            isModalOpen={isProjectListModalOpen}
            onOk={() => {
               setIsProjectListModalOpen(false);
            }}
            onCancel={() => {
               setIsProjectListModalOpen(false);
            }}
         />
         {isCreateModalOpen && (
            <CreateProjectModal
               isModalOpen={isCreateModalOpen}
               onProjectCreated={() => {
                  onProjectCreated();
                  setIsCreateModalOpen(false);
               }}
               onCancel={() => {
                  setIsCreateModalOpen(false);
               }}
            />
         )}
         {isDeleteProjectModalOpen && (
            <DeleteProjectModal
               isModalOpen={isDeleteProjectModalOpen}
               projectName={projectName}
               onCancel={() => {
                  setIsDeleteProjectModalOpen(false);
               }}
               onOk={() => {
                  setIsDeleteProjectModalOpen(false);
                  deleteProject(projectId ?? '');
               }}
            />
         )}
         {isChangeBranchModal && (
            <ChangeBranchModal
               isModalOpen={isChangeBranchModal}
               branchName={branch}
               onCancel={() => {
                  setIsChangeBranchModal(false);
               }}
               onOk={() => {
                  setIsChangeBranchModal(false);
                  gitBranchCheckout(branch);
               }}
            />
         )}
         {isCreateBranchModal && (
            <CreateBranchModal
               isModalOpen={isCreateBranchModal}
               onCancel={() => {
                  setIsCreateBranchModal(false);
               }}
               onOk={(branchName) => {
                  setIsCreateBranchModal(false);
                  gitBranchCheckout(branchName);
               }}
            />
         )}
         {isModifiedFileModalOpen && (
            <DefaultSmallModal
               title={`DBT ${dbtDataRef.current.type.toUpperCase()}!`}
               description="Do you want to execute the command? You have unsaved modified files."
               cancelButtonText="Cancel"
               okButtonText="Yes"
               isModalOpen={isModifiedFileModalOpen}
               onCancel={() => {
                  setIsModifiedFileModalOpen(false);
               }}
               onOk={() => {
                  rubDbtApi(
                     dbtDataRef.current.type,
                     dbtDataRef.current.freshness,
                  );
                  setIsModifiedFileModalOpen(false);
               }}
            />
         )}
      </div>
   );
};

export default ProjectStructure;
