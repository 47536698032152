import React from 'react';
import { Button, Modal } from 'antd';

interface IDeleteProjectModal {
   isModalOpen: boolean;
   onCancel: () => void;
   onOk: () => void;
   projectName: string;
}
const DeleteProjectModal: React.FC<IDeleteProjectModal> = (props) => {
   const { isModalOpen, onCancel, projectName, onOk } = props;

   return (
      <>
         <Modal
            title={`Delete Project?`}
            open={isModalOpen}
            onCancel={() => {
               onCancel();
            }}
            footer={(_, { OkBtn, CancelBtn }) => (
               <>
                  <Button type="text" onClick={onCancel}>
                     Cancel
                  </Button>

                  <Button key="submit" type="primary" onClick={onOk} danger>
                     Delete
                  </Button>
               </>
            )}
            width={'25%'}
         >
            <div className="flex flex-col items-center ">
               Are you sure you want to delete {projectName}? this cannot be
               undone.
            </div>
         </Modal>
      </>
   );
};

export default DeleteProjectModal;
