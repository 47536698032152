import React, { type ReactElement } from 'react';
import '../App.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GlobalStateProvider } from '../context/GlobalContext';
import Main from './Main';

const App = (): ReactElement => {
   const queryClient = new QueryClient({
      defaultOptions: {
         queries: {
            refetchOnWindowFocus: false, // default: true
         },
      },
   });

   return (
      <BrowserRouter>
         <GlobalStateProvider>
            <QueryClientProvider client={queryClient}>
               <Main />
            </QueryClientProvider>
         </GlobalStateProvider>
      </BrowserRouter>
   );
};

export default App;
