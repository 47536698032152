/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, {
   useEffect,
   useRef,
   useState,
   type Dispatch,
   type SetStateAction,
} from 'react';
import { sqlite, formatDialect } from 'sql-formatter';
import { Editor } from '@monaco-editor/react';
import { Breadcrumb, Button, Dropdown, Tooltip, type MenuProps } from 'antd';
import { PlayButtonState, type TabData } from './ProjectData';
import { useParams } from 'react-router-dom';
import {
   useProjectFiles,
   useUpdateFile,
} from '../../providers/useProjectFiles';
import Loader from '../../components/Loader';
import { useGlobalState } from '../../context/GlobalContext';
import { type IProjectFile } from '../../api/models/IProjectFile';
import { getEditorTheme, showError } from '../../utils/Utils';
import Loading from '../../components/Loading';
import {
   CaretRightOutlined,
   DownOutlined,
   FormatPainterOutlined,
   SaveOutlined,
} from '@ant-design/icons';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IDBTProjectPayload,
   type IDBTRunJobResponse,
} from '../../providers/useDBTJob';
import { useQueryValueDebounce } from '../../hooks/useDebounce';

interface IActiveTab {
   activeTabs: TabData;
   fileContent: any;
   runDbtProjectJob: UseMutateFunction<
      IDBTRunJobResponse,
      Error,
      IDBTProjectPayload,
      unknown
   >;
   runQueryPreview: (type: string, fileValue: string) => void;
   playButtonState: PlayButtonState;
   setPlayButtonState: Dispatch<SetStateAction<PlayButtonState>>;
   projectName: string;
   setActiveTabs: (activeTabs: any) => void;
}
const ActiveTab: React.FC<IActiveTab> = (props) => {
   const {
      activeTabs,
      runDbtProjectJob,
      runQueryPreview,
      playButtonState,
      setPlayButtonState,
      projectName,
      setActiveTabs,
   } = props;
   const editorRef = useRef<any>(undefined);
   const [fileValue, setFileValue] = useState<string>('');
   const [apiFileValue, setApiFileValue] = useState<string>('');
   const [fileData, setFileData] = useState<IProjectFile | null>(null);
   const [dbtYmlData, setDbtYmlData] = useState<IProjectFile | null>(null);
   const [playButtonTargetfileData, setPlayButtonTargetFileData] =
      useState<IProjectFile | null>(null);
   const { messageApi, currentTheme } = useGlobalState();
   const { id } = useParams();
   const isDirtyQuery = useQueryValueDebounce(apiFileValue !== fileValue, 500);

   const {
      mutate: updateFile,
      data: updateFileData,
      isError: isUpdateFileError,
      error: updateError,
      isPending: isUpdateFileLoading,
   } = useUpdateFile();

   const {
      data: fileContent,
      isError: isFileContentError,
      isPending: isFileContentLoading,
      error: fileContentError,
   } = useProjectFiles(fileData);

   const {
      data: dbtProjectYmlData,
      isError: isDbtProjectYmlDataError,
      isPending: isDbtProjectYmlDataLoading,
      error: dbtProjectYmlDataError,
   } = useProjectFiles(dbtYmlData);

   const {
      data: playButtonTargetFileContent,
      isError: isPlayButtonTargetFileContentError,
      isPending: isPlayButtonTargetFileContentLoading,
      error: playButtonTargetFileContentError,
   } = useProjectFiles(playButtonTargetfileData);

   useEffect(() => {
      setFileData({
         projectName: id!,
         path: activeTabs?.activeTabKey ?? '',
      });
   }, []);

   useEffect(() => {
      const tabs = [...activeTabs.tabs];
      tabs.forEach((value) => {
         if (value.key === activeTabs.activeTabKey) {
            value.isModified = isDirtyQuery;
         }
      });
      setActiveTabs((prev: any) => {
         return { ...prev, tabs };
      });
   }, [isDirtyQuery]);

   useEffect(() => {
      if (isFileContentError) {
         void messageApi?.open({
            type: 'error',
            content: `${fileContentError?.message}`,
         });
      }
      if (fileContent !== null && fileContent !== undefined) {
         setFileValue(fileContent?.content);
         setApiFileValue(fileContent?.content);
      }
   }, [fileContent, isFileContentLoading]);

   useEffect(() => {
      if (isDbtProjectYmlDataError) {
         void messageApi?.open({
            type: 'error',
            content: `${dbtProjectYmlDataError?.message}`,
         });
      }
      if (dbtProjectYmlData !== null && dbtProjectYmlData !== undefined) {
         const projectName = extractName(dbtProjectYmlData.content);
         const arr = activeTabs?.activeTabKey?.split('/');
         console.log('projectName ***', projectName);
         if (arr !== undefined) {
            const filnameWithExtention = arr[arr.length - 1];
            setPlayButtonTargetFileData({
               projectName: id!,
               path: `target/compiled/${projectName}/${activeTabs?.activeTabKey}`,
            });
         }
      }
   }, [dbtProjectYmlData, isDbtProjectYmlDataLoading]);

   useEffect(() => {
      if (isPlayButtonTargetFileContentError) {
         void messageApi?.open({
            type: 'error',
            content: `${playButtonTargetFileContentError?.message}`,
         });
      }
      if (
         playButtonTargetFileContent !== null &&
         playButtonTargetFileContent !== undefined
      ) {
         // if (playButtonState.valueOf() === PlayButtonState.RefetchData) {
         setPlayButtonState(PlayButtonState.NotStarted);
         console.log({ fileContent });
         const content = playButtonTargetFileContent?.content;
         runQueryPreview('PREVIEW', content);
         // }
      }
   }, [playButtonTargetFileContent, isPlayButtonTargetFileContentLoading]);

   useEffect(() => {
      if (isUpdateFileError) {
         void messageApi?.open({
            type: 'error',
            content: `${updateError?.message}`,
         });
      }

      if (updateFileData !== null && updateFileData !== undefined) {
         if (updateFileData.ack === 'ok') setApiFileValue(fileValue); // void fileContentRefetch();
      }
   }, [updateFileData, isUpdateFileLoading]);

   useEffect(() => {
      if (playButtonState.valueOf() === PlayButtonState.RefetchData) {
         setDbtYmlData({
            projectName: id!,
            path: 'dbt_project.yml',
         });
      }
   }, [playButtonState]);

   const extractName = (value: string): string => {
      const nameRegex = /name:\s*"([^"]+)"/;
      const nameMatch = value.match(nameRegex);
      console.log('projectName *** R', nameMatch);
      return nameMatch ? nameMatch[1] : projectName;

      //   `` const nameRegex = /name: '(.*)'/;
      //    const match = value.match(nameRegex);
      //    console.log('projectName *** R', match, nameRegex, value);
      //    if (match?.[1]) {
      //       return match[1];
      //    } else {
      //       return projectName; // Or handle the case where name is not found
      //    }
   };

   // const sqlfmt = async (sql: any): Promise<any> => {
   //    return format(sql, {
   //       language: 'mysql',
   //       tabWidth: 2,
   //       keywordCase: 'upper',
   //       linesBetweenQueries: 2,
   //    });
   //    // const loc = document.location;
   //    // const base = loc.protocol + '//' + loc.host;
   //    // const url = base + '/sqlfmt?sql=' + encodeURIComponent(sql);
   //    // return await fetch(url).then(async (response) => {
   //    //    return await response.text();
   //    // });
   // };

   const runDbt = (type: string, fileType: string): void => {
      if (isDirtyQuery) {
         showError(
            messageApi,
            'Please save the file before executing this action.',
         );
         return;
      }
      const arr = activeTabs?.activeTabKey?.split('/');
      if (arr !== undefined) {
         const filnameWithExtention = arr[arr.length - 1];
         const filename = filnameWithExtention.split('.')[0];
         runDbtProjectJob({
            command: {
               type,
               args: {
                  type,
                  select: fileType.replace('filename', filename),
               },
            },
            projectId: id!,
         });
      }
   };

   const dbtActionitems: MenuProps['items'] = [
      {
         label: (
            <div
               onClick={() => {
                  if (isDirtyQuery) {
                     showError(
                        messageApi,
                        'Please save the file before executing this action.',
                     );
                     return;
                  }
                  runQueryPreview('PREVIEW', fileValue);
               }}
            >
               Preview Model
            </div>
         ),
         key: 'preview',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('COMPILE', '+filename+');
               }}
            >
               Build Model
            </div>
         ),
         key: 'run',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('COMPILE', 'filename+');
               }}
            >
               Build Model+(Downstream)
            </div>
         ),
         key: 'compile',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('COMPILE', '+filename');
               }}
            >
               Build Model+(Upstream)
            </div>
         ),
         key: 'seed',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('RUN', '+filename+');
               }}
            >
               Run Model
            </div>
         ),
         key: 'test',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('RUN', 'filename+');
               }}
            >
               Run Model+(Downstream)
            </div>
         ),
         key: 'install_packages',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('RUN', '+filename');
               }}
            >
               Run Model+(Upstream)
            </div>
         ),
         key: 'Run_Model_Upstream',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('TEST', '+filename+');
               }}
            >
               Test Model
            </div>
         ),
         key: 'Test_Model',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('TEST', 'filename+');
               }}
            >
               Test Model+(Downstream)
            </div>
         ),
         key: 'Test_Model_Downstream',
      },
      {
         label: (
            <div
               onClick={() => {
                  runDbt('TEST', '+filename');
               }}
            >
               Test Model+(Upstream)
            </div>
         ),
         key: 'Test_Model_Upstream',
      },
   ];

   const handlePlayFileAction = (sqlValues: string): void => {
      if (
         isDirtyQuery &&
         sqlValues &&
         (sqlValues.includes('{%') ||
            sqlValues.includes('{{ ref(') ||
            sqlValues.includes('config') ||
            sqlValues.includes('{{'))
      ) {
         showError(
            messageApi,
            'Please save the file before executing this action.',
         );
         return;
      }
      if (
         sqlValues &&
         (sqlValues.includes('{%') ||
            sqlValues.includes('{{ ref(') ||
            sqlValues.includes('config') ||
            sqlValues.includes('{{'))
      ) {
         // jinja format check
         setDbtYmlData(null);
         setPlayButtonTargetFileData(null);
         setPlayButtonState(PlayButtonState.Started);
         runDbt('COMPILE', '+filename');
      } else {
         runQueryPreview('PREVIEW', fileValue);
      }
   };

   return (
      <div className="flex flex-col w-[100%] ">
         {(isUpdateFileLoading || isPlayButtonTargetFileContentLoading) && (
            <Loader />
         )}
         {isFileContentLoading && <Loading messages="Loading model content" />}
         <div className="flex flex-row justify-between items-center">
            <Breadcrumb
               className="w-full ms-4 "
               separator=">"
               items={activeTabs.activeTabKey?.split('/').map((value) => {
                  return { title: value };
               })}
            />
            <div className="flex flex-row-reverse w-full p-2 gap-4">
               <Tooltip title="Formating">
                  <Button
                     onClick={() => {
                        if (editorRef !== undefined)
                           editorRef.current.trigger(
                              'editor',
                              'editor.action.formatDocument',
                           );
                     }}
                     type="link"
                     size="middle"
                     className="px-0 py-1"
                  >
                     <FormatPainterOutlined className="text-xl" />
                  </Button>
               </Tooltip>
               <Tooltip title="Save">
                  <Button
                     onClick={() => {
                        updateFile({
                           path: activeTabs.activeTabKey!,
                           content: fileValue,
                           projectName: id!,
                        });
                     }}
                     type="link"
                     size="middle"
                     disabled={!isDirtyQuery}
                     loading={isUpdateFileLoading}
                     className="px-0 py-1"
                  >
                     <SaveOutlined className="text-xl" />
                     {/* {!queryIsDirty && '*'} */}
                  </Button>
               </Tooltip>

               {activeTabs.activeTabKey?.endsWith('.sql') && (
                  <div>
                     <Tooltip title="Model actions">
                        <Dropdown.Button
                           trigger={['click']}
                           menu={{
                              items: dbtActionitems,
                           }}
                           icon={<DownOutlined />}
                           onClick={() => {
                              handlePlayFileAction(fileValue);
                           }}
                           type="primary"
                           className="model-action"
                        >
                           <div className="flex flex-row justify-center items-center font-semibold">
                              <CaretRightOutlined
                                 className="text-[12px] border-solid border-[1px] ps-[1px] py-[0.5]
                               border-white rounded-full me-1"
                              />
                              Run
                           </div>
                        </Dropdown.Button>
                     </Tooltip>
                  </div>
               )}
            </div>
         </div>
         <Editor
            height="calc(100vh - 110px)"
            width="100%"
            theme={getEditorTheme(currentTheme)}
            defaultLanguage="sql"
            value={fileValue}
            onChange={(value) => {
               setFileValue(value!);
            }}
            options={{
               guides: {
                  indentation: true,
               },
               renderLineHighlight: false,
            }}
            onMount={(editor: any, monaco: any) => {
               editorRef.current = editor;

               monaco.languages.registerDocumentFormattingEditProvider('sql', {
                  provideDocumentFormattingEdits(model: any, options: any) {
                     let formatted = model.getValue();
                     try {
                        formatted = formatDialect(model.getValue(), {
                           dialect: sqlite,
                           indentStyle: 'tabularLeft', // ' '.repeat(options.tabSize),
                        });
                     } catch {
                     } finally {
                        // eslint-disable-next-line no-unsafe-finally
                        return [
                           {
                              range: model.getFullModelRange(),
                              text: formatted,
                           },
                        ];
                     }
                  },
               });

               // define a range formatting provider
               // select some codes and right click those codes
               // you contextmenu will have an "Format Selection" action
               monaco.languages.registerDocumentRangeFormattingEditProvider(
                  'sql',
                  {
                     provideDocumentRangeFormattingEdits(
                        model: any,
                        range: any,
                        options: any,
                     ) {
                        let formatted = model.getValueInRange(range);
                        try {
                           formatted = formatDialect(
                              model.getValueInRange(range),
                              {
                                 dialect: sqlite,
                                 indentStyle: 'tabularLeft', // ' '.repeat(options.tabSize),
                              },
                           );
                        } catch {
                        } finally {
                           // eslint-disable-next-line no-unsafe-finally
                           return [
                              {
                                 range,
                                 text: formatted,
                              },
                           ];
                        }
                     },
                  },
               );

               // monaco.languages.registerDocumentFormattingEditProvider('sql', {
               //    async provideDocumentFormattingEdits(model: any) {
               //       console.log('model', model.getValue());
               //       const formatted = sqlfmt(model.getValue());
               //       return [
               //          {
               //             range: model.getFullModelRange(),
               //             text: formatted,
               //          },
               //       ];
               //    },
               // });
            }}
         />
      </div>
   );
};

export default ActiveTab;
