import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound: React.FC = () => {
   const navigate = useNavigate();

   return (
      <div className="min-h-screen flex flex-col w-full bg-primary relative">
         <div className="w-full"></div>
         <div className="absolute top-0 h-full w-full">
            <div className="flex flex-row justify-center h-full w-full">
               <div className="flex flex-col items-center justify-center">
                  <p className="flex text-[8rem] md:text-[12.5rem] text-main font-bold tracking-[-2px] leading-[8rem] md:leading-[204px]">
                     404
                  </p>
                  <p className="flex text-lg opacity-60 mt-4 tracking-[-0.5px] text-main">
                     page_not_found
                  </p>
                  <p
                     className="flex text-[26px] text-secondary font-bold tracking-[-1px] cursor-pointer"
                     data-testid="page-not-found-go-back"
                     onClick={() => {
                        navigate('/', { replace: true });
                     }}
                  >
                     go_to_home_page
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default PageNotFound;
