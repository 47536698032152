/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Button, Input } from 'antd';
import React, {
   type Dispatch,
   type SetStateAction,
   useEffect,
   useState,
} from 'react';
import ProjectTree from './ProjectTree';
import { uniq } from 'lodash';
import { type TabData } from './ProjectData';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IDBTProjectPayload,
   type IDBTRunJobResponse,
} from '../../providers/useDBTJob';
import {
   type IProjectInfo,
   type IProjectSearchData,
} from '../../api/models/IProject';
import { PlusCircleOutlined } from '@ant-design/icons';
import { type IFilesResponse } from '../../providers/models/IFiles';

interface IWorkspace {
   treeData: any;
   projectSearchData: IProjectSearchData | undefined;
   isRunDbtProjectJobLoading: boolean;
   isDbtJobPollLoading: boolean;
   dbtCurrentType: string;
   activeTabs: TabData;
   setActiveTabs: (activeTabs: TabData) => void;
   projectInfoRefetch: () => void;
   runDbtProjectJob: UseMutateFunction<
      IDBTRunJobResponse,
      Error,
      IDBTProjectPayload,
      unknown
   >;
   selectedProject: IProjectInfo | undefined;
   setIsCreateModalOpen: Dispatch<SetStateAction<boolean>>;
   projectInfoData: IFilesResponse | undefined;
}
const Workspace: React.FC<IWorkspace> = (props) => {
   const {
      projectSearchData,
      isRunDbtProjectJobLoading,
      isDbtJobPollLoading,
      dbtCurrentType,
      activeTabs,
      setActiveTabs,
      treeData,
      projectInfoRefetch,
      runDbtProjectJob,
      selectedProject,
      setIsCreateModalOpen,
      projectInfoData,
   } = props;
   const [expandedKeys, setExpandedKeys] = useState<any>([]);
   const [searchTreeData, setSearchTreeData] = useState('');

   useEffect(() => {
      const activeTabKeyArr = activeTabs.activeTabKey?.split('/');
      const activeTabFoldersArr = activeTabKeyArr?.slice(
         0,
         activeTabKeyArr.length - 1,
      );
      const res: string[] = [];
      if (activeTabFoldersArr?.length) {
         activeTabFoldersArr.forEach((folder: any, i: number) => {
            res.push(activeTabFoldersArr.slice(0, i + 1).join('/'));
         });
         setExpandedKeys(['0-1', ...res]);
      }
   }, [activeTabs.activeTabKey]);

   useEffect(() => {
      if (searchTreeData) {
         const newkeys = uniq(
            treeData?.content
               ?.filter((treeNode: any) => treeNode.relative_path !== '/')
               .map((directory: any) => directory.relative_path),
         );
         setExpandedKeys((prev: any) => ['0-1', ...prev, ...newkeys]);
      } else {
         setExpandedKeys(['0-1']);
      }
   }, [searchTreeData]);

   const fSearchFiles = (data: any[], string: any): any => {
      const dataWithoutBasicModes = data.filter((item: any) => {
         const fileTitle = item.name.split('.')[0];
         const itemEndsWithJson = item.name.endsWith('.json');
         const itemSqlPresented = data.find(
            (el: any) => el.name === fileTitle.concat('.sql'),
         );
         return !(itemEndsWithJson && itemSqlPresented);
      });
      if (string.length !== 0) {
         const res = dataWithoutBasicModes.filter((item: any) =>
            item.name.toUpperCase().includes(string.toUpperCase()),
         );
         return res;
      }
      return dataWithoutBasicModes;
   };

   return (
      <div>
         <div className="flex flex-row mx-2 gap-2">
            <Input
               placeholder="Search"
               className="px-2 mb-2 "
               onChange={(e) => {
                  setSearchTreeData(e.target.value);
               }}
            />
            <Button
               onClick={() => {
                  setIsCreateModalOpen(true);
               }}
            >
               <PlusCircleOutlined />
            </Button>
         </div>
         {/* <div className="text-sm font-semibold text-main ps-2 mb-2">
            {
               projectSearchData?.data?.find(
                  (project: any) => project.id === projectId,
               )?.projectName
            }
         </div> */}
         {/* {(isRunDbtProjectJobLoading || isDbtJobPollLoading) && (
            <div className="flex flex-row items-center my-2 ms-2 text-primary text-md gap-3">
               <span className="loading-bars !w-4" />
               executing dbt {dbtCurrentType}
            </div>
         )} */}

         <ProjectTree
            treeData={fSearchFiles(treeData?.content, searchTreeData)}
            activeTabs={activeTabs}
            setActiveTabs={setActiveTabs}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
            projectInfoRefetch={projectInfoRefetch}
            runDbtProjectJob={runDbtProjectJob}
            selectedProject={selectedProject}
            projectInfoData={projectInfoData}
         />
      </div>
   );
};

export default Workspace;
