import React, { useEffect, useState } from 'react';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { removeTokenAndNavigate } from '../utils/Utils';
import Loading from '../components/Loading';

export const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({
   children,
}) => {
   const [token, setToken] = useState<string | undefined>();

   useEffect(() => {
      void getToken();
   }, []);

   const getToken = async (): Promise<void> => {
      try {
         // const { username } = await getCurrentUser();
         const token = await fetchAuthSession();
         const { idToken } = token.tokens ?? { idToken: undefined };
         if (idToken !== undefined) {
            setToken(idToken.toString());
         } else {
            removeTokenAndNavigate();
         }
      } catch (err) {
         removeTokenAndNavigate();
      }
   };

   // if (token === '') {
   //    return <Navigate to="/signin" replace />;
   // }
   if (token === undefined) {
      return <Loading messages="Loading App..." />;
   }

   return children;
};
