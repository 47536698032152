import { apiDelete, apiGet, apiPost, apiPut } from '../../api/ApiService';
import {
   ACTION_CONFIG,
   ACTION_SIGNIN,
   ACTION_USERS,
} from '../../api/ApiRoutes';
import {
   type ITenantConfig,
   type ISigninPayload,
   type ISigninResponse,
} from '../../api/models/ISignin';
import {
   useMutation,
   type UseQueryResult,
   type UseMutationResult,
   useQuery,
} from '@tanstack/react-query';
import ApiConstants from '../../api/ApiConstants';
import {
   type IAddUserSecretsResponse,
   type IAddUserSecretsPayload,
   type IGetUserSecretsPayload,
   type IGetUserSecretsResponse,
} from '../../providers/models/IUserSecrets';

export function useSignin(): UseMutationResult<
   ISigninResponse,
   Error,
   ISigninPayload
> {
   const signinRequest = async (
      payload: ISigninPayload,
   ): Promise<ISigninResponse> => {
      try {
         const response = await apiPost({
            path: `${ACTION_SIGNIN}`,
            url: ApiConstants.workspaceBaseUrl,
            body: payload,
         });

         // here we will do parsing of models and use for components.. json to model
         if (response.status === 200) {
            return response.data as ISigninResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (error: any) {
         console.log(error);

         let errText = 'Login failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         console.log(errText);
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`please_check_your_internet_connection`);
         } else {
            throw new Error(errText);
         }
      }
   };
   return useMutation<ISigninResponse, Error, ISigninPayload>({
      mutationFn: signinRequest,
   });
}

export const useTenantConfig = (
   domain: string | undefined | null,
): UseQueryResult<ITenantConfig, Error> => {
   return useQuery({
      queryKey: ['TenantConfig'],
      staleTime: 0,
      gcTime: 100,
      retry: false,
      enabled: false,
      queryFn: async () => {
         if (domain) {
            try {
               const response = await apiGet({
                  path: `${ACTION_CONFIG}/domain/${domain}`,
                  url: ApiConstants.tenantBaseUrl,
                  body: null,
               });
               if (response.status === 200) {
                  return response.data as ITenantConfig;
               } else {
                  // TODO: add error handling when any error occur from server
                  throw new Error('Api Error');
               }
               // return response.data;
            } catch (error: any) {
               console.log('****Config Err', error);

               let errText = 'Config failed';
               if (error?.response?.data?.error_messages !== undefined) {
                  errText = error.response.data.error_messages[0];
               } else if (error.message !== undefined) {
                  errText = error.message;
               }
               console.log(errText);
               if (
                  errText === 'Network Error' ||
                  errText === 'timeout exceeded'
               ) {
                  throw new Error(`please_check_your_internet_connection`);
               } else {
                  throw new Error(errText);
               }
            }
         }
         return null;
      },
   });
};

export function useUserSignin(): UseMutationResult<
   ISigninResponse,
   Error,
   string | undefined | null
> {
   const signinRequest = async (
      userName: string | undefined | null,
   ): Promise<ISigninResponse> => {
      try {
         const response = await apiPost({
            path: `${ACTION_USERS}/login`,
            url: ApiConstants.tenantBaseUrl,
            body: null,
         });
         console.log('Login Response', response);
         // here we will do parsing of models and use for components.. json to model
         if (response.status === 204) {
            const signR: ISigninResponse = {
               email: 'test@gmail.com',
               id: 'idValue',
            };
            return signR;
         } else if (response.status === 200) {
            return response.data as ISigninResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (error: any) {
         console.log(error);

         let errText = 'Login failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         console.log(errText);
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`please_check_your_internet_connection`);
         } else {
            throw new Error(errText);
         }
      }
   };
   return useMutation<ISigninResponse, Error, string | undefined | null>({
      mutationFn: signinRequest,
   });
}

export function useAddUserSecrets(): UseMutationResult<
   IAddUserSecretsResponse,
   Error,
   IAddUserSecretsPayload
> {
   const addUserSecrets = async (
      payload: IAddUserSecretsPayload,
   ): Promise<IAddUserSecretsResponse> => {
      try {
         const response = await apiPost({
            path: `${ACTION_USERS}/secrets`,
            url: ApiConstants.tenantBaseUrl,
            body: payload,
         });

         if (response.status === 200 || response.status === 201) {
            return response.data as IAddUserSecretsResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('AddUserSecrets Api Error');
         }
      } catch (error: any) {
         console.log(error);

         let errText = 'AddUserSecrets failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         console.log(errText);
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`please_check_your_internet_connection`);
         } else {
            throw new Error(errText);
         }
      }
   };
   return useMutation<IAddUserSecretsResponse, Error, IAddUserSecretsPayload>({
      mutationFn: addUserSecrets,
   });
}

export function useEditUserSecrets(): UseMutationResult<
   IAddUserSecretsResponse,
   Error,
   IAddUserSecretsPayload
> {
   const editUserSecrets = async (
      payload: IAddUserSecretsPayload,
   ): Promise<IAddUserSecretsResponse> => {
      try {
         const response = await apiPut({
            path: `${ACTION_USERS}/secrets/${payload.id}`,
            url: ApiConstants.tenantBaseUrl,
            body: payload,
         });

         if (response.status === 200) {
            return response.data as IAddUserSecretsResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Edit User Secrets Api Error');
         }
      } catch (error: any) {
         console.log(error);

         let errText = 'Edit User Secrets failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         console.log(errText);
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`please_check_your_internet_connection`);
         } else {
            throw new Error(errText);
         }
      }
   };
   return useMutation<IAddUserSecretsResponse, Error, IAddUserSecretsPayload>({
      mutationFn: editUserSecrets,
   });
}

export function useDeleteUserSecrets(): UseMutationResult<
   IAddUserSecretsResponse,
   Error,
   string
> {
   const deleteUserSecrets = async (
      id: string,
   ): Promise<IAddUserSecretsResponse> => {
      try {
         const response = await apiDelete({
            path: `${ACTION_USERS}/secrets/${id}`,
            url: ApiConstants.tenantBaseUrl,
            body: null,
         });

         if (response.status === 200 || response.status === 204) {
            return response.data as IAddUserSecretsResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Delete User Secrets Api Error');
         }
      } catch (error: any) {
         console.log(error);

         let errText = 'Delete User Secrets failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         console.log(errText);
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`please_check_your_internet_connection`);
         } else {
            throw new Error(errText);
         }
      }
   };
   return useMutation<IAddUserSecretsResponse, Error, string>({
      mutationFn: deleteUserSecrets,
   });
}

export function useGetUserSecrets(): UseMutationResult<
   IGetUserSecretsResponse,
   Error,
   IGetUserSecretsPayload
> {
   const getUserSecrets = async (
      body: IGetUserSecretsPayload,
   ): Promise<IGetUserSecretsResponse> => {
      try {
         const response = await apiPost<IGetUserSecretsResponse>({
            path: `${ACTION_USERS}/secrets/search`,
            url: ApiConstants.tenantBaseUrl,
            body,
         });

         if (response.status === 200) {
            const data = response?.data;
            return data!;
         } else {
            throw new Error('Get user Secrets Error');
         }
      } catch (error: any) {
         console.log(error);

         let errText = 'GetUserSecrets failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         console.log(errText);
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`please_check_your_internet_connection`);
         } else {
            throw new Error(errText);
         }
      }
   };
   return useMutation<IGetUserSecretsResponse, Error, IGetUserSecretsPayload>({
      mutationFn: getUserSecrets,
   });
}

// export const useUserSignin = (
//    userName: string | undefined | null,
// ): UseQueryResult<any, Error> => {
//    return useQuery({
//       queryKey: ['useUserSignin'],
//       staleTime: 0,
//       gcTime: 100,
//       retry: false,
//       enabled: false,
//       queryFn: async () => {
//          if (userName) {
//             try {
//                const response = await apiGet({
//                   path: `${ACTION_USERS}/login`,
//                   url: ApiConstants.tenantBaseUrl,
//                   body: null,
//                });
//                if (response.status === 200) {
//                   return response.data as any;
//                } else {
//                   // TODO: add error handling when any error occur from server
//                   throw new Error('Api Error in Login');
//                }
//                // return response.data;
//             } catch (error: any) {
//                console.log('****Login Err', error);

//                let errText = 'Login failed';
//                if (error?.response?.data?.error_messages !== undefined) {
//                   errText = error.response.data.error_messages[0];
//                } else if (error.message !== undefined) {
//                   errText = error.message;
//                }
//                console.log(errText);
//                if (
//                   errText === 'Network Error' ||
//                   errText === 'timeout exceeded'
//                ) {
//                   throw new Error(`please_check_your_internet_connection`);
//                } else {
//                   throw new Error(errText);
//                }
//             }
//          }
//          return null;
//       },
//    });
// };
