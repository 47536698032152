import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiGet } from '../api/ApiService';
import ApiConstants from '../api/ApiConstants';

import {
   type ICatalogFileContentJson,
   type IDBTCatalogJson,
} from './models/Json/IModelCatalogJson';
import { type IDBTManifestJson } from './models/Json/IModelManifestJson';
import { decodeCatalogModel, type IDBTCatalog } from './models/IModelCatalog';

export interface ICatalogPayload {
   projectName: string;
   path: string;
   manifestPath: string;
   ds: string | null;
}

const makeEmptyCatalog = (error?: string): IDBTCatalog => ({
   metadata: null,
   nodes: null,
   sources: null,
   errors: error,
   childMap: {},
   docs: null,
   parentMap: {},
});

const PROJECT_CATALOG_KEY = 'project-dw-key';

export const useProjectCatalog = (
   payload: ICatalogPayload | null,
): UseQueryResult<IDBTCatalog, Error> => {
   return useQuery({
      queryKey: [PROJECT_CATALOG_KEY],
      queryFn: async () => {
         if (!payload) {
            return makeEmptyCatalog();
         }
         try {
            let catalogJson: IDBTCatalogJson | null = null;
            // this not required for now later we will use this
            if (payload?.path === 'skip') {
               const catalogResp = await apiGet<ICatalogFileContentJson>({
                  path: `api/v1/project/${payload.projectName}/content?path=${payload.path}`,
                  url: ApiConstants.workspaceBaseUrl,
                  body: null,
               });
               if (catalogResp?.data?.content) {
                  catalogJson = JSON.parse(catalogResp.data.content);
               }
            }

            let manifestJson: IDBTManifestJson | null = null;
            // skip call change condition
            if (payload?.manifestPath !== '') {
               const manifestResp = await apiGet<ICatalogFileContentJson>({
                  path: `api/v1/project/${payload.projectName}/content?path=${payload.manifestPath}`,
                  url: ApiConstants.workspaceBaseUrl,
                  body: null,
               });
               if (manifestResp?.data?.content) {
                  manifestJson = JSON.parse(
                     manifestResp.data.content,
                  ) as IDBTManifestJson;

                  // if (manifest?.content) {
                  //    manifestJson =
                  //       manifest?.content as unknown as IDBTManifestJson;
                  // }
                  const model = decodeCatalogModel(
                     catalogJson,
                     manifestJson,
                     payload.ds,
                     [],
                  );

                  return model;
               }
            }
            return makeEmptyCatalog();
         } catch (e: any) {
            let errText = 'DBT Catalog failed!';
            if (e?.response?.data?.error) {
               errText = e.response.data.error;
            } else if (
               e?.response?.data?.error_messages !== undefined &&
               e?.response?.data?.error_messages?.length
            ) {
               errText = e?.response?.data?.error_messages[0];
            } else if (e.message) {
               errText = e.message;
            }
            return makeEmptyCatalog(errText);
         }
      },
   });
};
