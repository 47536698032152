/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'antd';
import { datamapTitleCleanup } from '../../../utils/helpers';

interface IAddFileInput {
   onAddFolder: any;
   onBlurFolder: any;
}
const AddFolderInput: React.FC<IAddFileInput> = ({
   onAddFolder,
   onBlurFolder,
}) => {
   const inputRef = useRef<any>(null);
   useEffect(() => {
      if (inputRef.current) {
         inputRef.current.focus();
      }
   }, [inputRef]);

   const [folderName, setFolderName] = useState('');
   const onKeyDown = (e: any): void => {
      if (e.key === 'Enter') {
         e.preventDefault();
         onAddFolder(folderName);
      }
   };

   const onChangeFolderName = (e: any): void => {
      e.preventDefault();
      const cleanedTitle = datamapTitleCleanup(
         e.target.value,
         90,
      ).toLowerCase();
      setFolderName(cleanedTitle);
   };

   return (
      <Input
         ref={inputRef}
         value={folderName}
         style={{ margin: 0 }}
         onKeyDown={onKeyDown}
         onChange={onChangeFolderName}
         onBlur={() => (!folderName ? onBlurFolder() : onAddFolder(folderName))}
      />
   );
};
export default AddFolderInput;
