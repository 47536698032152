import { Divider } from 'antd';
import React from 'react';

const DataSourceSidePanel: React.FC = () => {
   return (
      <div
         className="w-[40%]  min-h-[calc(100vh-0px)] h-[calc(100vh-0px)]
      overflow-y-auto border-0 border-solid border-l-[0.5px] border-border"
      >
         <div className="bg-sidebar p-4 fixed w-full">
            <div className="text-main text-xl font-semibol">
               Getting Started
            </div>
         </div>
         <div className="px-4 pb-4 mt-16">
            <div className="text-main mt-20">
               Collaborated with Snowflake to provide support for configuring
               Snowflake in Data Workspace using the Snowflake Partner Connect
               feature. Data Workspace can now be accessed from within the
               Snowflake platform, through which you can seamlessly set up an
               account and create data Pipelines with Snowflake as your
               Destination.
            </div>
            <Divider className="border-[0.5px] border-border" />

            <div className="text-main text-lg mt-4">Prerequisties</div>
            <ul className="text-main ps-4 flex flex-col gap-3 mt-2">
               <li>An active Snowflake account.</li>
               <li>
                  The user has either ACCOUNTADMIN or SECURITYADMIN role in
                  Snowflake to create a new role for Data Workspace.
               </li>
               <li>
                  The user must have ACCOUNTADMIN or SYSADMIN role in Snowflake,
                  if a warehouse is to be created.
               </li>
               <li>
                  Data Workspace is assigned USAGE permissions on data
                  warehouses.
               </li>
               <li>
                  Data Workspace is assigned USAGE and CREATE SCHEMA permissions
                  on databases.
               </li>
               <li>
                  Data Workspace is assigned USAGE, MONITOR, CREATE TABLE,
                  CREATE EXTERNAL TABLE, and MODIFY permissions on the current
                  and future schemas.
               </li>
               <li>
                  You are assigned the Team Collaborator, or any administrator
                  role except the Billing Administrator role in Data Workspace
                  to create the Destination.
               </li>
               <li>
                  Refer to section,{' '}
                  {/* <a
                     href="https://docs.data.com/destinations/data-warehouses/snowflake/#create-and-configure-your-snowflake-warehouse"
                     target="_blank"
                     rel="noreferrer"
                  > */}
                  Create and Configure your Snowflake Warehouse to create a
                  Snowflake warehousev with adequate permissions for Data
                  Workspace to access your data.
                  {/* </a> */}
               </li>
               <li>
                  Follow steps to{' '}
                  {/* <a
                     href="https://docs.data.com/destinations/data-warehouses/snowflake/#connect-using-the-snowflake-credentials"
                     target="_blank"
                     rel="noreferrer"
                  > */}
                  Connect Using Snowflake Partner Connect (Recommended Method)
                  {/* </a> */}
               </li>
            </ul>
         </div>
      </div>
   );
};

export default DataSourceSidePanel;
