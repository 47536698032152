import React, { useEffect, useState } from 'react';
import { Button, Divider, Input } from 'antd';
import GoogleIcon from '../../assets/svgs/google-icon.svg';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession, signInWithRedirect, signUp } from 'aws-amplify/auth';
import { useGlobalState } from '../../context/GlobalContext';
import { Hub } from 'aws-amplify/utils';
import { validateEmail, validatePassword } from '../../utils/Utils';

const Signup: React.FC = () => {
   const [fullname, setFullname] = useState<string>('');
   const [email, setEmail] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const [confirmPassword, setConfirmPassword] = useState<string>('');
   const [loading, setLoading] = useState<boolean>(false);
   const { messageApi, currentTheme } = useGlobalState();
   const navigate = useNavigate();

   useEffect(() => {
      const unsubscribe = Hub.listen('auth', ({ payload }) => {
         setLoading(false);
         switch (payload.event) {
            case 'signInWithRedirect':
               void setAuth();
               break;
            case 'signInWithRedirect_failure':
               showError('An error has occurred during the OAuth flow.');
               break;
            case 'customOAuthState':
               showError(payload.data);
               break;
         }
      });
      void setAuth();
      return unsubscribe;
   }, []);

   const setAuth = async (): Promise<void> => {
      try {
         const auth = await fetchAuthSession();
         if (auth.tokens?.accessToken !== undefined) {
            navigate('/');
         }
      } catch (error) {
         console.error(error);
      }
   };

   const isValidate = (): boolean => {
      if (fullname === '') {
         showError('Fullname Required');
         return false;
      }
      if (!validateEmail(email)) {
         showError('Email is not valid');
         return false;
      }

      if (
         password === '' ||
         confirmPassword === '' ||
         password !== confirmPassword
      ) {
         showError('Password not matched');
         return false;
      }
      const message = validatePassword(password);
      if (message !== '') {
         showError(message);
         return false;
      }
      return true;
   };

   const signupClicked = async (): Promise<void> => {
      if (!isValidate()) return;
      setLoading(true);
      try {
         const { isSignUpComplete, userId } = await signUp({
            username: email,
            password,
            options: {
               userAttributes: {
                  name: fullname,
               },
               // optional
               // autoSignIn: true,
            },
         });
         if (userId) {
            if (!isSignUpComplete)
               void messageApi?.open({
                  type: 'success',
                  content: 'Please confirm your email and Sign in',
               });

            setTimeout(() => {
               navigate('/signin');
            }, 2000);
         }
      } catch (error: any) {
         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
         showError(error.toString());
      }
      setLoading(false);
   };

   const showError = (message: string): void => {
      void messageApi?.open({
         type: 'error',
         content: message,
      });
   };

   const onGoogleClick = async (): Promise<void> => {
      setLoading(true);
      void signInWithRedirect({
         provider: 'Google',
      });
   };

   return (
      <div className={'min-h-screen flex flex-row justify-center bg-default'}>
         {loading && <Loader />}
         <div className="flex flex-col w-1/2 justify-center">
            <div className="flex flex-row justify-center">
               <div className="flex flex-col w-[60%] items-center">
                  <img
                     src={require(`../../assets/png/logo-${currentTheme}.png`)}
                     alt="logo"
                     className="w-16 h-16"
                     onClick={() => {
                        navigate('/');
                     }}
                  />
                  <p className="text-main text-[30px] mb-0">
                     Create Your Free Account
                  </p>
                  <p className="text-main opacity-50 text-md mb-16">
                     Experience Data Workspace 14 days for no cost
                  </p>
                  <Input
                     placeholder="Fullname"
                     className="mb-6"
                     size="large"
                     onChange={(e) => {
                        setFullname(e.target.value);
                     }}
                     value={fullname}
                  />
                  <Input
                     placeholder="Email"
                     className="mb-6"
                     size="large"
                     onChange={(e) => {
                        setEmail(e.target.value);
                     }}
                     value={email}
                  />
                  <Input.Password
                     placeholder="Password"
                     autoComplete="new-password"
                     className="mb-6"
                     size="large"
                     onChange={(e) => {
                        setPassword(e.target.value);
                     }}
                     value={password}
                  />
                  <Input.Password
                     placeholder="Cofirm Password"
                     autoComplete="new-password"
                     className="mb-6"
                     size="large"
                     onChange={(e) => {
                        setConfirmPassword(e.target.value);
                     }}
                     value={confirmPassword}
                  />

                  <Button
                     onClick={() => {
                        void signupClicked();
                     }}
                     type="primary"
                     size="large"
                     className="w-full"
                  >
                     Sign up
                  </Button>
                  <Divider plain>
                     <span className="text-border">OR</span>
                  </Divider>
                  <Button
                     size="large"
                     className="w-full text-sm font-semibold flex items-center justify-center gap-1"
                     onClick={() => {
                        void onGoogleClick();
                     }}
                  >
                     <img src={GoogleIcon} alt="logo" className="w-4 h-4" />
                     SIGN UP WITH GOOGLE
                  </Button>
                  <p className="text-border text-sm ">
                     Already have an account?{' '}
                     <span
                        className="text-primary cursor-pointer"
                        onClick={() => {
                           navigate('/signin');
                        }}
                     >
                        Sign in
                     </span>
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Signup;
