import React, {
   useEffect,
   useMemo,
   useRef,
   useState,
   type Dispatch,
   type SetStateAction,
} from 'react';
import { Button, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import ActiveTab from './ActiveTab';
import {
   type TabData,
   type ITab,
   projectOverviewTabName,
   type PlayButtonState,
} from './ProjectData';
import Catalog from './catalog/Catalog';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IDBTProjectPayload,
   type IDBTRunJobResponse,
} from '../../providers/useDBTJob';
import { useGlobalState } from '../../context/GlobalContext';
import { HomeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import DiffEditorTab from './editor/DiffEditorTab';
import CreateFileModal from './modals/CreateFileModal';
import { type IFilesResponse } from '../../providers/models/IFiles';
import { getIconPathForFileType } from '../../utils/TreeConfig.util';
import DefaultSmallModal from '../../modals/DefaultSmallModal';

interface IFileTabs {
   activeTabs: TabData;
   setActiveTabs: (activeTabs: any) => void;
   catalogData: any;
   runDbtProjectJob: UseMutateFunction<
      IDBTRunJobResponse,
      Error,
      IDBTProjectPayload,
      unknown
   >;
   runQueryPreview: (type: string, fileValue: string) => void;
   playButtonState: PlayButtonState;
   setPlayButtonState: Dispatch<SetStateAction<PlayButtonState>>;
   projectName: string;
   projectInfoRefetch: () => void;
   treeData: any;
   projectInfoData: IFilesResponse | undefined;
}
const FileTabs: React.FC<IFileTabs> = (props) => {
   const {
      activeTabs,
      setActiveTabs,
      catalogData,
      runDbtProjectJob,
      runQueryPreview,
      playButtonState,
      setPlayButtonState,
      projectName,
      projectInfoRefetch,
      treeData,
      projectInfoData,
   } = props;
   const [isCreateFileModalOpen, setIsCreateFileModalOpen] =
      useState<boolean>(false);
   const [isCloseFileModalOpen, setIsCloseFileModalOpen] =
      useState<boolean>(false);
   const [fileName, setFileName] = useState<string>('');
   const { currentTheme } = useGlobalState();
   const { id: projectId } = useParams();
   const selectedFileIndex = useRef<number>();

   const onEdit = (
      targetKey: React.MouseEvent | React.KeyboardEvent | string,
      action: 'add' | 'remove',
   ): void => {
      if (action === 'remove') {
         if (typeof targetKey === 'string') {
            const arr = [...activeTabs.tabs];
            const index = arr.findIndex((data) => data.key === targetKey);
            selectedFileIndex.current = index;
            const currentFileData = arr[index];
            if (currentFileData.isModified) {
               setIsCloseFileModalOpen(true);
            } else {
               closeFile();
            }
         }
      } else if (action === 'add') {
         setIsCreateFileModalOpen(true);
      }
   };

   const closeFile = (): void => {
      console.log(selectedFileIndex.current);
      const arr = [...activeTabs.tabs];
      const index = selectedFileIndex.current ?? -1;
      arr.splice(index, 1);
      if (arr.length >= 0) {
         let dataFile: ITab;
         if (index === 0) dataFile = arr[index];
         else dataFile = arr[index - 1];
         setActiveTabs((prev: any) => ({
            activeTabKey: dataFile?.key,
            tabs: arr,
         }));
      }
   };

   const runDbt = (type: string): void => {
      runDbtProjectJob({
         command: {
            type,
            args: {
               type,
            },
         },
         projectId: projectId!,
      });
   };

   const isModelAvailable = useMemo(() => {
      const content = treeData.content;
      if (content) {
         for (const file of content) {
            if (
               file?.type === 'file' &&
               file?.relative_path?.includes('models') &&
               file?.name?.endsWith('.sql')
            ) {
               return true;
            }
         }
      }

      return false;
   }, [treeData]);

   useEffect(() => {
      if (
         projectInfoData?.content?.length &&
         projectInfoData?.content?.length > 0
      ) {
         if (fileName !== '') {
            let _fileName = '';
            const fileNameSplitted = fileName.split('.');
            if (fileNameSplitted.length < 2) {
               _fileName = `${fileName}.sql`;
            } else {
               _fileName = fileName;
            }
            const filePath = 'models/' + _fileName;

            const newTab = {
               title: fileName,
               key: filePath,
               modified: false,
               icon: getIconPathForFileType(fileName),
            };
            setActiveTabs((prevState: TabData) => {
               return {
                  tabs: [...prevState.tabs, newTab],
                  activeTabKey: filePath,
               };
            });
            setFileName('');
         }
      }
   }, [projectInfoData]);

   const lineageEmptyPage = (): React.ReactNode => (
      <div className="flex flex-col justify-center items-center  h-[calc(100vh-105px)]">
         <HomeOutlined className="text-primary text-4xl border-[2px] border-solid p-2 rounded-full" />
         <div className="text-main text-2xl mt-3">No Lineage Found</div>
         <div className="text-border text-sm mt-1">
            {isModelAvailable
               ? 'It seems you have not compiled the project. You can start compiling from below button.'
               : 'It seems you have not any model in the project. You can start by creating one from below button.'}
         </div>
         {isModelAvailable ? (
            <Button
               type="primary"
               className="mt-4"
               onClick={() => {
                  runDbt('COMPILE');
               }}
            >
               Compile project
            </Button>
         ) : (
            <Button
               type="primary"
               className="mt-4"
               onClick={() => {
                  setIsCreateFileModalOpen(true);
               }}
            >
               Create Model
            </Button>
         )}
      </div>
   );

   return (
      <div className="flex flex-col w-[100%] pt-1">
         <Tabs
            defaultActiveKey={projectOverviewTabName}
            activeKey={activeTabs.activeTabKey ?? projectOverviewTabName}
            popupClassName={`${currentTheme}`}
            type="editable-card"
            onEdit={onEdit}
            tabBarStyle={{
               marginBottom: '0px',
               paddingLeft: '3px',
            }}
         >
            {activeTabs.tabs.map((fileNode, i) => {
               return (
                  <TabPane
                     key={fileNode.key}
                     closable={fileNode.key !== projectOverviewTabName}
                     className="pb-2"
                     tab={
                        <div
                           className={`min-w-36 cursor-default`}
                           key={fileNode.key}
                           onClick={() => {
                              setActiveTabs((prevState: TabData) => {
                                 return {
                                    ...prevState,
                                    activeTabKey: fileNode.key,
                                 };
                              });
                           }}
                        >
                           <div className="flex flex-row text-main items-center gap-2">
                              {fileNode.icon === projectOverviewTabName ? (
                                 <HomeOutlined className="w-3 h-3 text-primary" />
                              ) : (
                                 <img src={fileNode.icon} className="w-3 h-3" />
                              )}
                              {fileNode.title?.toString()}{' '}
                              {fileNode.isModified && '*'}
                           </div>
                        </div>
                     }
                  >
                     {fileNode.key === projectOverviewTabName ? (
                        catalogData.nodes !== null ? (
                           <Catalog
                              catalogData={catalogData}
                              runDbtProjectJob={runDbtProjectJob}
                           />
                        ) : (
                           lineageEmptyPage()
                        )
                     ) : fileNode?.isDiffTab === true ? (
                        <DiffEditorTab
                           activeTabs={activeTabs}
                           fileContent={'fileContent'}
                           runDbtProjectJob={runDbtProjectJob}
                           runQueryPreview={runQueryPreview}
                           playButtonState={playButtonState}
                           setPlayButtonState={setPlayButtonState}
                           projectName={projectName}
                        />
                     ) : (
                        <ActiveTab
                           activeTabs={activeTabs}
                           setActiveTabs={setActiveTabs}
                           fileContent={'fileContent'}
                           runDbtProjectJob={runDbtProjectJob}
                           runQueryPreview={runQueryPreview}
                           playButtonState={playButtonState}
                           setPlayButtonState={setPlayButtonState}
                           projectName={projectName}
                        />
                     )}
                  </TabPane>
               );
            })}
         </Tabs>
         {isCreateFileModalOpen && (
            <CreateFileModal
               isModalOpen={isCreateFileModalOpen}
               onCancel={() => {
                  setIsCreateFileModalOpen(false);
               }}
               onOk={(_filename: string) => {
                  setFileName(_filename);
                  setIsCreateFileModalOpen(false);
                  projectInfoRefetch();
               }}
            />
         )}
         {isCloseFileModalOpen && (
            <DefaultSmallModal
               title="Warning!"
               description="Are you sure you want to close? Unsaved changes will be lost."
               cancelButtonText="Cancel"
               okButtonText="Yes"
               isModalOpen={isCloseFileModalOpen}
               onCancel={() => {
                  setIsCloseFileModalOpen(false);
               }}
               onOk={() => {
                  closeFile();
                  setIsCloseFileModalOpen(false);
               }}
            />
         )}
      </div>
   );
};

export default FileTabs;
