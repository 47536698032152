/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import GitActions from './GitActions';
import {
   useGitBranchCheckout,
   useGitBranchPull,
   useGitBranchPush,
   useGitProjectBranches,
   useGitProjectRemoveFiles,
   useGitProjectStatus,
   useGitProjectsAddFile,
   useGitProjectsCommit,
} from '../../../providers/useGitProjects';
import { useParams } from 'react-router-dom';
import { showError } from '../../../utils/Utils';
import { useGlobalState } from '../../../context/GlobalContext';
import { ACTION_OK, SECRETS_INITIAL_PAYLOAD } from '../../../utils/Constants';
import { LocalStorage } from '../../../utils/LocalStorage';
import {
   KEY_GIT_TOKEN,
   KEY_GIT_USERNAME,
} from '../../../utils/LocalStorageKeys';
import Loading from '../../../components/Loading';
import { useProjectsSearch } from '../../../providers/useProjects';
import { type IProjectInfo } from '../../../api/models/IProject';
import { initialSearchData } from '../../dashboard/TableData';
import { type TabData } from '../ProjectData';
import { useGetUserSecrets } from '../../signin/useSignin';

interface IGitActionsContainer {
   setActiveTabs: (activeTabs: TabData) => void;
   activeTabs: TabData;
   selectedProject: IProjectInfo | undefined;
   selectedTabKey: string;
}
const GitActionsContainer: React.FC<IGitActionsContainer> = (props) => {
   const { setActiveTabs, activeTabs, selectedProject, selectedTabKey } = props;
   const { messageApi } = useGlobalState();
   const { id: projectId } = useParams();
   const commitMessage = useRef('');
   const commitData = useRef<{ isAddAllFiles: boolean; files: string[] }>();
   const [gitUrl, setGitUrl] = useState('');
   const [statusMessage, setStatusMessage] = useState('');

   const {
      data: gitStatuslData,
      isError: isGitStatusDataError,
      isFetching: isGitStatusDataLoading,
      error: gitStatuslDataError,
      refetch: gitSatusReftech,
   } = useGitProjectStatus(projectId);

   const {
      data: gitBranches,
      isError: isGitBranchesError,
      isFetching: isGitBranchesLoading,
      error: gitBranchesError,
      refetch: refetchGitBranches,
   } = useGitProjectBranches(projectId, selectedProject);

   const {
      mutate: gitProjectsAddFiles,
      data: gitProjectsAddFilesData,
      isError: isGitProjectsAddFilesError,
      error: gitProjectsAddFilesError,
      isPending: isGitProjectsAddFilesLoading,
   } = useGitProjectsAddFile();

   const {
      mutate: gitProjectsCommitFiles,
      data: gitProjectsCommitFilesData,
      isError: isGitProjectsCommitFilesError,
      error: gitProjectsCommitFilesError,
      isPending: isGitProjectsCommitFilesLoading,
   } = useGitProjectsCommit();

   const {
      mutate: mutateGitBranchCheckout,
      data: gitBranchCheckoutData,
      isError: isGitBranchCheckoutError,
      error: gitBranchCheckoutError,
      isPending: isGitBranchCheckoutLoading,
   } = useGitBranchCheckout();

   const {
      mutate: mutateGitBranchPull,
      data: gitBranchPulltData,
      isError: isGitBranchPullError,
      error: gitBranchPullError,
      isPending: isGitBranchPullLoading,
   } = useGitBranchPull();

   const {
      mutate: mutateGitBranchPush,
      data: gitBranchPushtData,
      isError: isGitBranchPushError,
      error: gitBranchPushError,
      isPending: isGitBranchPushLoading,
   } = useGitBranchPush();

   const {
      mutate: projectSearch,
      isError: isProjectSearchError,
      data: projectSearchData,
      isPending: isProjectSearchLoading,
      error: projSearchError,
   } = useProjectsSearch();

   const {
      mutate: gitProjectRemoveFiles,
      data: gitProjectRemoveFilesData,
      isError: isGitProjectRemoveFilesError,
      error: gitProjectRemoveFilesError,
      isPending: isGitProjectRemoveFilesLoading,
   } = useGitProjectRemoveFiles();

   const {
      data: userSecrets,
      isError: isUserSecretsError,
      isPending: isUserSecretsLoading,
      error: userSecretsError,
      mutate: userSecretsMutate,
   } = useGetUserSecrets();

   useEffect(() => {
      projectSearch(initialSearchData);
      userSecretsMutate(SECRETS_INITIAL_PAYLOAD);
   }, []);

   useEffect(() => {
      if (selectedTabKey === '3') {
         void gitSatusReftech();
      }
   }, [selectedTabKey]);

   useEffect(() => {
      if (isGitStatusDataError) {
         showError(messageApi, `${gitStatuslDataError.message}`);
      }
      if (gitStatuslData !== undefined && gitStatuslData !== null) {
         setStatusMessage('Git status successfull');
      }
   }, [isGitStatusDataLoading, gitStatuslData]);

   useEffect(() => {
      if (isGitBranchesError) {
         showError(messageApi, `${gitBranchesError.message}`);
      }
      if (gitBranches !== undefined && gitBranches !== null) {
         console.log({ gitBranches });
      }
   }, [isGitBranchesLoading, gitBranches]);

   useEffect(() => {
      if (isGitProjectsAddFilesError) {
         showError(messageApi, `${gitProjectsAddFilesError.message}`);
      }
      if (
         gitProjectsAddFilesData !== undefined &&
         gitProjectsAddFilesData !== null &&
         gitProjectsAddFilesData.ack === ACTION_OK
      ) {
         commitCode(false);
      }
   }, [isGitProjectsAddFilesLoading, gitProjectsAddFilesData]);

   useEffect(() => {
      if (isGitProjectsCommitFilesError) {
         showError(messageApi, `${gitProjectsCommitFilesError.message}`);
      }
      if (
         gitProjectsCommitFilesData !== undefined &&
         gitProjectsCommitFilesData !== null
      ) {
         void gitSatusReftech();
      }
   }, [isGitProjectsCommitFilesLoading, gitProjectsCommitFilesData]);

   useEffect(() => {
      if (isGitBranchCheckoutError) {
         showError(messageApi, `${gitBranchCheckoutError.message}`);
      }
      if (
         gitBranchCheckoutData !== undefined &&
         gitBranchCheckoutData !== null &&
         gitBranchCheckoutData.ack === ACTION_OK
      ) {
         void refetchGitBranches();
      }
   }, [isGitBranchCheckoutLoading, gitBranchCheckoutData]);

   useEffect(() => {
      if (isGitBranchPullError) {
         showError(messageApi, `${gitBranchPullError.message}`);
      }
      if (gitBranchPulltData !== undefined && gitBranchPulltData !== null) {
         setStatusMessage(gitBranchPulltData.message);
      }
   }, [isGitBranchPullLoading, gitBranchPulltData]);

   useEffect(() => {
      if (isGitBranchPushError) {
         showError(messageApi, `${gitBranchPushError.message}`);
      }
      if (gitBranchPushtData !== undefined && gitBranchPushtData !== null) {
         setStatusMessage(gitBranchPushtData.message);
      }
   }, [isGitBranchPushLoading, gitBranchPushtData]);

   useEffect(() => {
      if (isProjectSearchError) {
         showError(messageApi, `${projSearchError.message}`);
      }
      if (projectSearchData !== undefined) {
         const _project = projectSearchData?.data?.find(
            (project: IProjectInfo) => project.id === projectId,
         );
         setGitUrl(_project?.gitUrl ?? '');
      }
   }, [isProjectSearchLoading, projectSearchData]);

   useEffect(() => {
      if (isGitProjectRemoveFilesError) {
         showError(messageApi, `${gitProjectRemoveFilesError.message}`);
      }
      if (
         gitProjectRemoveFilesData !== undefined &&
         gitProjectRemoveFilesData !== null &&
         gitProjectRemoveFilesData.ack === ACTION_OK
      ) {
         checkConfigBeforeCommit();
      }
   }, [isGitProjectRemoveFilesLoading, gitProjectRemoveFilesData]);

   useEffect(() => {
      if (isUserSecretsError) {
         showError(messageApi, `${userSecretsError.message}`);
      }
      if (userSecrets !== undefined && userSecrets !== null) {
         console.log('userSecrets ');
      }
   }, [isUserSecretsLoading, userSecrets]);

   const addAndCommitFiles = (
      files: string[],
      message: string,
      isAddAllFiles: boolean,
      removedFiles: string[],
   ): void => {
      commitMessage.current = message;
      commitData.current = { isAddAllFiles, files };
      if (removedFiles.length !== 0) {
         gitProjectRemoveFiles({
            id: projectId ?? '',
            files: removedFiles,
         });
      } else {
         checkConfigBeforeCommit();
      }
   };

   const checkConfigBeforeCommit = (): void => {
      const { isAddAllFiles, files } = commitData.current!;
      if (isAddAllFiles) {
         commitCode(true);
      } else if (files.length !== 0) {
         gitProjectsAddFiles({
            id: projectId ?? '',
            files,
         });
      } else {
         commitCode(false);
      }
   };

   const commitCode = (isAddAllFiles: boolean): void => {
      gitProjectsCommitFiles({
         id: projectId ?? '',
         message: commitMessage.current,
         git_username: '',
         git_token: '',
         add_all: isAddAllFiles,
      });
   };

   const gitBranchCheckout = (branchName: string): void => {
      mutateGitBranchCheckout({ id: projectId ?? '', name: branchName });
   };

   const gitBranchPullOrPush = (isPull: boolean): void => {
      setStatusMessage('');
      if (isPull) {
         mutateGitBranchPull({
            id: projectId ?? '',
         });
      } else {
         mutateGitBranchPush({
            id: projectId ?? '',
         });
      }
   };

   const afterCommitMessage = useMemo(() => {
      if (
         gitProjectsCommitFilesData !== null &&
         gitProjectsCommitFilesData !== undefined
      ) {
         const map = new Map<string, number>();
         const data = Array.from(Object.values(gitProjectsCommitFilesData));
         data.forEach((value) => {
            if (map.has(value.change_type)) {
               const count = map.get(value.change_type)! + 1;
               map.set(value.change_type, count);
            } else {
               map.set(value.change_type, 1);
            }
         });

         const added = map.get('ADDED');
         const deleted = map.get('DELETED');
         const modified = map.get('MODIFIED');
         let message = '';
         if (added !== undefined && added !== 0)
            message += added + ' file added(+), ';
         if (deleted !== undefined && deleted !== 0)
            message += deleted + ' file deletion(-) ,';
         if (modified !== undefined && modified !== 0)
            message += modified + ' file modified';
         return message;
      }
      return '';
   }, [gitProjectsCommitFilesData]);

   return (
      <>
         {(isGitStatusDataLoading ||
            isGitBranchesLoading ||
            isGitProjectsAddFilesLoading ||
            isGitProjectsCommitFilesLoading ||
            isGitBranchCheckoutLoading ||
            isGitBranchPullLoading ||
            isGitBranchPushLoading ||
            isGitProjectRemoveFilesLoading) && (
            <Loading messages="Fetching git changes" />
         )}

         <GitActions
            gitStatuslData={gitStatuslData}
            gitSatusReftech={gitSatusReftech}
            gitBranches={gitBranches}
            addAndCommitFiles={addAndCommitFiles}
            gitBranchCheckout={gitBranchCheckout}
            gitBranchPullOrPush={gitBranchPullOrPush}
            gitUrl={gitUrl}
            statusMessage={statusMessage}
            setActiveTabs={setActiveTabs}
            activeTabs={activeTabs}
            secrets={userSecrets?.results}
            afterCommitMessage={afterCommitMessage}
         />
      </>
   );
};

export default GitActionsContainer;
