export const API_VERSION = 'api/v1/';
export const ACTION_SIGNIN = 'login';
export const ACTION_CREATE_PROJECT = API_VERSION + 'project';
export const ACTION_DATA_SOURCES_SEARCH = API_VERSION + 'data-source';
export const ACTION_DATA_SOURCES = API_VERSION + 'connectors';
export const ACTION_CONNECTORS = API_VERSION + 'connectors';
export const ACTION_DRIVERS = API_VERSION + 'connectors/drivers';
export const ACTION_DATA = API_VERSION + 'data';
export const ACTION_METADATA = API_VERSION + 'metadata';
export const ACTION_PROJECT = API_VERSION + 'project';
export const ACTION_CONFIG = API_VERSION + 'tenant';
export const ACTION_USERS = API_VERSION + 'users';
