import React from 'react';
import { Button, Modal, Table } from 'antd';
import { PROJECT_COLUMNS } from './TableData';
import {
   type IProjectInfo,
   type IProjectSearchData,
} from '../../api/models/IProject';

interface ICreateProjectModal {
   projSearchData: IProjectSearchData | undefined;
   onItemClick: (data: any, index: number) => void;
   isModalOpen: boolean;
   onOk: () => void;
   onCancel: () => void;
}
const ProjectsListModal: React.FC<ICreateProjectModal> = (props) => {
   const { projSearchData, isModalOpen, onOk, onCancel, onItemClick } = props;

   const onDataSourceSelect = (value: string, options: any): void => {
      console.log(`selected `, options);
   };

   const onDeleteClick = (data: IProjectInfo): void => {
      console.log(data);
   };

   return (
      <>
         <Modal
            title="All Projects"
            open={isModalOpen}
            onOk={() => {
               onOk();
            }}
            onCancel={() => {
               onCancel();
            }}
            footer={[
               <Button key="back" onClick={onCancel}>
                  Cancel
               </Button>,
               <Button key="submit" type="primary" onClick={onOk}>
                  Ok
               </Button>,
            ]}
            width={'80%'}
         >
            <Table
               className="mt-4 "
               rowClassName="cursor-pointer"
               scroll={{ y: '45vh' }}
               dataSource={projSearchData?.data}
               columns={PROJECT_COLUMNS(onDeleteClick)}
               onRow={(record, rowIndex) => {
                  return {
                     onClick: (event) => {
                        onItemClick(record, rowIndex!);
                     },
                  };
               }}
            />
         </Modal>
      </>
   );
};

export default ProjectsListModal;
