import React, { useEffect, useState } from 'react';
import { useProjectsSearch } from '../../providers/useProjects';
import { useDataSources } from '../../providers/useDataSources';
import { useGlobalState } from '../../context/GlobalContext';
import {
   DATA_SOURCE_STATE,
   PROJECT_DIRECTORY_TREE_STATE,
   PROJECT_HOME_STATE,
} from '../../utils/Constants';
import { Alert, Button, Table } from 'antd';
import { PlusOutlined, WarningFilled } from '@ant-design/icons';
import DashboardRightSideImage from '../../assets/svgs/dashboard_right_side.svg';
import { initialSearchData, PROJECT_COLUMNS } from './TableData';
import CreateProjectModal from './CreateProjectModal';
import { useNavigate, useParams } from 'react-router-dom';
import { type IProjectInfo } from '../../api/models/IProject';
import { type IDataSource } from '../../providers/models/IDataSource';
import { ReactComponent as ProjectIcon } from '../../assets/svgs/selected-project-icon.svg';
import Loader from '../../components/Loader';
import ProjectChangeModal from '../../modals/ProjectChangeModal';
import { showError } from '../../utils/Utils';
import { useProjectDelete } from '../../providers/useProjectFiles';
import DeleteProjectModal from '../project/DeleteProjectModal';
interface IHome {
   data?: string;
}
const Home: React.FC<IHome> = () => {
   const [searchData] = useState(initialSearchData);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isChangeProductModalOpen, setIsChangeProductModalOpen] =
      useState(false);
   const [selectedProjectData, setSelectedProjectData] =
      useState<IProjectInfo>();
   const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] =
      useState(false);
   const { data: dataSources, isPending: isDSSearchLoading } = useDataSources();
   const { id: projectId } = useParams();
   const navigate = useNavigate();

   const {
      mutate: projectSearch,
      isError: isSearchError,
      data: projSearchData,
      isPending: isProjSearchLoading,
      error: projSearchError,
   } = useProjectsSearch();

   const {
      setIsCollapsed,
      selectedProjectState,
      setSelectedProjectState,
      messageApi,
   } = useGlobalState();

   const {
      mutate: deleteProject,
      data: deleteProjectData,
      isPending: isDeleteProjectLoading,
      isError: isDeleteProjectError,
      error: deleteProjectError,
   } = useProjectDelete();

   useEffect(() => {
      if (!projSearchData) {
         projectSearch(searchData);
      }
   }, []);

   useEffect(() => {
      if (isSearchError) {
         void messageApi?.open({
            type: 'error',
            content: projSearchError?.message,
         });
      }
   }, [projSearchData, isProjSearchLoading]);

   useEffect(() => {
      if (isDeleteProjectError) {
         // showError(messageApi, `${deleteProjectError.message}`);
         projectSearch(searchData);
      }
      if (deleteProjectData !== undefined && deleteProjectData !== null) {
         if (deleteProjectData?.ack === 'ok') {
            projectSearch(searchData);
         }
      }
   }, [isDeleteProjectLoading, deleteProjectData]);

   const onProjectCreated = (): void => {
      projectSearch(searchData);
      setIsModalOpen(false);
   };

   const handleCancel = (): void => {
      setIsModalOpen(false);
   };
   const createProject = (): void => {
      setIsModalOpen(true);
   };

   const onItemClick = (record: any, rowIndex: number | undefined): void => {
      setSelectedProjectData(record as IProjectInfo);
      if (projectId !== undefined) {
         if (projectId !== record.id) {
            setIsChangeProductModalOpen(true);
         } else {
            setSelectedProjectState(PROJECT_DIRECTORY_TREE_STATE);
         }
      } else {
         navigateProject(record);
      }
   };

   const navigateProject = (record: any): void => {
      navigate('/project/' + record.id, {
         state: {
            projectData: record,
         },
      });
   };

   const getDSource = (id: string): IDataSource | any => {
      const ds = dataSources?.results.find(
         (item: IDataSource) => item.id === id,
      );
      return ds;
   };

   const projectData = (): IProjectInfo[] | any[] => {
      const data = projSearchData?.data?.map((item: IProjectInfo) => {
         const dataSource = getDSource(item.configuration?.sourceId ?? '');
         const dsName = dataSource?.name;
         const dsIcon = dataSource?.icon;
         return {
            ...item,
            configuration: {
               ...item.configuration,
               dataSource: dsName,
               dataSourceIcon: dsIcon,
            },
         };
      });
      return data ?? [];
   };

   const onDeleteClick = (data: IProjectInfo): void => {
      setSelectedProjectData(data);
      setIsDeleteProjectModalOpen(true);
   };

   return (
      <div className="w-full overflow-y-auto h-[calc(100vh-0px)] max-h-[calc(100vh-0px)]">
         {(isProjSearchLoading ||
            isDSSearchLoading ||
            isDeleteProjectLoading) && (
            <Loader
               description={
                  isDeleteProjectLoading
                     ? 'Deleting Project'
                     : 'Loading Projects'
               }
            />
         )}
         {projSearchData?.data?.length === 0 && (
            <div className="flex flex-col p-5">
               <div className="text-main text-base font-bold ">
                  Data Workspace
               </div>
               <div className="flex flex-row justify-center h-[calc(100vh-100px)] mt-6">
                  <div className="flex flex-col justify-center w-1/2 px-16  gap-2">
                     {dataSources?.results?.length === 0 && (
                        <Alert
                           className="py-2 mb-20 flex items-center gap-2"
                           message={
                              <div className="text-sm font-semibold mb-0">
                                 No Datasource available
                              </div>
                           }
                           description={
                              <div className="text-sm mt-0">
                                 You need at least one datasource to create a
                                 project.&nbsp;
                              </div>
                           }
                           type="warning"
                           showIcon
                           icon={<WarningFilled />}
                        />
                     )}
                     <div className="text-primary text-xs uppercase font-bold">
                        Projects
                     </div>
                     <div className="text-main text-base font-bold">
                        Structure your Code and Logic
                     </div>
                     <div className="text-border text-xs w-[60%]">
                        SQL files, configurations, and resources in a folder
                        with version-control and easily deployable.
                     </div>

                     <div>
                        <Button
                           type="primary"
                           className="mt-4"
                           onClick={() => {
                              createProject();
                           }}
                           disabled={dataSources?.results?.length === 0}
                        >
                           <PlusOutlined /> Create Project
                        </Button>
                     </div>
                  </div>
                  <div className="w-1/2 flex flex-col justify-center">
                     <div className="w-10/12 flex justify-center py-16 rounded-md bg-border bg-opacity-10">
                        <img src={DashboardRightSideImage} />
                     </div>
                  </div>
               </div>
            </div>
         )}
         {projSearchData?.data?.length !== 0 &&
            dataSources?.results?.length !== 0 && (
               <div className="px-5">
                  <div className="flex flex-row items-center justify-between mt-5">
                     <div className="flex flex-row items-center gap-2">
                        <div className="text-3xl text-main">Projects</div>
                        <ProjectIcon className="size-6 mt-1 text-main" />
                        <div className="text-xs text-main mt-1.5">
                           Integrate, Orchestrate, Automate
                        </div>
                     </div>
                     <Button
                        onClick={() => {
                           createProject();
                        }}
                        type="primary"
                        size="large"
                     >
                        <PlusOutlined />
                        Create Project
                     </Button>
                  </div>
                  {isDeleteProjectError &&
                     deleteProjectError?.message !== '' && (
                        <Alert
                           className="py-2 my-2 flex items-center gap-2"
                           description={
                              <div className="text-sm mt-0">
                                 {deleteProjectError?.message}
                              </div>
                           }
                           type="error"
                           showIcon
                           icon={<WarningFilled />}
                           closable={true}
                        />
                     )}
                  <div className="mt-2 shadow-lg rounded-lg ">
                     <Table
                        className="mt-4"
                        rowClassName="cursor-pointer group"
                        scroll={{ y: 'calc(100vh - 200px)' }}
                        dataSource={projectData()}
                        columns={PROJECT_COLUMNS(onDeleteClick)}
                        onRow={(record, rowIndex) => {
                           return {
                              onClick: (event) => {
                                 onItemClick(record, rowIndex);
                              },
                           };
                        }}
                     />
                  </div>
               </div>
            )}

         {isModalOpen && (
            <CreateProjectModal
               isModalOpen={isModalOpen}
               onProjectCreated={onProjectCreated}
               onCancel={handleCancel}
            />
         )}
         {isChangeProductModalOpen && (
            <ProjectChangeModal
               isModalOpen={isChangeProductModalOpen}
               onCancel={() => {
                  setIsChangeProductModalOpen(false);
               }}
               onOk={() => {
                  setIsChangeProductModalOpen(false);
                  navigateProject(selectedProjectData);
               }}
               projectName={selectedProjectData?.projectName ?? ''}
            />
         )}

         {isDeleteProjectModalOpen && (
            <DeleteProjectModal
               isModalOpen={isDeleteProjectModalOpen}
               projectName={selectedProjectData?.projectName ?? ''}
               onCancel={() => {
                  setIsDeleteProjectModalOpen(false);
               }}
               onOk={() => {
                  setIsDeleteProjectModalOpen(false);
                  deleteProject(selectedProjectData?.id ?? '');
               }}
            />
         )}
      </div>
   );
};

export default Home;
