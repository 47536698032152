import React from 'react';
import { Button, Modal } from 'antd';

interface IChangeBranchModal {
   isModalOpen: boolean;
   onCancel: () => void;
   onOk: () => void;
   branchName: string;
}
const ChangeBranchModal: React.FC<IChangeBranchModal> = (props) => {
   const { isModalOpen, onCancel, onOk, branchName } = props;

   return (
      <>
         <Modal
            title={`Switch branch to "${branchName}"`}
            open={isModalOpen}
            onCancel={() => {
               onCancel();
            }}
            footer={(_) => (
               <>
                  <Button type="text" onClick={onCancel}>
                     Cancel
                  </Button>

                  <Button
                     key="submit"
                     type="primary"
                     onClick={() => {
                        onOk();
                     }}
                  >
                     Switch
                  </Button>
               </>
            )}
            width={'30%'}
         >
            <div className="flex flex-col">
               Please commit your changes before switching branches. Otherwise
               your changes will be lost.
            </div>
         </Modal>
      </>
   );
};

export default ChangeBranchModal;
