export const IN_PROGRESS = 'IN_PROGRESS';
export const FAILED = 'FAILED';
export const COMPLETED = 'COMPLETED';
export const PROJECT_HOME_STATE = 'project_home_state';
export const PROJECT_DIRECTORY_TREE_STATE = 'project_directory_tree_state';
export const PROJECT_JOBS_STATE = 'project_jobs_state';
export const PROJECT_GIT_STATE = 'project_git_state';
export const DATA_SOURCE_STATE = 'data_source_state';
export const PIPELINES_STATE = 'pipelines_state';
export const SETTINGS_STATE = 'settings_state';

export const THEME_DARK = 'theme-dark';
export const THEME_LIGHT = 'theme-light';
export const DATABASES = ['PostgreSQL', 'MySQL', 'SQLServer', 'Snowflake'];
export const ACTION_OK = 'ok';

export enum AuthNextType {
   CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
   CONTINUE_SIGN_IN_WITH_MFA_SELECTION = 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
   CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
   CONFIRM_SIGN_IN_WITH_TOTP_CODE = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
   CONTINUE_SIGN_IN_WITH_TOTP_SETUP = 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
   CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP',
   RESET_PASSWORD = 'RESET_PASSWORD',
   DONE = 'DONE',
   CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
   CONFIRM_RESET_PASSWORD_WITH_CODE = 'CONFIRM_RESET_PASSWORD_WITH_CODE',
}

export const SECRETS_INITIAL_PAYLOAD = {
   page: 0,
   size: 20,
   query: '*',
   sort_key: 'last_updated_timestamp',
   sort_value: 'desc',
   filter_by: [],
};
