import {
   useMutation,
   type UseQueryResult,
   type UseMutationResult,
   useQuery,
} from '@tanstack/react-query';
import { apiGet, apiPost } from '../api/ApiService';
import ApiConstants from '../api/ApiConstants';

export enum DBTJobType {
   RUN = 'RUN', // presto represents federated type
   SEED = 'SEED',
   DOCS = 'DOCS',
   COMPILE = 'COMPILE',
   TEST = 'TEST',
   DEPS = 'DEPS',
}

interface IDBTCommand {
   type: string;
   args: {
      type: string;
      freshness?: string;
      select?: string;
      action?: string;
   };
}

export interface IDBTProjectPayload {
   command: IDBTCommand;
   projectId: string;
}

export interface RowsFetched {
   status: string;
   total_error_rows: number;
   total_fetched_rows: number;
   execution_start_ts: number;
   execution_end_ts: number;
}

export interface Metadata {
   rows_fetched: RowsFetched;
}

export interface Args {
   type: string;
}

export interface Jobparameters {
   args: Args;
}

export interface IDBTRunJobResponse {
   created_at: string;
   last_modified_at: string;
   created_by: string;
   last_modified_by: string;
   tenant_id: string;
   id: string;
   name: string;
   reference_id: string;
   job_parameters: Jobparameters;
   metadata: Metadata;
   job_type: string;
   job_status: string;
   status: string;
   message: string;
   error: string | null;
   result_path: string | null;
   error_path: string | null;
}

export interface IDBTProjectJobHistoryResponse {
   total: number;
   results: IDBTRunJobResponse[];
}

export function useDBTProjectRunJob(): UseMutationResult<
   IDBTRunJobResponse,
   Error,
   IDBTProjectPayload
> {
   const runDBTProject = async (
      data: IDBTProjectPayload,
   ): Promise<IDBTRunJobResponse> => {
      const { projectId, command } = data;
      const requestPayload = {
         project_id: projectId,
         command,
      };

      console.log('### RUN DBT project 0', requestPayload, command);
      // here first create datamap against which pipeline to create
      try {
         const response = await apiPost({
            path: `api/v1/project/${requestPayload.project_id}/dbt`,
            url: ApiConstants.workspaceBaseUrl,
            body: requestPayload,
         });
         if (response.status === 200) {
            return response.data as IDBTRunJobResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (e: any) {
         let errText = 'DBT Job Failed!';
         console.log('### ERR DBT JOB:', e);
         if (e?.response?.data?.error) {
            errText = e.response.data.error;
         } else if (
            e?.response?.data?.error_messages !== undefined &&
            e?.response?.data?.error_messages?.length
         ) {
            errText = e?.response?.data?.error_messages[0];
         } else if (e.message) {
            errText = e.message;
         }
         throw new Error(errText);
      }
   };

   return useMutation<IDBTRunJobResponse, Error, IDBTProjectPayload>({
      mutationFn: runDBTProject,
      onSuccess: async (dm: any) => {
         console.log("### Reset Fact success I'm first!", dm);
      },
      onSettled: async (data: any) => {
         console.log("### Reset Fact  I'm second!", data);
         // queryClient.removeQueries("data-map-job-historyy-logs", { exact: true });
      },
      onError: async (err: any) => {
         console.error("### Reset Fact  I'm second!", err);
      },
   });
}

const waitTimeout = async (ms: number): Promise<void> => {
   await new Promise((resolve) => {
      console.log(`waiting ${ms} ms...`);
      setTimeout(resolve, ms);
   });
};

export const useDBTJobPoll = (
   jobId: string | null,
): UseQueryResult<IDBTRunJobResponse | null, Error> => {
   return useQuery({
      queryKey: ['projectJobStatus', `${jobId}`],
      queryFn: async () => {
         try {
            if (jobId === null) return null;
            if (jobId) {
               let jobResponse: any;
               let receivedStatus = 'IN_PROGRESS';
               while (
                  receivedStatus === 'IN_PROGRESS' ||
                  receivedStatus === 'INIT'
               ) {
                  jobResponse = await apiGet({
                     path: `api/v1/job/${jobId}`,
                     body: null,
                     url: ApiConstants.jobBaseUrl,
                     headers: {},
                  });
                  if (!jobResponse) {
                     throw new Error('Api Error');
                  }
                  console.log('Job Status 0', jobResponse);

                  receivedStatus = jobResponse?.data?.job_status;
                  // 3.2 If status is success, we can skip the rest of the loop and query results.
                  console.log('Job Status 1', receivedStatus);
                  if (
                     receivedStatus === 'COMPLETED' ||
                     receivedStatus === 'FAILED'
                  ) {
                     break;
                  }

                  // 3.4 Wait a bit before checking again
                  await waitTimeout(3000); // pooling of api for job status
               }

               return jobResponse.data as IDBTRunJobResponse;
            }
            return null;
         } catch (error: any) {
            let errText = 'File failed';
            if (error?.response?.data?.error !== undefined) {
               errText = error.response.data.error;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
      },
   });
};

export const useDBTProjectJobHistory = (
   projId: string | null,
): UseQueryResult<IDBTProjectJobHistoryResponse | null, Error> => {
   return useQuery({
      queryKey: ['projectJobStatusHistory', `${projId}`],
      queryFn: async () => {
         try {
            if (projId === null) {
               return {
                  total: 0,
                  results: [],
               };
            }
            if (projId) {
               const jobResponse = await apiGet<IDBTProjectJobHistoryResponse>({
                  path: `api/v1/job?ref_id=${projId}&size=100&from=0`,
                  body: null,
                  url: ApiConstants.jobBaseUrl,
                  headers: {},
               });
               if (!jobResponse || jobResponse.status !== 200) {
                  throw new Error('Api Error');
               }

               return jobResponse.data!;
            }
            return null;
         } catch (error: any) {
            let errText = 'File failed';
            if (error?.response?.data?.error !== undefined) {
               errText = error.response.data.error;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
      },
   });
};

export const useProjectJobLogFiles = (
   jobId: string | null,
): UseQueryResult<any, Error> => {
   return useQuery({
      queryKey: ['projectJobLogFile', `${jobId}`],
      queryFn: async () => {
         try {
            if (jobId === null) return null;
            if (jobId) {
               const response: any = await apiGet({
                  path: `api/v1/log/${jobId}`,
                  body: null,
                  url: ApiConstants.workspaceBaseUrl,
                  headers: { Accept: 'application/octet-stream' },
               });
               console.log('Log File Resp:', response);
               if (response.status === 200) {
                  return response.data?.content ?? { content: response.data };
               } else {
                  throw new Error('Api Error');
               }
            }
            return null;
         } catch (error: any) {
            let errText = 'File failed';
            if (error?.response?.data?.error !== undefined) {
               errText = error.response.data.error;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
      },
   });
};
