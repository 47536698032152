/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-types */
import { type DataNode } from 'antd/lib/tree';
import { type ITreeParsedRawFile, type ITreeRawDirectory } from './types/tree';
import { type IFile } from './types/files';

const getSplittedPath = (path: string): string[] => {
   const splitted = path.split('/').filter((i) => !!i);
   return splitted.length ? splitted : [];
};

export const getParsedRawFile = (file: IFile): ITreeParsedRawFile => {
   const path = getSplittedPath(file.relative_path);

   return {
      title: file.name,
      pathParts: path,
      type: file.type,
   };
};

export const getSortedByLeaf = (array: any[]): any => {
   return array.sort((a) => {
      if (a.isLeaf) return 1;
      return -1;
   });
};

export const getSortedByAlphabet = (array: any[]): any => {
   return array.sort((a, b) => {
      return !a.isLeaf ? a.title.localeCompare(b.title) : 1;
   });
};

export const getObjectByPath = (
   obj: any,
   pathParts: string[],
): ITreeRawDirectory => {
   const key = pathParts.length ? pathParts.shift() : undefined;

   if (pathParts.length && key) {
      return getObjectByPath(obj[key], pathParts);
   }

   return key ? obj[key] : obj;
};

const findElements = (
   path: any,
   source: any,
   prevPath: any,
   pathSplit: any,
): DataNode => {
   const writeBeginning = prevPath ? '/' : '';

   const key = `${prevPath}${pathSplit[0] ? `${writeBeginning}${pathSplit[0]}` : ''}`;

   const element = source.find((s: any) => s.key === path);
   if (!element) {
      pathSplit.splice(0, 1);
      const newRoot = source.find((s: any) => s.key === key);
      return findElements(path, newRoot.children, key, pathSplit);
   }
   return element;
};

export const execSearch = (data: any, path: string): DataNode => {
   const pathSplit = path.split('/').filter((v) => !!v);
   const res = findElements(path, data, '', pathSplit);
   return res;
};

export const fileNameTitleCleanUp = (str: string, charLimit: number): string =>
   // eslint-disable-next-line no-useless-escape
   str
      .replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')
      .replace(/[\n\r]/g, ' ')
      .replace(/\s\s+/g, ' ')
      .replace(/[\s]/gi, '_')
      .trim()
      .substring(0, charLimit)
      .trim()
      .toLowerCase();

export const datamapTitleCleanup = (str: string, charLimit: number): string =>
   // eslint-disable-next-line no-useless-escape
   str
      .replace(/[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
      .replace(/[\n\r]/g, ' ')
      .replace(/\s\s+/g, ' ')
      .replace(/[\s]/gi, '_')
      .trim()
      .substring(0, charLimit)
      .trim();
