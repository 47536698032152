import React, { type ReactElement, useState, useEffect } from 'react';
import { Alert, Button } from 'antd';

interface IProps {
   message: string;
   callback?: () => void;
   initialTick?: number;
   context?: string;
}

export default function TimerAlert({
   message,
   callback,
   initialTick = 0,
   context = '',
}: IProps): ReactElement {
   const [ticks, setTick] = useState<number>(initialTick);
   const [isClicked, setIsClicked] = useState(false);

   useEffect(() => {
      const interval = setInterval(() => {
         setTick((sec) => sec + 1);
      }, 1000);

      return () => {
         clearInterval(interval);
         setTick(0);
      };
   }, [message]);

   function toClockPresentation(timer: number): string {
      const minutes = Math.floor(timer / 60);
      const seconds = Math.floor(timer % 60);

      const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      return `${formattedMinute}:${formattedSeconds}`;
   }

   function handleClick(): void {
      setIsClicked(true);
      if (callback) {
         callback();
      }
   }

   return (
      <Alert
         type={
            context === '' || context === 'error-alert-create-project-modal'
               ? 'error'
               : 'success'
         }
         showIcon={context !== ''}
         className={`time-alert-with-margins ${context === '' ? '' : context}`}
         message={`${message} ${context !== 'error-alert-create-project-modal' ? toClockPresentation(ticks) : ''}`}
         action={
            callback && (
               <Button
                  size="small"
                  danger
                  type="link"
                  disabled={isClicked}
                  onClick={handleClick}
               >
                  Cancel
               </Button>
               // eslint-disable-next-line
        )
         }
      />
   );
}

TimerAlert.defaultProps = {
   callback: null,
   initialTick: 0,
};
