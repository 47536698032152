import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { showError } from '../../../utils/Utils';
import { useGlobalState } from '../../../context/GlobalContext';
import { LocalStorage } from '../../../utils/LocalStorage';
import {
   KEY_GIT_TOKEN,
   KEY_GIT_USERNAME,
} from '../../../utils/LocalStorageKeys';

interface IGitUsernameModal {
   isModalOpen: boolean;
   onCancel: () => void;
   onOk: () => void;
}
const GitUsernameModal: React.FC<IGitUsernameModal> = (props) => {
   const { isModalOpen, onCancel, onOk } = props;
   const [gitUsername, setGitUsername] = useState<string>('');
   const [gitToken, setGitToken] = useState<string>('');
   const { messageApi } = useGlobalState();

   return (
      <>
         <Modal
            title={`Git Username & token`}
            open={isModalOpen}
            onCancel={() => {
               onCancel();
            }}
            footer={(_) => (
               <>
                  <Button type="text" onClick={onCancel}>
                     Cancel
                  </Button>

                  <Button
                     key="submit"
                     type="primary"
                     onClick={() => {
                        if (gitUsername === '' || gitToken === '') {
                           showError(
                              messageApi,
                              'Please add Git username and Git token',
                           );
                           return;
                        }
                        LocalStorage.set(KEY_GIT_USERNAME, gitUsername);
                        LocalStorage.set(KEY_GIT_TOKEN, gitToken);
                        onOk();
                     }}
                  >
                     Save
                  </Button>
               </>
            )}
            width={'30%'}
         >
            <div className="flex flex-col gap-2 my-4">
               <Input
                  placeholder="Git Username"
                  value={gitUsername}
                  size="large"
                  onChange={(e) => {
                     setGitUsername(e.target.value);
                  }}
               />
               <Input.Password
                  placeholder="Git Token"
                  value={gitToken}
                  autoComplete="new-password"
                  size="large"
                  onChange={(e) => {
                     setGitToken(e.target.value);
                  }}
               />
            </div>
         </Modal>
      </>
   );
};

export default GitUsernameModal;
