import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { type ReactElement } from 'react';

import { uniformDataTypeForIcon } from '../utils/normalizeData';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
   faQuestion,
   faCalendarDays,
   faToggleOn,
   faFont,
} from '@fortawesome/free-solid-svg-icons';

library.add(faCalendarDays, faToggleOn, faFont, faQuestion);

export enum IconDataTypes {
   STRING = 'string',
   DECIMAL = 'decimal',
   NON_DECIMAL = 'nonDecimal',
   BOOLEAN = 'boolean',
   DATE = 'date',
   OTHER_OBJECTS = 'otherObjects',
}

export type ColumnFieldType =
   | IconDataTypes.STRING
   | IconDataTypes.BOOLEAN
   | IconDataTypes.DATE
   | IconDataTypes.DECIMAL
   | IconDataTypes.NON_DECIMAL
   | IconDataTypes.OTHER_OBJECTS;

interface IProps {
   fieldType: ColumnFieldType;
}

export default function ColumnTypeIcon(props: IProps): ReactElement {
   const { fieldType } = props;

   const getTypeIcon = (): ReactElement => {
      const uniformDataTypeVal: ColumnFieldType =
         uniformDataTypeForIcon(fieldType);
      let body: ReactElement | string;

      console.log(uniformDataTypeVal);
      switch (uniformDataTypeVal) {
         case IconDataTypes.DECIMAL:
            body = <span className="font-semibold">#.#</span>;
            break;
         case IconDataTypes.NON_DECIMAL:
            body = <span className="font-semibold">#</span>;
            break;
         case IconDataTypes.STRING:
            body = <FontAwesomeIcon icon={faFont} />;
            break;
         case IconDataTypes.BOOLEAN:
            body = <FontAwesomeIcon icon={faToggleOn} />;
            break;
         case IconDataTypes.DATE:
            body = <FontAwesomeIcon icon={faCalendarDays} />;
            break;
         case IconDataTypes.OTHER_OBJECTS:
            body = <span className="font-semibold">{'{}'}</span>;
            break;
         default:
            body = <FontAwesomeIcon icon={faQuestion} />;
            break;
      }

      return <>{body}</>;
   };

   return getTypeIcon();
}
