import {
   useMutation,
   type UseMutationResult,
   useQuery,
   type UseQueryResult,
   useQueryClient,
} from '@tanstack/react-query';

import { apiDelete, apiGet, apiPost } from '../api/ApiService';
import {
   type IProjectFileUpdatePayload,
   type IProjectFileUpdateResponse,
} from '../api/models/IProjectFileUpdate';
import { type IProjectFile } from '../api/models/IProjectFile';
import ApiConstants from '../api/ApiConstants';
import { type IProjectDeleteResponse } from '../api/models/IProject';

export enum ProjectPreviewTabs {
   PREVIEW = 'preview',
   ACTIVITY = 'activity',
}

export const useProjectFiles = (
   payload: IProjectFile | null,
): UseQueryResult<any, Error> => {
   return useQuery({
      queryKey: ['projectFile', `${payload?.projectName}`, `${payload?.path}`],
      queryFn: async () => {
         try {
            if (
               payload === null ||
               payload?.path === '' ||
               payload.path === 'tabs/main/ProjectOverviewTab'
            )
               return null;
            if (payload?.projectName) {
               const response: any = await apiGet({
                  path: `api/v1/project/${payload.projectName}/content?path=${payload.path}`,
                  body: null,
                  url: ApiConstants.workspaceBaseUrl,
                  headers: { Accept: 'application/octet-stream' },
               });
               console.log('Project File Resp:', response);
               if (response.status === 200) {
                  return response.data?.content ?? { content: response.data };
               } else {
                  throw new Error('Api Error');
               }
            }
            return null;
         } catch (error: any) {
            let errText = 'File failed';
            if (error?.response?.data?.error !== undefined) {
               errText = error.response.data.error;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
      },
      refetchOnWindowFocus: false,
   });
};

export function useFileReset(): UseMutationResult<
   IProjectFile,
   Error,
   IProjectFile
> {
   const queryClient = useQueryClient();
   const updateDataMapCall = async (
      data: IProjectFile,
   ): Promise<IProjectFile> => {
      return data;
   };

   return useMutation<IProjectFile, Error, IProjectFile>({
      mutationFn: updateDataMapCall,
      onSuccess: async (dm: any) => {
         console.log("### Reset Fact success I'm first!", dm);
         queryClient.setQueryData(
            ['projectFile', `${dm?.projectName}`, `${dm?.path}`],
            null,
         );
      },
      onSettled: async (data: any) => {
         console.log("### Reset Fact  I'm second!", data);
         // queryClient.removeQueries("data-map-job-historyy-logs", { exact: true });
      },
      onError: async (err: any) => {
         console.error("### Reset Fact  I'm second!", err);
      },
   });
}

export function useUpdateFile(): UseMutationResult<
   IProjectFileUpdateResponse,
   Error,
   IProjectFileUpdatePayload
> {
   const updateFileRequest = async (
      payload: IProjectFileUpdatePayload,
   ): Promise<IProjectFileUpdateResponse> => {
      try {
         const response = await apiPost({
            path: `api/v1/project/${payload.projectName}/content`,
            url: ApiConstants.workspaceBaseUrl,
            body: payload,
         });

         if (response.status === 200) {
            return response.data as IProjectFileUpdateResponse;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error('Api Error');
         }
      } catch (error: any) {
         let errText = 'File failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };
   return useMutation<
      IProjectFileUpdateResponse,
      Error,
      IProjectFileUpdatePayload
   >({
      mutationFn: updateFileRequest,
   });
}

export const useProjectDeleteFile = (): UseMutationResult<
   any,
   Error,
   IProjectFile
> => {
   return useMutation<any, Error, IProjectFile>({
      mutationFn: async (payload: IProjectFile | null) => {
         try {
            if (payload === null || payload?.path === '') return null;
            if (payload?.projectName) {
               const response: any = await apiDelete({
                  path: `api/v1/project/${payload.projectName}/content?path=${payload.path}`,
                  body: null,
                  url: ApiConstants.workspaceBaseUrl,
                  headers: { Accept: 'application/octet-stream' },
               });
               console.log('Project File Resp:', response);
               if (response.status === 200) {
                  return response.data;
               } else {
                  throw new Error('Api Error');
               }
            }
            return null;
         } catch (error: any) {
            let errText = 'File delete failed';
            if (error?.response?.data?.error !== undefined) {
               errText = error.response.data.error;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
      },
   });
};

export const useProjectDelete = (): UseMutationResult<
   IProjectDeleteResponse | null,
   Error,
   string
> => {
   return useMutation<IProjectDeleteResponse | null, Error, string>({
      mutationFn: async (
         projectId: string,
      ): Promise<IProjectDeleteResponse | null> => {
         try {
            if (projectId === null) return null;
            if (projectId) {
               const response: any = await apiDelete({
                  path: `api/v1/project/${projectId}`,
                  body: null,
                  url: ApiConstants.workspaceBaseUrl,
                  headers: { Accept: 'application/octet-stream' },
               });

               if (response.status === 200) {
                  return response.data as IProjectDeleteResponse;
               } else {
                  throw new Error('Api Error');
               }
            }
            return null;
         } catch (error: any) {
            let errText = 'Project delete failed';
            if (error?.response?.data?.detail !== undefined) {
               errText = error.response.data.detail;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
      },
   });
};
