import React, { memo, type ReactElement } from 'react';
import { Handle, Position } from 'reactflow';
import viewIcon from '../../../assets/svgs/viewIconBlue.svg';
import tableIcon from '../../../assets/svgs/tableIconPurple.svg';
import { SearchOutlined } from '@ant-design/icons';
interface IProps {
   data: any;
}

const BasicNode = memo(({ data }: IProps): ReactElement => {
   let icon: ReactElement | null = null;

   switch (data?.type) {
      case 'view':
         icon = <img alt="view" src={viewIcon} />;
         break;
      case 'table':
         icon = <img alt="table" src={tableIcon} />;
         break;
      case 'seed':
         icon = <SearchOutlined />; // <FontAwesomeIcon icon={['fas', 'seedling']} />;
         break;
      default:
         break;
   }

   return (
      <>
         <Handle
            type="target"
            position={Position.Left}
            style={{ opacity: 0 }}
            onConnect={(params) => {
               console.log('handle onConnect', params);
            }}
         />
         <div className={`catalog-graph-node-wrapper ${data?.type}`}>
            <div className="catalog-grapg-node-icon">
               {icon}
               <div className="static-text !text-main">{data?.type}</div>
            </div>
            <div
               className="catalog-grapg-node-title !text-main"
               title={data.table_name}
            >
               {data.table_name}
            </div>
         </div>
         <Handle
            type="target"
            position={Position.Bottom}
            id="type"
            style={{ opacity: 0 }}
         />
         <Handle
            type="source"
            position={Position.Right}
            id="nextTrans"
            style={{ opacity: 0 }}
         />
      </>
   );
});

export default BasicNode;
