/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useCallback, useEffect, useState } from 'react';
import ProjectStructure from './ProjectStructure';
import ResizeableSpliter from '../../common/ResizeableSpliter/ResizeableSpliter';
import { useProjectInfo, useProjectsSearch } from '../../providers/useProjects';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
   type ICatalogPayload,
   useProjectCatalog,
} from '../../providers/useModelsCatalog';

import FileTabs from './FileTabs';
import {
   type TabData,
   projectOverviewTabName,
   EXPAND_THRESHOLD,
   PlayButtonState,
} from './ProjectData';
import { initialSearchData } from '../dashboard/TableData';
import Loader from '../../components/Loader';
import {
   useFileReset,
   useProjectDelete,
} from '../../providers/useProjectFiles';
import { useGlobalState } from '../../context/GlobalContext';
import { useDBTJobPoll, useDBTProjectRunJob } from '../../providers/useDBTJob';
import {
   ACTION_OK,
   COMPLETED,
   DATA_SOURCE_STATE,
   FAILED,
   IN_PROGRESS,
   PIPELINES_STATE,
   PROJECT_DIRECTORY_TREE_STATE,
   PROJECT_HOME_STATE,
   PROJECT_JOBS_STATE,
   SETTINGS_STATE,
} from '../../utils/Constants';
import JobHistory from './jobHistory/JobHistory';
import { Button } from 'antd';
import { ExpandOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import './style.scss';
import FileTabFooter from './FileTabFooter';
import DataSource from '../datasource/DataSource';
import {
   useResultPathURL,
   useResultsPreviewData,
   useResultsPreviewDataTxt,
   useRunQuery,
} from '../../providers/useDataSources';
import { calculateTimeDifference, showError } from '../../utils/Utils';
import Pipelines from '../pipelines/Pipelines';
import SettingsContainer from '../settings/SettingsContainer';
import {
   useGitBranchCheckout,
   useGitProjectBranches,
} from '../../providers/useGitProjects';
import { type IProjectInfo } from '../../api/models/IProject';
import Home from '../dashboard/Home';
import Fallback from '../../components/Fallback';

const intialTab = {
   activeTabKey: null,
   tabs: [
      {
         title: 'Project Overview',
         key: projectOverviewTabName,
         modified: false,
         icon: projectOverviewTabName,
      },
   ],
};
const Project: React.FC = (props) => {
   const [dataSourceInfoSize, setDataSourceInfoSize] = useState(320);
   const [treeData, setTreeData] = useState<any>(null);
   const [fileData, setFileData] = useState<ICatalogPayload | null>(null);
   const [activeTabs, setActiveTabs] = useState<TabData>(intialTab);
   const [jobId, setJobId] = useState<string | null>(null);
   const [modelJobId, setModelJobId] = useState<string | null>(null);
   const [footerSize, setFooterSize] = useState(200);
   const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
   const [isFooterCollapsed, setIsFooterCollapsed] = useState(true);
   const [isFooterRefetch, setIsFooterRefetch] = useState(false);
   const [dbtCurrentType, setDbtCurrentType] = useState('');
   const [playButtonState, setPlayButtonState] = useState<PlayButtonState>(
      PlayButtonState.NotStarted,
   );
   const [rowsAndTimeData, setRowsAndTimeData] = useState<string>('');
   const navigate = useNavigate();
   const { mutate: resetFilesData } = useFileReset();
   const {
      messageApi,
      isCollapsed,
      setIsCollapsed,
      selectedProjectState,
      setSelectedProjectState,
   } = useGlobalState();

   const { state } = useLocation();
   const { id: projectId } = useParams();
   const [projectName, setProjectName] = useState('');
   const [selectedProject, setSelectedProject] = useState<
      IProjectInfo | undefined
   >();

   const {
      mutate: projectSearch,
      isError: isProjectSearchError,
      data: projectSearchData,
      isPending: isProjectSearchLoading,
      error: projSearchError,
   } = useProjectsSearch();

   const {
      isError: isProjectError,
      data: projectInfoData,
      isFetching: isProjectLoading,
      error: projectError,
      refetch: projectInfoRefetch,
   } = useProjectInfo(projectId); // project id from search response

   // will be used later for lineage
   const {
      data: catalogData,
      isLoading: isFileContentLoading,
      isError: isFileContentError,
      error: fileErrorData,
      refetch: refetchCatalogs,
   } = useProjectCatalog(fileData);

   const {
      mutate: runDbtProjectJob,
      isError: isRunDbtProjectJobError,
      data: runDbtProjectJobData,
      isPending: isRunDbtProjectJobLoading,
      error: runDbtProjectJobError,
   } = useDBTProjectRunJob();

   const {
      data: dbtJobPollData,
      isError: isDbtJobPollError,
      isPending: isDbtJobPollLoading,
      error: dbtJobPollError,
   } = useDBTJobPoll(jobId);

   const {
      data: modelJobPollData,
      isError: isModelJobPollError,
      isPending: isModelJobPollLoading,
      error: modelJobPollError,
   } = useDBTJobPoll(modelJobId);

   const {
      mutate: runQuery,
      data: runQueryJobData,
      isError: isRunQueryError,
      error: runQueryError,
      isPending: isRunQueryLoading,
   } = useRunQuery();

   const {
      mutate: getResultsPathData,
      data: resultsPathData,
      isError: isResultsPathError,
      error: resultPathError,
      isPending: isResultPathLoading,
   } = useResultPathURL();

   const {
      mutate: getPreviewData,
      data: previewQueryJobData,
      isError: isPreviewQueryError,
      error: previewQueryError,
      isPending: isPreviewQueryLoading,
      reset: previewQueryJobDataReset,
   } = useResultsPreviewData();

   const {
      mutate: getPreviewDataText,
      data: previewQueryJobDataText,
      isError: isPreviewQueryErrorText,
      error: previewQueryErrorText,
      isPending: isPreviewQueryLoadingText,
   } = useResultsPreviewDataTxt();

   const {
      mutate: deleteProject,
      data: deleteProjectData,
      isPending: isDeleteProjectLoading,
      isError: isDeleteProjectError,
      error: deleteProjectError,
   } = useProjectDelete();

   const {
      data: gitBranches,
      isError: isGitBranchesError,
      isPending: isGitBranchesLoading,
      error: gitBranchesError,
      refetch: refetchGitBranches,
   } = useGitProjectBranches(projectId, selectedProject);

   const {
      mutate: mutateGitBranchCheckout,
      data: gitBranchCheckoutData,
      isError: isGitBranchCheckoutError,
      error: gitBranchCheckoutError,
      isPending: isGitBranchCheckoutLoading,
   } = useGitBranchCheckout();

   useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      void projectSearch(initialSearchData);
      setIsCollapsed(false);
      setSelectedProjectState(PROJECT_DIRECTORY_TREE_STATE);
   }, []);
   useEffect(() => {
      setRowsAndTimeData('');
   }, [projectId]);

   useEffect(() => {
      console.log('state', state);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      projectInfoRefetch();
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      void projectSearch(initialSearchData);
      setIsCollapsed(false);
      setSelectedProjectState(PROJECT_DIRECTORY_TREE_STATE);
      setActiveTabs(intialTab);
   }, [state]);

   useEffect(() => {
      if (isProjectSearchError) {
         showError(messageApi, `${projSearchError.message}`);
      }
      if (projectSearchData !== undefined) {
         const _project = projectSearchData?.data?.find(
            (project: any) => project.id === projectId,
         );
         setProjectName(_project?.projectName ?? '');
         setSelectedProject(_project);
      }
   }, [isProjectSearchLoading, projectSearchData]);

   useEffect(() => {
      if (isFileContentError) {
         showError(messageApi, `${fileErrorData.message}`);
      }
      if (catalogData !== undefined) {
         resetFilesData({
            path: 'target/manifest.json',
            projectName: projectId!,
         });
      }
   }, [isFileContentLoading, catalogData]);

   useEffect(() => {
      if (fileData) {
         void refetchCatalogs();
      }
   }, [fileData]);

   useEffect(() => {
      if (isProjectError) {
         console.log('projectInfoDataError', projectError);
         showError(messageApi, `${projectError.message}`);
      }

      if (
         projectInfoData?.content?.length &&
         projectInfoData?.content?.length > 0
      ) {
         console.log('projectInfoData', projectInfoData);

         setTreeData(projectInfoData);
      }
      const manifestFileAvailable = projectInfoData?.content.find(
         (file: any) => file.name === 'manifest.json',
      );
      if (
         projectInfoData?.content?.length &&
         manifestFileAvailable !== undefined
      ) {
         setFileData({
            projectName: projectId!,
            path: 'target/manifest.json',
            manifestPath: 'target/manifest.json',
            ds: '123e4567-e89b-12d3-a456-426655440000',
         });
      }
      resetFilesData({ path: 'target/manifest.json', projectName: projectId! });
   }, [isProjectLoading, projectInfoData]);

   useEffect(() => {
      if (isRunDbtProjectJobError) {
         showError(messageApi, `${runDbtProjectJobError.message}`);
      }
      if (isRunDbtProjectJobLoading) {
         setIsFooterCollapsed(false);
      }
      if (runDbtProjectJobData !== undefined) {
         console.log(runDbtProjectJobData);
         if (runDbtProjectJobData.job_status === IN_PROGRESS) {
            setIsFooterRefetch(true);
            setJobId(runDbtProjectJobData.id);
         }
      }
   }, [isRunDbtProjectJobLoading, runDbtProjectJobData]);

   useEffect(() => {
      if (isDbtJobPollError) {
         setIsFooterCollapsed(false);
         showError(messageApi, `${dbtJobPollError.message}`);
      }
      if (dbtJobPollData !== undefined && dbtJobPollData !== null) {
         if (dbtJobPollData.job_status === COMPLETED) {
            void projectInfoRefetch();
            void refetchCatalogs();
            setTimeout(() => {
               setIsFooterRefetch(false);
            }, 2000);

            if (playButtonState.valueOf() === PlayButtonState.Started) {
               setPlayButtonState(PlayButtonState.RefetchData);
            }
         } else if (dbtJobPollData.job_status === FAILED) {
            setTimeout(() => {
               setIsFooterRefetch(false);
            }, 2000);
            setIsFooterCollapsed(false);
         }
      }
   }, [isDbtJobPollLoading, dbtJobPollData]);

   useEffect(() => {
      if (isRunQueryError) {
         void messageApi?.open({
            type: 'error',
            content: `${runQueryError?.message}`,
         });
      }

      if (runQueryJobData !== null && runQueryJobData !== undefined) {
         setModelJobId(runQueryJobData.id);
      }
   }, [runQueryJobData, isRunQueryLoading]);

   useEffect(() => {
      if (isModelJobPollError) {
         showError(messageApi, modelJobPollError.message);
      }
      if (modelJobPollData !== undefined && modelJobPollData !== null) {
         if (modelJobPollData.job_status === 'FAILED') {
            if (modelJobPollData.error_path !== undefined) {
               getResultsPathData(modelJobPollData.error_path ?? '');
            } else {
               showError(messageApi, modelJobPollData.message);
            }
         } else {
            if (
               modelJobPollData.error_path &&
               modelJobPollData.error_path !== undefined &&
               !modelJobPollData.result_path
            ) {
               getResultsPathData(modelJobPollData.error_path ?? '');
            } else {
               if (
                  modelJobPollData.metadata.rows_fetched !== undefined &&
                  modelJobPollData.metadata.rows_fetched !== null
               ) {
                  const fetched =
                     // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-base-to-string
                     modelJobPollData.metadata.rows_fetched.total_fetched_rows +
                     ' rows and ' +
                     calculateTimeDifference(
                        modelJobPollData.metadata.rows_fetched
                           .execution_start_ts,
                        modelJobPollData.metadata.rows_fetched.execution_end_ts,
                     ) +
                     ' seconds runtime';
                  setRowsAndTimeData(fetched);
               }
               getResultsPathData(modelJobPollData.result_path ?? '');
            }
         }
      }
   }, [isModelJobPollLoading, modelJobPollData]);

   useEffect(() => {
      if (isResultsPathError) {
         showError(messageApi, resultPathError.message);
      }
      if (resultsPathData !== undefined && resultsPathData !== null) {
         if (
            resultsPathData?.url &&
            modelJobPollData?.job_status === 'FAILED'
         ) {
            getPreviewDataText(resultsPathData.url);
         } else if (resultsPathData?.url) {
            getPreviewData(resultsPathData.url);
         }
      }
   }, [isResultPathLoading, resultsPathData]);

   useEffect(() => {
      if (isPreviewQueryError) {
         showError(messageApi, previewQueryError.message ?? '');
      }
      if (previewQueryJobData !== undefined && previewQueryJobData !== null) {
         console.log('previewQueryJobData', previewQueryJobData);
      }
   }, [isPreviewQueryLoading, previewQueryJobData]);

   useEffect(() => {
      console.log('previewQueryJobDataText', previewQueryJobDataText);
      // if (previewQueryJobDataText) {
      //    showError(messageApi, previewQueryJobDataText ?? '');
      // }
   }, [
      isPreviewQueryErrorText,
      previewQueryJobDataText,
      previewQueryErrorText,
   ]);

   useEffect(() => {
      if (isDeleteProjectError) {
         showError(messageApi, `${deleteProjectError.message}`);
      }
      if (deleteProjectData !== undefined && deleteProjectData !== null) {
         if (deleteProjectData?.ack === 'ok') {
            navigate('/');
         }
      }
   }, [isDeleteProjectLoading, deleteProjectData]);

   useEffect(() => {
      if (isGitBranchesError) {
         showError(messageApi, `${gitBranchesError.message}`);
      }
      if (gitBranches !== undefined && gitBranches !== null) {
         // console.log(gitBranches);
      }
   }, [isGitBranchesLoading, gitBranches]);

   useEffect(() => {
      if (isGitBranchCheckoutError) {
         showError(messageApi, `${gitBranchCheckoutError.message}`);
      }
      if (
         gitBranchCheckoutData !== undefined &&
         gitBranchCheckoutData !== null &&
         gitBranchCheckoutData.ack === ACTION_OK
      ) {
         void refetchGitBranches();
      }
   }, [isGitBranchCheckoutLoading, gitBranchCheckoutData]);

   const gitBranchCheckout = (branchName: string): void => {
      mutateGitBranchCheckout({ id: projectId ?? '', name: branchName });
   };

   const runQueryPreview = (type: string, fileValue: string): void => {
      setIsFooterCollapsed(false);
      const arr = activeTabs?.activeTabKey?.split('/');
      if (arr !== undefined) {
         const filnameWithExtention = arr[arr.length - 1];
         const filename = filnameWithExtention.split('.')[0];
         console.log('runQueryPreview', filename, runQuery);
         const dataSourceId =
            projectSearchData?.data?.find(
               (project: any) => project.id === projectId,
            )?.configuration?.sourceId ?? '';
         runQuery({
            type: 'SQL',
            query: fileValue,
            source_id: dataSourceId, // here pass DataSource Id of project
         });
      }
   };

   const handleCollapseDsSideBar = (value: any): void => {
      console.log(value);
   };

   const onProjectCreated = (): void => {
      projectSearch(initialSearchData);
   };

   const onFooterSetSize = useCallback((newSize: number, maxSize: number) => {
      const isExpended = maxSize - newSize <= EXPAND_THRESHOLD;
      setIsPreviewExpanded(isExpended);
      setFooterSize(newSize);
   }, []);

   const toggleExpandPreview = (): void => {
      if (!isPreviewExpanded) {
         setFooterSize(Infinity);
         setIsPreviewExpanded(true);
      } else {
         setFooterSize(200);
         setIsPreviewExpanded(false);
      }
   };
   const renderPreviewToolbar = (): JSX.Element => {
      return (
         <div className="flex flex-row gap-2 ">
            <div className="text-main mt-0.5 me-12">{rowsAndTimeData}</div>
            <Button size="small" type="text" onClick={toggleExpandPreview}>
               {isPreviewExpanded ? (
                  <FullscreenExitOutlined />
               ) : (
                  <ExpandOutlined />
               )}
            </Button>
         </div>
      );
   };
   const onRenderPreviewToolbar = (): JSX.Element => {
      return (
         <div className="flex items-center justify-between h-full text-main w-full me-6">
            Preview & Activity
            {/* <div className="flex flex-row gap-4 me-6">
               <FileTextOutlined className="text-main cursor-pointer" />
               <FileTextOutlined className="text-main cursor-pointer" />
            </div> */}
         </div>
      );
   };

   const renderSecondTab = (): JSX.Element => {
      return (
         <ResizeableSpliter
            alignment="column"
            lead="second"
            size={footerSize}
            minSize={200}
            minTailSize={EXPAND_THRESHOLD}
            isCollapsed={isFooterCollapsed}
            onToggleCollapse={(value) => {
               setIsFooterCollapsed(value);
            }}
            first={
               <FileTabs
                  activeTabs={activeTabs}
                  setActiveTabs={setActiveTabs}
                  catalogData={catalogData}
                  runDbtProjectJob={(data) => {
                     previewQueryJobDataReset();
                     runDbtProjectJob(data);
                     let type = data.command.type;
                     type = type === 'DEPS' ? 'INSTALL' : type;
                     setDbtCurrentType(type);
                  }}
                  runQueryPreview={(type, _filValue) => {
                     previewQueryJobDataReset();
                     let filValue = _filValue;
                     const upperCaseFile = filValue.toUpperCase();
                     if (
                        type === 'PREVIEW' &&
                        (upperCaseFile.includes('SELECT') ||
                           upperCaseFile.includes('WITH'))
                     ) {
                        const last30Chars = filValue.slice(-30);
                        const lowerSQL = filValue?.toLowerCase() || '';
                        if (
                           !last30Chars.includes('limit') &&
                           (lowerSQL.includes('with') ||
                              (lowerSQL.includes('select') &&
                                 !lowerSQL.includes('drop') &&
                                 !lowerSQL.includes('show')))
                        ) {
                           filValue = filValue + '\nlimit 500';
                        }
                     }
                     runQueryPreview(type, filValue);
                  }}
                  projectInfoRefetch={() => {
                     void projectInfoRefetch();
                  }}
                  playButtonState={playButtonState}
                  setPlayButtonState={setPlayButtonState}
                  projectName={projectName}
                  treeData={treeData}
                  projectInfoData={projectInfoData}
               />
            }
            second={
               <FileTabFooter
                  jobId={jobId}
                  footerSize={footerSize + 15}
                  isDbtJobPollLoading={
                     isRunDbtProjectJobLoading || isDbtJobPollLoading
                  }
                  isModelJobLoading={
                     isRunQueryLoading ||
                     isPreviewQueryLoading ||
                     isResultPathLoading ||
                     isModelJobPollLoading ||
                     isPreviewQueryLoadingText
                  }
                  isFooterRefetch={isFooterRefetch}
                  setIsFooterRefetch={setIsFooterRefetch}
                  dbtCurrentType={dbtCurrentType}
                  previewQueryJobData={previewQueryJobData}
                  errorText={previewQueryJobDataText}
                  setRowsAndTimeData={setRowsAndTimeData}
                  previewQueryError={previewQueryError}
               />
            }
            onResized={onFooterSetSize}
            onRenderTailToolbar={renderPreviewToolbar}
            // onRenderCollapsedContent={onRenderPreviewToolbar}
            context="p-layout"
         />
      );
   };

   return (
      <div
         className="flex flex-row !bg-default w-[calc(100vw-80px)]
      overflow-hiden h-[calc(100vh-0px)] max-h-[calc(100vh-0px)]"
      >
         {(isProjectSearchLoading ||
            isProjectLoading ||
            isFileContentLoading ||
            isDeleteProjectLoading ||
            isGitBranchCheckoutLoading) && <Loader />}
         {treeData && selectedProjectState === PROJECT_DIRECTORY_TREE_STATE && (
            <ResizeableSpliter
               alignment="row"
               lead="first"
               size={dataSourceInfoSize}
               minSize={320}
               minTailSize={EXPAND_THRESHOLD}
               isCollapsed={isCollapsed}
               onToggleCollapse={(value) => {
                  handleCollapseDsSideBar(value);
               }}
               first={
                  <ProjectStructure
                     treeData={treeData}
                     activeTabs={activeTabs}
                     setActiveTabs={setActiveTabs}
                     projectSearchData={projectSearchData}
                     onProjectCreated={onProjectCreated}
                     projectInfoRefetch={() => {
                        void projectInfoRefetch();
                     }}
                     runDbtProjectJob={runDbtProjectJob}
                     isRunDbtProjectJobLoading={isRunDbtProjectJobLoading}
                     isDbtJobPollLoading={isDbtJobPollLoading}
                     dbtCurrentType={dbtCurrentType}
                     setDbtCurrentType={setDbtCurrentType}
                     isProjectLoading={isProjectLoading}
                     projectName={projectName}
                     deleteProject={deleteProject}
                     gitBranches={gitBranches}
                     gitBranchCheckout={gitBranchCheckout}
                     projectInfoData={projectInfoData}
                  />
               }
               second={renderSecondTab()}
               onResized={(newSize) => {
                  setDataSourceInfoSize(newSize);
               }}
               context="p-layout"
            />
         )}
         {selectedProjectState === PROJECT_JOBS_STATE && (
            <JobHistory isFromMainPage={true} />
         )}
         {selectedProjectState === PROJECT_HOME_STATE && <Home />}
         {selectedProjectState === DATA_SOURCE_STATE && <DataSource />}
         {selectedProjectState === PIPELINES_STATE && <Pipelines />}
         {selectedProjectState === SETTINGS_STATE && (
            <SettingsContainer selectedProject={selectedProject} />
         )}
         {(treeData === null || treeData === undefined) &&
            !isProjectLoading &&
            selectedProjectState === PROJECT_DIRECTORY_TREE_STATE && (
               <Fallback
                  title="Oops! Something went wrong"
                  description="We apologize for the inconvenience. An error occurred while
                  processing your request."
                  error={projSearchError?.message}
               />
            )}
      </div>
   );
};

export default Project;
