import React, { useEffect, useState } from 'react';
import { Button, Divider, Input, Modal } from 'antd';
import { KEY_USER_EMAIL } from '../utils/LocalStorageKeys';
import { LocalStorage } from '../utils/LocalStorage';
import SceretRow from '../components/SceretRow';
import { PlusOutlined } from '@ant-design/icons';
import {
   useAddUserSecrets,
   useDeleteUserSecrets,
   useEditUserSecrets,
   useGetUserSecrets,
} from '../pages/signin/useSignin';
import { showError, showSuccess } from '../utils/Utils';
import { useGlobalState } from '../context/GlobalContext';
import Loading from '../components/Loading';
import DeleteSecretModal from './DeleteSecretModal';
import { type SecretData } from '../providers/models/IUserSecrets';
import { SECRETS_INITIAL_PAYLOAD } from '../utils/Constants';

interface IUserSecretModal {
   isModalOpen: boolean;
   onOk: () => void;
   onCancel: () => void;
   refetchSecrets?: () => void;
}
const UserSecretModal: React.FC<IUserSecretModal> = (props) => {
   const { isModalOpen, onOk, onCancel, refetchSecrets } = props;
   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
   const [selectedSecret, setSelectedSecret] = useState<
      SecretData | undefined
   >();
   const [isNewSecretRowVisible, setIsNewSecretRowVisible] =
      useState<boolean>(false);
   const [email, setEmail] = useState('');
   const { messageApi, currentTheme } = useGlobalState();

   const {
      data: userSecrets,
      isError: isUserSecretsError,
      isPending: isUserSecretsLoading,
      error: userSecretsError,
      mutate: userSecretsMutate,
   } = useGetUserSecrets();

   const {
      data: addUserSecrets,
      isError: isAddUserSecretsError,
      isPending: isAddUserSecretssLoading,
      error: addUserSecretsError,
      mutate: addUserSecretsMutate,
   } = useAddUserSecrets();

   const {
      data: editUserSecrets,
      isError: isEditUserSecretsError,
      isPending: isEditUserSecretsLoading,
      error: editUserSecretsError,
      mutate: editUserSecretsMutate,
   } = useEditUserSecrets();

   const {
      data: deleteUserSecrets,
      isError: isDeleteUserSecretsError,
      isPending: isDeleteUserSecretsLoading,
      error: deleteUserSecretsError,
      mutate: deleteUserSecretsMutate,
   } = useDeleteUserSecrets();

   useEffect(() => {
      const email = LocalStorage.get(KEY_USER_EMAIL);
      setEmail(email ?? '');
      fetchList();
   }, []);

   useEffect(() => {
      if (isUserSecretsError) {
         showError(messageApi, `${userSecretsError.message}`);
      }
      if (userSecrets !== undefined && userSecrets !== null) {
         // console.log({ userSecrets });
      }
   }, [isUserSecretsLoading, userSecrets]);

   useEffect(() => {
      if (isAddUserSecretsError) {
         showError(messageApi, `${addUserSecretsError.message}`);
      }
      if (addUserSecrets !== undefined && addUserSecrets !== null) {
         fetchList();
         setIsNewSecretRowVisible(false);
         if (refetchSecrets !== undefined && refetchSecrets !== null) {
            refetchSecrets();
         }
      }
   }, [isAddUserSecretssLoading, addUserSecrets]);

   useEffect(() => {
      if (isEditUserSecretsError) {
         showError(messageApi, `${editUserSecretsError.message}`);
      }
      if (editUserSecrets !== undefined && editUserSecrets !== null) {
         showSuccess(messageApi, 'Secret Updated successfuly');
      }
   }, [isEditUserSecretsLoading, editUserSecrets]);

   useEffect(() => {
      if (isDeleteUserSecretsError) {
         showError(messageApi, `${deleteUserSecretsError.message}`);
      }
      if (deleteUserSecrets !== undefined && deleteUserSecrets !== null) {
         fetchList();
         showSuccess(messageApi, 'Secret Deleted successfuly');
      }
   }, [isDeleteUserSecretsLoading, deleteUserSecrets]);

   const fetchList = (): void => {
      userSecretsMutate(SECRETS_INITIAL_PAYLOAD);
   };

   return (
      <>
         <Modal
            title={
               <div className="text-main text-xl opacity-80">User Profile</div>
            }
            open={isModalOpen}
            onOk={() => {
               onOk();
            }}
            onCancel={() => {
               onCancel();
            }}
            footer={[
               <Button key="back" onClick={onCancel}>
                  Cancel
               </Button>,
               <Button key="submit" type="primary" onClick={onOk}>
                  Done
               </Button>,
            ]}
            width={'60%'}
            className={`${currentTheme}`}
         >
            {(isAddUserSecretssLoading ||
               isUserSecretsLoading ||
               isEditUserSecretsLoading ||
               isDeleteUserSecretsLoading) && <Loading />}
            <div className="flex flex-col mt-6 w-full">
               <div className="flex flex-row gap-2 ">
                  <div className="text-sm text-main font-semibold ">Email:</div>
                  <div className="text-sm text-border">{email}</div>
               </div>
               <Divider />
               {/* <label className="text-sm text-gray-400 w-1/4">Email</label>
               <Input
                  placeholder="Username"
                  className="w-1/2 mb-4 mt-1"
                  size="large"
                  onChange={(e) => {
                     setEmail(e.target.value);
                  }}
                  value={email}
                  disabled
               /> */}
               <div className="flex flex-row items-center  justify-between ">
                  <label className="text-xl text-main font-semibold w-1/4">
                     Git Credentials
                  </label>
                  <Button
                     type="primary"
                     className=""
                     onClick={() => {
                        setIsNewSecretRowVisible(true);
                     }}
                  >
                     Add <PlusOutlined />
                  </Button>
               </div>

               <div className="flex flex-col space-y-2 mb-8 mt-4">
                  <div className="flex flex-row flex-nowrap gap-2 mt-2 text-main font-semibold">
                     {/* <div className="w-full">Type</div> */}
                     <div className="w-[60%]">Name</div>
                     <div className="w-[60%] ps-3">Username</div>
                     <div className="w-full ps-1">Token</div>
                     <div className="w-[47%]">Actions</div>
                  </div>
                  {userSecrets?.results.map((value, index) => {
                     return (
                        <SceretRow
                           key={index}
                           onSaveClick={(payload) => {
                              editUserSecretsMutate(payload);
                           }}
                           onDeleteClick={(secretData) => {
                              if (secretData !== null) {
                                 setSelectedSecret(secretData);
                                 setIsDeleteModalOpen(true);
                              }
                           }}
                           isEditAble={false}
                           data={value}
                           isNewSecret={false}
                        />
                     );
                  })}
                  {isNewSecretRowVisible && (
                     <SceretRow
                        onSaveClick={(payload) => {
                           addUserSecretsMutate(payload);
                        }}
                        onDeleteClick={() => {
                           setIsNewSecretRowVisible(false);
                        }}
                        isEditAble={true}
                        data={null}
                        isNewSecret={true}
                     />
                  )}
               </div>
            </div>
         </Modal>
         {isDeleteModalOpen && (
            <DeleteSecretModal
               isModalOpen={isDeleteModalOpen}
               onCancel={() => {
                  setIsDeleteModalOpen(false);
               }}
               onOk={() => {
                  const id: string = selectedSecret?.id ?? '';
                  deleteUserSecretsMutate(id);
                  setIsDeleteModalOpen(false);
               }}
               secretName={selectedSecret?.name ?? ''}
            />
         )}
      </>
   );
};

export default UserSecretModal;
