import { type MessageInstance } from 'antd/es/message/interface';
import React, { createContext, useContext, useState } from 'react';
import { PROJECT_HOME_STATE, THEME_DARK } from '../utils/Constants';

interface IGlobalContext {
   messageApi: MessageInstance | undefined;
   setMessageApi: (messageApi: MessageInstance) => void;
   isCollapsed: boolean;
   setIsCollapsed: (isCollapsed: boolean) => void;
   selectedProjectState: string;
   setSelectedProjectState: (selectedProjectState: string) => void;
   currentTheme: string;
   setCurrentTheme: (currentTheme: string) => void;
}

interface IGlobalContextProvider {
   children: React.ReactNode;
}

export const GlobalContext = createContext<IGlobalContext>({
   messageApi: undefined,
   setMessageApi: () => {},
   isCollapsed: false,
   setIsCollapsed: () => {},
   selectedProjectState: '',
   setSelectedProjectState: () => {},
   currentTheme: '',
   setCurrentTheme: () => {},
});

export const GlobalStateProvider: React.FC<IGlobalContextProvider> = ({
   children,
}) => {
   const setMessageApi = (messageApi: any | undefined): void => {
      setState((prevState) => ({
         ...prevState,
         messageApi,
      }));
   };

   const setIsCollapsed = (isCollapsed: boolean): void => {
      setState((prevState) => ({
         ...prevState,
         isCollapsed,
      }));
   };

   const setSelectedProjectState = (selectedProjectState: string): void => {
      setState((prevState) => ({
         ...prevState,
         selectedProjectState,
      }));
   };

   const setCurrentTheme = (currentTheme: string): void => {
      setState((prevState) => ({
         ...prevState,
         currentTheme,
      }));
   };

   const initialState: IGlobalContext = {
      messageApi: undefined,
      setMessageApi,
      isCollapsed: false,
      setIsCollapsed,
      selectedProjectState: PROJECT_HOME_STATE,
      setSelectedProjectState,
      currentTheme: THEME_DARK,
      setCurrentTheme,
   };
   const [state, setState] = useState<IGlobalContext>(initialState);

   return (
      <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>
   );
};

export const useGlobalState = (): IGlobalContext => {
   const context = useContext(GlobalContext);
   if (context === undefined || context === null) {
      throw new Error(
         'useGlobalState must be used withim GlobalContext Provider',
      );
   }
   return context;
};
