import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectsListModal from './ProjectsListModal';
import { useGlobalState } from '../../context/GlobalContext';
import { Button } from 'antd';
import {
   DATA_SOURCE_STATE,
   PIPELINES_STATE,
   PROJECT_HOME_STATE,
} from '../../utils/Constants';
import DataSource from '../datasource/DataSource';
import Pipelines from '../pipelines/Pipelines';
import Home from './Home';
import { useDataSources } from '../../providers/useDataSources';

const Dashboard: React.FC = () => {
   // const [isProjectListModalOpen, setIsProjectListModalOpen] = useState(false);
   // const [dataSourceInfoSize, setDataSourceInfoSize] = useState(250);
   const { selectedProjectState, setSelectedProjectState } = useGlobalState();
   const { data: dataSources, isPending: isDSSearchLoading } = useDataSources();
   const navigate = useNavigate();

   useEffect(() => {
      setSelectedProjectState(PROJECT_HOME_STATE);
   }, []);

   useEffect(() => {
      if (
         dataSources !== undefined &&
         dataSources !== null &&
         dataSources.results.length === 0 &&
         selectedProjectState !== DATA_SOURCE_STATE
      ) {
         setSelectedProjectState(DATA_SOURCE_STATE);
      }
   }, [dataSources]);

   // const onItemClick = (record: any, rowIndex: number | undefined): void => {
   //    console.log(record);
   //    navigate('/project/' + record.id, {
   //       state: {
   //          projectData: record,
   //       },
   //    });
   // };

   // const sidePanel = (
   //    <div>
   //       <div
   //          className="flex flex-row overflow-y-scroll justify-between border-0
   //      mb-4 p-2 pe-4 border-b-[0.5px] border-solid border-border"
   //       >
   //          <div>
   //             <div className="text-md text-main">EXPLORER</div>
   //          </div>
   //       </div>
   //       <div className="flex flex-col gap-y-4 px-4 mt-40">
   //          <Button
   //             key="submit"
   //             type="primary"
   //             onClick={() => {
   //                // createProject();
   //             }}
   //          >
   //             New Project
   //          </Button>
   //          <Button
   //             key="submit"
   //             type="primary"
   //             onClick={() => {
   //                setIsProjectListModalOpen(true);
   //             }}
   //          >
   //             Open
   //          </Button>
   //          <Button
   //             key="submit"
   //             type="primary"
   //             onClick={() => {
   //                // createProject();
   //             }}
   //          >
   //             Clone git project
   //          </Button>
   //       </div>
   //    </div>
   // );
   return (
      <div className="flex flex-col h-[calc(100vh-0px)] max-h-[calc(100vh-0px)]">
         {/* {selectedProjectState === PROJECT_HOME_STATE && (
            <ResizeableSpliter
               alignment="row"
               lead="first"
               size={dataSourceInfoSize}
               minSize={250}
               minTailSize={EXPAND_THRESHOLD}
               isCollapsed={isCollapsed}
               onToggleCollapse={(value) => {
                  // handleCollapseDsSideBar(value);
               }}
               first={sidePanel}
               second={dashboard}
               onResized={(newSize) => {
                  setDataSourceInfoSize(newSize);
               }}
               context="p-layout"
            />
         )} */}
         {selectedProjectState === PROJECT_HOME_STATE && <Home />}
         {selectedProjectState === DATA_SOURCE_STATE && <DataSource />}
         {selectedProjectState === PIPELINES_STATE && <Pipelines />}

         {/* <ProjectsListModal
            onItemClick={onItemClick}
            projSearchData={projSearchData}
            isModalOpen={isProjectListModalOpen}
            onOk={() => {
               setIsProjectListModalOpen(false);
            }}
            onCancel={() => {
               setIsProjectListModalOpen(false);
            }}
         /> */}
      </div>
   );
};

export default Dashboard;
