import React, {
   type ReactElement,
   type ReactNode,
   useCallback,
   useEffect,
} from 'react';
import {
   type HandleStyles,
   type NumberSize,
   Resizable,
   type Size,
} from 're-resizable';
import { useResizeDetector } from 'react-resize-detector';
import { type Direction } from 're-resizable/lib/resizer';
import { Button } from 'antd';

import Handler from './Handler';
import CollapsedPanel from './CollapsedPanel';
import { Orientation } from './Orientation';
import { DownOutlined } from '@ant-design/icons';

type Alignment = 'column' | 'row';
type Lead = 'first' | 'second';

interface IProps {
   alignment: Alignment;
   lead: Lead;
   size: number;
   minSize?: number;
   maxSize?: number;
   minTailSize?: number;
   first: ReactNode;
   second: ReactNode;
   isCollapsed: boolean;
   onRenderTailToolbar?: () => ReactNode | null;
   onToggleCollapse?: (isCollapsed: boolean) => void;
   onRenderCollapsedContent?: () => ReactElement | null;
   onResized: (newSize: number, maxSize: number) => void;
   isCollapsedPanelDisabled?: boolean;
   context?: string;
}

function getOrientation(alignment: Alignment, lead: Lead): Orientation {
   if (alignment === 'row' && lead === 'first') {
      return Orientation.Right;
   }

   if (alignment === 'row' && lead === 'second') {
      return Orientation.Left;
   }

   if (alignment === 'column' && lead === 'first') {
      return Orientation.Bottom;
   }

   return Orientation.Top;
}

function getSize(alignment: Alignment, size: number): Size {
   return alignment === 'row'
      ? { width: size, height: '100%' }
      : { width: '100%', height: size };
}

function buildHandlerStyles(context = ''): HandleStyles {
   const border =
      context === 'p-layout' ? '0.5px solid #828282' : '1px solid #E2E2E2';
   return {
      left: {
         left: 0,
         width: 22,
         borderLeft: border,
      },
      right: {
         right: 0,
         width: 2,
         borderRight: border,
      },
      top: {
         top: 0,
         height: 22,
         borderTop: border,
      },
      bottom: {
         bottom: 0,
         height: 22,
         borderBottom: border,
      },
   };
}

export default function ResizeableSpliter({
   alignment,
   lead,
   size,
   minSize,
   maxSize,
   first,
   second,
   isCollapsed,
   minTailSize = 0,
   onToggleCollapse = () => {},
   onRenderTailToolbar = () => null,
   onRenderCollapsedContent = () => null,
   onResized,
   isCollapsedPanelDisabled,
   context = '',
}: IProps): ReactElement {
   const orientation = getOrientation(alignment, lead);
   const {
      width: splitterWidth,
      height: splitterHeight,
      ref: splitterRef,
   } = useResizeDetector();

   const getSplitterSize = useCallback((): number => {
      return alignment === 'column' ? splitterHeight! : splitterWidth!;
   }, [alignment, splitterHeight, splitterWidth]);

   function toggleLead(value: boolean): void {
      onToggleCollapse(value);
   }

   const updateSplitterSize = useCallback(
      (
         _1: MouseEvent | TouchEvent,
         _2: Direction,
         _3: HTMLElement,
         delta: NumberSize,
      ): void => {
         const splitterSize = getSplitterSize() - minTailSize;
         const deltaSize = alignment === 'column' ? delta.height : delta.width;
         const newSize = size + deltaSize;
         onResized(newSize, splitterSize);
      },
      [getSplitterSize, size, onResized, alignment, minTailSize],
   );

   const calculateMaxSize = useCallback((): number | undefined => {
      if (maxSize !== undefined) {
         return maxSize;
      }
      return getSplitterSize() - minTailSize;
   }, [getSplitterSize, minTailSize]);

   useEffect(() => {
      const splitterSize = getSplitterSize() - minTailSize;

      if (size >= splitterSize) {
         onResized(splitterSize, splitterSize);
      }
   }, [getSplitterSize, size, onResized, alignment, minTailSize]);

   function renderLead(elem: ReactNode): ReactElement {
      if (isCollapsed) {
         return (
            <CollapsedPanel
               context={context}
               orientation={orientation}
               onExpand={() => {
                  toggleLead(false);
               }}
               disabled={isCollapsedPanelDisabled}
            >
               {onRenderCollapsedContent()}
            </CollapsedPanel>
         );
      }

      return (
         <Resizable
            size={getSize(alignment, size)}
            className={'splitter-lead bg-default'}
            minWidth={alignment === 'row' ? minSize : undefined}
            minHeight={alignment === 'column' ? minSize : undefined}
            maxWidth={alignment === 'row' ? calculateMaxSize() : undefined}
            maxHeight={alignment === 'column' ? calculateMaxSize() : undefined}
            onResizeStop={updateSplitterSize}
            handleStyles={buildHandlerStyles(context)}
            handleClasses={{
               top: 'splitter-handler-top', // splitter-handler
               right: 'splitter-handler',
               bottom: 'splitter-handler',
               left: 'splitter-handler',
            }}
            enable={{
               top: orientation === Orientation.Top,
               right: orientation === Orientation.Right,
               bottom: orientation === Orientation.Bottom,
               left: orientation === Orientation.Left,
               topRight: false,
               bottomRight: false,
               bottomLeft: false,
               topLeft: false,
            }}
            handleComponent={{
               right: <Handler orientation={orientation} context={context} />,
               left: <Handler orientation={orientation} context={context} />,
               bottom: <Handler orientation={orientation} />,
               top: <Handler orientation={orientation} context={context} />,
            }}
         >
            <div className={`splitter-content ${orientation} ${context}`}>
               {(context !== 'p-layout' ||
                  (context === 'p-layout' && orientation === 'top')) && (
                  <div className="flex gap-3 splitter-collapse-button w-ful">
                     <Button
                        size="small"
                        type="link"
                        onClick={() => {
                           toggleLead(true);
                        }}
                        className=""
                     >
                        <DownOutlined className="text-main" />
                     </Button>
                     {onRenderCollapsedContent()}
                  </div>
               )}

               <div className={'splitter-toolbar tail-toolbar'}>
                  {onRenderTailToolbar()}
               </div>

               <div className="splitter-content-inner">{elem}</div>
            </div>
         </Resizable>
      );
   }

   function renderFollower(
      order: 'first' | 'second',
      elem: ReactNode,
   ): ReactElement {
      return (
         <div className={`splitter-${order} splitter-follower`}>
            <div>{elem}</div>
         </div>
      );
   }

   return (
      <div
         ref={splitterRef}
         className={`relative flex w-full h-full  ${alignment === 'row' ? 'flex-row' : 'flex-col'}`}
      >
         {splitterWidth !== undefined && splitterHeight !== undefined ? (
            <>
               {lead === 'first'
                  ? renderLead(first)
                  : renderFollower('first', first)}
               {lead === 'second'
                  ? renderLead(second)
                  : renderFollower('second', second)}
            </>
         ) : null}
      </div>
   );
}
