import { type TreeDataNode } from 'antd';

export interface ITab {
   title: string;
   key: string;
   modified: boolean;
   icon: string;
   isDiffTab?: boolean | undefined;
   isModified?: boolean;
}
export interface TabData {
   activeTabKey: string | null;
   tabs: ITab[];
}
export interface CustomTreeDataNode extends TreeDataNode {
   fileData?: string;
   children: any[];
}

export const EXPAND_THRESHOLD = 0;
export const projectOverviewTabName = 'tabs/main/ProjectOverviewTab';
export const gitActionsTabName = 'tabs/main/GitActions';

export enum PlayButtonState {
   NotStarted,
   Started,
   RefetchData,
}

export const gitTab = {
   title: 'Git Actions',
   key: gitActionsTabName,
   modified: false,
   icon: gitActionsTabName,
};
