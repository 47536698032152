import {
   useMutation,
   type UseMutationResult,
   useQuery,
   type UseQueryResult,
} from '@tanstack/react-query';
import { apiPost, apiGet } from '../api/ApiService';
import jobs from './jobs_mock.json';
import { ACTION_CREATE_PROJECT } from '../api/ApiRoutes';
import {
   type IProjectSearchData,
   type IProjectConfiguration,
   type IProjectSearch,
   type IProjectSearchJson,
   type IProjectInfo,
   type IProjectsJson,
} from '../api/models/IProject';
import ApiConstants from '../api/ApiConstants';
import { type IFilesResponse } from './models/IFiles';
import moment from 'moment';

const projectConfigFromJson = (json: IProjectsJson): IProjectConfiguration => ({
   createdBy: json.created_by ?? 'qaiser@gmail.com',
   createdTimestamp: moment(json.created_timestamp).valueOf(),
   defaultBranch: json.default_branch,
   gitUrl: json.source_type_meta?.url,
   id: json.id,
   // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
   lastUpdatedTimestamp: moment(json.last_modified_at).valueOf(),
   materializationType: json.materialization_type,
   name: json.name,
   schema: json.source_schema,
   sourceId: json.source_id,
   sourceDatabase: json.source_database,
   updatedBy: json.updated_by,
   destSchema: json.destination_schema,
   destSourceDatabase: json.destination_database,
   destSourceId: json.destination_id,
   type: json.type,
});

export const useProjectsSearch = (): UseMutationResult<
   IProjectSearchData,
   Error,
   IProjectSearch
> => {
   const searchProject = async (
      payload: IProjectSearch,
   ): Promise<IProjectSearchData> => {
      try {
         const responseApi = await apiPost<IProjectSearchJson>({
            path: `api/v1/project/search`,
            url: ApiConstants.workspaceBaseUrl,
            body: payload,
         });

         const projects: IProjectInfo[] = [];
         if (responseApi != null) {
            responseApi.data?.results?.forEach((p: IProjectsJson) => {
               projects.push({
                  projectName: p.name,
                  hasWorkspace: p.source_type_meta?.url !== undefined,
                  id: p.id,
                  configuration:
                     p.source_id != null ? projectConfigFromJson(p) : null,
                  IsDbtProject: p.is_dbt_project,
                  gitUrl: p.source_type_meta?.url ?? '',
               });
            });
         }
         // here we will do parsing of models and use for components.. json to model
         if (jobs != null || responseApi.status === 200) {
            const searchData = {
               data: projects,
               total: jobs?.total ?? 0,
            };
            return searchData;
         } else {
            // TODO: add error handling when any error occur from server
            throw new Error(`Api Error ${responseApi?.status ?? 0}`);
         }
      } catch (error: any) {
         let errText = 'Search failed';
         if (error?.response?.data?.error !== undefined) {
            errText = error.response.data.error;
         } else if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages?.length
         ) {
            errText = error?.response?.data?.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`Please check your internet connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<IProjectSearchData, Error, IProjectSearch>({
      mutationFn: searchProject,
   });
};

export enum ICreateProjectAction {
   CREATE = 'CREATE',
   IMPORT_DBT = 'IMPORT_DBT',
   IMPORT = 'IMPORT',
}

interface ICreateProject {
   projectName: string;
   gitUrl?: string;
   type?: string;
   branchName?: string;
   sourceId: string;
   sourceDatabase: string;
   schema: string;
   materializationType: string;
   enable_git?: boolean;
   sourceType?: string;
   actionType: ICreateProjectAction;
   userName?: string;
   accessToken?: string;
   addSchemaMacro?: boolean;
   destinationId?: string;
   destinationDatabase?: string;
   destinationSchema?: string;
   location?: string;
   dbtVersion?: string;
   gitUsername?: string;
   gitToken?: string;
   secretId?: string;
}

export const useCreateProject = (): UseMutationResult<
   any,
   Error,
   ICreateProject
> => {
   const createProject = async (payload: ICreateProject): Promise<any> => {
      const getBody = (): any => {
         if (payload.actionType === ICreateProjectAction.CREATE) {
            return {
               name: payload.projectName,
               source_id: payload.sourceId,
               source_database: payload.sourceDatabase,
               source_schema: payload.schema,
               materialization_type: payload.materializationType,
               action_type: ICreateProjectAction.CREATE,
               enable_git: payload.enable_git,
               git_url: payload.gitUrl,
               branch_name: payload.branchName,
               destination_id: payload.destinationId,
               destination_database: payload.destinationDatabase,
               destination_schema: payload.destinationSchema,
               location: payload.location,
               type: payload.type,
               dbt_version: payload.dbtVersion,
               git_username: payload.gitUsername,
               git_token: payload.gitToken,
               secret_id: payload.secretId,
            };
         }
         if (
            payload.actionType === ICreateProjectAction.IMPORT_DBT ||
            payload.actionType === ICreateProjectAction.IMPORT
         ) {
            return {
               name: payload.projectName,
               source_id: payload.sourceId,
               source_database: payload.sourceDatabase,
               source_schema: payload.schema,
               materialization_type: payload.materializationType,
               action_type: payload.actionType,
               source_type: payload.sourceType,
               destination_id: payload.destinationId,
               destination_database: payload.destinationDatabase,
               destination_schema: payload.destinationSchema,
               project_clone_request: payload.gitUrl
                  ? {
                       add_schema_macro: payload.addSchemaMacro,
                       source: {
                          git_url: payload.gitUrl,
                          branch_name: payload.branchName,
                          user_name: payload.userName,
                          access_token: payload.accessToken,
                       },
                    }
                  : undefined,
            };
         }
         return {};
      };

      try {
         const response = await apiPost<IProjectSearchJson>({
            path: `${ACTION_CREATE_PROJECT}`,
            url: ApiConstants.workspaceBaseUrl,
            body: getBody(),
         });
         return response;
      } catch (error: any) {
         let errText = 'Project craetion failed';
         if (
            error?.response?.data?.error_messages !== undefined &&
            error?.response?.data?.error_messages.length !== 0
         ) {
            errText = error.response.data.error_messages[0];
         } else if (error.message !== undefined) {
            errText = error.message;
         }
         if (errText === 'Network Error' || errText === 'timeout exceeded') {
            throw new Error(`please_check_your_internet_connection`);
         } else {
            throw new Error(errText);
         }
      }
   };

   return useMutation<any, Error, ICreateProject>({
      mutationFn: createProject,
   });
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useProjectInfo = (
   projectId: string | undefined,
): UseQueryResult<IFilesResponse, Error> => {
   return useQuery({
      queryKey: ['get projects'],
      staleTime: 0,
      gcTime: 10,
      queryFn: async () => {
         try {
            if (projectId) {
               const response = await apiGet({
                  path: `api/v1/project/${projectId}/content`,
                  url: ApiConstants.workspaceBaseUrl,
                  body: null,
               });
               return response.data;
            }
         } catch (error: any) {
            let errText = 'Project loading failed.';
            if (error?.response?.data?.error !== undefined) {
               errText = error.response.data.error;
            } else if (
               error?.response?.data?.error_messages !== undefined &&
               error?.response?.data?.error_messages?.length
            ) {
               errText = error?.response?.data?.error_messages[0];
            } else if (error.message !== undefined) {
               errText = error.message;
            }
            if (errText === 'Network Error' || errText === 'timeout exceeded') {
               throw new Error(`Please check your internet connection`);
            } else {
               throw new Error(errText);
            }
         }
         return null;
      },
      retry: false,
   });
};
