/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import {
   type IDBTRunJobResponse,
   useDBTProjectJobHistory,
} from '../../../providers/useDBTJob';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useParams } from 'react-router-dom';
import { useGlobalState } from '../../../context/GlobalContext';
import { Avatar, Table, Tooltip } from 'antd';
import moment from 'moment';
import JobHistoryLogsModal from './JobHistoryLogsModal';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/Loader';

library.add(faFileExcel);

interface IJobHistory {
   isFromMainPage?: boolean;
   selectedTabKey?: string;
}
const JobHistory: React.FC<IJobHistory> = ({
   isFromMainPage,
   selectedTabKey,
}) => {
   const [isJobHistoryModalOpen, setIsJobHistoryModalOpen] = useState(false);
   const [selectedJobHistory, setSelectedJobHistory] =
      useState<IDBTRunJobResponse | null>(null);
   const { messageApi } = useGlobalState();
   const { id: projectId } = useParams();

   const {
      data: dbtJobHistoryData,
      isError: isDbtJobHistoryError,
      isFetching: isDbtJobHistoryLoading,
      error: dbtJobHistoryError,
      refetch: dbtJobHistoryRefetch,
   } = useDBTProjectJobHistory(projectId!);

   useEffect(() => {
      if (
         selectedTabKey !== undefined &&
         selectedTabKey !== null &&
         selectedTabKey === 'jobHistory'
      ) {
         void dbtJobHistoryRefetch();
      }
   }, [selectedTabKey]);

   useEffect(() => {
      if (isDbtJobHistoryError) {
         showError(`${dbtJobHistoryError.message}`);
      }
      if (dbtJobHistoryData !== undefined) {
         console.log(dbtJobHistoryData);
      }
   }, [isDbtJobHistoryLoading, dbtJobHistoryData]);

   const showError = (message: string): void => {
      void messageApi?.open({
         type: 'error',
         content: message,
      });
   };

   const PROJECT_COLUMNS = [
      {
         title: 'User',
         dataIndex: 'created_by',
         key: 'created_by',
         render: (created_by: string) => {
            return (
               <div>
                  <Tooltip title={created_by}>
                     <Avatar
                        className="bg-primary cursor-pointer ms-4"
                        size={25}
                     >
                        {created_by.charAt(0).toUpperCase()}
                     </Avatar>
                  </Tooltip>
               </div>
            );
         },
      },
      {
         title: 'Status',
         dataIndex: 'job_status',
         key: 'job_status',
      },
      {
         title: 'Start Time',
         dataIndex: 'created_at',
         key: 'created_at',
         render: (created_at: string) => {
            const dateObject = moment(created_at, moment.ISO_8601);
            const time =
               moment.duration(moment().diff(dateObject)).humanize() + ' ago';
            return <div>{time}</div>;
         },
      },
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: 'Type',
         dataIndex: 'job_type',
         key: 'job_type',
      },
      {
         title: 'Job Details',
         dataIndex: '',
         key: '',
         render: (_data: any, record: IDBTRunJobResponse) => {
            console.log(record, 'record');
            return (
               <div
                  className="text-primary underline"
                  onClick={() => {
                     setSelectedJobHistory(record);
                     setIsJobHistoryModalOpen(true);
                  }}
               >
                  View Details
               </div>
            );
         },
      },
   ];

   return (
      <div className={`w-full mt-4 ${isFromMainPage && 'mx-5'}`}>
         {isDbtJobHistoryLoading && (
            <Loader description="Loading Jobs History" />
         )}
         {isFromMainPage && <div className="text-3xl text-main">Jobs</div>}
         {!isDbtJobHistoryLoading &&
            (dbtJobHistoryData === undefined ||
               dbtJobHistoryData?.results.length === 0) && (
               <div
                  className={`flex flex-col justify-center items-center ${isFromMainPage && '!h-[calc(100vh-150px)]'}`}
               >
                  <FontAwesomeIcon
                     icon={faFileExcel}
                     className={`text-primary text-xl border-[2px] border-solid p-2 px-2.5  
                        rounded-full ${isFromMainPage && '!text-6xl !p-3 !px-5'}`}
                  />
                  <div
                     className={`text-main text-xl mt-1 ${isFromMainPage && '!text-2xl !mt-3'}`}
                  >
                     No Job Found
                  </div>
                  <div className="text-border text-sm mt-1">
                     No dbt jobs have been executed. Please initiate a job from
                     the actions menu.
                  </div>
               </div>
            )}
         {dbtJobHistoryData !== undefined &&
            dbtJobHistoryData?.results.length !== 0 && (
               <Table
                  className="mt-2"
                  rowClassName="cursor-pointer"
                  // scroll={{ y: 'calc(100vh - 190px)' }}
                  pagination={{ pageSize: 10, size: 'small' }}
                  dataSource={dbtJobHistoryData?.results}
                  columns={PROJECT_COLUMNS}
                  onRow={(record, rowIndex) => {
                     return {
                        onClick: (event) => {
                           //   onItemClick(record, rowIndex);
                        },
                     };
                  }}
                  sticky={true}
               />
            )}
         {isJobHistoryModalOpen && (
            <JobHistoryLogsModal
               isModalOpen={isJobHistoryModalOpen}
               jobHistoryData={selectedJobHistory!}
               onOk={() => {
                  setIsJobHistoryModalOpen(false);
               }}
            />
         )}
      </div>
   );
};

export default JobHistory;
