/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useCallback, useEffect } from 'react';
import dagre from '@dagrejs/dagre';
import ReactFlow, {
   Controls,
   isNode,
   Position,
   ReactFlowProvider,
   useEdgesState,
   useNodesState,
} from 'reactflow';
import BasicNode from './BasicNode';
import 'reactflow/dist/style.css';
import useFlowElements from './useFlowElements';
import './style.scss';
import { SyncOutlined } from '@ant-design/icons';
import { type UseMutateFunction } from '@tanstack/react-query';
import {
   type IDBTProjectPayload,
   type IDBTRunJobResponse,
} from '../../../providers/useDBTJob';
import { useParams } from 'react-router-dom';

const MAX_ZOOM_FACTOR = 1.25;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (_nodes: any[], _edges: any[]): any => {
   const nodes = _nodes;
   const edges = _edges;
   if (nodes === undefined || nodes.length === 0)
      return { nodes: [], edges: [] };

   const direction = 'LR';
   const isHorizontal = true;
   dagreGraph.setGraph({ rankdir: direction });
   nodes.forEach((el: any) => {
      if (isNode(el)) {
         dagreGraph.setNode(el.id, { width: 230, height: 32 });
      } else {
         dagreGraph.setEdge(el.source, el.target);
      }
   });

   edges.forEach((el: any) => {
      if (isNode(el)) {
         dagreGraph.setNode(el.id, { width: 230, height: 32 });
      } else {
         dagreGraph.setEdge(el.source, el.target);
      }
   });
   dagre.layout(dagreGraph);
   nodes.forEach((el: any) => {
      if (isNode(el)) {
         const nodeWithPosition = dagreGraph.node(el.id);
         el.targetPosition = isHorizontal ? Position.Left : Position.Top;
         el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;
         el.position = {
            x: nodeWithPosition.x + 20, // / 2 + Math.random() / 1000,
            y: nodeWithPosition.y + 100,
         };
      }
      return el;
   });
   return { nodes, edges };
};

interface ICatalog {
   catalogData: any;
   runDbtProjectJob: UseMutateFunction<
      IDBTRunJobResponse,
      Error,
      IDBTProjectPayload,
      unknown
   >;
}
const nodeTypes = {
   basic: BasicNode,
};

const Catalog: React.FC<ICatalog> = (props) => {
   const { catalogData, runDbtProjectJob } = props;
   const { nodes: _nodes, edges: _edges } = useFlowElements(catalogData);
   const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      _nodes,
      _edges,
   );
   const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
   const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

   const { id: projectId } = useParams();

   useEffect(() => {
      setNodes(layoutedNodes);
   }, [layoutedNodes]);

   useEffect(() => {
      setEdges(layoutedEdges);
   }, [layoutedNodes]);

   const onNodeContextMenu = useCallback((event: React.MouseEvent): void => {
      event.preventDefault();
   }, []);

   return (
      <div className="project-catalog-graph flex flex-col h-[calc(100vh-105px)] justify-center items-center gap-4">
         <ReactFlowProvider>
            <ReactFlow
               nodes={nodes}
               edges={edges}
               onNodesChange={onNodesChange}
               onEdgesChange={onEdgesChange}
               selectNodesOnDrag={false}
               nodeTypes={nodeTypes}
               onNodeContextMenu={onNodeContextMenu}
               maxZoom={MAX_ZOOM_FACTOR}
               fitView
            >
               <SyncOutlined
                  onClick={() => {
                     runDbtProjectJob({
                        command: {
                           type: 'COMPILE',
                           args: {
                              type: 'COMPILE',
                           },
                        },
                        projectId: projectId!,
                     });
                  }}
                  className="absolute left-auto right-[25px] bottom-48  bg-white text-black text-[18px] p-1 !cursor-pointer z-50"
               />
               <Controls className="project-catalog-graph-controls" />
            </ReactFlow>
         </ReactFlowProvider>
      </div>
   );
};

export default Catalog;
