export const API_TIMEOUT = 30000;

export const BASE_URL = 'https://api.playground.dataworkspace.io:'; // 'https://dummyjson.com/auth';

export const RESPONSE_OK = 'OK';

export default {
   cognito: {
      userPoolClientId: 'FF3a21sq11ir645762iesef22e0b',
      userPoolId: 'FFap-southeast-1_xBfKN0vgh',
      loginWith: {
         oauth: {
            domain: 'dws-tenant-1',
            scopes: ['email', 'openid'],
            redirectSignIn: [
               'http://localhost:3000',
               'https://playground.dataworkspace.io/',
            ],
            redirectSignOut: [
               'http://localhost:3000/signin',
               'https://playground.dataworkspace.io/signin',
            ],
            responseType: 'token',
         },
      },
   },
   jobBaseUrl: BASE_URL + '8080',
   connectorBaseUrl: BASE_URL + '8082',
   datasourceBaseUrl: BASE_URL + '8081',
   workspaceBaseUrl: BASE_URL + '8083',
   tenantBaseUrl: BASE_URL + '8084',
};

// export default {
//    cognito: {
//       REGION: 'us-east-1',
//       USER_POOL_ID: 'us-east-1_dddd',
//       APP_CLIENT_ID: 'xxxxxx',
//       IDENTITY_POOL_ID: 'xxxxxxxxx',
//    },
//    jobBaseUrl: 'http://18.141.57.6:8080',
//    connectorBaseUrl: 'http://13.215.59.41:8080',
//    datasourceBaseUrl: 'http://54.179.180.241:8080',
//    workspaceBaseUrl: 'http://52.77.216.194:8080',
// };
