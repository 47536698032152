import React from 'react';
import { useGlobalState } from '../context/GlobalContext';
import { THEME_DARK } from '../utils/Constants';

interface ILoading {
   size?: number;
   className?: string;
   messages?: string;
}
const Loading: React.FC<ILoading> = ({ size = 18, className, messages }) => {
   const { currentTheme } = useGlobalState();
   return (
      <div
         className={`flex flex-col w-full h-full justify-center items-center gap-2 absolute top-0 left-0 z-10
         ${currentTheme === THEME_DARK ? 'bg-[#17191acc]' : 'bg-[#ffffffcc]'} ${className}`}
      >
         <span className={`loading-bars text-primary`} />
         <span className={`text-primary text-xs`}>{messages}</span>
      </div>
   );
};
export default Loading;
