export const CONNECTORS = [
   {
      id: '6cbd4aef-6cb5-4f82-bc2b-97ec6b6c55c1',
      name: 'generic-connector',
      type: 'JDBC',
      created_at: '2024-05-16T05:34:30.93375Z',
      last_modified_at: '2024-05-16T05:34:30.93385Z',
      created_by: 'system',
      last_modified_by: 'system',
      versions: [
         {
            id: '4b5ee75b-6409-4966-8390-5a0cf7503b9a',
            version: 'v1',
         },
      ],
   },
];

export const DRIVERS = [
   {
      id: '072fadc8-e17f-410b-915f-fc7167fc7657',
      name: 'mysql',
      connector_id: '6cbd4aef-6cb5-4f82-bc2b-97ec6b6c55c1',
      versions: [
         {
            id: 'b990be4b-8e65-4974-a647-6552b87fc25b',
            version: 'v8',
         },
      ],
   },
];

export const PROPERTIES = [
   {
      id: 1,
      name: 'type',
      label: 'type',
      type: 'STRING',
      description: '',
      required: true,
      encrypted: false,
   },
   {
      id: 2,
      name: 'driver',
      label: 'driver',
      type: 'STRING',
      description: '',
      required: true,
      encrypted: false,
   },
   {
      id: 3,
      name: 'url',
      label: 'url',
      type: 'STRING',
      description: 'jdbc url with host, port adn other properties',
      required: true,
      encrypted: false,
   },
   {
      id: 4,
      name: 'user',
      label: 'user',
      type: 'STRING',
      description: '',
      required: true,
      encrypted: false,
   },
   {
      id: 5,
      name: 'password',
      label: 'password',
      type: 'STRING',
      description: '',
      required: true,
      encrypted: false,
   },
   {
      id: 6,
      name: 'name',
      label: 'name',
      type: 'STRING',
      description: '',
      required: true,
      encrypted: false,
   },
];
