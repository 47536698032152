import { LeftOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select, Space } from 'antd';
import React, { useState } from 'react';
import { type IDataSource } from '../../providers/models/IDataSource';
import { PROJECT_DIRECTORY_TREE_STATE } from '../../utils/Constants';
import { useGlobalState } from '../../context/GlobalContext';
import { type IProjectInfo } from '../../api/models/IProject';
import { type IBranch } from '../../providers/models/Json/IGitProject';
import SettingRightSideImage from '../../assets/svgs/setting_right_side.svg';

interface ISettings {
   dataSourceList: IDataSource[];
   selectedProject: IProjectInfo | undefined;
   selectedBranch: IBranch | undefined;
}
const Settings: React.FC<ISettings> = (props) => {
   const { dataSourceList, selectedProject, selectedBranch } = props;
   const [selectedSource, setSelectedSource] = useState<string>(
      selectedProject?.configuration?.destSourceDatabase ?? '',
   );
   const [projectName, setProjectName] = useState<string>(
      selectedProject?.projectName ?? '',
   );
   const [schema, setSchema] = useState<string>(
      selectedProject?.configuration?.schema ?? '',
   );
   const [gitUrl, setGitUrl] = useState<string>(
      selectedProject?.configuration?.gitUrl ?? '',
   );
   const [branch, setBranch] = useState<string>(selectedBranch?.name ?? '');
   const { setSelectedProjectState } = useGlobalState();

   return (
      <div className="w-full overflow-y-auto h-[calc(100vh-0px)] max-h-[calc(100vh-0px)] p-5">
         <div className="text-3xl text-main">Settings</div>
         <div className="flex flex-row">
            <div className="flex  flex-col w-1/2 ps-8 pe-20">
               <div
                  className="text-sm text-border w-1/4 mt-4 cursor-pointer"
                  onClick={() => {
                     setSelectedProjectState(PROJECT_DIRECTORY_TREE_STATE);
                  }}
               >
                  <LeftOutlined className="me-1" />
                  Back
               </div>
               <div className="text-base text-primary font-semibold uppercase mt-2">
                  Project Configuration
               </div>
               <div className="flex flex-col mt-4">
                  <label className="text-xs text-border w-1/4">
                     Project Name
                  </label>
                  <Input
                     placeholder="Project Name"
                     className="mb-4 mt-1"
                     size="large"
                     disabled
                     onChange={(e) => {
                        setProjectName(e.target.value);
                     }}
                     value={projectName}
                  />
                  <label className="text-xs text-border w-1/4">
                     Data Source
                  </label>
                  <Select
                     placeholder="Data Source"
                     size="large"
                     className="w-full mb-4 mt-1"
                     onChange={(value: any, options: any) => {
                        setSelectedSource(options.id as string);
                     }}
                     value={selectedSource}
                     options={dataSourceList}
                     disabled
                  />
                  <label className="text-xs text-border w-1/4">
                     Default Schema
                  </label>
                  <Input
                     placeholder="Default Schema"
                     className="mb-4 mt-1"
                     size="large"
                     onChange={(e) => {
                        setSchema(e.target.value);
                     }}
                     value={schema}
                     disabled
                  />
                  <div className="flex flex-row-reverse">
                     <Button
                        type="primary"
                        size="middle"
                        disabled
                        //    disabled={!isConnected || !isValid()}
                        //    onClick={onSaveClick}
                     >
                        Save
                     </Button>
                  </div>
               </div>
               <Divider />

               <div className="text-base text-primary font-semibold uppercase ">
                  Git Configuration
               </div>
               <div className="text-border text-xs mt-1">
                  To configure this project with Git, you&apos;ll need an
                  existing empty repository
               </div>

               <div className="flex flex-col mt-4 ">
                  <label className="text-xs text-border w-1/4">
                     Git Repository URL
                  </label>
                  <Space.Compact className="mb-4 mt-1">
                     <Input
                        placeholder="Git Repository URL"
                        size="large"
                        value={gitUrl}
                        onChange={(e) => {
                           setGitUrl(e.target.value);
                        }}
                        disabled
                     />
                     <Button type="primary" size="large" disabled>
                        Test Git Connection
                     </Button>
                  </Space.Compact>

                  <label className="text-xs text-border w-1/4">Branch</label>
                  <Input
                     placeholder="Branch"
                     className="mb-4 mt-1"
                     size="large"
                     onChange={(e) => {
                        setBranch(e.target.value);
                     }}
                     value={branch}
                     disabled
                  />
                  <div className="flex flex-row-reverse">
                     <Button
                        type="primary"
                        size="middle"
                        disabled
                        //    disabled={!isConnected || !isValid()}
                        //    onClick={onSaveClick}
                     >
                        Save
                     </Button>
                  </div>
               </div>
            </div>

            <div className="w-1/2 flex flex-col justify-center items-center">
               <div className="w-10/12 flex justify-center py-16 rounded-md bg-border bg-opacity-10">
                  <img src={SettingRightSideImage} className="w-1/2" />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Settings;
