/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'antd';
import { fileNameTitleCleanUp } from '../../../utils/helpers';

interface IAddFileInput {
   onAddFile: any;
   onBlurFile: any;
}
const AddFileInput: React.FC<IAddFileInput> = ({ onAddFile, onBlurFile }) => {
   const inputRef = useRef<any>(null);

   useEffect(() => {
      if (inputRef.current) {
         inputRef.current.focus();
      }
   }, [inputRef]);
   const [fileName, setFileName] = useState('');
   const onKeyDown = (e: any): void => {
      if (e.key === 'Enter') {
         e.preventDefault();
         addFile();
      }
   };

   const addFile = (): void => {
      const fileNameSplitted = fileName.split('.');
      if (fileNameSplitted.length < 2) {
         setFileName((prev) => {
            return `${prev}.sql`;
         });
         onAddFile(`${fileName}.sql`);
      } else {
         onAddFile(fileName);
      }
   };

   const onChangeFileName = (e: any): void => {
      e.preventDefault();
      const cleanedTitle = fileNameTitleCleanUp(
         e.target.value,
         90,
      ).toLowerCase();
      setFileName(cleanedTitle);
   };

   return (
      <Input
         value={fileName}
         ref={inputRef}
         style={{ margin: 0 }}
         onKeyDown={onKeyDown}
         onChange={onChangeFileName}
         // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
         onBlur={() => (!fileName ? onBlurFile() : addFile())}
      />
   );
};
export default AddFileInput;
