import axios, { type RawAxiosRequestHeaders } from 'axios';
import ApiConstants, { API_TIMEOUT } from './ApiConstants';
import {
   type IRequestPayload,
   type IGenericResponse,
   type IRequestException,
} from './models/IGenericResponse';
import { fetchAuthSession } from 'aws-amplify/auth';

export const clearLocalStorageItems = (): void => {
   // LocalStorage.remove(KEY_USER_DATA);
};

async function apiWrapper<T>(
   apiCall: any,
   returnError = true,
   throwException = true,
): Promise<IGenericResponse<T>> {
   try {
      const response = await apiCall;
      return { data: response.data, status: response.status };
   } catch (error: any) {
      if (error.response.status === 401 || error.response.status === 403) {
         clearLocalStorageItems();
         window.location.assign('/signin');
      }
      if (throwException) throw error;
      if (returnError && error?.response !== undefined) {
         return error.response;
      }
      return { data: null, status: 0 };
   }
}

const getIdToken = async (): Promise<string> => {
   let idToken: string | undefined;
   if (idToken === null || idToken === undefined) {
      const { idToken: token } = (await fetchAuthSession()).tokens ?? {};
      idToken = token?.toString();
   }
   return idToken ?? '';
};

// TODO:: we can add headers here and jwt etc
const getHeaders = async (
   headers: object = {},
): Promise<RawAxiosRequestHeaders> => {
   let headersNew = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${await getIdToken()}`,
   };
   headersNew = { ...headersNew, ...headers };
   return headersNew;
};

export const apiPost = async <T>(
   { path, body, url = ApiConstants.workspaceBaseUrl }: IRequestPayload,
   { returnError, throwException }: IRequestException | undefined = {
      returnError: true,
      throwException: true,
   },
   responseType?: any,
): Promise<IGenericResponse<T>> => {
   return await apiWrapper(
      axios.post(`${url}/${path}`, body, {
         headers: await getHeaders(),
         timeout: API_TIMEOUT,
         responseType,
      }),
      returnError,
      throwException,
   );
};

export const apiGet = async <T>(
   { path, url = ApiConstants.workspaceBaseUrl, headers }: IRequestPayload,
   { returnError, throwException }: IRequestException | undefined = {
      returnError: true,
      throwException: true,
   },
): Promise<IGenericResponse<T>> => {
   return await apiWrapper(
      axios.get(`${url}/${path}`, {
         headers: await getHeaders(headers ?? {}),
         timeout: API_TIMEOUT,
      }),
      returnError,
      throwException,
   );
};

export const apiGetJson = async <T>(
   { path, url = ApiConstants.workspaceBaseUrl, headers }: IRequestPayload,
   { returnError, throwException }: IRequestException | undefined = {
      returnError: true,
      throwException: true,
   },
): Promise<IGenericResponse<T>> => {
   return await apiWrapper(
      axios.get(`${url}`, {
         headers: {},
         timeout: API_TIMEOUT,
      }),
      returnError,
      throwException,
   );
};

export const apiPut = async <T>(
   { path, body, url = ApiConstants.workspaceBaseUrl }: IRequestPayload,
   { returnError, throwException }: IRequestException | undefined = {
      returnError: true,
      throwException: true,
   },
): Promise<IGenericResponse<T>> => {
   return await apiWrapper(
      axios.put(`${url}/${path}`, body, {
         headers: await getHeaders(),
         timeout: API_TIMEOUT,
      }),
      returnError,
      throwException,
   );
};

export const apiPatch = async <T>(
   { path, body, url = ApiConstants.workspaceBaseUrl }: IRequestPayload,
   {
      returnError = true,
      throwException = true,
   }: IRequestException | undefined = {
      returnError: true,
      throwException: true,
   },
): Promise<IGenericResponse<T>> => {
   return await apiWrapper(
      axios.patch(`${url}/${path}`, body, {
         headers: await getHeaders(),
         timeout: API_TIMEOUT,
      }),
      returnError,
      throwException,
   );
};

export const apiDelete = async <T>(
   { path, body, url = ApiConstants.workspaceBaseUrl }: IRequestPayload,
   { returnError, throwException }: IRequestException | undefined = {
      returnError: true,
      throwException: true,
   },
): Promise<IGenericResponse<T>> => {
   return await apiWrapper(
      axios.delete(`${url}/${path}`, {
         headers: await getHeaders(),
         data: body,
         timeout: API_TIMEOUT,
      }),
      returnError,
      throwException,
   );
};
