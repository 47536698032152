import { Button, Input, Select } from 'antd';
import Password from 'antd/es/input/Password';
import React, {
   type ReactNode,
   type Dispatch,
   type SetStateAction,
} from 'react';
import mySqlIcon from '../../assets/svgs/mysql-icon.svg';
import {
   type IProperty,
   type IConnector,
   type IDriver,
} from '../../providers/models/IConnector';
import { capitalizeFirstLetter } from '../../utils/Utils';
import { DataSourcePageType } from './DataSource';

interface IStep2Configuration {
   onNextClick: () => void;
   drivers: IDriver[] | undefined | null;
   connectors: IConnector[] | undefined | null;
   selectedConnector: IConnector | undefined;
   setSelectedConnector: Dispatch<SetStateAction<IConnector | undefined>>;
   properties: IProperty[] | undefined | null;
   setInputProperties: Dispatch<SetStateAction<IProperty[]>>;
   selectedDriver: IDriver | undefined;
   setSelectedDriver: Dispatch<SetStateAction<IDriver | undefined>>;
   setDataSourcePageType: Dispatch<SetStateAction<DataSourcePageType>>;
   onTestConnectionClicked: () => void;
   onSaveClick: () => void;
   isConnected: boolean;
   isLoading: boolean;
}
const Step2Configuration: React.FC<IStep2Configuration> = (props) => {
   const {
      drivers,
      connectors,
      selectedConnector,
      setSelectedConnector,
      properties,
      setInputProperties,
      selectedDriver,
      setSelectedDriver,
      setDataSourcePageType,
      onTestConnectionClicked,
      onSaveClick,
      isConnected,
      isLoading,
   } = props;

   const onConnectorChange = (value: string): void => {
      setSelectedConnector(
         connectors?.find((connector) => connector.id === value),
      );
   };

   const onDriverChange = (value: string): void => {
      setSelectedDriver(drivers?.find((driver) => driver.id === value));
   };
   const handleInputChange = (value: string, index: number): void => {
      setInputProperties((prevData) => {
         const updatedData = [...prevData];
         updatedData[index].value = value;
         return updatedData;
      });
   };
   const inputFieldProperty = (
      property: IProperty,
      index: number,
   ): ReactNode => {
      const label = capitalizeFirstLetter(property.label);
      return (
         <div key={property.id} className="flex flex-row items-center gap-5">
            <div className="text-sm text-gray-400 w-1/4">
               {label}:
               <span className="text-red-700">{property.required && '*'}</span>
            </div>
            {property.encrypted || property.label === 'Password' ? (
               <Password
                  placeholder={property.description}
                  size="large"
                  autoComplete="new-password"
                  onChange={(e) => {
                     handleInputChange(e.target.value, index);
                  }}
                  value={property.value ?? ''}
               />
            ) : (
               <Input
                  placeholder={property.description}
                  size="large"
                  onChange={(e) => {
                     handleInputChange(e.target.value, index);
                  }}
                  value={property.value ?? ''}
               />
            )}
         </div>
      );
   };

   const isValid = (): boolean => {
      if (
         selectedConnector === null ||
         selectedConnector === undefined ||
         // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
         properties === undefined ||
         properties === null
      )
         return false;

      for (let i = 0; i < properties?.length; i++) {
         const property = properties[i];
         if (
            property.required &&
            (property.value === '' || property.value === undefined)
         ) {
            return false;
         }
      }

      return true;
   };

   return (
      <>
         <div className="flex flex-col w-full mt-5 gap-4">
            <div className="flex flex-row items-center gap-5">
               <div className="text-sm text-gray-400 w-1/4">Connentor: </div>
               <Select
                  placeholder="Select Connentor"
                  className="w-full"
                  size="large"
                  defaultValue={
                     connectors?.find(
                        (connector) =>
                           connector.id.toLowerCase() === selectedConnector?.id,
                     )?.id
                  }
                  value={selectedConnector?.id}
                  onChange={onConnectorChange}
                  options={connectors?.map((connector) => {
                     return {
                        value: connector.id,
                        label: (
                           <span className="flex flex-row items-center gap-2">
                              <img
                                 className="w-6 h-6"
                                 src={connector.icon ?? mySqlIcon}
                              />
                              {connector.name}
                           </span>
                        ),
                        icon: connector.icon,
                     };
                  })}
               />
            </div>
            {selectedConnector !== undefined &&
               drivers !== undefined &&
               drivers?.length !== 0 && (
                  <div className="flex flex-row items-center gap-5">
                     <div className="text-sm text-gray-400 w-1/4">Driver:</div>
                     <Select
                        placeholder="Select Driver"
                        className="w-full"
                        size="large"
                        defaultValue={
                           drivers?.find(
                              (driver) =>
                                 driver.id.toLowerCase() === selectedDriver?.id,
                           )?.id
                        }
                        onChange={onDriverChange}
                        value={selectedDriver?.id}
                        options={drivers?.map((driver) => {
                           return {
                              value: driver.id,
                              label: (
                                 <span className="flex flex-row items-center gap-2">
                                    <img
                                       className="w-6 h-6"
                                       src={driver.icon ?? mySqlIcon}
                                    />
                                    {driver.name}
                                 </span>
                              ),
                           };
                        })}
                        // optionRender={labelRender}
                        labelInValue={true}
                     />
                  </div>
               )}

            {properties?.map((property, index) =>
               inputFieldProperty(property, index),
            )}
         </div>
         <div className="flex flex-row justify-between w-full mt-7">
            <Button
               className="ps-0"
               key="back"
               type="link"
               onClick={() => {
                  setDataSourcePageType(DataSourcePageType.LIST);
               }}
            >
               Cancel
            </Button>
            <div className="flex flex-row gap-3">
               <Button
                  type="primary"
                  disabled={!isValid() || isLoading}
                  onClick={() => {
                     onTestConnectionClicked();
                  }}
               >
                  Test Connection
               </Button>

               <Button
                  type="primary"
                  disabled={!isConnected || !isValid()}
                  onClick={onSaveClick}
               >
                  Save
               </Button>
            </div>
         </div>
      </>
   );
};

export default Step2Configuration;
