/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { type TreeDataNode, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
   useDataSources,
   useMetaData,
   useMetaDataFields,
   useResultPathURL,
   useResultsPreviewDataJson,
} from '../../../providers/useDataSources';
import { useDBTJobPoll } from '../../../providers/useDBTJob';
import { showError } from '../../../utils/Utils';
import Databases from './Databases';
import { useParams } from 'react-router-dom';
import { type IProjectSearchData } from '../../../api/models/IProject';
import { type IDataSource } from '../../../providers/models/IDataSource';
import {
   DatabaseOutlined,
   FundOutlined,
   PictureOutlined,
   TableOutlined,
} from '@ant-design/icons';
import { type IField } from '../../../providers/models/IMetaDataFields';
import { type ISchemaResponse } from '../../../providers/models/ISchema';

interface IDatabases {
   projectSearchData: IProjectSearchData | undefined;
}
export enum PollType {
   Schema,
   MetaData,
}
const DatabasesContainer: React.FC<IDatabases> = (props) => {
   const { projectSearchData } = props;
   const [messageApi] = message.useMessage();

   const [jobId, setJobId] = useState<string | null>(null);
   const [currentPollType, setCurrentPollType] = useState<PollType>(
      PollType.Schema,
   );
   const [dataSource, setDataSource] = useState<IDataSource>();
   const [catalogDirectoryArray, setCatalogDirectoryArray] = useState<
      TreeDataNode[]
   >([]);
   const [tablefields, setTablefields] = useState<IField[]>([]);
   const { id: projectId } = useParams();

   const currentProject = useMemo(() => {
      return projectSearchData?.data?.find(
         (project: any) => project.id === projectId,
      );
   }, [projectId]);

   const {
      data: dataSources,
      isError: isDataSourcesError,
      isFetching: isDataSourcesLoading,
      error: dataSourcesError,
      refetch: refetchDataSources,
   } = useDataSources();

   const {
      mutate: metaDataObjects,
      data: metaQueryJobData,
      isError: isMetaQueryError,
      error: metaQueryError,
      isPending: isMetaQueryLoading,
   } = useMetaData();

   const {
      mutate: metaDataFieldsRequest,
      data: metaDataFieldsData,
      isError: isMetaDataFieldsError,
      error: metaDataFieldsError,
      isPending: isMetaDataFieldsLoading,
   } = useMetaDataFields();

   const {
      mutate: getResultsPathData,
      data: resultsPathData,
      isError: isResultsPathError,
      error: resultPathError,
      isPending: isResultPathLoading,
   } = useResultPathURL();

   const {
      mutate: getPreviewData,
      data: previewQueryJobData,
      isError: isPreviewQueryError,
      error: previewQueryError,
      isPending: isPreviewQueryLoading,
   } = useResultsPreviewDataJson();

   const {
      data: schemaJobPollData,
      isError: isSchemaJobPollError,
      isPending: isSchemaJobPollLoading,
      error: schemaJobPollError,
   } = useDBTJobPoll(jobId);

   useEffect(() => {
      if (isMetaQueryError) {
         showError(messageApi, metaQueryError.message);
      }
      if (metaQueryJobData !== null && metaQueryJobData !== undefined) {
         setCurrentPollType(PollType.Schema);
         setJobId(metaQueryJobData.id);
      }
   }, [isMetaQueryLoading, metaQueryJobData]);

   useEffect(() => {
      if (isSchemaJobPollError) {
         showError(messageApi, schemaJobPollError.message);
      }
      if (schemaJobPollData !== undefined && schemaJobPollData !== null) {
         if (
            schemaJobPollData.result_path &&
            schemaJobPollData.job_status === 'COMPLETED'
         ) {
            getResultsPathData(schemaJobPollData.result_path ?? '');
         } else if (schemaJobPollData.job_status === 'FAILED') {
            console.log('schemaJobPollData', schemaJobPollData.message);
            showError(messageApi, schemaJobPollData.message);
         }
      }
   }, [isSchemaJobPollLoading, schemaJobPollData]);

   useEffect(() => {
      if (isResultsPathError) {
         showError(messageApi, resultPathError.message);
      }
      if (resultsPathData !== undefined && resultsPathData !== null) {
         if (resultsPathData?.url !== undefined) {
            getPreviewData(resultsPathData?.url);
         }
      }
   }, [isResultPathLoading, resultsPathData]);

   useEffect(() => {
      if (isPreviewQueryError) {
         showError(messageApi, previewQueryError.message);
      }
      if (previewQueryJobData !== undefined && previewQueryJobData !== null) {
         if (currentPollType?.valueOf() === PollType.Schema) {
            const _arr = previewQueryJobData as ISchemaResponse[];
            const arr = convertToCatalogDirectory(_arr);
            setCatalogDirectoryArray([
               {
                  title: dataSource?.name ?? 'Datasource',
                  key: 'datasource',
                  children: arr,
                  icon: (
                     <img
                        width={15}
                        height={15}
                        className="mt-1 me-1"
                        src={dataSource?.icon}
                     />
                  ),
               },
            ]);
         } else {
            const arr = previewQueryJobData as IField[];
            setTablefields(arr);
         }
      }
   }, [isPreviewQueryLoading, previewQueryJobData]);

   useEffect(() => {
      // getResultsPathData(
      //    'objects/cid=627e7d3f-8c89-420d-82e6-3f5b5bcc2066/jobId=52a33565-c422-4a98-88c1-3695aaa4e21c/data.json',
      // );
      metaDataObjects({
         source_id: currentProject?.configuration?.sourceId,
         filter: {},
      });
   }, []);

   useEffect(() => {
      if (isDataSourcesError) {
         showError(messageApi, dataSourcesError.message);
      }
      if (dataSources !== null && dataSources !== undefined) {
         const _dataSource = dataSources.results.find(
            (_dataSource) =>
               _dataSource.id === currentProject?.configuration?.sourceId,
         );
         setDataSource(_dataSource);
      }
   }, [isDataSourcesLoading, dataSources]);

   useEffect(() => {
      if (isMetaDataFieldsError) {
         showError(messageApi, metaDataFieldsError.message);
      }
      if (metaDataFieldsData !== null && metaDataFieldsData !== undefined) {
         setCurrentPollType(PollType.MetaData);
         setJobId(metaDataFieldsData.id ?? '');
      }
   }, [isMetaDataFieldsLoading, metaDataFieldsData]);

   const convertToCatalogDirectory = (arr: any[]): TreeDataNode[] => {
      const catalogs = new Map();

      for (const item of arr) {
         const {
            catalog,
            schema,
            table_name,
            type,
            table_name_fqn,
            description,
         } = item;

         const catalogEntry = catalogs.get(catalog) || new Map();
         const schemaMap = catalogEntry.get(schema) || new Set();

         schemaMap.add({
            tableName: table_name,
            type,
            table_name_fqn,
            description,
         });
         catalogEntry.set(schema, schemaMap);
         catalogs.set(catalog, catalogEntry);
      }

      const catalogArray = [];
      for (const [catalogName, catalogEntry] of Array.from(
         catalogs.entries(),
      )) {
         const scehmas = [];
         for (const [schemaName, tableEntry] of Array.from(
            catalogEntry.entries() as any[],
         )) {
            scehmas.push({
               title: schemaName,
               key: `${catalogName}/${schemaName}`,
               children: Array.from(tableEntry.values() as any[]).map(
                  (table) => {
                     return {
                        title: table.tableName,
                        isLeaf: true,
                        key: `${catalogName}/${schemaName}/${table.tableName}`,
                        icon:
                           table.type === 'VIEW' ? (
                              <PictureOutlined />
                           ) : (
                              <TableOutlined />
                           ),
                        table_name_fqn: table.table_name_fqn,
                        type: table.type,
                        description: table.description,
                     };
                  },
               ),
               icon: <FundOutlined />,
            });
         }
         catalogArray.push({
            title: catalogName,
            children: scehmas,
            key: `${catalogName}`,
            icon: <DatabaseOutlined />,
         });
      }
      return catalogArray;
   };

   if (
      currentPollType === PollType.Schema &&
      (schemaJobPollData?.job_status === 'FAILED' ||
         isMetaQueryError ||
         isSchemaJobPollError ||
         isResultsPathError ||
         isPreviewQueryError)
   ) {
      return (
         <div>
            <p>{schemaJobPollData?.message ?? 'Objects failed'}</p>
         </div>
      );
   }

   return (
      <Databases
         catalogDirectoryArray={catalogDirectoryArray}
         currentProject={currentProject}
         dataSource={dataSource}
         metaDataFieldsRequest={(payload) => {
            metaDataFieldsRequest(payload);
            setTablefields([]);
         }}
         isLoading={
            isDataSourcesLoading ||
            isPreviewQueryLoading ||
            isResultPathLoading ||
            isSchemaJobPollLoading ||
            isMetaQueryLoading
         }
         isMetaDataFieldsLoading={
            isMetaDataFieldsLoading ||
            isPreviewQueryLoading ||
            isResultPathLoading ||
            isSchemaJobPollLoading
         }
         tablefields={tablefields}
         currentPollType={currentPollType}
         refetchDataSources={() => {
            metaDataObjects({
               source_id: currentProject?.configuration?.sourceId,
               filter: {},
            });
         }}
      />
   );
};

export default DatabasesContainer;
