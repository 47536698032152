/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import {
   useDataSources,
   useDeleteDataSource,
} from '../../providers/useDataSources';
import CreateDataSource from './CreateDataSource';
import { useGlobalState } from '../../context/GlobalContext';
import DataSourceList from './DataSourceList';
import Loader from '../../components/Loader';
import { type IDataSource } from '../../providers/models/IDataSource';
import { Alert } from 'antd';

export enum DataSourcePageType {
   LIST,
   CREATE,
   EDIT,
}
const DataSource: React.FC = () => {
   const [dataSourcePageType, setDataSourcePageType] =
      useState<DataSourcePageType>(DataSourcePageType.LIST);
   const [editDataSource, setEditDataSource] = useState<
      IDataSource | undefined
   >();
   const { messageApi } = useGlobalState();
   const {
      data: dataSources,
      isError: isDataSourcesError,
      isFetching: isDataSourcesLoading,
      error: dataSourcesError,
      refetch: refetchDataSource,
   } = useDataSources();

   const {
      mutate: deleteDS,
      data: deleteDSData,
      isPending: isDeleteDSLoading,
      isError: isDeleteDSError,
      error: deleteDSError,
   } = useDeleteDataSource();

   useEffect(() => {
      if (isDataSourcesError) {
         showError(dataSourcesError.message);
      }
   }, [isDataSourcesLoading, dataSources]);

   useEffect(() => {
      if (isDeleteDSError) {
         // showError(deleteDSError.message);
      }
      if (deleteDSData !== null && deleteDSData !== undefined) {
         void refetchDataSource();
      }
   }, [isDeleteDSLoading, deleteDSData]);

   const showError = (message: string): void => {
      void messageApi?.open({
         type: 'error',
         content: message,
      });
   };

   return (
      <div className="w-full overflow-y-auto h-[calc(100vh-0px)] max-h-[calc(100vh-0px)]">
         {(isDataSourcesLoading || isDeleteDSLoading) && (
            <Loader
               description={
                  isDeleteDSLoading ? 'Deleting DataSource' : undefined
               }
            />
         )}

         {dataSourcePageType !== DataSourcePageType.LIST ? (
            <CreateDataSource
               dataSourcePageType={dataSourcePageType}
               setDataSourcePageType={(pageType) => {
                  setDataSourcePageType(pageType);
                  if (
                     pageType.valueOf() === DataSourcePageType.LIST.valueOf()
                  ) {
                     void refetchDataSource();
                  }
               }}
               editDataSource={editDataSource}
            />
         ) : (
            <DataSourceList
               setEditDataSource={setEditDataSource}
               dataSources={dataSources?.results}
               setDataSourcePageType={setDataSourcePageType}
               deleteDS={(dataSourceId) => {
                  deleteDS(dataSourceId);
               }}
               deleteDataSourceMessage={deleteDSError?.message}
            />
         )}
      </div>
   );
};

export default DataSource;
