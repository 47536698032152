import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { Outlet } from 'react-router-dom';
import CustomHeader from '../components/CustomHeader';
import CustomSidebar from '../components/CustomSidebar';

const BaseLayout: React.FC = () => {
   return (
      <Layout className="min-h-svh">
         <CustomSidebar />
         <Layout>
            {/* <CustomHeader /> */}
            <Content className="!bg-default">
               <Outlet />
            </Content>
         </Layout>
      </Layout>
   );
};

export default BaseLayout;
