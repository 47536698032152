import React, { type ReactElement } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Signin from '../pages/signin/Signin';
import BaseLayout from '../layout/BaseLayout';
import PageNotFound from '../pages/pagenotfound/PageNotFound';
import Dashboard from '../pages/dashboard/Dashboard';
import { ProtectedRoute } from './ProtectedRoute';
import Project from '../pages/project/Project';
import Signup from '../pages/signup/Signup';
import ResetPassword from '../pages/resetpassword/ResetPassword';

const AppRoutes = (): ReactElement => {
   const location = useLocation();
   return (
      <Routes>
         <Route path="*" element={<PageNotFound />} />
         <Route path="/signin" element={<Signin />} />
         <Route path="/resetpassword" element={<ResetPassword />} />
         <Route path="/signup" element={<Signup />} />
         <Route path="/" element={<BaseLayout />}>
            <Route
               path="/"
               element={
                  <ProtectedRoute>
                     <Dashboard />
                  </ProtectedRoute>
               }
            />
            <Route
               path="/project/:id"
               element={
                  <ProtectedRoute key={location.pathname}>
                     <Project />
                  </ProtectedRoute>
               }
            />
         </Route>
      </Routes>
   );
};
export default AppRoutes;
